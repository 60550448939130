import { useState } from "react"
import { useHistory } from "react-router"
import { deleteCustomer } from "../../APIfunctionsGeneric/deleteCustomer"
import { deletePoint } from "../../APIfunctionsGeneric/deletePoint"
import { useTranslation } from "../../Contexts"
import { EntityType } from "../../Resources/types"
import { useGlobalAlert } from "../../States/globalAlert"
import { useSiteConfig } from "../../States/siteConfig"
import { ModalContainer } from "../../UI"

type IDeletionModal = {
	entity: EntityType
	id: string
	name: string
	onCancel: () => void
}
// DO not create other copy ->
// TODO: Extend to be universal deletion modal
export const DeletetionModal = ({ id, name, onCancel, entity }: IDeletionModal) => {
	const { trans } = useTranslation()
	const history = useHistory()
	const entityTranslated = trans("deletionModal." + entity)
	const title = trans("deletionModal.title", entityTranslated)
	const question = trans("deletionModal.question", entityTranslated, name)
	const confirmed = trans("deletionModal.confirmed", entityTranslated, name)
	const error = trans("deletionModal.error")
	const { setGlobalAlert } = useGlobalAlert()
	const [loading, setLoading] = useState<boolean>(false)
	const siteConfig = useSiteConfig(state => state.siteConfig)

	const onStartCallBack = () => {
		setLoading(false)
		setGlobalAlert({ type: "success", message: confirmed })
		const path = history.location.pathname.split("/") // TODO: universal entity type
		history.replace(path.slice(0, path.length - 1).join("/"))
		onCancel()
	}
	const onFailCallBack = () => {
		setLoading(false)
		setGlobalAlert({ type: "danger", message: error })
	}

	// TODO: Extend deletion of operator
	const onDelete = () => {
		if (id) {
			setLoading(true)
			if (entity === "containers" || entity === "points")
				deletePoint({
					id,
					onStartCallBack,
					onFailCallBack,
					refetchQuery: siteConfig.points.data.fetchAll,
				})
			else if (entity === "customers")
				deleteCustomer({
					id,
					onStartCallBack,
					onFailCallBack,
					refetchQuery: siteConfig.points.data.fetchAll,
				})
		} else {
			setGlobalAlert({ type: "danger", message: error })
		}
	}

	return (
		<ModalContainer
			width="400px"
			title={title}
			onConfirm={onDelete}
			onConfirmText={"deletionModal.deleteButtonText"}
			onCancel={onCancel}
			loadingConfirm={loading}
		>
			<div>{question}</div>
		</ModalContainer>
	)
}

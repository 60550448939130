import { isArray } from "lodash"
import React from "react"
import { match } from "ts-pattern"
import DateTimeSelect from "../../../Filter/components/DateTimeSelect/DateTimeSelect"
import SearchOnceInput from "../../../Filter/components/SearchOnceInput/SearchOnceInput"
import SearchSelect from "../../../Filter/components/SearchSelect/SearchSelect"
import { IEventsBrowserFilterParams } from "../../configurations/types/types"
import {
	IFilterFetchWithOptions,
	IFilterSearchOnceInputFetch,
	IFilterSearchSelectFetchWithOptions,
} from "./types"

export const getControllers = (
	filters: IFilterFetchWithOptions[],
	paramsFilter: IEventsBrowserFilterParams | null
) =>
	filters.map((f, i) =>
		match(f.control.component)
			.with("DateTimeSelect", () => <DateTimeSelect key={i} filterKey={f.id} />)
			.with("SearchSelect", () => (
				<SearchSelect
					key={i}
					filterKey={f.id}
					placeholder={(f as IFilterSearchSelectFetchWithOptions).control.placeholder}
					options={
						isArray((f as IFilterSearchSelectFetchWithOptions).control.options)
							? (f as IFilterSearchSelectFetchWithOptions).control.options
							: []
					}
					value={f.id !== "time" && paramsFilter ? (paramsFilter as any)[f.id] : undefined}
				/>
			))
			.with("SearchOnceInput", () => (
				<SearchOnceInput
					key={i}
					filterKey={f.id}
					placeholder={(f as IFilterSearchOnceInputFetch).control.placeholder}
				/>
			))
			.exhaustive()
	)

import Fuse from "fuse.js"
import { FC, useEffect, useState, useMemo, SelectHTMLAttributes } from "react"
import { useTranslation } from "../../Contexts"
import { SearchIcon } from "../../Icons/SearchIcon"
import { Spinner } from ".."
import "./styles.css"
import { useContextMenu } from "../ContextMenu"

function createFuse(options: any) {
	return new Fuse(options, {
		shouldSort: true,
		threshold: 0.3,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: ["option"],
	})
}

type TSelectOption = { option: string; value?: string }

export const Select: FC<{
	type?: "basic" | "searchable"
	data: TSelectOption[]
	placeholder?: string
	onChange?: (option: TSelectOption) => any
	onInputChange?: (value: string) => any
	onFocus?: () => any
	onBlur?: () => any
	loading?: boolean
	searchIcon?: boolean
	dropdownIcon?: boolean
	initialValue?: string
	disabled?: boolean
	allowInput?: boolean
	/** Experimental feature */
	useContextDropdown?: boolean
	dropdownStyle?: React.CSSProperties
	error?: boolean
	dontShowNoResults?: boolean
	fontSize?: number | string
	noInitialOption?: boolean
}> = ({
	type = "searchable",
	data,
	placeholder,
	onChange,
	onInputChange,
	onFocus,
	onBlur,
	loading,
	searchIcon,
	dropdownIcon,
	initialValue,
	disabled,
	allowInput,
	useContextDropdown,
	dropdownStyle,
	error,
	dontShowNoResults,
	fontSize,
	noInitialOption,
	children,
}) => {
	const [activeOption, setActiveOption] = useState<TSelectOption>(
		data?.length && !noInitialOption
			? initialValue
				? data.find(o => o.value === initialValue) || data[0]
				: data[0]
			: { option: "" }
	)
	const [selectedValue, setSelectedValue] = useState(activeOption.option)
	const [searchValue, setSearchValue] = useState("")
	const [active, setActive] = useState(false)
	const [onSelectBoundries, setOnSelectBoundries] = useState(false)

	const { closeContextMenu, openContextMenuByPos } = useContextMenu()

	const fuzze = useMemo(() => createFuse(data), [data])

	const { trans } = useTranslation()

	useEffect(() => {
		if (onChange) onChange(activeOption)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOption])

	useEffect(() => {
		if (onInputChange) onInputChange(searchValue)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue])

	const handleFocus = (e: React.FocusEvent) => {
		const { x, y, height } = e.target.getBoundingClientRect()
		useContextDropdown &&
			e &&
			openContextMenuByPos(
				{ x, y: y + height },
				data.map(o => ({
					label: o.option,
					fn: () => onChange && onChange(o),
				}))
			)
		setActive(true)
		setOnSelectBoundries(true)
		if (!allowInput) setSearchValue("")
		if (onFocus) onFocus()
	}

	const handleBlur = () => {
		if (!onSelectBoundries) {
			if (!allowInput) setSearchValue(activeOption.option)
			setActive(false)
		}
		if (onBlur) onBlur()
	}

	const handleOptionClick = (option: TSelectOption) => {
		setSearchValue(allowInput ? option.option : "")
		if (useContextDropdown) closeContextMenu()
		setSelectedValue(option.option)
		setActiveOption(option)
		setActive(false)
	}

	const searchOptions = useMemo(() => {
		if (!searchValue) return data
		return fuzze.search(searchValue).map(result => result.item) as TSelectOption[]
	}, [data, fuzze, searchValue])

	if (type === "basic")
		return (
			<BasicSelect
				data={data}
				disabled={disabled}
				value={selectedValue}
				onChange={e => {
					handleOptionClick(data.find(d => d.option === e.target.value) || data[0])
				}}
			/>
		)

	return (
		<div
			className="select"
			onFocus={e => !disabled && handleFocus(e)}
			onBlur={() => handleBlur()}
			onMouseLeave={() => setOnSelectBoundries(false)}
		>
			<div
				className="search flex-row flex-center-left"
				style={{
					borderColor: error ? "red" : undefined,
				}}
			>
				<input
					value={active || allowInput ? searchValue : selectedValue}
					onChange={e => {
						setSearchValue(e.target.value)
					}}
					placeholder={placeholder}
					disabled={disabled}
					style={{ fontSize }}
				/>
				{loading ? (
					<Spinner height={20} />
				) : searchIcon ? (
					<SearchIcon height="16" width="16" opacity="0.3" />
				) : null}
			</div>
			{active &&
				!!data.length &&
				!useContextDropdown &&
				(searchOptions.length ? (
					<div
						className="dropdown"
						style={dropdownStyle}
						onMouseEnter={() => setOnSelectBoundries(true)}
					>
						{searchOptions.map((option, i) => {
							return (
								<div
									key={`dropdown-option${i}`}
									className="option"
									title={String(option.option)}
									onClick={e => {
										handleOptionClick(option)
									}}
								>
									{option.option}
								</div>
							)
						})}
					</div>
				) : dontShowNoResults ? null : (
					<div
						className="dropdown"
						style={dropdownStyle}
						onMouseEnter={() => setOnSelectBoundries(true)}
					>
						<div className="option">{trans("noResults")}</div>
					</div>
				))}
			{children}
		</div>
	)
}

const BasicSelect: FC<{ data: TSelectOption[] } & SelectHTMLAttributes<HTMLSelectElement>> =
	props => {
		return (
			<select className="BasicSelect" {...props}>
				{props.data.map((d, i) => (
					<option key={`BasicSelect_option${i}`}>{d.option}</option>
				))}
			</select>
		)
	}

import { gql } from "@apollo/client"

export const GET_CUSTOMER_IDENTITIES = gql`
	query GET_CUSTOMER_IDENTITIES($customerId: String) {
		store {
			customers(id: $customerId) {
				id
				name
				identities {
					id
					status
					properties {
						key
						value
					}
					identity {
						id
						externalKeys {
							key
							value
						}
					}
				}
			}
		}
	}
`

export const GET_IDENTITIES = gql`
	query GET_IDENTITIES($key: String) {
		store {
			identities {
				id
				text: externalKey(key: $key)
				customer {
					id
				}
			}
		}
	}
`

export const GET_PARTIAL_IDENTITIES = gql`
	query GET_IDENTITIES($key: String, $value: String) {
		store {
			identities(externalKey: $key, externalKeyValue: $value, useRegex: true) {
				id
				text: externalKey(key: $key)
				customer {
					id
				}
			}
		}
	}
`

// Make sure id is added to query - else it holds a very long query
export const GET_IDENTITY = gql`
	query GET_IDENTITY($id: String) {
		store {
			identities(id: $id) {
				id
				externalKeys {
					key
					value
				}
				customer {
					id
					name
					identities {
						id
						status
						properties {
							key
							value
						}
					}
				}
			}
		}
	}
`

export const ADD_IDENT_TO_CUSTOMER = gql`
	mutation ADD_IDENT_TO_CUSTOMER($customerId: String!, $identId: String!) {
		addCustomerIdentitiesMutation(
			input: { wait: true, payload: { customerId: $customerId, newIdentities: [{ id: $identId }] } }
		) {
			primaryKey
			apiResultCode
			commandProcessError
			newIdentities {
				id
			}
		}
	}
`

export const REMOVE_IDENTS_FROM_CUSTOMER = gql`
	mutation REMOVE_IDENTS_FROM_CUSTOMER($customerId: String!, $identId: String!) {
		removeCustomerIdentitiesMutation(
			input: {
				wait: true
				payload: { customerId: $customerId, removeIdentities: [{ id: $identId }] }
			}
		) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

export const SAVE_CUSTOMER_IDENTS_PROPS = (input: string) => gql`
	mutation SAVE_CUSTOMER_IDENTS_PROPS {
		updateCustomerMutation(
			input: ${input}
		) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

export const SAVE_CUSTOMER_IDENT_PROPS = gql`
	mutation SAVE_CUSTOMER_IDENT_PROPS($input: updateCustomerMutationInput!) {
		updateCustomerMutation(input: $input) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

export const CREATE_NEW_IDENTITY = gql`
	mutation CREATE_NEW_IDENTITY(
		$type: identityType!
		$externalKeys: [KeyValueInput]
		$customer: CustomerSelect
	) {
		addIdentityMutation(
			input: {
				wait: true
				payload: { identity: { type: $type, externalKeys: $externalKeys, customer: $customer } }
			}
		) {
			primaryKey
			commandProcessError
		}
	}
`

const genPropertiesUpdate = ({
	id,
	key,
	value,
}: {
	id: string
	key: string
	value: string | null
}) =>
	`{
		id: "${id}",
		propertiesUpdate: {
			mode: SET,
			props: { key: "${key}", value: "${value || ""}" }
		}
	}`

export const genCustomerIdentsPropsUpdate = (
	customerId: string,
	idents: { id: string; key: string; value: string | null }[]
) =>
	`{
		wait: true
		payload: {
			customerId: "${customerId}",
			updateIdentities: [${idents.map(i => genPropertiesUpdate(i))}]
		}
	}`

import React, { useMemo } from "react"
import { useHistory } from "react-router"
import { useModal, useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import { useFilter } from "../Filter"
import Error from "../_components/Error/Error"
import { useToolbar } from "../_components/Toolbar/Toolbar"
import CustomerModal from "../../Modals/CustomerModal/CustomerModal"
import { useGetCustomers } from "./data"
import Grid from "./Grid"

const Customers = () => {
	const { trans } = useTranslation()
	const { showModal, hideModal } = useModal()
	const { showToolbar, hideToolbar } = useToolbar()
	const { siteConfig } = useSiteConfig()
	const history = useHistory()

	const { headers, filters: filter, onCellClick, columnSizes } = siteConfig.customers

	const { error, data: customers, client } = useGetCustomers()

	const filterSpec = useMemo(() => filter(client, customers), [client, customers, filter])

	const filteredCustomers = useFilter(customers, filterSpec)

	React.useEffect(() => {
		function handleAction(action: string) {
			if (action === "create") {
				showModal(<CustomerModal useHistory={history} customers={customers} />)
			}
		}
		const configModes = siteConfig.customerModal?.modes
		if (configModes?.includes("create") || configModes?.includes("upsert"))
			showToolbar("actions.createCustomer", handleAction)
		return () => hideToolbar()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hideModal, hideToolbar, showModal, showToolbar, siteConfig.customerModal, customers])

	if (error) {
		console.error(error)
		return <Error>{trans("errors.failedFetch")}</Error>
	}

	return (
		<Grid
			customers={filteredCustomers ? filteredCustomers : []}
			headers={headers}
			onCellClick={onCellClick} // TODO: do something with this
			columnSizes={columnSizes}
		/>
	)
}

export default Customers

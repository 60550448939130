import { get } from "lodash"
import React, { useMemo, useState } from "react"
import { AutoSizer, Column, Table } from "react-virtualized"
import { useTranslation } from "../../Contexts/Translation"
import { IColumnSizes, IHeader, IRow } from "../../Resources/types"
import { monad } from "../../Utils/fs"
import { Checkbox } from "../Inputs"
import styles from "./DataGridEditable.module.css"
import { calculateTableStyle, defaultSorter, onCheckboxChange, sortByHeader } from "./functions"
import { MultiSelect } from "./MultiSelect"

export type IDataGridEditable = {
	gridRef?: React.MutableRefObject<null>
	columns: IHeader[]
	rows: IRow[]
	columnSizes?: IColumnSizes
	rowHeight?: number
	rowClassname?: string
	checkboxes?: boolean
	border?: boolean
	disableSort?: boolean
	cellRender?: ({
		id,
		cellContent,
		rowIndex,
	}: {
		id: string
		column: string
		cellContent: any
		rowContent: IRow
		rowIndex: number
	}) => JSX.Element
}

export const DataGridEditable: React.FC<IDataGridEditable> = ({
	columns,
	rows,
	columnSizes = {},
	rowHeight,
	rowClassname,
	checkboxes: hasCheckboxes,
	border,
	disableSort,
	cellRender,
	gridRef,
}) => {
	const [checked, setChecked] = useState<string[]>([])
	const [vScrollbarSize, setVScrollbarSize] = useState(0)
	const { trans } = useTranslation()

	const [sorter, setSorter] = useState({
		sortFunc: defaultSorter,
		descending: false,
		column: "",
	})

	const sortedRows = useMemo(() => sorter.sortFunc(rows), [sorter, rows])

	return (
		<>
			<MultiSelect
				{...{
					checked,
					setChecked,
					rows,
					elems: sortedRows,
					active: !!hasCheckboxes,
				}}
			/>
			{sortedRows ? (
				<AutoSizer>
					{({ width, height }) => (
						<Table
							className={`${styles.dataGridEditable}`}
							gridStyle={{ border: border ? "var(--border)" : undefined }}
							ref={gridRef}
							headerRowRenderer={() => (
								<div
									className={`${styles.headerTr}`}
									style={{
										...calculateTableStyle(columns, columnSizes, hasCheckboxes),
										width: width - vScrollbarSize,
									}}
								>
									{hasCheckboxes && (
										<div
											key={`dataGridEditable_main_headers_checkbox`}
											className={`${styles.headerTh}`}
										></div>
									)}
									{columns.map((column, i) => (
										<div
											key={`dataGridEditable_main_headers_` + i}
											onClick={!disableSort ? sortByHeader(column, sorter, setSorter) : undefined}
											className={`${styles.headerTh}`}
										>
											<div className={styles.innerCell}>
												{column && column !== "actions" && trans(`headers.${column}`)}
											</div>
										</div>
									))}
								</div>
							)}
							headerHeight={50}
							height={height || 200}
							rowCount={sortedRows.length}
							rowGetter={index => sortedRows[index.index]}
							rowHeight={rowHeight || 45}
							rowClassName={rowClassname || styles.bodyTr}
							rowStyle={{
								...calculateTableStyle(columns, columnSizes, hasCheckboxes),
							}}
							width={width}
							onScrollbarPresenceChange={({ vertical, size }) => {
								setVScrollbarSize(vertical ? size : 0)
							}}
						>
							{hasCheckboxes && (
								<Column
									key={`dataGridEditable_columns_checkbox`}
									width={50}
									dataKey={"checkbox"}
									style={{
										display: "flex",
										alignItems: "center",
									}}
									cellRenderer={({
										rowData,
										rowIndex,
									}: {
										rowData: { id: string } & { [key: string]: any }
										rowIndex: number
									}) => (
										<div
											key={`dataGridEditable_body_checkbox` + rowIndex}
											aria-colindex={rowIndex}
											role="gridcell"
											className={`${styles.bodyTd}`}
										>
											<div className={styles.innerCell}>
												{
													<label key={rowIndex}>
														<Checkbox
															checked={checked.includes(rowData.id)}
															onChange={() => setChecked(onCheckboxChange(rowData.id, checked))}
														></Checkbox>
													</label>
												}
											</div>
										</div>
									)}
								/>
							)}
							{columns.map((column, i) => (
								<Column
									key={`dataGridEditable_body_` + column + i}
									width={250}
									dataKey={column}
									cellRenderer={({
										rowData,
										rowIndex,
									}: {
										rowData: { id: string } & { [key: string]: any }
										rowIndex: number
									}) =>
										monad(column in rowData ? rowData[column] : null).map(cellContent => (
											<div
												key={`dataGridEditable_columns_` + column + rowIndex + "_" + i}
												aria-colindex={i + 1}
												role="gridcell"
												className={`${styles.bodyTd}`}
											>
												{cellRender ? (
													<div className={styles.innerCell} style={{ height: rowHeight || 45 }}>
														{cellRender({
															id: rowData.id,
															column,
															cellContent,
															rowContent: rowData,
															rowIndex,
														})}
													</div>
												) : (
													<div className={styles.innerCell}>
														<span>{get(rowData, column, `KEY ${column} NOT FOUND`)}</span>
													</div>
												)}
											</div>
										))[0]
									}
								/>
							))}
						</Table>
					)}
				</AutoSizer>
			) : (
				<></>
			)}
		</>
	)
}

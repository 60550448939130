import { DocumentNode } from "apollo-link"
import { postFunc } from "../Utils/api"
import { DELETE_POINT } from "./mutations/deletePoint"

export const deletePoint = ({
	id,
	refetchQuery,
	onStartCallBack,
	onFailCallBack,
}: {
	id: string
	refetchQuery?: DocumentNode
	onStartCallBack?: () => void
	onFailCallBack?: () => void
}) => {
	postFunc(
		DELETE_POINT,
		() => {
			onStartCallBack && onStartCallBack()
		},
		{ variables: { Id: id }, refetchQueries: refetchQuery ? [{ query: refetchQuery }] : [] },
		() => {
			onFailCallBack && onFailCallBack()
		}
	)
}

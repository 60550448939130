import create from "zustand"

export type IGlobalAlert = {
	type: "warning" | "success" | "danger" | "info"
	message: string
}

type IUseGlobalAlert = {
	globalAlert: IGlobalAlert | null
	setGlobalAlert: (_ga: IGlobalAlert | null) => void
}

export const useGlobalAlert = create<IUseGlobalAlert>((set) => ({
	globalAlert: null,
	setGlobalAlert: (_ga) => set(() => ({ globalAlert: _ga })),
}))

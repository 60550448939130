import { cloneDeep } from "lodash"
import moment from "moment"
import { keyValueArrayToObject } from "../../../../Utils/objArr"
import { IEvent, IEventModified, IIdentity } from "../../types"
import { IEventByCustomerId } from "./types"

export function getIdentity(customer: IEventByCustomerId, event: IEvent) {
	const identityId = event.identityId
	if (identityId) {
		const identities = customer.identities?.map(i => i.identity)
		const idents = identities?.find(i => i && i.id === identityId)
		return (
			idents?.externalKeys?.reduce(
				(pi, ci) => ({ ...pi, ...{ [ci.key]: ci.value } }),
				{} as { [key: string]: string }
			) ?? null
		)
	}
	return null
}

export function getIdentities(customer: IEventByCustomerId) {
	const identities = customer.identities?.map(i => i.identity?.externalKeys)
	return identities
		?.map(
			is =>
				is?.reduce(
					(pi, ci) => ({ ...pi, ...{ [ci.key]: ci.value } }),
					{} as { [key: string]: string }
				) ?? null
		)
		.filter(is => is) as IIdentity[] | null
}

export const normalize = (
	customers: IEventByCustomerId[],
	currentLocale: any
): IEventModified[] => {
	const _customers = cloneDeep(customers)
	return _customers.length
		? _customers.flatMap(_customer =>
				_customer.events
					? _customer.events.map(_event => ({
							id: _event.id,
							type: _event.type,
							timestamp: moment(_event.timestamp).locale(currentLocale).format("lll"),
							weight: _event.weight,
							weightUnit: _event.weightUnit,
							fraction: _event.fraction,
							properties: keyValueArrayToObject(_event.properties),
							result: _event.result,
							ts: _event.timestamp,
							customer: {
								id: _customer.id,
								name: _customer.name,
								externalKeys: keyValueArrayToObject(_customer.externalKeys),
							},
							point: _event?.point?.point
								? {
										..._event.point.point,
										properties: keyValueArrayToObject(_event.point.point.properties),
										externalKeys: keyValueArrayToObject(_event.point.point.externalKeys),
								  }
								: null,
							identity: getIdentity(_customer, _event),
							identities: getIdentities(_customer),
					  }))
					: []
		  )
		: []
}

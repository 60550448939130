import { useLazyQuery, useQuery } from "@apollo/client"
import React, { useCallback, useEffect, useMemo } from "react"
import { useHistory } from "react-router"
import { DUMMY_QUERY } from "../../APIfunctionsGeneric/queries/dummyQuery"
import { useModal } from "../../Contexts"
import { useQueryParam } from "../../domHooks"
import { useSiteConfig } from "../../States/siteConfig"
import { useFilter } from "../Filter"
import { IFilterSpec } from "../types"
import { useToolbar } from "../_components/Toolbar/Toolbar"
import Grid from "./Grid"
import Map from "../_components/Map/Map"
import { IAccessPoint, IAccessPointData } from "./types"
import StationConfig from "../../Modals/StationModal/StationConfigModal"

const Points = () => {
	const { siteConfig } = useSiteConfig()
	const [mode] = useQueryParam("mode", "list")
	const { showToolbar, hideToolbar } = useToolbar()
	const { showModal, hideModal } = useModal()
	const [queryFilters] = useQueryParam("filter", {})
	const history = useHistory()
	const activeFilters = queryFilters as {
		fillLevel: string
		fraction: [
			{
				label: string
				value: string
			}
		]
	}

	const config = siteConfig.points

	const GET_ACCESS_POINTS_QUERY = siteConfig.points.data.fetchAll
	const POST_GET_ACCESS_POINTS_QUERY = siteConfig.points.data.postFetchAll

	const { client, loading, data } = useQuery<IAccessPointData>(GET_ACCESS_POINTS_QUERY, {
		errorPolicy: "all",
	})

	const [postFetch, { loading: streamedLoading, data: streamedData }] =
		useLazyQuery<IAccessPointData>(POST_GET_ACCESS_POINTS_QUERY || DUMMY_QUERY, {}) // Using dummy query indead of gql`` because it crashes apollo

	useEffect(() => {
		if (POST_GET_ACCESS_POINTS_QUERY && !loading) postFetch()
	}, [POST_GET_ACCESS_POINTS_QUERY, loading, postFetch])

	const recurMerge = useCallback(
		(origin: IAccessPoint[], source: IAccessPoint[] | undefined): IAccessPoint[] => {
			if (!source) return origin
			return origin.map(o => {
				const src = source.find(s => s.id === o.id)
				return { ...o, ...src, points: recurMerge(o?.points || [], src?.points || []) }
			})
		},
		[]
	)

	const parsedData = useMemo(
		() =>
			streamedLoading
				? data
				: {
						store: {
							data: recurMerge(data?.store.data || [], streamedData?.store.data),
						},
				  },
		[data, recurMerge, streamedData?.store.data, streamedLoading]
	)

	const points = useMemo(
		() => (parsedData ? siteConfig.points.data.fetchAllParse(parsedData) : []),
		[parsedData, siteConfig.points.data]
	)

	const headers = useMemo(() => config.headers, [config.headers])

	const filterSpec: IFilterSpec = useMemo(
		() => config.filters(client, points),
		[client, config, points]
	)

	const filteredPoints = useFilter(points, filterSpec)

	useEffect(() => {
		function handleAction(action: string) {
			if (action === "create") {
				showModal(<StationConfig useHistory={history} />)
			}
		}
		if (siteConfig.stationModal && siteConfig.stationModal?.modes?.includes("create"))
			showToolbar("actions.createAllocation", handleAction)
		return () => hideToolbar()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hideModal, hideToolbar, showModal, showToolbar, siteConfig.stationModal])

	if (loading) {
		return <></>
	}

	// TODO: Error handling? there's a bunch of them in the mock data
	//  if (error) {
	//    console.log('got errors', error)
	//  }

	if (mode === "map") {
		return <Map points={filteredPoints} />
	}

	return (
		<Grid
			points={filteredPoints}
			headers={headers}
			pointCategory={"points"}
			activeFilters={activeFilters}
		/>
	)
}

export default Points

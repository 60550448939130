import React, { useMemo } from "react"
import { useModal, useTranslation } from "../../Contexts"
import { useQueryParam } from "../../domHooks"
import { CreateAccessPointModal } from "../../Modals/CreateAccessPointModal/CreateAccessPointModal"
import { useSiteConfig } from "../../States/siteConfig"
import { useFilter } from "../Filter"
import Error from "../_components/Error/Error"
import Map from "../_components/Map/Map"
import { useToolbar } from "../_components/Toolbar/Toolbar"
import { useGetContainers } from "./data"
import Grid from "./Grid"

export const Containers = () => {
	const { trans } = useTranslation()
	const { hideToolbar, showToolbar } = useToolbar()
	const { showModal } = useModal()
	const { siteConfig } = useSiteConfig()

	const [mode] = useQueryParam("mode", "list")

	const { headers, filters: filter, columnSizes } = siteConfig.containers // TODO: dataParser = undefined

	const { error, data: containers, client } = useGetContainers()

	const filterSpec = useMemo(() => filter(client, containers), [client, containers, filter])

	const filteredContainers = useFilter(containers, filterSpec)

	React.useEffect(() => {
		function handleAction(action: string) {
			if (action === "create") {
				showModal(<CreateAccessPointModal />)
			}
		}
		if (siteConfig.accessPointModal?.enable) showToolbar("", handleAction)
		return () => hideToolbar()
	}, [hideToolbar, showModal, showToolbar, siteConfig.accessPointModal?.enable])

	React.useEffect(() => {
		return () => hideToolbar()
	}, [hideToolbar])

	if (error) {
		console.error(error)
		return <Error>{trans("errors.failedFetch")}</Error>
	}

	if (mode === "map") {
		return <Map points={filteredContainers || []} />
	}

	return (
		<Grid
			accessPoints={filteredContainers ? filteredContainers : []}
			headers={headers}
			columnSizes={columnSizes}
		/>
	)
}

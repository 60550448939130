/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react"
import { isEqual, get, find } from "lodash"
import * as L from "leaflet"
import { useHistory, useRouteMatch } from "react-router-dom"

import "./Map.css"
import { useModal } from "../../../Contexts"
import { useQueryParam } from "../../../domHooks"
import { useSiteConfig } from "../../../States/siteConfig"
import StationConfig from "../../../Modals/StationModal/StationConfigModal"

function initMap(elem: any, createStationPopup: boolean, popupOnClickFun?: any) {
	var map = L.map(elem, {
		center: [60.397076, 5.324383],
		zoomControl: false,
		zoom: 15,
	})

	createStationPopup &&
		map.on("click", (e: any) => {
			const pos = e.latlng
			const popup = L.popup({ closeButton: true, autoPan: true })
				.setLatLng(pos)
				.setContent(NewStationPopup(`${pos.lat},${pos.lng}`, popupOnClickFun))
			map.openPopup(popup).panTo(pos)
		})

	elem._map = map

	L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", {
		attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
	}).addTo(map)
}

const NewStationPopup = (location: string, popupOnClickFun?: any) => {
	const popupHTML = window.document.createElement("div")
	popupHTML.className = "popup stationLink"
	popupHTML.innerHTML = "Create a new station here"
	if (popupOnClickFun) popupHTML.onclick = () => popupOnClickFun(location)
	return popupHTML
}

class PointMarker extends L.CircleMarker {
	point: any

	constructor(latLng: L.LatLngExpression, point: any, options?: L.CircleMarkerOptions) {
		super(latLng, options)
		this.point = point
	}

	getPoint() {
		return this.point
	}
}

function addPoints(
	elem: any,
	points: any[],
	history: any,
	highlight: any,
	urlMatch: "points" | "containers"
) {
	const map = elem._map
	const oldMarkers = elem._markers
	if (oldMarkers) {
		oldMarkers.clearLayers()
	}

	const markers = L.featureGroup()

	// SVG uses painters algorithm, ie: later shapes are drawn on top of former.
	// Sort the points array so the point we want to highlight is last and
	// so appears on top.
	const highlightLast = [...points]
	highlightLast.sort((p: any) => (p.id === highlight.id ? 1 : 0))

	const highlightInstallation = get(highlight, "installation.id")
	highlightLast.forEach((p: any) => {
		const pos = p.pos
		if (!pos) return

		const classNames = ["point"]
		const pointInstallation = get(p, "installation.id")

		if (highlightInstallation && highlightInstallation === pointInstallation) {
			classNames.push("peer")
		}

		if (highlight.id && highlight.id === p.id) {
			classNames.push("highlight")
		}

		const marker: any = new PointMarker(pos, p, {
			radius: 8,
			className: classNames.join(" "),
		}).on("click", e => {
			L.DomEvent.stopPropagation(e)
			Object.values((markers as any)._layers).forEach((m: any) => {
				m._path.classList.remove("highlight")
				const otherInstallation = get(m.getPoint(), "installation.id")
				if (otherInstallation && otherInstallation === pointInstallation) {
					m._path.classList.add("peer")
				} else {
					m._path.classList.remove("peer")
				}
			})

			marker._path.classList.add("highlight")
			marker.bringToFront()

			map.panTo({ lat: pos.lat, lon: pos.lon })
			history.replace({
				pathname:
					urlMatch === "points" ? `/resources/points/${p.id}` : `/resources/containers/${p.id}`,
				search: history.location.search,
			})
		})

		markers.addLayer(marker)
	})

	map.addLayer(markers)
	const bounds: any = markers.getBounds()

	// if valid bounds
	if (bounds._northEast) {
		if (!elem._markers) {
			map.fitBounds(markers.getBounds())
		} else {
			map.flyToBounds(markers.getBounds(), { duration: 0.25 })
		}
		elem._markers = markers
	}
}

const Map = (props: any) => {
	const { points } = props
	const { siteConfig } = useSiteConfig()
	const mapEl = useRef(null)
	const history = useHistory()
	const [renderedPoints, setRenderedPoints] = useState<any>([])
	const match = useRouteMatch("/resources/:type/:id")
	const matchPoints = useRouteMatch("/resources/points")
	const highlight = get(match, "params.id", null)

	const { showModal } = useModal()
	const [createStation] = useQueryParam("createStation", null)

	useEffect(() => {
		const newParams = decodeURI(history.location.search).replace(
			/&createStation="(\d+\.\d+,\d+\.\d+)"/g,
			""
		)
		history.replace(newParams)
		const createStationPopup =
			(siteConfig.stationModal && siteConfig.stationModal?.modes?.includes("create")) || false
		initMap(mapEl.current, createStationPopup, popupOnClickFun)
	}, [])

	useEffect(() => {
		createStation &&
			showModal(
				<StationConfig
					location={createStation}
					onClose={() => {
						const newHistory = decodeURI(history.location.search).replace(
							/&createStation="(\d+\.\d+,\d+\.\d+)"/g,
							""
						)
						history.replace(newHistory)
					}}
				/>
			)
	}, [createStation])

	const popupOnClickFun = (location: string) => {
		const params = history.location.search
		const newParams =
			decodeURI(params).replace(/&createStation="(\d+\.\d+,\d+\.\d+)"/g, "") +
			`&createStation="${location}"`
		history.replace(newParams)
	}

	useEffect(() => {
		const ids = points.map((p: any) => p.id)
		if (!isEqual(renderedPoints, ids)) {
			addPoints(
				mapEl.current,
				points,
				history,
				find(points, { id: highlight }) || {},
				matchPoints ? "points" : "containers"
			)
			setRenderedPoints(ids)
		}
	}, [points])

	return <div ref={mapEl} id="resource-map"></div>
}

export default Map

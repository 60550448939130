import { useQuery } from "@apollo/client"
import { cloneDeep, get } from "lodash"
import { nanoid } from "nanoid"
import { useState } from "react"
import { upsertAccessPoints } from "../../APIfunctionsGeneric/upsertAccessPoint"
import { GET_ALL_STATIONS } from "../../APIfunctionsGeneric/queries/getStations"
import { useModal, useTranslation } from "../../Contexts"
import { SvgDelete } from "../../Icons/Delete"
import { useSiteConfig } from "../../States/siteConfig"
import { Button, ModalContainer } from "../../UI"
import { ModalDropdown } from "../_components/dropdown"
import {
	GeneratorStateValues,
	InputKeyValConfigList,
	ModalFieldGenerator,
} from "../_components/fieldGenerator"
import { ModalLabel } from "../_components/label"
import { AccessPointStatus } from "../../Utils/gqlRequestTypes/generic"
import { ModalInput } from "../_components/input"

export type accessPointModalConfig = {
	enable: boolean
	width?: string
	// stationExternalKeyName?: string
	fieldList: InputKeyValConfigList
	defaultStatus?: AccessPointStatus
	singleContainer?: boolean
} & (
	| {
			method: "add" // TODO: implement createAccessPointMutation
			containerExternalKeyName?: undefined
			containerExternalKeyValueGenerator?: undefined
	  }
	| {
			method: "upsert"
			containerExternalKeyName: string
			containerExternalKeyValueGenerator?: (data: {
				station: { id: string; name: string; [prop: string]: any }
			}) => string
	  }
)
interface ICreateAccessPointModalProps {
	initialStationId?: string
	refetchQueries?: Parameters<typeof upsertAccessPoints>[number]["refetchQueries"]
}

export const CreateAccessPointModal = ({
	initialStationId,
	refetchQueries,
}: ICreateAccessPointModalProps) => {
	const { hideModal } = useModal()
	const { trans } = useTranslation()
	const siteConfig = useSiteConfig().siteConfig
	const config = siteConfig.accessPointModal

	const { data, loading } = useQuery(GET_ALL_STATIONS(['externalKey(key: "ImportID")']), {
		fetchPolicy: "no-cache",
	})
	const stations = get<{ id: string; name: string }[]>(data, "store.accessPoints", [])
	const stationList = stations.map(({ id, name }) => ({ value: id, name }))

	const initialRow =
		config?.fieldList.reduce((prev, input) => {
			if (input.inputType === "multiValue") return prev
			return {
				...prev,
				[input.key]: {
					type: input.keyType,
					value: input.defaultValue || "",
				},
			}
		}, {} as { [key: string]: { type: "property" | "externalKey" | "name" | "status"; value: string } }) ||
		{}

	const [stationId, setStationId] = useState(initialStationId || "")
	const [containerName, setContainerName] = useState<string>()
	const [containerData, setContainerData] = useState({ [nanoid()]: initialRow })
	const [requiredSet, setRequiredSet] = useState<{ [uid: string]: boolean }>({})

	const addRow = () => setContainerData({ ...containerData, [nanoid()]: initialRow })

	const removeRow = (id: string) => {
		const newData = cloneDeep(containerData)
		delete newData[id]
		setContainerData(newData)
		setRequiredSet(
			Object.entries(requiredSet).reduce(
				(prev, [key, val]) => (key === id ? { ...prev } : { ...prev, [key]: val }),
				{}
			)
		)
	}

	const onDataUpdate = (data: GeneratorStateValues, id: string, allRequiredSet: boolean) => {
		const newData = cloneDeep(containerData)
		setContainerData({ ...newData, [id]: data })
		setRequiredSet({ ...requiredSet, [id]: allRequiredSet })
	}

	const onConfirm = () => {
		const station = stations.find(s => s.id === stationId)
		const getContainerId = () =>
			config?.containerExternalKeyValueGenerator && station
				? config?.containerExternalKeyValueGenerator({ station }) || ""
				: ""
		upsertAccessPoints({
			accessPoints: Object.values(containerData).map(container => {
				const statusList = Object.entries(container).filter(([_, entry]) => entry.type === "status")
				const status =
					(statusList.length && (statusList[0][1].value as AccessPointStatus)) || undefined
				const name = Object.entries(container)
					.filter(([_, entry]) => entry.type === "name")
					.reduce((prev, [_, { value }]) => value, "")
				return {
					externalKeyName: config?.containerExternalKeyName!,
					id: getContainerId(),
					updateFields: {
						name: name || containerName || "",
						type: "ACCESS_POINT",
						status: status || config?.defaultStatus || "ACTIVE",
					},
					propertiesUpdate: {
						mode: "SET",
						props:
							Object.entries(container)
								.filter(([_, entry]) => entry.type === "property")
								.map(([key, entry]) => ({ key, value: entry.value })) || [],
					},
					parent: {
						id: stationId,
						// externalKeyName: config?.stationExternalKeyName || "",
					},
				}
			}),
			refetchQueries: [
				// ...(siteConfig?.containers?.data?.fetchAll
				// 	? [{ query: siteConfig.containers.data.fetchAll }]
				// 	: []),
				{ query: siteConfig.containers.data.fetchAll },
				...(refetchQueries ? refetchQueries : []),
			],
			onStartCallBack: () => hideModal(),
		})
	}

	return (
		<ModalContainer
			width={config?.width || "550px"}
			minHeight="300px"
			// maxHeight="95%"
			onCancel={() => hideModal()}
			title={trans("stationModal.titleAccessPoint")}
			onConfirm={() => {
				console.log(containerData)
				onConfirm()
			}}
			loading={loading}
			onConfirmDisabled={!stationId || Object.values(requiredSet).some(set => !set)}
		>
			<ModalLabel label={trans("customerModal.addStation")} />
			<ModalDropdown
				placeholder="selectStation"
				options={stationList}
				onSelect={({ value }) => setStationId(value)}
				defaultValue={stationList.find(station => station.value === initialStationId)}
				error={!stationId}
			/>
			<p
				style={{
					fontSize: "12px",
					margin: "0.5rem 0",
				}}
			>
				<i>{trans("hints.ifNoStation")}</i>
			</p>
			<ModalLabel
				label={trans(siteConfig.accessPointModal?.singleContainer ? "container" : "containers")}
			/>
			{siteConfig.accessPointModal?.singleContainer ? (
				<ModalInput
					placeholder={trans("headers.name")}
					value={containerName || ""}
					onChange={ev => setContainerName(ev.target.value)}
					error={!containerName}
				/>
			) : (
				<></>
			)}
			{Object.entries(containerData).map(([uid]) => (
				<div
					key={`containerRow-${uid}`}
					className={`flex-${config?.singleContainer ? "col" : "row"} col-gap-1`}
				>
					{config?.fieldList && (
						<ModalFieldGenerator
							key={`containerFields-${uid}`}
							inputs={config.fieldList}
							onChange={(data, allRequiredSet) => onDataUpdate(data, uid, allRequiredSet)}
						/>
					)}
					{Object.entries(containerData).length > 1 && (
						<div>
							<SvgDelete
								className="pointer"
								height="36px"
								width="14px"
								color="var(--black)"
								onClick={() => removeRow(uid)}
							/>
						</div>
					)}
				</div>
			))}
			{siteConfig.accessPointModal?.singleContainer ? (
				<></>
			) : (
				<div className="w100 flex-row flex-center-right">
					<Button onClick={() => addRow()}>{"actions.addNew"}</Button>
				</div>
			)}
		</ModalContainer>
	)
}

import { gql } from "apollo-boost"

export const GET_CUSTOMER_EXT_KEYS_AND_PROPS = gql`
	query ACGetCustomerExtKeysAndProps($id: String) {
		store {
			customers(id: $id) {
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
			}
		}
	}
`

export const GET_POINT_EXT_KEYS_AND_PROPS = gql`
	query ACGetPointExtKeysAndProps($id: String) {
		store {
			accessPoints(id: $id) {
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
			}
		}
	}
`

export const UPDATE_CUSTOMER_EXT_KEYS = gql`
	mutation ACUpdateCustomerExtKeys($id: String!, $array: [KeyValueInput]!) {
		updateCustomerMutation(
			input: {
				payload: {
					customerId: $id
					externalKeysUpdate: { mode: SET, props: $array }
				}
				wait: true
				failOnError: true
			}
		) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

export const UPDATE_CUSTOMER_PROPERTIES = gql`
	mutation ACUpdateCustomerProperties($id: String!, $array: [KeyValueInput]!) {
		updateCustomerMutation(
			input: {
				payload: {
					customerId: $id
					propertiesUpdate: { mode: SET, props: $array }
				}
				wait: true
				failOnError: true
			}
		) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

export const UPDATE_POINT_EXT_KEYS = gql`
	mutation ACUpdatePointExtKeys($id: String!, $array: [KeyValueInput]!) {
		updateAccessPointMutation(
			input: {
				payload: {
					accessPoint: { id: $id }
					externalKeysUpdate: { mode: SET, props: $array }
				}
				wait: true
				failOnError: true
			}
		) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

export const UPDATE_POINT_PROPERTIES = gql`
	mutation ACUpdatePointProperties($id: String!, $array: [KeyValueInput]!) {
		updateAccessPointMutation(
			input: {
				payload: {
					accessPoint: { id: $id }
					propertiesUpdate: { mode: SET, props: $array }
				}
				wait: true
				failOnError: true
			}
		) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

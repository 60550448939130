import { gql } from "apollo-boost"

export const ihm_fetcAll_points = ({
	fillLevel,
	essentials = true,
}: {
	essentials?: boolean
	fillLevel?: boolean
}) => gql`
	fragment IHMdata on accessPointExtended {
		id
		${
			essentials
				? `name
		description
		status
		pos: geoLocation {
			lat: latitude
			lon: longitude
		}
		totalCustomers: property(key: "totalCustomers")
		terminal: ancestor(type: "TERMINAL") {id name}
		customers {
			name
			id
		}
		properties {
			key value
		}`
				: ""
		}
		accessPoints: children {
			id
			properties { key value }
			${
				fillLevel
					? `streamed {
						fillLevel: property(key: "fillLevel")
				versionSpec: property(key: "_versionSpec_fillLevel")
						}`
					: ""
			}
		}
		__typename
	}
	query ACGetAccessPointsIHM {
		store {
			data: accessPoints(type: "ACCESS_PARENT") {
				...IHMdata
			}
		}
	}
`

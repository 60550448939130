import { get } from "lodash"
import React from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "../../Contexts"
import { FillLevel } from "../../UI"
import { FillLevelCircle } from "../../UI/FillLevel/FillLevelCircle"
import { isNumber, sortDescByField } from "../../Utils/general"
import { round } from "../../Utils/numbers"
import exportCSV from "../exportCSV"
import { IListViewConfig } from "../types"
import DataGrid from "../_components/DataGrid/DataGrid"
import styles from "./index.module.css"
import { IAccessPointModified } from "./types"

function csvOptions(headers: IListViewConfig["headers"], trans: any) {
	const filename = `${trans("points")}.csv`
	const fields = headers.map(h => ({
		label: trans(h.name ? h.name : `headers.${h.path}`),
		value: h.path,
	}))

	return { filename, fields }
}

const getFractionName = (trans: any) => (point: any) => {
	const fraction = get(point, "properties.fraction", {})
	if (!fraction) {
		return ""
	}
	const f = trans(`fraction.${fraction}`)
	return f ? f : fraction
}

const Grid = ({
	points,
	headers,
	pointCategory,
	activeFilters,
}: {
	points: any[]
	headers: IListViewConfig["headers"]
	pointCategory: "containers" | "points"
	activeFilters: { fillLevel: string; fraction: { label: string; value: string }[] }
}) => {
	const history = useHistory()
	const { trans } = useTranslation()
	const filterFillLevel =
		activeFilters.fillLevel && isNumber(activeFilters.fillLevel)
			? parseFloat(activeFilters.fillLevel)
			: null
	const filterFractions = activeFilters.fraction?.length ? activeFilters.fraction : null

	const actions = [
		{
			label: "actions.exportCsv",
			func: exportCSV(csvOptions(headers, trans)),
		},
	]

	const overrides = {
		"properties.fraction": getFractionName(trans),
		fillLevel: (point: any) => {
			const _point: IAccessPointModified = point
			return (
				<div className={styles.fillLevelCircleWrapper}>
					{/* FILL LEVEL INDICATORS

							If filtering by fill level and/or fractions:
							- Show all containers as filled circles with colored fill level
							- Filter by fill level and fractions
							
							If not filtering
							- Show only the container with the highest fill level as level meter
							- Filter only by fill level
					*/}
					{filterFractions || filterFillLevel
						? [_point.fillLevels]
								.map(fls =>
									filterFillLevel ? fls.filter(fl => (fl?.fillLevel || 0) >= filterFillLevel) : fls
								)
								.map(fls =>
									filterFractions
										? fls.filter(fl => filterFractions.find(ff => ff.value === fl.fraction))
										: fls
								)[0]
								.sort(sortDescByField("fillLevel"))
								.map((fl, i) =>
									fl ? (
										<span
											key={`pointFillLevel_` + i}
											title={`${fl.fractionName}: ${round(fl?.fillLevel || 0)}%`}
										>
											<FillLevelCircle fillLevel={fl?.fillLevel || 0} size={7} />
										</span>
									) : (
										<></>
									)
								)
						: [
								[_point.fillLevels]
									.map(fls =>
										filterFillLevel
											? fls.filter(fl => (fl?.fillLevel || 0) >= filterFillLevel)
											: fls
									)[0]
									.sort(sortDescByField("fillLevel"))[0],
						  ].map((fl, i) =>
								fl ? (
									<div
										key={`pointFillLevel_` + i}
										title={`${fl.fractionName}: ${round(fl?.fillLevel || 0)}%`}
									>
										<FillLevel value={fl.fillLevel} />
									</div>
								) : (
									<></>
								)
						  )[0]}
				</div>
			)
		},
	}

	return (
		<DataGrid
			headers={headers}
			entityType={"points"}
			rows={points}
			actions={actions}
			overrides={overrides}
			allowEmptyField
			onRowClick={(point: any) =>
				history.replace({
					pathname:
						pointCategory === "points"
							? `/resources/points/${point.id}`
							: `/resources/containers/${point.id}`,
					search: history.location.search,
				})
			}
		/>
	)
}

export default Grid

import { get } from "lodash"
import React from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import { stringifyQuery } from "../../domHooks"
import { EntityType } from "../../Resources/types"
import { useSiteConfig } from "../../States/siteConfig"
import "./Drawer.css"
import { DrawerContent } from "./DrawerContent"

export const Drawer: React.FC = () => {
	const matcher = useRouteMatch("/resources/:type/:id")
	const history = useHistory()

	const { siteConfig } = useSiteConfig()

	const id = get(matcher, ["params", "id"], null)
	const type = get(matcher, ["params", "type"], null) as EntityType | null

	const closeDrawer =
		id && type
			? () =>
					history.replace({
						pathname: `/resources/${type}`,
						search: stringifyQuery({}),
					})
			: () => null

	let style = {
		transform: id ? "translate(0px)" : "translate(550px)",
		userSelect: id ? "auto" : "none",
	} as React.CSSProperties

	return (
		<div className="drawer" style={style}>
			<svg
				onClick={closeDrawer}
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z" />
			</svg>

			<Switch>
				<Route path="/resources/:entity/:id" exact>
					{type && siteConfig[type]?.drawer?.ui && siteConfig[type].drawer.ui !== "disabled" && (
						<DrawerContent />
					)}
				</Route>
			</Switch>
		</div>
	)
}

import { get } from "lodash"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import { useTranslation } from "../Contexts"
import { stringifyQuery, useQueryParam } from "../domHooks"
import { useSiteConfig } from "../States/siteConfig"
import { Drawer } from "../UI/Drawer"
import Allocations from "./Allocations"
import { Containers } from "./Containers"
import Customers from "./Customers"
import Events from "./Events"
import "./index.css"
import Invoices from "./Invoices"
import Operators from "./Operators"
import Points from "./Points"
import RecyclingStationVisits from "./RecyclingStationVisits"
import { PaginationProvider, Paginator } from "./_components/Pagination/Pagination"
import { Toolbar, ToolbarProvider } from "./_components/Toolbar/Toolbar"

const List = () => {
	const match = useRouteMatch()
	const typeMatch = useRouteMatch(`${match.url}/:type`)
	const idMatch = useRouteMatch(`${match.url}/:type/:id`)
	const [mode] = useQueryParam("mode", "list")
	const { trans } = useTranslation()

	const type = get(typeMatch, "params.type", null)
	const id = get(idMatch, "params.id", null)

	const listClass = id ? "drawer-open" : ""
	const pointIsNotMovableContainer = !useSiteConfig
		.getState()
		.siteConfig.menu.includes("movableContainers")

	return (
		<div id="List" className={listClass}>
			<ToolbarProvider>
				<PaginationProvider>
					<Drawer />

					<div className="listmenu">
						<ul>
							<li>
								<Link
									to={{ search: stringifyQuery({ mode: "list" }) }}
									className={mode === "list" ? "active" : ""}
								>
									{trans("list")}
								</Link>
							</li>
							<li>
								{["points", "containers"].includes(type) && (
									<Link
										to={{ search: stringifyQuery({ mode: "map" }) }}
										className={mode === "map" ? "active" : ""}
									>
										{trans("map")}
									</Link>
								)}
							</li>
						</ul>

						<Toolbar />
						<Paginator />
					</div>

					<Switch>
						<Route path={`${match.path}/customers`}>
							<Customers />
						</Route>
						<Route path={`${match.path}/points`}>
							<Points />
						</Route>
						<Route path={`${match.path}/containers`}>
							{pointIsNotMovableContainer ? <Containers /> : <Points />}
						</Route>
						<Route path={`${match.path}/inlets`}>
							<Points />
						</Route>
						<Route path={`${match.path}/movableContainers`}>
							<Containers />
						</Route>
						<Route path={`${match.path}/events`}>
							<Events />
						</Route>
						<Route path={`${match.path}/allocations`}>
							<Allocations />
						</Route>
						<Route path={`${match.path}/operators`}>
							<Operators />
						</Route>
						<Route path={`${match.path}/invoices`}>
							<Invoices />
						</Route>
						<Route path={`${match.path}/recyclingStationVisits`}>
							<RecyclingStationVisits />
						</Route>
					</Switch>
				</PaginationProvider>
			</ToolbarProvider>
		</div>
	)
}

export default List

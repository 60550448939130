import { Moment } from "moment"
import { fetchFunc } from "../../../../Utils/api"
import { IEventsDataByInterval } from "../eventsByInterval/types"
import { GET_EVENTS_BY_EVENT_TYPES } from "./queries"

export const fetchEventsByEventTypes = (
	types: string[] | string[][],
	dates: { from: Moment; to: Moment }
) =>
	fetchFunc<IEventsDataByInterval, IEventsDataByInterval>(
		GET_EVENTS_BY_EVENT_TYPES,
		cs => cs.data,
		{ variables: { start: dates.from, end: dates.to, types: types.flatMap(t => t) } }
	)

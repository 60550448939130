import { gql } from "@apollo/client/core"

export const default_fetchAll_points = ({
	fillLevel,
	pos,
	essentials = true,
}: {
	essentials?: boolean
	fillLevel?: boolean
	pos?: boolean
}) => gql`
    fragment defaultPoints on accessPointExtended {
		id
        ${
					essentials
						? `name
							description
							type
							status
							properties {
								key
								value
							}
							externalKeys {
								key
								value
							}`
						: ""
				}
        ${
					pos
						? `pos: geoLocation {
							lat: latitude
							lon: longitude
						}
						resolveAddress(onlyWhenGeoPosIsUnknown: true) {
							lat: latitude
							lon: longitude
						}`
						: ""
				}
        ${
					fillLevel
						? `streamed {
							fillLevel: property(key: "fillLevel")
							versionSpec: property(key: "_versionSpec_fillLevel")fillLevel: property(key: "fillLevel")
						}`
						: ""
				}
        __typename
    }

	query ACGetHierAccessPoints {
		store {
			data: rootAccessPoints {
				...defaultPoints
				points: children {
					...defaultPoints
					points: children {
						...defaultPoints
						points: children {
							...defaultPoints
							points: children {
								...defaultPoints
								points: children {
									...defaultPoints
								}
							}
						}
					}
				}
			}
		}
	}
`

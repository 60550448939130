import { gql } from "apollo-boost"

export const GET_CUSTOMER_IDS_BY_IDENTITY_VALUES = gql`
	query ACGetIdentityEM($key: String!, $values: [String]!) {
		store {
			identities(externalKey: $key, externalKeyValues: $values) {
				customer {
					id
				}
			}
		}
	}
`

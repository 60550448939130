import { DocumentNode } from "apollo-link"
import { omit } from "lodash"
import React, { FC, useEffect, useRef, useState } from "react"
import { useModal, useTranslation } from "../../Contexts"
import { translate } from "../../Contexts/Translation"
import { DotsVerticalIcon } from "../../Icons/DotsVertical"
import { useSiteConfig } from "../../States/siteConfig"
import { Button, ModalContainer, Spinner } from "../../UI"
import { TContextMenuItem, useContextMenu } from "../../UI/ContextMenu"
import { DataGridEditable, IDataGridEditable } from "../../UI/DataGridEditable/DataGridEditable"
import { Select } from "../../UI/GenericComponents/Select"
import { useConfirmationDialog } from "../../UI/Overlays/ConfirmationDialog"
import {
	fetchCustomerIdentities,
	fetchPartialIdentities,
	normalizeGetCustomerIdentities,
} from "./functions/data"
import styles from "./IdentitiesHandler.module.css"
import { useIdentitiesHandlerState } from "./IdentitiesHandlerState"
import { IGetIdentities } from "./types"

export type TIdentityType = "ISOEM" | "ISO" | "JWT" | "PUBLIC_KEY"

export type IdentitiesModalConfig = {
	modes: "edit"[]
	identityType: TIdentityType
	defaultKey: string
	searchMinCharacters?: number
	propertyList: string[]
	externalKeyList: string[]
	requiredKeyList: string[]
	searchableFieldsKeys: string[]
}

type IIdentitiesHandler = {
	customerId: string
	entity: string
	refetchQuery: DocumentNode
}

export const IdentitiesHandler: React.FC<IIdentitiesHandler> = ({ customerId, entity }) => {
	const { trans } = useTranslation()
	const { siteConfig } = useSiteConfig()
	const { hideModal } = useModal()

	const {
		customerIdentites,
		editingId,
		allowSave,
		loading,
		filter,
		setFilter,
		setLoading,
		setCustomerIdentities,
		resetCustomerIdentities,
		setCustomerId,
		setEditingId,
		cancelEditing,
		addNewIdentity,
		setGridRef,
		saveIdentity,
	} = useIdentitiesHandlerState()

	const gridRef = useRef(null)

	const columns = [
		...siteConfig.identitiesModal.propertyList,
		...siteConfig.identitiesModal.externalKeyList,
		"status",
		"actions",
	]

	// Get customer Identities
	useEffect(() => {
		setLoading(true)
		fetchCustomerIdentities(customerId)
			.then(_customer => normalizeGetCustomerIdentities(_customer).reverse()) // Reverse list to show latest modifiend in top
			.then(_identitites => {
				setCustomerIdentities(_identitites)
				setLoading(false)
			})
			.catch(() => setLoading(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setGridRef(gridRef)
	}, [gridRef, setGridRef])

	// scroll to new identity
	useEffect(() => {
		if (editingId === "__TEMP_ID") (gridRef?.current as any)?.scrollToRow(0)
	}, [customerIdentites, editingId])

	useEffect(() => {
		setCustomerId(customerId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerId])

	const onClose = () => {
		hideModal()
		resetCustomerIdentities()
	}

	const filteredIdentities = filter
		? customerIdentites.filter(
				id =>
					id.id === "__TEMP_ID" || // dont filter editing ids
					id.id === editingId ||
					Object.values(omit(id, "status")).some(v => RegExp(filter, "gi").test(v))
		  )
		: customerIdentites

	return (
		<ModalContainer width={"calc(100% - 4rem)"} maxWidth={"75rem"} onCancel={onClose}>
			<p className={styles.bigTitle}>{`${trans("identitiesOverview.title")} ${entity}`}</p>
			<p className={styles.hint}>{trans("hints.identityManaging1")}</p>
			<p className={styles.hint}>{trans("hints.identityManaging2")}</p>
			<p className={styles.hint}>{trans("hints.identityManaging3")}</p>
			<p className={styles.hint}>{trans("hints.identityManaging4")}</p>
			<p className={styles.hint}>{trans("hints.identityManaging5")}</p>
			<br />
			<Select
				data={[]}
				searchIcon
				allowInput
				onInputChange={v => setFilter(v)}
				placeholder={trans("addIdentities.filter")}
			/>

			<div className={styles.addIdentityContainer}>
				{!loading ? (
					<>
						<DataGridEditable
							disableSort
							gridRef={gridRef}
							columns={columns}
							rows={filteredIdentities}
							cellRender={cellRender({
								setEditRow: setEditingId,
								editRow: editingId,
								trans,
							})}
							columnSizes={{
								status: "8rem",
								actions: "3rem",
							}}
							rowClassname={styles.tableRow}
							rowHeight={42}
							border
						/>
					</>
				) : (
					<Spinner />
				)}
			</div>
			<div
				className={styles.actionButtons}
				style={{ justifyContent: editingId ? "end" : undefined }}
			>
				{editingId ? (
					<>
						<Button type={"background"} onClick={() => cancelEditing()}>
							ask.cancel
						</Button>
						<Button type={"primary"} disabled={!allowSave} onClick={() => saveIdentity()}>
							ask.save
						</Button>
					</>
				) : (
					<Button type={"primary"} onClick={() => addNewIdentity()}>
						actions.addIdentity
					</Button>
				)}
			</div>
		</ModalContainer>
	)
}

export const cellRender = ({
	setEditRow,
	editRow,
	trans,
}: {
	setEditRow: React.Dispatch<string>
	editRow: string
	trans: ReturnType<typeof useTranslation>["trans"]
}) => {
	return ({
		id,
		column,
		cellContent,
	}: IDataGridEditable["cellRender"] extends ((...args: infer P) => any) | undefined
		? P[0]
		: never) => {
		const { searchableFieldsKeys } = useSiteConfig.getState().siteConfig.identitiesModal
		const { customerId, removeIdentity } = useIdentitiesHandlerState.getState()
		const { openConfirmationDialog } = useConfirmationDialog.getState()
		const menuItems: TContextMenuItem[] = [
			{
				label: "actions.edit",
				translateLabel: true,
				fn: () => {
					setEditRow(id)
				},
			},
			{
				label: "remove",
				translateLabel: true,
				fn: () =>
					openConfirmationDialog(translate("ask.removeIdentityFromCustomer"), () => {
						removeIdentity(id, customerId)
					}),
				style: { color: "var(--danger)" },
			},
		]
		return column === "actions" ? (
			<DotsMenu menuItems={menuItems} />
		) : editRow === id ? (
			column === "status" ? (
				<StatusSelect />
			) : searchableFieldsKeys.includes(column) && editRow === "__TEMP_ID" ? (
				<SearchableInput column={column} />
			) : (
				<EditInput column={column} />
			)
		) : (
			<label>{column === "status" ? trans(`status.${cellContent}`) : cellContent}</label>
		)
	}
}

const StatusSelect: FC<{ value?: string }> = () => {
	const { editingIdentity, updateField } = useIdentitiesHandlerState()
	const { trans } = useTranslation()
	return (
		<Select
			type={"basic"}
			onChange={option => updateField("status", option.value || "")}
			initialValue={editingIdentity?.status || "ACTIVE"}
			data={[
				{ option: trans("status.ACTIVE"), value: "ACTIVE" },
				{ option: trans("status.DISABLED"), value: "DISABLED" },
			]}
		/>
	)
}

const SearchableInput: FC<{ column: string }> = ({ column }) => {
	const { updateField, addExistingIdentity, editingIdentity } = useIdentitiesHandlerState()
	const { searchMinCharacters, requiredKeyList } = useSiteConfig().siteConfig.identitiesModal
	const { trans } = useTranslation()

	const [identitiesList, setIdentitiesList] = useState<IGetIdentities>([])
	// Input loading when fetching
	const [inputLoading, setInputLoading] = useState<boolean>(false)
	const [searchVal, setSearchVal] = useState("")

	const onInputChange = (val: string) => {
		updateField(column, val)
		setSearchVal(val)
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (searchVal && searchVal?.length >= (searchMinCharacters || 4)) {
				setInputLoading(true)
				fetchPartialIdentities(column, searchVal).then(identities => {
					// Filter out used identities
					const filteredIdentities = identities.store.identities.filter(i => !i.customer?.id)
					setIdentitiesList(filteredIdentities.length ? filteredIdentities : [])
					setInputLoading(false)
				})
			}
		}, 500)
		return () => {
			clearTimeout(timeout)
			setInputLoading(false)
		}
	}, [column, searchMinCharacters, searchVal])

	return (
		<Select
			allowInput
			searchIcon
			loading={inputLoading}
			placeholder={trans(`placeholders.${column}`)}
			onChange={({ value }) => value && addExistingIdentity(value)}
			onInputChange={val => onInputChange(val)}
			data={identitiesList.map(i => ({ option: i.text, value: i.id }))}
			error={requiredKeyList.includes(column) && !!editingIdentity && !editingIdentity[column]}
			dropdownStyle={{
				maxHeight: 200,
				overflow: "auto",
			}}
		/>
	)
}

const EditInput: FC<{ column: string }> = ({ column }) => {
	const { editingId, editingIdentity, updateField } = useIdentitiesHandlerState()
	const { externalKeyList, requiredKeyList } = useSiteConfig().siteConfig.identitiesModal
	const { trans } = useTranslation()
	const disabled = !!editingId && editingId !== "__TEMP_ID" && externalKeyList.includes(column)
	return (
		<input
			type="text"
			placeholder={disabled ? "--" : trans(`placeholders.${column}`)}
			disabled={disabled}
			value={(editingIdentity && editingIdentity[column]) || ""}
			onChange={({ target: { value } }) => updateField(column, value)}
			style={{
				borderColor:
					requiredKeyList.includes(column) && editingIdentity && !editingIdentity[column]
						? "red"
						: undefined,
			}}
		/>
	)
}

const DotsMenu: FC<{ menuItems: TContextMenuItem[] }> = ({ menuItems }) => {
	const { openContextMenu } = useContextMenu()
	const { editingId } = useIdentitiesHandlerState()

	if (editingId) return null

	return (
		<div>
			<DotsVerticalIcon
				className={styles.dotMenu}
				onClick={e => {
					openContextMenu(e, menuItems)
				}}
			/>
		</div>
	)
}

import { useQuery } from "@apollo/client"
import { gql } from "apollo-boost"
import { useMemo } from "react"
import { useSiteConfig } from "../../States/siteConfig"
import { IAccessPointData } from "../Points/types"
import {} from "./types"

// const GET_ACCESS_POINTS = () => useSiteConfig.getState().siteConfig.accessPoints.data.fetchAll

export function useGetContainers() {
	const dataMethods = useSiteConfig(state => state.siteConfig.containers.data)
	const { loading, error, data, client } = useQuery<IAccessPointData>(dataMethods.fetchAll, {
		errorPolicy: "ignore",
	})
	const containers = useMemo(
		() => (data ? dataMethods.fetchAllParse(data) : []),
		[data, dataMethods]
	)
	return { loading, error, data: containers, client }
}

export const GET_OPERATOR_BY_ID = gql`
	query ACGetOperatorById($operatorID: String) {
		store {
			operators(id: $operatorID) {
				id
				name
			}
		}
	}
`

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import {
	df_input_fraction,
	df_input_name,
	df_input_status,
	df_input_vendor_inlet,
} from "../../_drawerFragments/inputs"

export const bir_drawerContent_containers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name({
				header: "stationModal.inletName", // beholdernavn / container name
				disabled: ({ data }) => data.parentTypeGroup && data.type !== "GROUP",
			}),
			df_input_name({
				header: "stationModal.containerInletName", // lukenavn / inlet name
				disabled: ({ data }) => !data.parentTypeGroup || data.type !== "ACCESS_POINT",
			}),
			// df_input_terminal(),
			df_input_status(),
			df_input_fraction(),
			df_input_vendor_inlet(),
			{
				updater: "property",
				key: "properties.hatchTypeCode",
				header: "headers.hatchSize",
				permission: "edit",
				type: "dropdown",
				dropdownOptions: [{ value: "L" }, { value: "S" }, { value: "LS" }],
				translate: true,
				translatePrefix: "stationModal.hatches",
				disabled: ({ data }) => data.type === "GROUP",
			},
		],
	},
	// pointEditorDrawer: null,
	// map: null,
}

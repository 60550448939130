import { uniq } from "lodash"
import { useApolloStore } from "../../../../States/apollo"
import { useSiteConfig } from "../../../../States/siteConfig"
import { IDates, IEventModified } from "../../types"
import { eventsFromResponse } from "./prepare"
import { IAllEventData } from "./types"
import { fetchFunc } from "../../../../Utils/api"
import { ICustomerWithIdentitiesByIds } from "../eventsByPoints/types"
import { GET_IDENITIES_BY_CUSTOMER_IDS } from "../eventsByPoints/queries"

export const getEvents = async (dates: IDates): Promise<IEventModified[]> => {
	const { client } = useApolloStore.getState()
	const config = useSiteConfig.getState().siteConfig.events

	if (client) {
		const eventsResult = await client
			.query<IAllEventData>({
				query: config?.data.fetchAll,
				variables: {
					start: dates.from,
					end: dates.to,
				},
			})

			if (!eventsResult.data.store.events?.length) {
				return []
			}

			if (config?.data.joinCustomerDetails) {
				const customerIds = uniq(eventsResult.data.store.events?.flatMap(e => e?.customer?.id).filter(Boolean)) as string[]

				const customers = await fetchFunc<ICustomerWithIdentitiesByIds, ICustomerWithIdentitiesByIds>(GET_IDENITIES_BY_CUSTOMER_IDS({includeExternalKeys: true}), cs => cs.data, {
					variables: { customerIds },
				})

				return eventsFromResponse(eventsResult.data.store.events.map(e => {
					const customer = customers.store.customers.find(c => c.id === e.customer?.id)

					return {
						...e,
						customer: customer ? {
							id: customer.id,
							name: customer.name,
							customer,
						} : null
					}
				}))
			}

			return eventsFromResponse(eventsResult.data.store.events)

	} else {
		throw new Error("Problems connecting to api client")
	}
}

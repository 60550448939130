import { SVGProps } from 'react'

export const ElektronikkIcon = ({
  fill = 'black',
  width = '116',
  height = '280',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 116 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45 23.5V47H58.5H72V23.5V0H82H92V23.5V47H104H116V60.8C116 75.7 114.8 82.6 110.5 90.9C106.8 98.3 99.2 106.9 90.6 113.4L83 119.3V133.1V147H58.5H34V132.7V118.5L29.7 115.8C23.6 111.9 13.3 101.4 8.79999 94.6C2.99999 85.6 1.79999 80.7 1.29999 62.7L0.799988 47H12.9H25V23.5V0H35H45V23.5ZM69 222.5V280H59H49V222.5V165H59H69V222.5Z"
        fill={fill}
      />
    </svg>
  )
}

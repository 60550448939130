import { cloneDeep, isEmpty, omit } from "lodash"
import { useEffect, useMemo, useReducer } from "react"
import { localeFromLocalStorage } from "../../Contexts/Translation"
import { useSiteConfig } from "../../States/siteConfig"
import {
	fromArrayToObj,
	fromObjToArray,
	getDynamicEmptyFieldsFromFields,
	getDynamicEmptyFieldsFromInitialArray,
} from "./functions"
import styles from "./MultiKeyValue.module.css"
import {
	IMultiKeyValue,
	IMultiKeyValueFields,
	IMultiKeyValueObj,
	IMultiKeyValueObjs,
	IMultiKeyValueState,
} from "./types"

/**
 * @description
 * Add, remove and edit rows or data. Fields are dynamicly recognized, but can be overwritten and have a basis.
 * @param initKeyValues
 * Initial data. When set - initKeyValues field structure will be tracked.
 * @param initFields
 * Initial fields to use in each row. Will be overwritten by initKeyValues field structure.
 * @param activeFields
 * Which fields should show in each row. Will override both initFields and initKeyValues field structure.
 * @param save - Trigger onSave function to run
 * @param onSave - All data in MultiKeyValue is passed to this function to be saved
 */
export const MultiKeyValue = ({
	initKeyValues,
	initFields,
	activeFields,
	save,
	onSave,
	onRemove,
	disableAddRows,
	disableInitRow,
}: IMultiKeyValue) => {
	// Empty fields used in added rows
	const emptyFields = activeFields?.length
		? getDynamicEmptyFieldsFromFields(activeFields)
		: initKeyValues?.length
		? getDynamicEmptyFieldsFromInitialArray(initKeyValues)
		: initFields?.length
		? getDynamicEmptyFieldsFromFields(initFields)
		: { key: "", value: "" }

	const [state, dispatch] = useReducer(reducer, {
		nextIndex: 1,
		multiKeyValue: !disableInitRow
			? {
					0: { index: "0", ...(emptyFields ? { fields: emptyFields } : {}) },
			  }
			: ({} as { [index: number]: IMultiKeyValueObj }),
	})
	const { siteConfig } = useSiteConfig()
	const trans = siteConfig.translations[localeFromLocalStorage]

	// If initKeyValues are set or re-set - update component accordingly
	useEffect(() => {
		// If initKeyValues change override everything
		if (initKeyValues) {
			if (initKeyValues?.length) {
				// converting key value array to object to use in logic
				const initKV = fromArrayToObj(initKeyValues)
				if (initKV) {
					dispatch({ type: "init", obj: initKV })
				}
			}
		}
	}, [initKeyValues])

	// WHen save is triggered onSave function with data from state
	// as array
	useEffect(() => {
		if (save) {
			const data = fromObjToArray({ ...state.multiKeyValue })
			onSave(data || [])
		}
	}, [save, onSave, state])

	return (
		<div className={styles.multiKeyValue}>
			{Object.values(state.multiKeyValue)?.map((keyValue, i: number) => {
				const { index, ...fields } = keyValue
				const theFields = "fields" in fields ? fields["fields"] : {}
				return (
					<KeyValueComponent
						key={`KeyValue_${index}`}
						mapIndex={i}
						index={index}
						fields={theFields}
						dispatch={dispatch}
						emptyFields={emptyFields}
						activeFields={activeFields}
						onRemove={onRemove}
					/>
				)
			})}
			{!disableAddRows && (
				<div
					className={`${styles.keyValue} ${styles.addRow}`}
					onClick={() => dispatch({ type: "add", emptyFields })}
				>
					<svg width="20" height="20" viewBox="0 0 512 512">
						<line x1="256" y1="112" x2="256" y2="400" />
						<line x1="400" y1="256" x2="112" y2="256" />
					</svg>
					<div>{trans.multiKeyValue.addNewLine}</div>
				</div>
			)}
		</div>
	)
}

type IKeyValueComponent = {
	index: string
	mapIndex: number
	fields: IMultiKeyValueFields
	dispatch: React.Dispatch<IReducerAction>
	emptyFields: IMultiKeyValueFields | null
	activeFields?: string[]
	onRemove?: (obj: IMultiKeyValueFields) => void
}

const KeyValueComponent = ({
	index,
	mapIndex,
	fields,
	dispatch,
	emptyFields,
	activeFields,
	onRemove,
}: IKeyValueComponent) => {
	const { siteConfig } = useSiteConfig()
	const transKey = (_key: string) =>
		siteConfig.translations[localeFromLocalStorage].headers[_key] || _key

	const update = (_key: string, _value: string) =>
		dispatch({ type: "update", index, key: _key, value: _value })

	const add = () => dispatch({ type: "add", emptyFields })
	const remove = () => {
		dispatch({ type: "remove", index })
		if (onRemove) onRemove(fields)
	}

	const fieldsKeyValue = useMemo(
		() =>
			activeFields
				? Object.entries(fields).filter(([k, v]) => !!activeFields.find(af => af === k))
				: Object.entries(fields),
		[fields, activeFields]
	)

	return (
		<div className={styles.keyValue}>
			<form
				onSubmit={(ev: any) => {
					if (Object.values(fields).find(f => f !== "")) add()
					ev.preventDefault()
				}}
			>
				{fieldsKeyValue.map(([k, v], i) => (
					<div key={`keyvaluerow_` + index + "_" + i} className={styles.inputField}>
						{mapIndex === 0 && <label>{transKey(k)}</label>}
						<input
							type="text"
							required
							placeholder={transKey(k)}
							value={v || ""}
							onChange={(ev: any) => update(k, ev.target.value)}
						/>
					</div>
				))}
				{!isEmpty(fields) && (
					<svg
						className={`${styles.remove} ${mapIndex === 0 ? styles.labelRow : ""}`}
						width="20"
						height="20"
						viewBox="0 0 512 512"
						onClick={remove}
					>
						<line x1="400" y1="256" x2="112" y2="256" />
					</svg>
				)}
			</form>
		</div>
	)
}

type IReducerAction =
	| { type: "update"; index: string; key: string; value: string }
	| { type: "add"; emptyFields: IMultiKeyValueFields | null }
	| { type: "remove"; index: string }
	| { type: "init"; obj: IMultiKeyValueObjs }

function reducer(state: IMultiKeyValueState, action: IReducerAction) {
	const { nextIndex, multiKeyValue } = cloneDeep(state)

	switch (action.type) {
		case "update":
			return {
				nextIndex,
				multiKeyValue: {
					...multiKeyValue,
					[action.index]: {
						index: action.index,
						fields: {
							...((multiKeyValue[parseInt(action.index)] as any)["fields"] || []),
							[action.key]: action.value,
						},
					},
				},
			}

		case "add":
			return {
				nextIndex: nextIndex + 1,
				multiKeyValue: {
					...multiKeyValue,
					[nextIndex]: {
						index: nextIndex.toString(),
						fields: { ...action.emptyFields },
					},
				},
			}
		case "remove":
			return {
				nextIndex,
				multiKeyValue: omit(multiKeyValue, action.index),
			}
		case "init":
			return {
				nextIndex: Object.keys(action.obj)?.length,
				multiKeyValue: action.obj,
			}
		default:
			throw new Error()
	}
}

import { isStaging, siteName } from "../../Configs/config"
import { ISite } from "../../Configs/types"

import { getTokenFromCookie } from "../../Utils"

// TODO: Remove thonvest logic (from dashboard) when its migrated to common
export const IS_THON_VEST = window.location.origin.includes("thonvest")

export interface IDwhResponse {
	data: {
		terminalStats: ICenterStats[]
		centerStats: ICenterStats[]
		basicStats: ICenterBasicStats[] | IIKSBasicStats[]
		tenantoverview: ITenantOverview[]
		tenantdetails: ITenantDetails[] | null
		iksStats: IIKSStats[]
	}
	error: {}
}

export interface ICenterStats {
	fraction_distribution:
		| {
				count: number // 4
				fraction: string // '1221'
				percent: number // 0.58
				weight: string // 36
		  }[]
		| null
	period: "last_30_days" | string
	sorting_degree: string // "0.970"
	total_rest_month: number | null // "1.86"
	total_waste_month: number | null //"61.86"
	updated_timestamp: string | null // "2021-09-24T00:01:20.088Z"
}

export interface ITenantOverview {
	customerid: string
	customer_name: string | null
	transactions: number
	client: ISite
	distinct_waste_types: number // is new distinct_fractions
	end_time: string
	root_id: string | null
	sorting_degree: number
	start_time: string
	total_residual_waste: number | null
	total_waste: number | null
	updated_timestamp: string
}

export interface ITenantDetails {
	customer_name: string
	period: string
	total_waste_month: number
	total_rest_month: number
	sorting_degree: number
	fraction_distribution: {
		fraction: string
		count: number
		weight: number
		percent: number
	}[]
}

export interface ICenterBasicStats {
	containers: string
	customers: string
	fractions: string
	users: string
}

export interface IIKSStats {
	updated_timestamp: string
	client: string
	accessparent_distribution: IAccessParentDistribution[]
}

export interface IIKSBasicStats {
	active_and_inactive_access_points: number
	inactive_access_points: number
	unique_fractions: number
	unique_customers: number
	unique_identities: number
	successful_disposals_last_week: number
	rejected_disposals_last_week: number
}

export interface IAccessParentDistribution {
	accessparent_name: string
	accessparent_id: string
	reported_number_of_events: number
	unique_fractions: number
	period: string
}

export const fetchFromDWH = async (
	options: {
		token?: string
		id?: string
		search?: {}
	} & (
		| {
				endpoint: "centerStats"
		  }
		| {
				endpoint: "tenantOverview"
				search: {
					startTimeISO: string
					endTimeISO?: string
				}
		  }
		| {
				endpoint: "tenantDetails"
				id: string
		  }
		| {
				endpoint: "iksstats"
				client: ISite
		  }
		| {
				endpoint: "terminalCenterStats"
				id: string
		  }
	)
) => {
	const { token, endpoint = "centerStats", id, search } = options
	const accessToken = token || getTokenFromCookie()
	const sub = window.location.origin.includes("localhost") || isStaging ? "dwhtest" : "dwh"
	const domain = "carrot.tech"
	// const client = siteName === "thon" ? "thonvest" : siteName === "default" ? "bir" : siteName
	const client = IS_THON_VEST ? "thonvest" : siteName === "default" ? "bir" : siteName
	const searchParams = search
		? "?" +
		  Object.entries(search)
				.map(([key, value]) => key + "=" + value)
				.join("&")
		: ""
	const targetId = id ? `/${id}` : ""
	const requestUrl = `https://${sub}.${domain}/${endpoint}/${client}${targetId}${searchParams}`

	const res = await fetch(`${requestUrl}`, {
		headers: {
			authorization: `Bearer ${accessToken}`,
		},
	})
		.then(res => res.json())
		.catch(err => console.error(err))

	return res as IDwhResponse
}

import { ihm_translations } from "../../Contexts/translations/configurations/ihm"
import { IKSDefaultDashboard } from "../../Dashboard/siteDashboards/IKSDefault"
import { stringifyQuery } from "../../domHooks"
import {
	containerIdFilter,
	customerNameFilter,
	dateTimeFilter,
	eventTypeFilter,
	pointParentNameFilter,
	rfidIsoFilter,
} from "../../Resources/Events/configurations/filters"
import { IEventModified } from "../../Resources/Events/types"
import { getChildrenFillLevelsAndFractions } from "../../Resources/Points/data"
import { IFillLevel } from "../../Resources/Points/types"
import { IRow } from "../../Resources/types"
import { useSiteConfig } from "../../States/siteConfig"
import { keyValueArrayToObject } from "../../Utils/objArr"
import { mergeConfig } from "../config"
import { ihm_containertypes } from "../datasets/containerTypes/ihm"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { defaultConfig } from "./default"
import { default_fetchAll_events } from "./default/queries/default_events"
import { default_fetchById_containers } from "./default/queries/default_fetchById_containers"
import { default_fetchById_points } from "./default/queries/default_fetchById_points"
import { ihm_drawerContent_containers } from "./ihm/drawer/ihm_drawerContent_containers"
import { ihm_drawerContent_customers } from "./ihm/drawer/ihm_drawerContent_customers"
import { ihm_drawerContent_points } from "./ihm/drawer/ihm_drawerContent_points"
import { ihm_fractionList } from "./ihm/fractions"
import { ihm_accessPointModal } from "./ihm/modals/ihm_accessPointModal"
import { ihm_fetchAll_containers } from "./ihm/queries/ihm_fetchAll_containers"
import { ihm_fetcAll_customers } from "./ihm/queries/ihm_fetchAll_customers"
import { ihm_fetchAll_operators } from "./ihm/queries/ihm_fetchAll_operators"
import { ihm_fetcAll_points } from "./ihm/queries/ihm_fetchAll_points"
import { ihm_fetchById_operators } from "./ihm/queries/ihm_fetchById_operators"
import { eventFilterConfigGen, filterConfigGen } from "./_drawerFragments/filterConfigGenerator"
import {
	businessParentEQ,
	containerIdFuzzy,
	containerTypeEQ,
	customerNameEQ,
	fillLevel,
	filterEQ,
	filterFuzzy,
	fractionEQ,
	operatorEQ,
	stationEQ,
	stationTypeEQ,
	statusEQ,
	terminalEQ,
	volumeEQ,
} from "./_drawerFragments/filters"

export const ihmConfig = (): ISiteConfig => mergeConfig(defaultConfig(), ihmConfigObj)

export const ihmConfigObj: IPartialSiteConfig = {
	translations: ihm_translations,
	menu: ["customers", "points", "containers", "events", "operators"],
	dashboard: IKSDefaultDashboard,
	containers: {
		headers: [
			{ path: "checkbox" },
			{ name: "headers.containerId", path: "externalKeys.containerId" },
			{ name: "properties.fraction", path: "properties.fraction" },
			{ name: "properties.binType", path: "properties.containerType" },
			{ name: "properties.volume", path: "properties.volume" },
			{ name: "headers.RFID", path: "externalKeys.RFID_ISO" },
			{ path: "customerName" },
		],
		defaultSort: { header: "externalKeys.containerId" },
		filters: filterConfigGen([
			containerIdFuzzy,
			customerNameEQ,
			filterEQ({
				lookupKey: "customers.name",
				translateName: "stationModal.stationName",
				id: "parent",
			}),
			containerTypeEQ,
			fractionEQ,
			volumeEQ,
			filterEQ({
				lookupKey: "externalKeys.RFID_ISO",
				translateName: "headers.RFID",
				id: "RFID",
			}),
		]),
		data: {
			fetchAll: { ...ihm_fetchAll_containers(false) },
			fetchAllParse: (data: any) =>
				data.store.data.map((data: any) => ({
					...data,
					properties: keyValueArrayToObject(data.properties),
					externalKeys: keyValueArrayToObject(data.externalKeys),
					customerName: data.customers?.customers?.map((c: any) => c.name).join(", ") || null,
				})),
			fetchById: default_fetchById_containers({ fillLevel: true }),
			fetchByIdParse: (data: any) =>
				[data.store.data[0]].map(container => ({
					...container,
					properties: keyValueArrayToObject(container.properties),
					externalKeys: keyValueArrayToObject(container.externalKeys),
					assignedCustomers:
						container.parent?.customers?.map((c: any) => c.name).join(", ") || null,
					fillLevelTs: parseInt((container?.streamed?.versionSpec || "")?.slice(0, 8), 16) || 0,
				}))[0],
		},
		history: { action: true, location: true },
		drawer: {
			content: ihm_drawerContent_containers,
		},
	},
	customers: {
		headers: [
			{ path: "checkbox" },
			{ name: "customerName", path: "name" },
			{ path: "externalKeys.ImportID", name: "headers.ImportID" },
			{ name: "headers.properties.address", path: "properties.geoLocationName" },
			{ path: "stations", name: "stationModal.stationName" },
			{ path: "operator" },
			{ path: "totalContainers" },
		],
		filters: filterConfigGen([
			filterFuzzy("name", "customerName"),
			filterFuzzy("properties.geoLocationName", "customerGeoLocationName"),
			operatorEQ,
			stationEQ,
			businessParentEQ,
		]),
		onCellClick:
			({ history }: any) =>
			(row: IRow, header: string) => {
				if (!history) return false
				if (header === "totalContainers") {
					history?.replace({
						pathname: `/resources/containers`,
						search: stringifyQuery({
							filter: {
								customerName: [
									{
										label: row["name"],
										value: row["name"],
									},
								],
							},
						}),
					})
					return true
				}
				return false
			},
		data: {
			fetchAll: ihm_fetcAll_customers,
			fetchAllParse: (data: any) =>
				data.store.data.map((data: any) => ({
					...data,
					properties: data.properties?.reduce(
						(prev: any, prop: { key: any; value: any }) => ({ ...prev, [prop.key]: prop.value }),
						{}
					),
					externalKeys: data.externalKeys?.reduce(
						(prev: any, extKey: { key: any; value: any }) => ({
							...prev,
							[extKey.key]: extKey.value,
						}),
						{}
					),
					totalContainers: data.points?.reduce(
						(prev: number, p: any) => prev + p?.point?.children?.length || 0,
						0
					),
					operator: data.operator?.operator?.name,
					stations: data.points?.map((p: any) => p.point.name).join(", ") || null,
					ancestor: data.customerType !== "BUSINESS_PARENT" ? data.ancestor : undefined,
				})),
		},
		history: { action: true, location: true },
		drawer: {
			content: ihm_drawerContent_customers,
		},
	},
	points: {
		headers: [
			{ path: "checkbox" },
			{ name: "stationModal.stationName", path: "name" },
			{ path: "description" },
			{ path: "terminal.name" },
			{ path: "totalContainers" },
			{ path: "totalCustomers" },
			{ path: "fillLevel" },
		],
		filters: filterConfigGen([
			filterFuzzy("name", "stationModal.stationName"),
			stationTypeEQ,
			terminalEQ,
			statusEQ,
			fillLevel,
		]),
		history: { action: true, location: true },
		drawer: {
			ui: "drawer",
			content: ihm_drawerContent_points,
		},
		data: {
			fetchAll: ihm_fetcAll_points({ fillLevel: false }),
			postFetchAll: ihm_fetcAll_points({ essentials: false, fillLevel: true }),
			fetchAllParse: (data: any) =>
				data.store.data.map((st: any) => ({
					...st,
					properties: keyValueArrayToObject(st.properties),
					totalContainers: st?.accessPoints?.length || 0,
					totalCustomers:
						st?.totalCustomers ||
						(st?.customers?.length === 1 ? st?.customers[0].name : st?.customers?.length) ||
						0,
					fillLevels: getChildrenFillLevelsAndFractions(
						st?.accessPoints || [],
						useSiteConfig.getState().siteConfig.translations
					) as IFillLevel[],
				})),
			fetchById: default_fetchById_points({ fillLevel: true }),
		},
	},
	operators: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ path: "properties.address" },
			{ path: "totalCustomers" },
		],
		filters: filterConfigGen([filterFuzzy("name")]),
		history: { action: true, location: true },
		drawer: {
			ui: "drawer",
		},
		data: {
			fetchAll: ihm_fetchAll_operators(),
			fetchAllParse: (data: any) =>
				data.store.data.map((operator: any) => ({
					...operator,
					properties: keyValueArrayToObject(operator.properties),
					externalKeys: keyValueArrayToObject(operator.externalKeys),
					totalCustomers: data.store.customers.reduce((p: number, c: any) => {
						if (operator.id === c?.operator?.operator?.id) return p + 1
						return p
					}, 0),
				})),
			fetchById: ihm_fetchById_operators(),
			fetchByIdParse: (data: any) =>
				[data.store.data[0]].map((operator: any) => ({
					...operator,
					properties: keyValueArrayToObject(operator.properties),
					externalKeys: keyValueArrayToObject(operator.externalKeys),
					customers: data.store.customers,
				}))[0],
		},
	},
	events: {
		headers: [
			{ path: "checkbox" },
			{ path: "timestamp" },
			{ path: "customer.name" },
			{ path: "point.parent.name", name: "stationModal.stationName" },
			{ path: "point.externalKeys.RFID_ISO", name: "headers.RFID" },
			{ path: "properties.weight", name: "headers.weight" },
			{ path: "point.properties.fraction", name: "properties.fraction" },
			{ path: "type" },
			{ path: "result" },
		],
		columnSizes: {
			"point.parent.name": "0.8fr",
		},
		filters: eventFilterConfigGen([
			dateTimeFilter(),
			customerNameFilter(),
			pointParentNameFilter(),
			containerIdFilter(),
			rfidIsoFilter(),
			eventTypeFilter(),
		]),
		filterEventsFn: (event: IEventModified) =>
			!(event.type === "EMPTY" && event.point?.externalKeys?.RFID_ISO),
		data: {
			fetchAll: default_fetchAll_events({ pointProperties: true, pointExternalKeys: true }),
		},
	},
	stationModal: {
		modes: ["create"],
		inletHatchSizeProp: false,
		status: {
			hide: true,
			default: "ACTIVE",
		},
		inletStatus: {
			hide: true,
			default: "ACTIVE",
		},
		stationPropertyList: [],
		inletPropertyList: [],
		containerTypes: ihm_containertypes(),
		stationAutoKey: { generator: "nanoid", key: "ImportID", lenght: 5, prefix: "S" },
		inletAutoKey: { generator: "nanoid", key: "containerId", lenght: 5, prefix: "B" },
	},
	accessPointModal: ihm_accessPointModal,
	customerModal: {
		modes: ["create"],
		customerTypes: ["BUSINESS"],
		allocationScheme: ["NONE"],
		useDescriptionField: true,
		externalKeyList: [{ key: "CRMID", name: "CRMID", value: "" }],
		propertyList: [
			{ key: "orgNumber", name: "taxNumber", value: "" },
			{ key: "orgForm", name: "orgForm", value: "" },
			{ key: "companyCode", name: "companyCode", value: "" },
			{ key: "companyDescription", name: "companyDescription", value: "" },
			{ key: "geoLocationName", name: "geoLocationName", value: "" },
			{ key: "postalCode", name: "postalCode", value: "" },
			{ key: "postal", name: "postal", value: "" },
		],
		customerAutoKey: { generator: "nanoid", key: "ImportID", lenght: 5, prefix: "C" },
	},
	fractionsList: ihm_fractionList,
	identitiesModal: { modes: [] },
}

import { InputComponent } from "../../../UI/Drawer/components/InputsGenerator"
import {
	default_vendorOptions_inlet,
	default_vendorOptions_station,
} from "../../datasets/VendorOptions/default_vendorOptions"
import { default_fractionsList } from "../default/default_fractions"

type InputConfig = Partial<Omit<InputComponent, "updater" | "type">>

export const df_input_id = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "none",
	key: "id",
	header: "headers.id",
	...config,
})

export const df_input_name = (config?: InputConfig): InputComponent => ({
	updater: "name",
	key: "name",
	header: "headers.name",
	permission: "edit",
	type: "text",
	...config,
})

export const df_input_terminal = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "terminalName",
	key: "terminal.name",
	header: "headers.installation.name",
	type: "text",
	permission: "edit",
	...config,
})

export const df_input_status = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "status",
	key: "status",
	header: "headers.status",
	placeholder: "stationModal.selectStatus",
	type: "dropdown",
	translate: true,
	translatePrefix: "status",
	permission: "edit",
	dropdownOptions: [
		{ value: "ACTIVE" },
		{ value: "INACTIVE" },
		{ value: "DEPRECATED" },
		{ value: "PLANNED" },
	],
	...config,
})

export const df_input_fraction = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "property",
	key: "properties.fraction",
	header: "headers.properties.fraction",
	placeholder: "stationModal.selectFraction",
	permission: "edit",
	type: "dropdown",
	translate: true,
	translatePrefix: "fraction",
	dropdownOptions: default_fractionsList.map(f => ({ value: f })),
	parantecesOriginal: true,
	...config,
})

export const df_input_address = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "property",
	key: "properties.geoLocationName",
	header: "headers.properties.geoLocationName",
	placeholder: "headers.properties.geoLocationName",
	permission: "edit",
	type: "text",
	...config,
})

export const df_input_gps = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "GPS",
	key: "pos.dd",
	header: "properties.gps",
	permission: "edit",
	type: "text",
	...config,
})

export const df_input_description = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "description",
	type: "text",
	header: "headers.description",
	key: "description",
	permission: "edit",
	...config,
})

export const df_input_vendor_station = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "property",
	key: "properties.vendor",
	header: "properties.vendor",
	placeholder: "stationModal.vendor",
	permission: "edit",
	type: "dropdown",
	translate: false,
	dropdownOptions: default_vendorOptions_station,
	...config,
})

export const df_input_vendor_inlet = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({
	updater: "property",
	key: "properties.vendor",
	header: "properties.vendor",
	placeholder: "stationModal.vendor",
	permission: "edit",
	type: "dropdown",
	translate: false,
	dropdownOptions: default_vendorOptions_inlet,
	...config,
})

/* TEMPLATE
export const df_input_status = (
	config?: Partial<Omit<InputComponent, "updater" | "type">>
): InputComponent => ({

	...config,
})
*/

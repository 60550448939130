import { gql } from "apollo-boost"

//startTimeIso: "2022-04-11T00:07:00.984Z"
//endTimeIso: "2022-05-06T20:16:05.853Z"

export const default_fetchAll_recyclingStationVisits = gql`
	query get_session_stats($start: DateTime!, $end: DateTime!) {
		store {
			accessPoints(type: "CONTAINER_ROOT") {
				children {
					id
					name
					sessionId: externalKey(key: "ImportID")
					customers {
						name
						customerClass: property(key: "customerClass")
						orgNr: property(key: "orgNumber")
						postalCode: property(key: "postalCode")
					}
					eventsAggregate(startTimeIso: $start, endTimeIso: $end, aggKey: "properties.visitId") {
						groupBy {
							aggKey {
								keyValue {
									key
									aggregate {
										max {
											timestamp
										}
										min {
											timestamp
										}
									}
									values {
										type
										timestampIso
										paymentAmountIncludingVAT: property(key: "paymentAmount")
										paymentAmountExcludingVAT: property(key: "paymentAmountExcludingVAT")
										totalBillableWeight: property(key: "totalBillableWeight")
										paymentType: property(key: "paymentType")
										paymentRef: property(key: "paymentRef")
										initiatedByCustomerRefName: property(key: "initiatedByCustomerRefName")
										weight: property(key: "weight")
										deltaWeight: property(key: "deltaWeight")
										properties {
											key
											value
										}
										children {
											type
											point {
												point {
													eid: externalKey(key: "ImportID")
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

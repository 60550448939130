import { SVGProps } from 'react'

export const RengjoringsmiddlerIcon = ({
  fill = 'black',
  width = '179',
  height = '191',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 179 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4444 21.2891C31.9444 6.78908 46.4444 21.2891 46.4444 21.2891V27.5391C46.4444 27.5391 51.0146 30.5312 48.0779 32.7351C53.6369 28.8842 60.8932 26.4503 49.9444 7.5C49.9444 7.5 103.944 3.88171 109.444 4.28906C118.444 7.78922 120.151 14.9991 124.944 23.2891V100.289C124.944 100.289 164.444 103.289 160.944 115.289V124.289V136.539V148.789V173.289C160.944 173.289 152.444 186.789 125.694 186.789C98.9444 186.789 90.4444 173.289 90.4444 173.289V148.789L49.9444 154.789H29.9444C29.9444 154.789 20.9444 154.789 19.4444 153.789C17.9444 152.789 4.44444 145.789 4.44444 142.289C4.44444 138.789 3.44444 41.789 4.44444 40.289C5.44444 38.789 4 32.2108 11.5 32.5C14.4405 32.6134 15.8353 32.836 16.4444 33.0648V27.7892V21.2891Z"
        fill={fill}
      />
      <path
        d="M16.4444 21.2891C31.9444 6.78908 46.4444 21.2891 46.4444 21.2891M16.4444 21.2891C16.4444 21.2891 25.3287 26.7891 31.4444 26.7891C37.5602 26.7891 46.4444 21.2891 46.4444 21.2891M16.4444 21.2891C16.4444 23.9036 16.4444 26.054 16.4444 27.7892M46.4444 21.2891V27.5391M46.4444 21.2891C46.4444 21.2891 41.9214 37.0273 48 32.7892C53.5687 28.9066 60.9444 26.5389 49.9444 7.5C49.9444 7.5 103.944 3.88171 109.444 4.28906C118.444 7.78922 120.151 14.9991 124.944 23.2891C124.944 55.7892 124.944 100.289 124.944 100.289M124.944 100.289C124.944 100.289 164.444 103.289 160.944 115.289M124.944 100.289C111.205 101.434 102.928 103.89 97.9445 106.489M160.944 115.289C160.944 115.289 158.944 125.289 126.444 127.289M160.944 115.289V124.289V136.539V148.789V173.289C160.944 173.289 152.444 186.789 125.694 186.789C98.9444 186.789 90.4444 173.289 90.4444 173.289V148.789M126.444 127.289C87.4444 124.289 90.4444 115.289 90.4444 115.289M126.444 127.289C128.007 129.242 124.944 129.789 124.944 129.789C124.944 129.789 144.242 141.575 160.381 148.789M90.4444 115.289V148.789M90.4444 115.289C90.4444 115.289 89.8709 110.701 97.9445 106.489M90.4444 148.789L49.9444 154.789H29.9444M29.9444 154.789C29.9444 154.789 20.9444 154.789 19.4444 153.789C17.9444 152.789 4.44444 145.789 4.44444 142.289C4.44444 138.789 3.44444 41.789 4.44444 40.289C5.44444 38.789 4 32.2108 11.5 32.5C19 32.7892 16.4444 33.7891 16.4444 33.7891M29.9444 154.789C29.9444 154.789 26.4444 150.289 25.4444 148.789C24.4444 147.289 25.4444 53.289 25.4444 53.289M16.4444 33.7891C16.4444 33.7891 24.3253 37 30.5 37C36.6747 37 42.0556 35.5619 47 33.3509C51.9444 31.14 46.4444 27.5391 46.4444 27.5391M16.4444 33.7891C16.4444 33.7891 16.4444 32.0272 16.4444 27.7892M16.4444 27.7892C16.4444 27.7892 27.4444 32.7892 31.4444 32.7892C35.4445 32.7892 46.4444 27.5391 46.4444 27.5391M97.9445 106.489L76.9834 87.7892M53.4445 66.7892L76.9834 87.7892M76.9834 87.7892L53.4445 113.789M76.9834 87.7892L99.9445 61.7892"
        stroke="white"
        stroke-width="7"
      />
      <path
        d="M46.5 24C31.5 32.2109 23.2107 28.2262 16.6552 24.5152C16.6552 24.5152 12.1552 12.5153 31.6552 13.0152C51.1552 13.5151 46.5 24 46.5 24Z"
        fill={fill}
      />
      <path
        d="M157.5 119C157.5 119 162.5 119 164.5 121.5C166.5 124 177.5 144 173.5 147.5C171.563 149.195 166.61 148.516 161.5 148C158.5 147.5 157.5 147.5 157.5 147.5M16.6552 24.5152C23.2107 28.2262 31.5 32.2109 46.5 24C46.5 24 51.1552 13.5151 31.6552 13.0152C12.1552 12.5153 16.6552 24.5152 16.6552 24.5152Z"
        stroke="black"
        stroke-width="8"
      />
    </svg>
  )
}

import React, { useMemo, useEffect } from "react"

import { useTranslation, useModal } from "../../Contexts"
import { useQueryParam } from "../../domHooks"
import { useFilter } from "../Filter"
import { useToolbar } from "../_components/Toolbar/Toolbar"
import Error from "../_components/Error/Error"

import { NewAllocation } from "./Modals"

import Grid from "./Grid"
import Map from "./Map"
import { IFilterSpec } from "../types"
import { useSiteConfig } from "../../States/siteConfig"
import { useQuery } from "@apollo/client"
import { useSetAllocation } from "./data"

const Allocations = () => {
	const { trans } = useTranslation()
	const { showToolbar, hideToolbar } = useToolbar()
	const [mode] = useQueryParam("mode", "list")
	const { showModal, hideModal } = useModal()
	const { siteConfig } = useSiteConfig()

	const setAllocation = useSetAllocation()

	useEffect(() => {
		function handleAction(action: string) {
			if (action === "create") {
				showModal(
					<NewAllocation
						onConfirm={(allocation: any) => {
							setAllocation(allocation)
							hideModal()
						}}
						onCancel={hideModal}
					/>
				)
			}
		}
		showToolbar("actions.createAllocation", handleAction)
		return () => hideToolbar()
	}, [showToolbar, showModal, hideToolbar, setAllocation, hideModal])

	const config = siteConfig.allocations

	const { client, loading, data, error } = useQuery<{ data: any[] }>(config.data.fetchAll, {
		errorPolicy: "all",
	})

	const allocations = useMemo(() => {
		return data ? config.data.fetchAllParse(data) : []
	}, [data, config])

	const headers = useMemo(() => config.headers, [config.headers])

	const filterSpec: IFilterSpec = useMemo(
		() => config.filters(client, allocations),
		[client, config, allocations]
	)

	const filteredAllocations = useFilter(allocations, filterSpec)

	if (loading) {
		return <></>
	}

	if (error) {
		console.error(error)
		return <Error>{trans("errors.failedFetch")}</Error>
	}

	if (mode === "map") {
		return <Map allocations={filteredAllocations} />
	}

	return <Grid allocations={filteredAllocations} headers={headers} />
}

export default Allocations

import { IEventDatesConfig } from "../../../Resources/Events/types"

export const default_eventsDatesConfig: IEventDatesConfig = {
	filterEventsByTime: false, // Limiting event results or not
	maxUnfilteredDays: 2,
	maxUnfilteredHours: 0,
	maxFilteredDays: 0, // If set to 0 - unlimited
	calendarIntervals: [
		[1, "day"],
		[2, "days"],
		[3, "days"],
		[1, "week"],
		[2, "weeks"],
		[1, "month"],
		[2, "months"],
		[3, "months"],
		[6, "months"],
	],
}

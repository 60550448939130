import { useMutation } from "@apollo/client"
import { gql } from "apollo-boost"
import { useCallback } from "react"
import {
	IAllocationModified,
	IAllocationModifiedPoint,
	IAllocationPoint,
	IAllocationRaw,
	IAllocationTemplate,
} from "../../Configs/configurations/default/queries/default_fetchAll_allocations"
import { GET_ALLOCATION_BY_ID } from "../../Configs/configurations/default/queries/default_fetchById_allocations"
import { useSiteConfig } from "../../States/siteConfig"
import {
	globalAlertMutationSaved,
	handleMutationErrorWithGlobalAlert,
	postFunc,
} from "../../Utils/api"
import { keyValueArrayToObject } from "../../Utils/objArr"

function parseLocationCode(allocation: IAllocationTemplate) {
	const [municipality, streetCode, houseNumber] = (allocation?.geoLocationCode || "").split(".")
	return {
		municipality: municipality || "",
		streetCode: streetCode || "",
		houseNumber: houseNumber || "",
	}
}

function transformPoint(point: IAllocationPoint) {
	return {
		...point.point,
		priority: point.priority,
	}
}

function transformAllocation(allocation: IAllocationTemplate) {
	return {
		...allocation,
		...parseLocationCode(allocation),
		properties: keyValueArrayToObject(allocation.properties),
		externalKeys: keyValueArrayToObject(allocation.externalKeys),
		points: allocation.points?.map(transformPoint) || [],
	}
}

export function allocationsFromResponse(data: IAllocationRaw | undefined) {
	return data?.data?.map(transformAllocation) || []
}

export function allocationFromResponse(data: IAllocationRaw | undefined) {
	return [data?.data?.map(transformAllocation)]?.map(a => (a?.length ? a[0] : {}))[0]
}

export const SET_ALLOCATION = gql`
	mutation ACSetAllocation($input: setAllocationsMutationInput!) {
		setAllocationsMutation(input: $input) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

function allocationId({
	municipality,
	streetCode,
	houseNumber,
}: {
	municipality: string
	streetCode: string
	houseNumber: string
}) {
	if (
		municipality &&
		municipality !== "" &&
		streetCode &&
		streetCode !== "" &&
		houseNumber &&
		houseNumber !== ""
	) {
		return `${municipality}.${streetCode}.${houseNumber}`
	}
	return null
}

function setAllocationPayload(allocation: IAllocationModified) {
	const id = allocationId(allocation)
	if (!id) {
		throw new Error(`could not make id from allocation ${allocation}`)
	}

	return {
		input: {
			wait: true,
			payload: {
				allocations: [
					{
						id,
						externalKeyName: "geoLocationCode",
						properties: [
							{
								key: "geoLocationName",
								value: allocation.geoLocationName,
							},
							{
								key: "assignHousehold",
								value: "1",
							},
							{
								key: "assignBusiness",
								value: "0",
							},
						],
						newPoints: allocation.points.map(p => ({
							id: p.id,
							priority: p.priority,
						})),
					},
				],
			},
		},
	}
}

// export function useGetAllocations() {
// 	const dataMethods = useSiteConfig(state => state.siteConfig.allocations.data)
// 	const { loading, data, error, client } = useQuery(dataMethods.fetchAll)
// 	const allocations = useMemo(
// 		() => (data ? dataMethods.fetchAllParse(data) : []),
// 		[data, dataMethods]
// 	)
// 	return { loading, allocations, error, client }
// }

export function useSetAllocation() {
	const refreshQuery = useSiteConfig(state => state.siteConfig.allocations.data.fetchAll)
	const [setAllocation] = useMutation(SET_ALLOCATION, {
		refetchQueries: [{ query: refreshQuery }],
	})

	const updateFunction = useCallback(
		(allocation: any) => {
			handleMutationErrorWithGlobalAlert(
				setAllocation({
					variables: setAllocationPayload(allocation),
				})
			)
		},
		[setAllocation]
	)

	return updateFunction
}

export const modifyAllocation =
	(allocation: IAllocationModified | undefined) =>
	(operation: "add" | "remove" | "create", _points: IAllocationModifiedPoint[]) => {
		if (allocation) {
			const input =
				operation === "add"
					? // Removing if already existing and adding all as new again
					  [allocation.points.filter(p1 => !_points.find(p2 => p1.id === p2.id))].map(
							filteredPoints => ({ ...allocation, points: [...filteredPoints, ..._points] })
					  )[0]
					: {
							...allocation,
							points: allocation.points.filter(p1 => !_points.find(p2 => p1.id === p2.id)),
					  }

			postFunc(SET_ALLOCATION, globalAlertMutationSaved, {
				variables: setAllocationPayload(input),
				refetchQueries: [{ query: GET_ALLOCATION_BY_ID, variables: { id: allocation.id } }],
			})
		}
	}

import { getContainerTypes } from "./all"

export const lfmEnterprise_containertypes = () =>
	getContainerTypes([
		"bin40",
		"bin140",
		"bin240",
		"bin360",
		"bin660",
		"bin770",
		"bin1000",
		"drum209",
		"bag100",
		"bag125",
		"bag1000",
		"container5000",
		"container10000",
		"compressor10000",
		"compressor14000",
		"compressor15000",
		"compressor16000",
		"compressor18000",
		"compressor20000",
		"openhookcontainer22000",
		"case1000",
		"elcage1500",
		"ibc1000",
		"closedibc1000",
		"redbox",
		"palletframe1000",
		"palletbox800",
		"palletbox500",
		"specialcontainer",
		"mrfillcabinet140",
		"mrfillcabinet240",
		"pottingercompressor10000",
		"pottingercompressor12000",
		"pottingercompressor14000",
		"pottingercompressor16000",
		"pottingercompressor18000",
		"pottingercompressor20000",
		"carton",
	])

import { mergeConfig } from "../config"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { defaultConfig } from "./default"
import { iksConfigObj } from "./iks"
import { iris_drawerContent_customers } from "./iris/drawer/iris_drawerContent_customers"

export const irisConfig = (): ISiteConfig => mergeConfig(defaultConfig(), irisConfigObj)

export const irisConfigObj: IPartialSiteConfig = {
	...iksConfigObj,
	customers: {
		drawer: {
			content: iris_drawerContent_customers,
		},
	},
}

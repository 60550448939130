import { useEffect, useMemo } from "react"
import { BrowserRouter as Router } from "react-router-dom"

import { ApolloProvider } from "@apollo/client"
import createApolloClient from "./apollo"

import Main from "./Main"
import Nav from "./Nav"
import { Spinner } from "./UI"

import { useAuth0, useTranslation } from "./Contexts"
import { ModalProvider } from "./Contexts/Modal"

import "./App.css"
import { configName, isStaging, siteConfig } from "./Configs/config"
import { useApolloStore } from "./States/apollo"
import { useSiteConfig } from "./States/siteConfig"
import { GlobalAlert } from "./UI/AlertLine/GlobalAlert"
import { ContextMenu } from "./UI/ContextMenu"
import { ConfirmationDialog } from "./UI/Overlays/ConfirmationDialog"

export const Loading = () => {
	const { trans } = useTranslation()
	return (
		<div id="initapp">
			<Spinner />
			<h1>{trans("starting")}</h1>
		</div>
	)
}

const App = () => {
	const { loading, isAuthenticated, loginWithRedirect, getTokenSilently } = useAuth0()!

	const { setClient } = useApolloStore()
	const { setSiteConfig } = useSiteConfig()

	const apolloClient = useMemo(
		() => {
			const ac = createApolloClient(getTokenSilently)
			setClient(ac)
			return ac
		},
		// eslint-disable-next-line
		[loading]
	)

	// Set specific site config to global config state at init
	useEffect(() => {
		setSiteConfig(siteConfig(configName))
	}, [setSiteConfig])

	useEffect(() => {
		// Adding test to browser tab (website title) when in staging mode.
		if (isStaging) document.title = document.title + " Test"
	}, [])

	if (loading) {
		return <Loading />
	}

	if (!isAuthenticated) {
		loginWithRedirect({
			appState: {
				targetUrl: `${window.location.pathname}${window.location.search}`,
			},
		})
		return <Loading />
	}

	return (
		<ApolloProvider client={apolloClient}>
			<Router>
				<ModalProvider>
					<Nav />
					<Main />
					<GlobalAlert />
				</ModalProvider>
				<ConfirmationDialog />
				<ContextMenu />
			</Router>
		</ApolloProvider>
	)
}

export default App

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import {
	df_input_fraction,
	df_input_name,
	df_input_status,
	df_input_terminal,
} from "../../_drawerFragments/inputs"

export const iks_drawerContent_containers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name({ header: "stationModal.inletName" }),
			df_input_terminal(),
			df_input_status(),
			df_input_fraction(),
			{
				updater: "property",
				key: "properties.vendor",
				header: "properties.vendor",
				permission: "edit",
				type: "text",
				// dropdownOptions: default_vendorOptions_station,
			},
		],
	},
	pointEditorDrawer: null,
}

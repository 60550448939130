import { useEffect, useMemo, useState } from "react"
import { useGlobalAlert } from "../../States/globalAlert"
import { AlertLine } from "./AlertLine"
import styles from "./GlobalAlert.module.css"

export const GlobalAlert = () => {
	const { globalAlert, setGlobalAlert } = useGlobalAlert()
	const [showAnim, setShowAnim] = useState<boolean>(false)

	const animClose = () => {
		setShowAnim(false)
		setTimeout(() => setGlobalAlert(null), 1000)
	}

	const hideButtonByType = useMemo(
		() =>
			globalAlert?.type === "danger" ||
			globalAlert?.type === "warning" ||
			globalAlert?.type === "info",
		[globalAlert]
	)

	useEffect(() => {
		if (globalAlert) {
			let timer1: NodeJS.Timeout
			let timer2: NodeJS.Timeout
			let timer3: NodeJS.Timeout

			// Slide in animation
			timer1 = setTimeout(() => setShowAnim(true), 100)
			// If success - slide out animation after 5 sec
			if (globalAlert.type === "success") {
				timer2 = setTimeout(() => {
					setShowAnim(false)
					timer3 = setTimeout(() => setGlobalAlert(null), 1000)
				}, 5000)
			}

			// Clear timeouts when closing popup
			return () => {
				if (timer1) clearInterval(timer1)
				if (timer2) clearInterval(timer2)
				if (timer3) clearInterval(timer3)
			}
		}
	}, [globalAlert, setGlobalAlert])

	return (
		<>
			{globalAlert ? (
				<AlertLine
					className={`${styles.globalAlert} ${showAnim ? styles.show : ""}`}
					type={globalAlert.type}
					show
					hideButton={hideButtonByType}
					setShow={animClose}
					message={globalAlert.message}
				/>
			) : (
				<></>
			)}
		</>
	)
}

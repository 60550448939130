import React from "react"
import "./Spinner.css"

const Spinner = ({
	centered = false,
	height,
	thickness,
}: {
	centered?: boolean
	height?: number
	thickness?: number
}) => {
	const style = centered
		? {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: height || "100%",
				flex: 1,
		  }
		: ({} as React.CSSProperties)

	return (
		<div className="spinner" style={style}>
			<svg
				height={height}
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="50"
					cy="50"
					r="45"
					style={{ strokeWidth: thickness ? `${thickness}px` : undefined }}
				/>
			</svg>
		</div>
	)
}

export default Spinner

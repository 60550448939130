import { cloneDeep } from "lodash"
import { IRow } from "../../Resources/types"
import { CUSTOMER_TYPE } from "../../Utils/gqlRequestTypes/generic"

export interface ICustomerConfigState {
	tab: 0 | 1
	name: string
	customerType?: CUSTOMER_TYPE
	allocationScheme?: "NONE" | "" | string
	properties: {
		[key: string]: string
	}
	externalKeys: {
		[key: string]: string
	}
	operatorID?: string
	operatorList: string[]
	points: { id: string; name: string }[]
	selectedPoint: { id: string; name: string }
	tableColumns: { key: string; name: string; required?: boolean }[]
	tableRows: IRow[]
	errorFields?: {
		[key: string]: boolean
	}
	warningFields?: {
		[key: string]: boolean
	}
}

export type IReducerAction =
	| { type: "set"; key: keyof ICustomerConfigState; value: string }
	| { type: "setProperty"; key: string; value: string }
	| { type: "setKey"; key: string; value: string }
	| { type: "addPoint"; id: string; name: string }
	| { type: "removePoint"; index: number }
	| { type: "setPoint"; point: { id: string; name: string } }
	| { type: "setOperatorList"; list: string[] }
	| { type: "switchTab"; tab: 0 | 1 }
	| { type: "setTableColumns"; columns: ICustomerConfigState["tableColumns"] }
	| {
			type: "setTableRows"
			rows: IRow[]
	  }
	| {
			type: "setErrorFields"
			fields: {
				[key: string]: boolean
			}
	  }
	| { type: "deleteErrorField"; field: string }
	| {
			type: "setWarningFields"
			fields: {
				[key: string]: boolean
			}
	  }
	| { type: "deleteWarningField"; field: string }

export function CustomerConfigReducer(state: ICustomerConfigState, action: IReducerAction) {
	const newState = cloneDeep(state)
	switch (action.type) {
		case "set":
			return { ...state, [action.key]: action.value }
		case "setProperty":
			newState.properties[action.key] = action.value
			return newState
		case "setKey":
			newState.externalKeys[action.key] = action.value
			return newState
		case "addPoint":
			return {
				...state,
				points: [...state.points, state.selectedPoint],
				selectedPoint: { id: "", name: "" },
			}
		case "setPoint":
			return { ...state, selectedPoint: action.point }
		case "setOperatorList":
			return { ...state, operatorList: action.list }
		case "removePoint":
			return {
				...state,
				points: state.points.filter((_, index) => {
					if (index === action.index) return false
					return true
				}),
			}
		case "switchTab":
			return { ...state, tab: action.tab }
		case "setTableColumns":
			return { ...state, tableColumns: action.columns }
		case "setTableRows":
			return { ...state, tableRows: action.rows }
		case "setErrorFields":
			if (!newState.errorFields) newState.errorFields = {}
			newState.errorFields = action.fields
			return newState
		case "deleteErrorField":
			if (!newState.errorFields) return newState
			delete newState.errorFields[action.field]
			return newState
		case "setWarningFields":
			if (!newState.warningFields) newState.warningFields = {}
			newState.warningFields = action.fields
			return newState
		case "deleteWarningField":
			if (!newState.warningFields) return newState
			delete newState.warningFields[action.field]
			return newState
		default:
			throw new Error()
	}
}

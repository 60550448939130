import { SVGProps } from 'react'

export const FrityrIcon = ({
  fill = 'black',
  width = '162',
  height = '251',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 162 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.6 11C92.1 32.8 98.8 45.1 123 82C132.2 96 142 111.6 144.9 116.5C162.3 147.3 165.6 174.2 155.1 200.5C150.8 211.1 146 218.1 136.5 227.5C115.1 248.8 83.8 256 55 246.4C29.8 238 9.59999 216.4 2.49999 190.5C0.199993 181.9 -0.499998 166.6 0.900002 157.5C3.9 138.9 12.3 121.4 34.2 88.4C60 49.3 70.5 30.1 77.1 9.89999C78.9 4.49999 80.5 0.0999878 80.6 0.299988C80.8 0.499988 82.6 5.3 84.6 11Z"
        fill={fill}
      />
    </svg>
  )
}

export const kvam_translations: any = {
	en: {
		headers: {
			visitDurationInMinutes: "Visit in minutes",
			zoneWeights: "Weight by zone",
		},
	},
	nb: {
		headers: {
			visitDurationInMinutes: "Besøk i minutter",
			zoneWeights: "Vekt per sone",
		},
	},
}

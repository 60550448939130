import React from "react"
import { useTranslation } from "../../../../Contexts"
import { useQueryParam } from "../../../../domHooks"
import "./SearchInput.css"

type ISearchInput = {
	placeholder: any
	filterKey: any
}

const SearchInput = ({ placeholder, filterKey }: ISearchInput) => {
	const { trans } = useTranslation()
	const [filter, setFilter] = useQueryParam(`filter.${filterKey}`, "")

	return (
		<label className="filter-searchbox">
			<input
				type="text"
				placeholder={trans(placeholder)}
				value={filter}
				onChange={(ev) => setFilter(ev.target.value)}
			/>
		</label>
	)
}

export default SearchInput

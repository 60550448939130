import React, { useCallback, useState } from "react"
import { useTranslation } from "../../../Contexts"
import { ModalContainer } from "../../../UI"
import { useGetAccessPoints } from "../data"
import "./EditAccessPoints.css"
import { SelectAccessPoints } from "./SelectAccessPoints"

export const EditAccessPoints = ({ onConfirm, onCancel, loadingOverride, pointsOverride }: any) => {
	const { trans } = useTranslation()
	const [newPoints, setNewPoints] = useState<any>([])
	const [removePoints, setRemovePoints] = useState<any>([])
	const { loading, points: accessPoints } = useGetAccessPoints("points")

	const applyChange = useCallback(() => {
		if (onConfirm && (newPoints.length > 0 || removePoints.length > 0)) {
			onConfirm({ newPoints, removePoints })
		}
	}, [onConfirm, newPoints, removePoints])

	return (
		<ModalContainer
			width="600px"
			title={trans("actions.editCustomerPoints")}
			onConfirm={applyChange}
			onCancel={onCancel}
			loading={loadingOverride || loading}
		>
			<div className="edit-accesspoints-container">
				<SelectAccessPoints
					header="actions.removePoints"
					accessPoints={pointsOverride || accessPoints}
					onChange={setRemovePoints}
				/>
				<SelectAccessPoints
					header="actions.addPoints"
					accessPoints={pointsOverride || accessPoints}
					onChange={setNewPoints}
				/>
			</div>
		</ModalContainer>
	)
}

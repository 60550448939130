import { DocumentNode, useApolloClient, useMutation, useQuery } from "@apollo/client"
import { gql } from "apollo-boost"
import { get } from "lodash"
import { useCallback, useMemo } from "react"
import {
	ICustomerByIdIdentitiesModified,
	ICustomerByIdModified,
	ICustomerByIdPointModified,
	ICustomerByIdPoints,
	Idefault_fetchById_customers,
	IGetCustomerByIdIdentity,
} from "../../Configs/configurations/default/queries/default_fetchById_customers"
import { useSiteConfig } from "../../States/siteConfig"
import {
	globalAlertMutationSaved,
	handleMutationErrorWithGlobalAlert,
	postFunc,
} from "../../Utils/api"
import { ICustomerQueryResult } from "../types"

export function useGetCustomers() {
	const dataFetch = useSiteConfig(state => state.siteConfig.customers.data)
	const { loading, error, data, client } = useQuery(dataFetch.fetchAll)
	const customers = useMemo<ICustomerQueryResult>(
		() => (data ? dataFetch.fetchAllParse(data) : []),
		[data, dataFetch]
	)
	return { loading, error, data: customers, client }
}

export const PRIMARY_POINT_LIST_FILTER = gql`
	query ACGetAccessPoints {
		store {
			accessPoints(type: "ACCESS_PARENT") {
				id
				name
			}
		}
	}
`

export const BUSINESS_PARENT_FILTER = gql`
	query ACGetAccessPoints {
		store {
			customers(customerTypes: BUSINESS_PARENT) {
				id
				name
			}
		}
	}
`

export const PRIMARY_ADDRESS_QUERY = gql`
	query ACGetPrimaryAddress($pointId: String) {
		store {
			accessPoint(id: $pointId) {
				id
				customers(priority: 1) {
					id
				}
			}
		}
	}
`

export const GET_CUSTOMERS_BY_ID = gql`
	query ACGetCustomersById($id: [String]) {
		store {
			customers(ids: $id) {
				id
				name
				unitCode: externalKey(key: "unitCode")
				address: property(key: "geoLocationName")
				crmId: externalKey(key: "PAAvtaleID")
				points {
					priority
					point {
						id
						name
						type
						status
						fraction: property(key: "fraction")
						pos: geoLocation {
							lat: latitude
							lon: longitude
						}
					}
				}
				identities {
					id
					status
					i: identity {
						keys: externalKeys {
							key
							value
						}
					}
				}
			}
		}
	}
`

export const GET_CUSTOMER_BY_ID = gql`
	query ACGetCustomersById($id: String) {
		store {
			customers(id: $id) {
				id
				name
				unitCode: externalKey(key: "unitCode")
				address: property(key: "geoLocationName")
				crmId: externalKey(key: "PAAvtaleID")
				points {
					priority
					point {
						id
						name
						type
						status
						fraction: property(key: "fraction")
						pos: geoLocation {
							lat: latitude
							lon: longitude
						}
					}
				}
				identities {
					id
					status
					i: identity {
						keys: externalKeys {
							key
							value
						}
					}
				}
			}
		}
	}
`
export const UPDATE_IDENTITY_STATUS = gql`
	mutation UpdateIdentityStatus($input: setCustomerIdentitiesStatusMutationInput!) {
		setIdentitiesStatusMutation(input: $input) {
			primaryKey
			apiResultCode
			commandProcessError
			clientMutationId
		}
	}
`
type IdentityStatus = "ACTIVE" | "DISABLED"
// TODO CustomerId
export function updateIdentityStatusPayload(
	customerId: string,
	status: IdentityStatus,
	identities: any[]
) {
	function transform(identity: any) {
		const externalKeyName = identity["ISO"] ? "ISO" : identity["EM"] ? "EM" : null
		const id = externalKeyName && identity[externalKeyName]
		if (!id) return null
		return { externalKeyName, id, status }
	}

	return {
		input: {
			wait: true,
			payload: {
				identities: identities.map(transform).filter(x => x),
			},
		},
	}
}

export const updateIdentityStatus =
	(customerId: string | undefined, refetchQuery: DocumentNode) =>
	(status: IdentityStatus, identities: any[]) => {
		if (customerId) {
			postFunc(UPDATE_IDENTITY_STATUS, globalAlertMutationSaved, {
				variables: updateIdentityStatusPayload(customerId, status, identities),
				refetchQueries: [{ query: refetchQuery, variables: { id: customerId } }],
			})
		}
	}

export function transformIdentities(identities: IGetCustomerByIdIdentity[] | null) {
	return identities?.length
		? identities.map(id => {
				const idKeys = id.i.keys?.length
					? id.i.keys.reduce((result, { key, value }) => ({ [key]: value, ...result }), {})
					: []
				return { ...idKeys, status: id.status, id: id.id }
		  })
		: []
}

export function toLeafletFormat(pos?: { lat: number; lon: number } | null) {
	return pos ? [pos.lat, pos.lon] : null
}

// TODO: move to Data/Points?
export function transformPoints(points: ICustomerByIdPoints[]) {
	const primaryPoints: ICustomerByIdPointModified[] = []
	const backupPoints: ICustomerByIdPointModified[] = []

	points.forEach((p: ICustomerByIdPoints) => {
		if (p.point) {
			const point = {
				...p.point,
				properties: p.point.properties.reduce(
					(prev, prop) => ({ ...prev, [prop.key]: prop.value }),
					{}
				),
				priority: p.priority,
				pos: toLeafletFormat(p.point.pos),
			}

			point.priority === 1 ? primaryPoints.push(point) : backupPoints.push(point)
		}
	})

	return {
		points: primaryPoints,
		backupPoints,
	}
}

export const getCustomerDataById = (data: Idefault_fetchById_customers) => {
	const customer = data.store.data ? data.store.data[0] : null
	if (customer) {
		const identities: ICustomerByIdIdentitiesModified[] = transformIdentities(customer.identities)
		const { points, backupPoints } = transformPoints(customer.points?.length ? customer.points : [])
		return { entity: customer, identities, points, backupPoints }
	} else {
		return {} as ICustomerByIdModified
	}
}

export function useUpdateCustomerName(id: string | undefined) {
	const fetchAll = useSiteConfig(state => state.siteConfig.customers.data.fetchAll)
	const [renameCustomer] = useMutation(UPDATE_CUSTOMER, {
		refetchQueries: [
			{
				query: fetchAll,
			},
		],
	})

	function renameCustomerPayload(id: string, name: string) {
		return {
			input: {
				wait: true,
				payload: {
					customerId: id,
					updateFields: {
						name,
					},
				},
			},
		}
	}

	const onCustomerNameChange = (newName: string) => {
		if (id) {
			handleMutationErrorWithGlobalAlert(
				renameCustomer({ variables: renameCustomerPayload(id, newName) }).then(data => {
					globalAlertMutationSaved()
					return data
				})
			)
		}
	}

	return onCustomerNameChange
}

export const UPDATE_CUSTOMER = gql`
	mutation ACUpdateCustomer($input: updateCustomerMutationInput!) {
		updateCustomerMutation(input: $input) {
			primaryKey
			scenarioId
			apiResultCode
			commandProcessError
		}
	}
`
export function customerModifyPoints(customer: any, newPoints: any[], removePoints: any[]) {
	const customerId = get(customer, "id")
	if (!customerId) {
		console.error("id not found on customer", customer)
		return null
	}

	return {
		input: {
			payload: {
				customerId,
				newPoints: newPoints.map(p => ({
					id: p.id,
					resolveRedundancyPoints: true,
					priority: 1,
				})),
				removePoints: removePoints.map(p => ({
					id: p.id,
					resolveRedundancyPoints: true,
				})),
			},
			wait: true,
			failOnError: true,
		},
	}
}

export const modifyCustomerPoints =
	(customer: ICustomerByIdModified | undefined, refetchQuery: DocumentNode) =>
	(operation: "add" | "remove" | "create", points: any[]) => {
		if (customer) {
			const input =
				operation === "add"
					? customerModifyPoints(customer, points, [])
					: customerModifyPoints(customer, [], points)

			if (input) {
				postFunc(UPDATE_CUSTOMER, globalAlertMutationSaved, {
					variables: input,
					refetchQueries: [
						{
							query: refetchQuery,
							variables: { id: customer.id },
						},
					],
				})
			}
		}
	}

export function objectToString(obj: any) {
	const isObject = (val: any) => typeof val === "object" && !Array.isArray(val)

	const isArray = (val: any) => typeof val === "object" && Array.isArray(val)

	const recur = (obj: any): any => {
		if (isObject(obj)) {
			const ents = Object.entries(obj).map(([key, value]) => {
				return `${key}: ${recur(value)}`
			})
			return `{ ${ents.join(", ")} }`
		}

		if (isArray(obj)) {
			const values = obj.map((value: any) => recur(value))
			return `[${values.join(", ")}]`
		}

		if (typeof obj === "string") {
			return `"${obj}"`
		}

		return obj
	}

	return recur(obj) + ""
}

export function editCustomersPointsQuery(customers: any[], newPoints: any[], removePoints: any[]) {
	const mutations = customers
		.map(c => [c.id, customerModifyPoints(c, newPoints, removePoints)!.input])
		.reduce((agg: string, [id, payload]: any) => {
			const mutation = `id${id}:updateCustomerMutation(input: ${objectToString(
				payload
			)}) {primaryKey apiResultCode commandProcessError}`
			return agg + " " + mutation
		}, "")

	return gql`mutation AddCustomerPoints { ${mutations} }`
}

export function useAddPointsToCustomers() {
	const client = useApolloClient()

	const updateFunction = useCallback(
		(customers: any[], newPoints: any[], removePoints: any[]) => {
			return handleMutationErrorWithGlobalAlert(
				client.mutate({
					mutation: editCustomersPointsQuery(customers, newPoints, removePoints),
					refetchQueries: [
						{
							query: GET_CUSTOMERS_BY_ID,
							variables: { id: customers.map((c: any) => c.id) },
						},
					],
				})
			)
		},
		[client]
	)

	return updateFunction
}

const ADD_CUSTOMER_IDENTITIES = gql`
	mutation AddCustomerIdentities($input: addCustomerIdentitiesMutationInput!) {
		addCustomerIdentitiesMutation(input: $input) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`

function addCustomerIdentitiesPayload(customer: any, identities: any[]) {
	return {
		input: {
			wait: true,
			payload: {
				customerId: get(customer, "id"),
				newIdentities: identities,
			},
		},
	}
}

export const REMOVE_CUSTOMER_IDENTITIES = gql`
	mutation RemoveCustomerIdentities($input: removeCustomerIdentitiesMutationInput!) {
		removeCustomerIdentitiesMutation(input: $input) {
			primaryKey
			apiResultCode
			commandProcessError
		}
	}
`
function removeCustomerIdentitiesPayload(customer: any, identities: any[]) {
	function transform(identity: any) {
		const externalKeyName = identity["ISO"] ? "ISO" : identity["EM"] ? "EM" : null
		const id = externalKeyName && identity[externalKeyName]
		if (!id) return null
		return { externalKeyName, id }
	}

	return {
		input: {
			wait: true,
			payload: {
				customerId: get(customer, "id"),
				removeIdentities: identities.map(transform).filter(x => x),
			},
		},
	}
}

export function useAddCustomerIdentities(customer: any) {
	const [addCustomerIdentities] = useMutation(ADD_CUSTOMER_IDENTITIES, {
		refetchQueries: [
			{
				query: GET_CUSTOMER_BY_ID,
				variables: { id: get(customer, "id") },
			},
		],
	})

	const updateFunction = useCallback(
		(newIdentities: any[]) => {
			if (newIdentities.length > 0) {
				handleMutationErrorWithGlobalAlert(
					addCustomerIdentities({
						variables: addCustomerIdentitiesPayload(customer, newIdentities),
					})
				)
			}
		},
		[addCustomerIdentities, customer]
	)

	return updateFunction
}

export function useDeleteCustomerIdentities(customer: any, refetchQuery: DocumentNode) {
	const [removeCustomerIdentities] = useMutation(REMOVE_CUSTOMER_IDENTITIES, {
		refetchQueries: [{ query: refetchQuery, variables: { id: customer.id } }],
	})

	const updateFunction = useCallback(
		(identities: any[]) => {
			handleMutationErrorWithGlobalAlert(
				removeCustomerIdentities({
					variables: removeCustomerIdentitiesPayload(customer, identities),
				})
			)
		},
		[removeCustomerIdentities, customer]
	)

	return updateFunction
}

import { gql } from "apollo-boost"
import { some } from "lodash"
import { useReducer, useState } from "react"
import { KeyValFieldConfig } from "../../Configs/types"
import { useModal, useTranslation } from "../../Contexts"
import { translate } from "../../Contexts/Translation"
import { IRow } from "../../Resources/types"
import { useGeneralConfig } from "../../States/generalConfig"
import { useSiteConfig } from "../../States/siteConfig"
import { IAuthJwt } from "../../types"
import { ModalContainer } from "../../UI"
import { postFunc } from "../../Utils/api"
import { generateGQLrequest } from "../../Utils/gqlRequestGenerator"
import { CUSTOMER_TYPE } from "../../Utils/gqlRequestTypes/generic"
import { UpsertCustomerEntryPayload } from "../../Utils/gqlRequestTypes/upsertCustomersMutation"
import { IUIDGeneratorTypes, uniqueIdGenerator } from "../../Utils/uniqueIdGenerator"
import "../CustomerAndStationModal.css"
import { AddSingleCustomer } from "./AddSingleCustomer"
import { CustomerConfigReducer, ICustomerConfigState } from "./CustomerModalReducer"
import { CUSTOMER_MUTATION, NewCUSTOMERmutationGen } from "./Functions/generateCustomerMutation"
import { ImportFromTable } from "./ImportFromTable"

export type CustomerModalConfig = {
	modes: ("create" | "upsert")[]
	customerTypes: CUSTOMER_TYPE[]
	allocationScheme: (string | "NONE")[]
	useDescriptionField?: boolean
	mandatory?: {
		station?: boolean | ((jwt: IAuthJwt | null) => boolean)
	}
	propertyList: KeyValFieldConfig
	/**
	 * A list of external keys that will show up in single customer creation
	 * Batch import requires atleast one externalKey and will use the first one and will be required
	 */
	externalKeyList: KeyValFieldConfig
	autoCreateParent?: {
		// TODO:
		suffix: string
	}
	customerAutoKey?: {
		key: string
		generator: IUIDGeneratorTypes
		lenght?: number
		prefix?: string
	}
}

const GET_CUSTOMERS =
	useSiteConfig?.getState()?.siteConfig?.customers?.data.fetchAll ||
	gql`
		{
			store {
				customers {
					id
					name
					address: property(key: "geoLocationName")
					crmId: externalKey(key: "PAAvtaleID")
				}
			}
		}
	`

const createSingleCustomer = async (
	configState: ICustomerConfigState,
	onSuccess: (res?: any) => void,
	setError?: any
) => {
	const { name, customerType, allocationScheme, properties, externalKeys, points } = configState

	const filterKeyValList = (obj: { [key: string]: string }) => {
		const filteredObj = Object.entries(obj).filter(([key, value]) => {
			if (value) return true
			return false
		})
		return filteredObj.map(([key, value]) => {
			return { key, value }
		})
	}

	const stationObject: CUSTOMER_MUTATION = {
		type: customerType || "BA",
		payload: {
			name: name,
			allocationScheme: allocationScheme || undefined,
			...(configState.operatorList.length
				? {
						operator: {
							id:
								configState.operatorList.length === 1
									? configState.operatorList[0]
									: configState.operatorID!,
						},
				  }
				: {}),
			properties: filterKeyValList(properties),
			externalKeys: filterKeyValList(externalKeys),
			newPoints: points.map(({ id }) => ({ id })),
		},
	}

	const GQL_REQUEST = NewCUSTOMERmutationGen(stationObject)

	postFunc(
		GQL_REQUEST,
		res => {
			onSuccess(res)
			return res
		},
		{
			refetchQueries: [
				{ query: GET_CUSTOMERS }, // Refresh customer list
			],
		}
	)
}

const upsertCustomers = (
	configState: ICustomerConfigState,
	onSuccess: (res?: any) => void,
	setError: any
) => {
	const { customerType, tableRows, tableColumns } = configState
	const { customerModal: config } = useSiteConfig.getState().siteConfig
	if (!tableRows.length) return setError("No data supplied")
	const missingFields = some(tableRows, row =>
		some(tableColumns, ({ required, key }) => required && !row[key]?.trim())
	)
	if (missingFields) {
		setError(translate("stationModal.errors.fields"))
		return
	}

	const customerList = tableRows.map(row => {
		const props = Object.entries(row)
			.map(([key, prop]) => {
				if (!config?.propertyList.some(p => p.key === key)) return undefined
				return {
					key: key,
					value: prop.trim(),
				}
			})
			.filter(f => f)
		return {
			id: config?.customerAutoKey
				? (config.customerAutoKey.prefix || "") +
				  uniqueIdGenerator(config.customerAutoKey.generator, config.customerAutoKey.lenght)
				: row[config?.externalKeyList[0].key!].trim(),
			externalKeyName: config?.customerAutoKey?.key || tableColumns[0].key,
			updateFields: {
				name: row["customerName"].trim(),
				...(config?.useDescriptionField ? { description: row["description"].trim() } : {}),
				customerType: customerType || "BA",
			},
			...(configState.operatorList.length
				? {
						updateOperator: {
							id:
								configState.operatorList.length === 1
									? configState.operatorList[0]
									: configState.operatorID,
						},
				  }
				: {}),
			propertiesUpdate: {
				mode: "SET",
				props,
			},
			newPoints: row.stationsToAssign_ids?.map((id: string) => ({ id })) || [],
		} as UpsertCustomerEntryPayload
	})

	const GQL_REQUEST = generateGQLrequest({
		type: "upsertCustomersMutation",
		input: {
			wait: true,
			payload: {
				customers: customerList,
			},
		},
	})

	postFunc(
		GQL_REQUEST,
		res => {
			return res
		},
		{
			refetchQueries: [
				{ query: GET_CUSTOMERS }, // Refresh customer list
			],
		}
	)
	onSuccess()
}

const listToObject = (list: { key: string; name: string; value: string }[] | undefined) => {
	const convertedObject: any = {}
	list?.forEach(item => {
		convertedObject[item.key] = item.value
	})
	return convertedObject
}

interface ICustomerModalProps {
	onClose?: () => void
	useHistory?: any
	customers: IRow[] // ICustomerQueryResult
}

const CustomerModal = (props: ICustomerModalProps) => {
	const { siteConfig } = useSiteConfig()
	const config = siteConfig.customerModal

	const { trans } = useTranslation()
	const { hideModal } = useModal()

	const [errorMessage, setError] = useState<string | null>(null)
	const [state, dispatch] = useReducer(CustomerConfigReducer, {
		tab: 0,
		name: "",
		customerType: config?.customerTypes.length === 1 ? config?.customerTypes[0] : undefined,
		operatorList: [],
		allocationScheme:
			config?.allocationScheme.length === 1 ? config?.allocationScheme[0] : undefined,
		properties: listToObject(config?.propertyList),
		externalKeys: {
			...listToObject(config?.externalKeyList),
			...(config?.customerAutoKey
				? {
						[config?.customerAutoKey?.key]:
							(config.customerAutoKey.prefix || "") +
							uniqueIdGenerator(config.customerAutoKey.generator, config.customerAutoKey.lenght),
				  }
				: {}),
		},
		points: [],
		selectedPoint: { id: "", name: "" },
		tableColumns: [],
		tableRows: [],
	})
	const { tab } = state

	const jwt = useGeneralConfig().authJwt

	const handleConfirm = () => {
		const { name, customerType } = state
		const mandatory = {
			station:
				typeof config?.mandatory?.station === "function"
					? config.mandatory.station(jwt)
					: !!config?.mandatory?.station,
		}
		const testRequiredFields = () => {
			const missingRequiredProps = config?.propertyList.some(
				({ key, required }) => required && !state.properties[key]
			)
			const missingRequiredKeys = config?.externalKeyList.some(
				({ key, required }) => required && !state.properties[key]
			)
			return !(missingRequiredProps && missingRequiredKeys)
		}

		if (!state.operatorID && state.operatorList.length > 1)
			return setError(trans("customerModal.errorOperator"))

		setError("")
		if (tab === 0) {
			if (mandatory.station && !state.points.length)
				return setError(trans("customerModal.errorStation"))
			if (!testRequiredFields() || !name || !customerType)
				return setError(trans("customerModal.error"))
			createSingleCustomer(
				state,
				(res?: any) => {
					if (res) {
						const customerID = res?.data?.addCustomerMutation?.primaryKey
						props.useHistory?.push(`/resources/customers/${customerID}`)
					}
					closeModal()
				},
				setError
			)
		} else if (tab === 1) upsertCustomers(state, () => hideModal(), setError)
	}

	const closeModal = () => {
		if (props.onClose) props.onClose()
		hideModal()
	}

	if (!config) return null

	return (
		<ModalContainer
			width={tab ? `${window.innerWidth - 30}px` : "600px"}
			onConfirm={handleConfirm}
			onCancel={closeModal}
		>
			<div className="flex-row tabs">
				<p
					onClick={() => {
						setError("")
						dispatch({ type: "switchTab", tab: 0 })
					}}
					className={tab === 0 ? "active" : undefined}
				>
					{trans("customerModal.title")}
				</p>
				{config.modes.includes("upsert") && (
					<p
						onClick={() => {
							setError("")
							dispatch({ type: "switchTab", tab: 1 })
						}}
						className={tab === 1 ? "active" : undefined}
					>
						{trans("customerModal.importTable")}
					</p>
				)}
			</div>
			{tab === 0 && <AddSingleCustomer state={state} dispatch={dispatch} />}
			{tab === 1 && (
				<ImportFromTable
					state={state}
					dispatch={dispatch}
					customers={props.customers}
					setError={setError}
				/>
			)}
			{errorMessage && <div className="error-message">{errorMessage}</div>}
		</ModalContainer>
	)
}

export default CustomerModal

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"

export const default_drawerContent_allocations: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			{
				updater: "property",
				key: "geoLocationName",
				header: "headers.name",
				permission: "edit",
				type: "text",
			},
			{
				updater: "externalKey", // These are not standart property fields, therefore, use parseEditedValue
				key: "externalKeys.geoLocationCode",
				parseValue: val => val?.split(".")[0] || "",
				parseEditedValue: (val, data) =>
					data.externalKeys.geoLocationCode
						.split(".")
						.map((d: string, i: number) => (i === 0 ? val : d))
						.join("."),
				header: "headers.properties.municipality",
				permission: "edit",
				type: "text",
			},
			{
				updater: "externalKey", // These are not standart property fields, therefore, use parseEditedValue
				key: "externalKeys.geoLocationCode",
				parseValue: val => val?.split(".")[1] || "",
				parseEditedValue: (val, data) =>
					data.externalKeys.geoLocationCode
						.split(".")
						.map((d: string, i: number) => (i === 1 ? val : d))
						.join("."),
				header: "headers.properties.streetCode",
				permission: "edit",
				type: "text",
			},
			{
				updater: "externalKey", // These are not standart property fields, therefore, use parseEditedValue
				key: "externalKeys.geoLocationCode",
				parseValue: val => val?.split(".")[2] || "",
				parseEditedValue: (val, data) =>
					data.externalKeys.geoLocationCode
						.split(".")
						.map((d: string, i: number) => (i === 2 ? val : d))
						.join("."),
				header: "headers.properties.houseNumber",
				permission: "edit",
				type: "text",
			},
		],
	},
	pointEditorDrawer: {
		comp: "pointEditorDrawer",
		order: 4,
		config: {
			permissions: ["edit", "read"],
			title: "points",
		},
	},
	map: {
		comp: "map",
		order: 1,
		config: {
			backupPointsDataPath: "backupPoints",
			pointsDataPath: "points",
		},
	},
}

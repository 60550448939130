import { parseAsync } from "json2csv"
import { ICSVOptions, IRow } from "./types"

const exportCSV =
	({ filename, fields }: ICSVOptions) =>
	(elems: IRow[]) => {
		const options = {
			fields: fields.map(f => (f.value === "checkbox" ? { value: "" } : f)),
			delimiter: ";",
		}

		parseAsync(elems, options)
			.then((csv: string) => {
				const a = document.createElement("a")
				a.href = URL.createObjectURL(
					new Blob(["\uFEFF" + csv], {
						type: "text/csv;charset=UTF-8",
					})
				)
				a.setAttribute("download", filename)
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
			})
			.catch((error: any) => console.error(error))
	}

export default exportCSV

import { gql } from "apollo-boost"

export const GET_ALLOCATION_BY_ID = gql`
	query ACGetAllocationById($id: String) {
		data: allocationTemplates(id: $id) {
			id
			geoLocationName: property(key: "geoLocationName")
			geoLocationCode: externalKey(key: "geoLocationCode")
			assignHousehold: property(key: "assignHousehold")
			assignBusiness: property(key: "assignBusiness")
			externalKeys {
				key
				value
			}
			properties {
				key
				value
			}
			points {
				priority
				point {
					id
					name
					type
					pos: geoLocation {
						lat: latitude
						lon: longitude
					}
					geoLocation {
						latitude
						longitude
					}
				}
			}
		}
	}
`

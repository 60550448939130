import React, { useMemo } from "react"
import { useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import { useFilter } from "../Filter"
import Error from "../_components/Error/Error"
import { useGetRecyclingStationVisits } from "./data"
import Grid from "./Grid"

const RecyclingStationVisits = () => {
	const { siteConfig } = useSiteConfig()
	const { trans } = useTranslation()
	const { headers, filters: filter, columnSizes } = siteConfig.recyclingStationVisits

	//Get recycling station visits
	const { error, data: recyclingStationVisits, client } = useGetRecyclingStationVisits()
	//filter invoices
	const filterSpec = useMemo(
		() => filter(client, recyclingStationVisits),
		[client, recyclingStationVisits, filter]
	)
	const filteredRecyclingStationVisits = useFilter(recyclingStationVisits, filterSpec)

	if (error) {
		return <Error>{trans("errors.failedFetch")}</Error>
	}

	if (filteredRecyclingStationVisits) {
		return (
			<Grid
				recyclingStationVisits={filteredRecyclingStationVisits}
				headers={headers}
				columnSizes={columnSizes}
			/>
		)
	} else {
		return <></>
	}
}

export default RecyclingStationVisits

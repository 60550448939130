import { gql } from "apollo-boost"

export const GET_POINT_HISTORY = gql`
	fragment pointHistoryData on HistoricAccessPoint {
		id
		type
		name
		description
		status
		statusReason
		externalKeys {
			key
			value
		}
		properties {
			key
			value
		}
		geoLocation {
			latitude
			longitude
		}
		parentId
	}
	query ACGetPointHistory($id: String!) {
		accessPointEntityHistory(pk: $id) {
			timestamp
			command {
				id
				command {
					type
					user {
						name
						id
					}
				}
			}
			current {
				...pointHistoryData
			}
			previous {
				...pointHistoryData
			}
		}
	}
`

export const GET_CUSTOMER_HISTORY = gql`
	fragment customerHistoryData on HistoricCustomer {
		id
		name
		description
		externalKeys {
			key
			value
		}
		externalKey
		properties {
			key
			value
		}
		property
		customerType
		points {
			id
			priority
		}
		identities {
			id
		}
	}

	query GetHistory($id: String!) {
		customerEntityHistory(pk: $id) {
			timestamp
			command {
				id
				command {
					type
					user {
						name
						id
					}
				}
			}
			current {
				...customerHistoryData
			}
			previous {
				...customerHistoryData
			}
		}
	}
`

export const RESOLVE_POINTS = gql`
	query RESOLVE_POINTS_NAME($ids: [String]!) {
		store {
			accessPoints(ids: $ids) {
				id
				name
			}
		}
	}
`

import { get, last } from "lodash"
import { match } from "ts-pattern"
import { updateEntity } from "../../../APIfunctionsGeneric/mutations/updateEntity"
import { updatePoint } from "../../../APIfunctionsGeneric/mutations/updatePoint"
import { upsertCustomer } from "../../../APIfunctionsGeneric/upsertCustomers"
import { configName } from "../../../Configs/config"
import { IConfig, ISiteConfig } from "../../../Configs/types"
import { useTranslation } from "../../../Contexts"
import { EntityType, IRow } from "../../../Resources/types"
import { useGeneralConfig } from "../../../States/generalConfig"
import { useSiteConfig } from "../../../States/siteConfig"
import { IAuthJwt } from "../../../types"
import { keyValueArrayToObject } from "../../../Utils/objArr"
import { DrawerField, IDropdownOption, IMultivalDropdownOption } from "./Inputs/DrawerField"

/**
 * key is used as both path and update key name, but if it contains dots (.) it will
 * be split and update use the last part as key name
 */
export type InputComponent = {
	key: string
	header: string
	placeholder?: string
	translate?: boolean
	translatePrefix?: string
	parantecesOriginal?: boolean
	parseValue?: (val: any, trans: any) => string
	permission?: "read" | "edit"
	disabled?:
		| ((conditions: {
				configName: IConfig
				data: IRow
				jwt: IAuthJwt | null
				config: ISiteConfig[EntityType]
				input: InputComponent
				value: any
		  }) => boolean)
		| boolean
} & (
	| {
			updater: "none"
	  }
	| {
			updater: "multivalue"
			type: "multivalue"
			multivalDropdownOptions: IMultivalDropdownOption[]
	  }
	| {
			updater: "property" | "externalKey" | "status"
			type: "dropdown"
			dropdownOptions: IDropdownOption[]
	  }
	| {
			updater: "property" | "externalKey" | "name" | "terminalName" | "description" | "id"
			type: "text" | "number"
			/** Function to parse final value that is sent to API */
			parseEditedValue?: (val: string, data: IRow) => string
	  }
	| {
			updater: "GPS"
			type: "text"
	  }
	| {
			updater: "customerParent"
			type: "dropdown"
			dropdownOptions: IDropdownOption[] | (() => IDropdownOption[])
	  }
)

export const InputsGenerator = ({
	entity,
	data,
	inputs,
}: {
	entity: EntityType
	data: IRow
	inputs: InputComponent[] | undefined
}) => {
	const { data: fetchData } = useSiteConfig().siteConfig[entity]
	const { trans } = useTranslation()

	const config = useSiteConfig().siteConfig[entity]

	const jwt = useGeneralConfig().authJwt

	const value = ({ key }: { key: string }) => get(data, key, "")

	const parseValue = ({
		key,
		translate,
		translatePrefix,
		parantecesOriginal,
		parseValue: valueParse,
	}: InputComponent) =>
		valueParse
			? valueParse(value({ key }), trans)
			: translate && value({ key })
			? trans(`${translatePrefix ? `${translatePrefix}.` : ""}${value({ key })}`) +
			  (parantecesOriginal ? ` (${value({ key })})` : "")
			: value({ key })

	const parseOptions = async ({
		translate,
		translatePrefix,
		dropdownOptions,
		parantecesOriginal,
	}: InputComponent & {
		dropdownOptions?: IDropdownOption[] | (() => IDropdownOption[])
	}) => {
		if (typeof dropdownOptions === "function") return dropdownOptions()
		const parsedOptions = dropdownOptions?.map(o => (!!o.name ? o : { ...o, name: o.value })) || []
		return translate && parsedOptions
			? parsedOptions.map((o: IDropdownOption) => ({
					...o,
					name:
						trans(`${translatePrefix ? translatePrefix + "." : ""}${o.name}`) +
						(parantecesOriginal ? ` (${o.name})` : ""),
			  }))
			: parsedOptions || ([] as IDropdownOption[])
	}

	const parseMultivalOptions = ({
		translate,
		translatePrefix,
		multivalDropdownOptions,
	}: InputComponent & {
		multivalDropdownOptions?: IMultivalDropdownOption[]
	}) =>
		translate && multivalDropdownOptions
			? multivalDropdownOptions.map((o: IMultivalDropdownOption) => ({
					...o,
					name: trans(`${translatePrefix ? translatePrefix + "." : ""}${o.name}`),
					values: o.values,
			  }))
			: multivalDropdownOptions || ([] as IMultivalDropdownOption[])

	const updateFields = (
		val: string,
		updater: "name" | "description" | "status" | "terminalName"
	) => {
		if (entity === "allocations") return

		if (updater === "terminalName")
			return (
				data.terminal?.id &&
				updateEntity(
					{
						entity,
						id: data.terminal.id,
						updateFields: { name: val },
					},
					refetchQueries
				)
			)

		updateEntity(
			{
				entity,
				id: data.id,
				updateFields: {
					...(updater === "name"
						? { name: val }
						: updater === "status"
						? { status: val }
						: { description: val }),
				},
			},
			refetchQueries
		)
	}

	const updatePropsAndKeys = (
		value: string | number | IDropdownOption,
		input: "property" | "externalKey",
		key: string
	) => {
		const isDropdownValue = typeof value === "object"
		const parsedKey = last(key.split("."))
		const parsedValue = isDropdownValue
			? {
					key: parsedKey,
					value: value.value,
			  }
			: {
					key: parsedKey,
					value: String(value),
			  }

		updateEntity(
			{
				entity,
				id: data.id,
				...{
					[input === "externalKey" ? "externalKeysUpdate" : "propertiesUpdate"]: {
						mode: "MERGE",
						props: [parsedValue],
					},
				},
			},
			refetchQueries
		)
	}

	const updateMultival = (
		option: IMultivalDropdownOption,
		{
			key,
			multivalDropdownOptions,
		}: InputComponent & { multivalDropdownOptions?: IMultivalDropdownOption[] }
	) => {
		const prevOptionValues =
			multivalDropdownOptions?.find(o => o.name === value({ key }))?.values || []

		const propertiesUpdate = {
			mode: "MERGE" as const,
			props: option.values
				.filter(o => o.type === "property")
				.map(({ key, value }) => ({ key, value })),
			removeList: prevOptionValues
				.filter(o => !option.values.some(ov => o.type === "property" && ov.key === o.key))
				.map(({ key }) => key),
		}
		const externalKeysUpdate = {
			mode: "MERGE" as const,
			props: option.values
				.filter(o => o.type === "externalKey")
				.map(({ key, value }) => ({ key, value })),
			removeList: prevOptionValues
				.filter(o => !option.values.some(ov => o.type === "externalKey" && ov.key === o.key))
				.map(({ key }) => key),
		}
		if (entity === "allocations") return

		updateEntity(
			{
				entity,
				id: data.id,
				externalKeysUpdate,
				propertiesUpdate,
			},
			refetchQueries
		)
	}

	const updateParent = (input: InputComponent, parent: IDropdownOption) => {
		const externalKeys = Object.entries<string>(data.externalKeys)
		const parentExternalKeys = Object.entries<string>(
			keyValueArrayToObject(parent.externalKeys) as { [key: string]: string }
		)

		if (!externalKeys?.length) return
		const firstExternalKey = externalKeys[0]
		const parentFirstExternalKey = parentExternalKeys[0]
		const [fk, fv] = firstExternalKey
		const [pfk, pfv] = parentFirstExternalKey || ["", ""]
		upsertCustomer({
			customers: [
				{
					id: fv,
					externalKeyName: fk,
					updateFields: {
						name: data.name || "",
					},
					parent:
						parent.value === "--"
							? undefined
							: {
									id: parent.value,
							  },
				},
			],
			refetchQueries,
			onStartCallBack: () => {
				// TEMPORARY: Until implemented in core. Please remind Jørgen to implement everytime you see this
				;(parent.customerType === "BUSINESS" || parent.customerType === "HOUSEHOLD") &&
					parent.value !== "--" &&
					pfk &&
					pfv &&
					parentExternalKeys?.length &&
					upsertCustomer({
						customers: [
							{
								id: pfv,
								externalKeyName: pfk,
								updateFields: {
									name: parent.name || "",
									customerType: (parent.customerType + "_PARENT") as
										| "BUSINESS_PARENT"
										| "HOUSEHOLD_PARENT",
								},
							},
						],
					})
			},
		})
	}

	const updateGPS = (value: string) => {
		updatePoint(
			{
				accessPoint: {
					id: data.id,
				},
				geoLocationUpdate: {
					mode: "MERGE",
					props: [{ key: "decimalDegrees", value }],
				},
			},
			refetchQueries
		)
	}

	const refetchQueries = [
		{ query: fetchData.fetchAll },
		{ query: fetchData.fetchById, variables: { id: data.id } },
	]

	return (
		<>
			{inputs?.map((_input, index) => {
				if (
					_input.disabled && typeof _input.disabled === "function"
						? _input.disabled({
								configName,
								jwt,
								data,
								config,
								value: value({ key: _input.key }),
								input: _input,
						  })
						: _input.disabled
				)
					return null

				return match(_input)
					.with({ type: "dropdown" }, input => (
						<DrawerField
							key={`DrawerInput_${index}`}
							header={input.header}
							permission={input.permission}
							onChange={(value: IDropdownOption) =>
								input.updater === "status"
									? updateFields(value.value, "status")
									: input.updater === "customerParent"
									? updateParent(input, value)
									: updatePropsAndKeys(value, input.updater, input.key)
							}
							value={parseValue(input)}
							placeholder={input.placeholder}
							inputType={input.type}
							dropDownOptions={parseOptions(input)}
						/>
					))
					.with({ type: "multivalue" }, input => (
						<DrawerField
							key={`DrawerInput_${index}`}
							header={input.header}
							permission={input.permission}
							onChange={(option: IMultivalDropdownOption) => updateMultival(option, input)}
							value={parseValue(input)}
							placeholder={input.placeholder}
							inputType={input.type}
							multivalDropDownOptions={parseMultivalOptions(input)}
						/>
					))
					.with({ type: "text" }, { type: "number" }, input => (
						<DrawerField
							key={`DrawerInput_${index}`}
							header={input.header}
							permission={input.updater === "id" ? "read" : input.permission}
							onChange={
								input.updater === "description" ||
								input.updater === "name" ||
								input.updater === "terminalName"
									? (value: string) =>
											updateFields(
												input.parseEditedValue ? input.parseEditedValue(value, data) : value,
												input.updater as "description" | "name" | "terminalName"
											)
									: input.updater === "id"
									? () => {}
									: input.updater === "GPS"
									? (value: string) => updateGPS(value)
									: (value: string | number) =>
											updatePropsAndKeys(
												input.parseEditedValue
													? input.parseEditedValue(String(value), data)
													: value,
												input.updater as "property" | "externalKey",
												input.key
											)
							}
							value={parseValue(input)}
							placeholder={input.placeholder}
							inputType={input.type}
						/>
					))
					.with({ updater: "none" }, input => (
						<DrawerField
							key={`DrawerInput_${index}`}
							header={input.header}
							onChange={() => null}
							value={parseValue(input)}
							placeholder={input.placeholder}
							inputType={"text"}
						/>
					))
					.exhaustive()
			})}
		</>
	)
}

import { DocumentNode } from "graphql"
import { globalAlertMutationSaved, postFunc } from "../../Utils/api"
import { gql } from "@apollo/client/core"
import { EntityType } from "../../Resources/types"
import { IAccessPointType } from "../../Resources/Points/types"

export const UPDATE_ENTITY_QUERY = (entity: EntityType) => {
	const props = (() => {
		switch (entity) {
			case "customers":
				return { mutation: "updateCustomerMutation", type: "updateCustomerMutationInput" }
			case "containers":
				return { mutation: "updateAccessPointMutation", type: "updateAccessPointMutationInput" }
			case "points":
				return { mutation: "updateAccessPointMutation", type: "updateAccessPointMutationInput" }
			case "allocations":
				return { mutation: "updateAllocationsMutation", type: "updateAllocationsMutationInput" }
			default:
				return null
		}
	})()

	return gql`
		mutation ACUpdatePoint($input: ${props?.type}!) {
			${props?.mutation}(input: $input) {
				primaryKey
				scenarioId
				apiResultCode
				commandProcessError
			}
		}
	`
}

type IUpdateEntityInput = {
	id: string
	entity: EntityType
	propertiesUpdate?: {
		mode: "SET" | "MERGE"
		props?: { key: string; value: string }[]
		removeList?: string[]
	}
	externalKeysUpdate?: {
		mode: "SET" | "MERGE"
		props?: { key: string; value: string }[]
		removeList?: string[]
	}
	updateFields?: {
		name?: string
		description?: string
		status?: string
		statusReason?: string
		type?: IAccessPointType
	}
}

export const updateEntity = (
	inputPayload: IUpdateEntityInput,
	refetchQueries:
		| {
				query: DocumentNode
				variables?:
					| {
							[key: string]: any
					  }
					| undefined
		  }[]
		| undefined
) => {
	const ent = inputPayload.entity
	const parsedInputPayload = {
		propertiesUpdate: inputPayload.propertiesUpdate || undefined,
		externalKeysUpdate: inputPayload.externalKeysUpdate || undefined,
		...(ent !== "allocations"
			? {
					updateFields:
						ent === "customers"
							? {
									name: inputPayload.updateFields?.name,
									description: inputPayload.updateFields?.description,
							  }
							: inputPayload.updateFields,
			  }
			: {}),
		...(ent === "customers" ? { customerId: inputPayload.id } : {}),
		...(ent === "containers" || ent === "points" ? { accessPoint: { id: inputPayload.id } } : {}),
		...(ent === "allocations" ? { allocationTemplate: { id: inputPayload.id } } : {}),
	}

	postFunc(UPDATE_ENTITY_QUERY(ent), globalAlertMutationSaved, {
		variables: {
			input: {
				wait: true,
				payload: parsedInputPayload,
			},
		},
		refetchQueries,
	})
}

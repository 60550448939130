/**
 * Formats number with thousand seperator
 * @param n
 * @returns formated string
 */
export const formatWithThousandSeperator = (n: number | string) => {
	return Number(n).toLocaleString()
}

/**
 * Rounds/formats number to specified decimal places
 * @param number
 * @param decimalPlaces
 * @returns rounded number with specific number of decimal places
 */
export const formatNumberWithDecimalPlaces = (number: number, decimalPlaces: number) =>
	Math.round((number + Number.EPSILON) * 10 ** decimalPlaces) / 10 ** decimalPlaces

import React from "react"
import { useHistory } from "react-router-dom"

import { useTranslation } from "../Contexts"

export function identityIsoValue(identityIso: any) {
	const { pk, value, score, ref } = identityIso
	if (!(pk && value)) {
		console.warn("bad identity iso received", identityIso)
		return
	}

	return {
		type: "identity-iso",
		id: pk,
		name: value,
		score: score || 0,
		...ref,
	}
}

const IdentityIsoOption = (option: any) => {
	const { name, customer } = option
	const { trans } = useTranslation()
	const history = useHistory()

	return (
		<div
			onClick={() => (customer?.id ? history.push(`/resources/customers/${customer.id}`) : null)}
		>
			<h1>{trans("identity")} </h1>
			<div className="row">
				<span>
					{name} -{" "}
					{customer?.id
						? customer.name
							? customer.name
							: customer.id
						: trans("search.unusedIdentity")}
				</span>
			</div>
		</div>
	)
}

export default IdentityIsoOption

import { SVGProps } from 'react'

export const HageavfallIcon = ({
  fill = 'black',
  width = '241',
  height = '369',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 241 369"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.865 160.755L23.4386 100.449C13.8452 119.057 6.37722 139.549 2.91455 161.551L107.865 231.52V160.755"
        fill={fill}
      />
      <path
        d="M0.832031 190.444C1.91736 272.633 63.7627 310.853 107.865 317.108V261.801L0.832031 190.444Z"
        fill={fill}
      />
      <path
        d="M107.865 0.0679928C90.9987 13.6627 60.3641 41.1907 36.1641 78.5733L107.865 129.789V0.0679928Z"
        fill={fill}
      />
      <path
        d="M133.063 0.0679928V129.789L204.765 78.5733C180.565 41.1907 149.931 13.6627 133.063 0.0679928Z"
        fill={fill}
      />
      <path
        d="M217.491 100.449L133.063 160.755V230.58L237.095 156.268C233.328 136.264 226.311 117.56 217.491 100.449Z"
        fill={fill}
      />
      <path
        d="M240.069 185.109L133.063 261.541V317.108C177.557 310.799 240.149 271.979 240.149 188.271C240.149 187.209 240.088 186.165 240.069 185.109"
        fill={fill}
      />
      <path
        d="M107.865 317.108V368.445H133.063V317.108C128.664 317.733 124.432 318.051 120.465 318.051C116.497 318.051 112.268 317.733 107.865 317.108"
        fill={fill}
      />
    </svg>
  )
}

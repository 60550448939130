import { SVGProps } from 'react'

export const PlastIcon = ({
  fill = 'black',
  width = '177',
  height = '366',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 177 366"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M107.361 44.5867V0.492011H44.3694V44.5867H107.361Z"
        fill={fill}
      />
      <path
        d="M151.457 239.863H126.26V141.748L130.108 147.344C144.075 167.661 151.457 191.428 151.457 216.077V239.863ZM150.872 133.071L107.361 69.7827H44.3692L23.3959 111.729C8.19322 142.14 0.275879 175.673 0.275879 209.673V365.847H176.653V216.077C176.653 186.437 167.663 157.496 150.872 133.071Z"
        fill={fill}
      />
    </svg>
  )
}

import { get } from "lodash"
import { IListViewConfig } from "../../../Resources/types"
import { useSiteConfig } from "../../../States/siteConfig"
import { IGeneratorButton } from "../../../UI/Drawer/components/ButtonGenerator"
import { isAdmin } from "../../../Utils"

export const df_link_routeGenerator = (
	route: string,
	text: string,
	filter: string,
	labelPath?: string
): IGeneratorButton => ({
	color: "primary",
	function: "filter",
	type: "routerLink",
	text,
	pathName: `/resources/${route}`,
	filterQuery: data => ({
		[filter]: [
			{
				label: get(data, labelPath || "name", "") || data?.id || "",
				value: data?.id || "",
			},
		],
	}),
})

export const df_link_keyEditor: IGeneratorButton = {
	color: "warning",
	function: "openKeyAndPropertyModal",
	type: "link",
	text: "extKeysAndProps.openModalText",
	disabled: () => !isAdmin(),
}

export const df_link_keyEditorTerminal: IGeneratorButton = {
	color: "warning",
	function: "openTerminalKeyAndPropertyModal",
	type: "link",
	text: "extKeysAndProps.openTerminalModalText",
	disabled: ({ data }) => !data?.terminal?.id || !isAdmin() || data?.type !== "ACCESS_PARENT",
}

export const df_link_history: IGeneratorButton = {
	color: "primary",
	function: "openHistoryModal",
	type: "link",
	text: "history.showHistory",
	disabled: ({ config }) =>
		!isAdmin() &&
		!(config as IListViewConfig).history?.action &&
		!(config as IListViewConfig).history?.location,
}

export const df_link_deleteCustomer: IGeneratorButton = {
	color: "danger",
	function: "deleteEntity",
	type: "link",
	text: "deletionModal.deleteCustomer",
}

export const df_link_deletePoint: IGeneratorButton = {
	color: "danger",
	function: "deleteEntity",
	type: "link",
	text: "deletionModal.deletePoint",
}

export const df_link_deleteContainer: IGeneratorButton = {
	color: "danger",
	function: "deleteEntity",
	type: "link",
	text: "deletionModal.deleteContainer",
}

export const df_link_pointCustomers: IGeneratorButton = {
	color: "primary",
	function: "filter",
	type: "routerLink",
	text: "allCustomersAtPoint",
	pathName: "/resources/customers",
	filterQuery: data => ({
		station: [
			{
				label: data?.name || "",
				value: data?.id || "",
			},
		],
	}),
	disabled: ({ data }) => data?.type !== "ACCESS_PARENT",
}

export const df_link_identitiesOverview: IGeneratorButton = {
	color: "primary",
	function: "openIdentitiesOverview",
	type: "link",
	text: "actions.identitiesOverview",
	disabled: () => !useSiteConfig.getState().siteConfig.identitiesModal.modes.includes("edit"),
}

export const df_link_customerEvents = df_link_routeGenerator(
	"events",
	"latestCustomerEvents",
	"customerName"
)

export const df_link_stationEvents = df_link_routeGenerator("events", "eventsAtPoint", "parent")

export const df_link_IHMcontainerEvents = df_link_routeGenerator(
	"events",
	"eventsAtContainer",
	"containerId",
	"externalKeys.containerId"
)

/* TEMPLATE
export const df_link_: IGeneratorButton = {
    
}

export const df_link_ = df_link_routeGenerator()
*/

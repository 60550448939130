import { DocumentNode, useQuery } from "@apollo/client"
import { get } from "lodash"
import React, { useMemo, useState } from "react"
import { useParams } from "react-router"
import { match } from "ts-pattern"
import { Spinner } from "../"
import { IAllocationModified } from "../../Configs/configurations/default/queries/default_fetchAll_allocations"
import { ICustomerByIdModified } from "../../Configs/configurations/default/queries/default_fetchById_customers"
import { modifyAllocation } from "../../Resources/Allocations/data"
import { modifyCustomerPoints } from "../../Resources/Customers/data"
import { EntityType, IRow } from "../../Resources/types"
import { useSiteConfig } from "../../States/siteConfig"
import { sortAscByField } from "../../Utils/general"
import { FlexBox } from "../Layouts/FlexBox/FlexBox"
import { GridBox } from "../Layouts/GridBox/GridBox"
import { ButtonGenerator, IGeneratorButton } from "./components/ButtonGenerator"
import { CustomersAllocated } from "./components/CustomersAllocated"
import { Identities } from "./components/Identities/Identities"
import { Image } from "./components/Image/Image"
import { InputComponent, InputsGenerator } from "./components/InputsGenerator"
import { Map } from "./components/Map"
import {
	IPointEditorDrawerPerms,
	PointEditor,
	PointEditorEntities,
} from "./components/PointEditor/PointEditor"
import { DrawerTitle } from "./components/Title"
import styles from "./Drawer.module.css"

export type DrawerConfig = {
	ui: "drawer" | "disabled"
	content: IDrawerContent
}

export type IDrawerContent = {
	inputSection?: { comp: "inputSection"; inputs: InputComponent[]; order: number }
	image?: { comp: "image"; order: number } | null
	map?: {
		comp: "map"
		config: { pointsDataPath: string; backupPointsDataPath: string }
		order: number
	} | null
	customersAllocated?: { comp: "customersAllocated"; config: { dataPath: string }; order: number }
	linkSection?: { comp: "links"; buttons: IGeneratorButton[]; order: number } | null
	pointEditorDrawer?: {
		comp: "pointEditorDrawer"
		config: {
			permissions: IPointEditorDrawerPerms
			title: PointEditorEntities
		}
		order: number
	} | null
	identityList?: {
		comp: "identities"
		order: number
		enable: boolean
	} | null
}

export const DrawerContent = () => {
	const { siteConfig } = useSiteConfig()
	const { id, entity } = useParams<{ id: string; entity: EntityType }>()

	const drawer = siteConfig[entity]?.drawer

	const drawerContentSorted = useMemo(
		() =>
			Object.values(drawer.content)
				.filter(c => !!c)
				.sort(sortAscByField("order")),
		[drawer.content]
	)

	const { zoomPoint, zoomTo } = useMap()

	const { data: queryData, loading } = useQuery(siteConfig[entity].data.fetchById, {
		variables: { id },
		errorPolicy: "all",
	})
	const data = useMemo<IRow>(
		() => (queryData ? siteConfig[entity].data.fetchByIdParse(queryData) : []),
		[entity, queryData, siteConfig]
	)

	const updatePointsFromEntity = useMemo(
		() => (data: any, refetchQuery: DocumentNode) => {
			if (entity === "customers")
				return modifyCustomerPoints(data as ICustomerByIdModified, refetchQuery)
			if (entity === "points") return undefined // Should we add this function for stations?
			if (entity === "allocations") return modifyAllocation(data as IAllocationModified)
			return undefined
		},
		[entity]
	)

	const content = useMemo(
		() => [
			<DrawerTitle key="drawer_title" type={entity} data={data} />,
			...drawerContentSorted.map((content, i) =>
				match(content)
					.with(null, c => null)
					.with({ comp: "inputSection" }, c => (
						<GridBox
							key={`drawer_content_gen_${i}`}
							columns={2}
							rowGap={0.25}
							columnGap={0.25}
							className={styles.sectionSpace}
						>
							<InputsGenerator entity={entity} inputs={c.inputs} data={data} />
						</GridBox>
					))
					.with({ comp: "image" }, c => (
						<Image key={`drawer_content_gen_${i}`} imageUrl={data?.properties?.imageUrl} />
					))
					.with({ comp: "customersAllocated" }, c => (
						<React.Fragment key={`drawer_content_gen_${i}`}>
							<CustomersAllocated customerCount={get(data, c.config.dataPath, 0)} />
						</React.Fragment>
					))
					.with({ comp: "links" }, c => (
						<FlexBox
							key={`drawer_content_gen_${i}`}
							rowGap={1}
							asColumns
							alignItems="flex-end"
							className={styles.sectionSpace}
						>
							<ButtonGenerator
								key={`drawer_content_gen_${i}a`}
								entity={entity}
								data={data}
								buttons={c.buttons}
							/>
						</FlexBox>
					))
					.with({ comp: "pointEditorDrawer" }, c => (
						<React.Fragment key={`drawer_content_gen_${i}`}>
							{data?.type !== "ACCESS_POINT" && (
								<PointEditor
									key={`drawer_content_gen_${i}a`}
									title={data?.type === "GROUP" ? "inlets" : c.config.title}
									entityName={data?.name || ""}
									entity={entity}
									points={{
										data: data?.points || [],
										fields: [
											{ field: "name" },
											{ field: "streamed" },
											{ field: "status", transKey: "status" },
										],
									}}
									terminal={data?.terminal}
									zoomTo={zoomTo}
									showOnMap={entity === "customers"}
									parentId={data?.id}
									permissions={c.config.permissions}
									updatePoints={updatePointsFromEntity(data, siteConfig[entity].data.fetchById)}
								/>
							)}
						</React.Fragment>
					))
					.with({ comp: "map" }, c => (
						<Map
							key={`drawer_content_gen_${i}`}
							points={get(data, c.config.pointsDataPath, c.config.pointsDataPath ? [data] : [])}
							backupPoints={get(data, c.config.backupPointsDataPath, [])}
							zoomPoint={zoomPoint} // TODO: enable zoompoint (customers only?)
							height={310}
						/>
					))
					.with(
						{ comp: "identities" },
						c =>
							c.enable ? (
								<Identities
									key={`drawer_content_gen_${i}`}
									customerData={data}
									updateIdentityStatus={() => null}
									refetchQuery={siteConfig[entity].data.fetchById}
								/>
							) : null
						// null
					)
					.exhaustive()
			),
		],
		[data, drawerContentSorted, entity, siteConfig, updatePointsFromEntity, zoomPoint, zoomTo]
	)

	if (loading) {
		return <Spinner />
	}

	return <>{content.map(c => c)}</>
}

export const useMap = () => {
	const [zoomPoint, setZoomPoint] = useState(null)
	const zoomTo = (point: any) => setZoomPoint(point)

	return { zoomPoint, zoomTo }
}

import { cloneDeep } from "lodash"
import moment from "moment"
import { keyValueArrayToObject } from "../../../../Utils/objArr"
import { IEventModified } from "../../types"
import { getIdentities } from "../events/prepare"
import { IEventByInterval } from "./types"

function getIdentity(event: IEventByInterval) {
	const _event = cloneDeep(event)
	const identityId = _event.identityId
	if (identityId) {
		const identities = _event?.customer?.customer?.identities?.map(i => i.identity)
		const idents = identities?.find(i => i && i.id === identityId)
		return (
			idents?.externalKeys?.reduce(
				(pi, ci) => ({ ...pi, ...{ [ci.key]: ci.value } }),
				{} as { [key: string]: string }
			) ?? null
		)
	}
	return null
}

export const normalize = (events: IEventByInterval[], currentLocale: any): IEventModified[] => {
	const _events = cloneDeep(events)
	return _events.map(event => ({
		id: event.id,
		type: event.type,
		timestamp: moment(event.timestamp).locale(currentLocale).format("lll"),
		weight: event.weight,
		weightUnit: event.weightUnit,
		fraction: event.fraction,
		properties: keyValueArrayToObject(event.properties),
		result: event.result,
		ts: event.timestamp,
		customer: event.customer?.customer
			? {
					id: event.customer.customer.id,
					name: event.customer.customer.name,
					externalKeys: keyValueArrayToObject(event.customer.customer.externalKeys),
			  }
			: null,
		point: event?.point?.point
			? {
					...event.point.point,
					properties: keyValueArrayToObject(event.point.point.properties),
					externalKeys: keyValueArrayToObject(event.point.point.externalKeys),
			  }
			: null,
		identity: getIdentity(event),
		identities: getIdentities(event),
	}))
}

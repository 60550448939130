import React, { FC } from "react"

export type ITileProps = {
	title?: string
	label?: string
	cssClass?: string
	rows?: number
	cols?: number
}

export const Tile: FC<ITileProps> = ({ title, label, children, cssClass, rows = 2, cols = 1 }) => {
	return (
		<div
			className={`dashboard-tile rows-${rows} cols-${cols} ${cssClass}`}
			style={{
				gridRow: `span ${rows} / auto`,
				gridColumn: `span ${cols} / auto`,
				// gridColumn: `span minmax(1fr, ${cols}) / auto`,
			}}
		>
			<div>
				<p className="tile-title" title={title}>
					{title}
				</p>
				<p title={label}>{label?.toLowerCase()}</p>
			</div>
			<div>{children}</div>
		</div>
	)
}

import { Moment } from "moment"
import { localeFromLocalStorage } from "../../../../Contexts/Translation"
import { IEventModified } from "../../types"
import { fetchEventsByCustomerIds } from "./fetch"
import { normalize } from "./prepare"

export const getEventsByCustomerId = (customerIds: string[], dates: { from: Moment; to: Moment }) =>
	customerIds?.length
		? fetchEventsByCustomerIds(customerIds, dates).then(e =>
				e.store?.customers?.length ? normalize(e.store.customers, localeFromLocalStorage) : []
		  )
		: (new Promise(() => []) as Promise<IEventModified[]>)

import { default_translations } from "../../Contexts/translations/configurations/default"
import { allocationFromResponse, allocationsFromResponse } from "../../Resources/Allocations/data"
import {
	customerNameFilter,
	dateTimeFilter,
	eventTypeFilter,
	identityEmFilter,
	identityIsoFilter,
	pointIdFilter,
	pointParentNameFilter,
} from "../../Resources/Events/configurations/filters"
import { pointsFromResponse } from "../../Resources/Points/data"
import { IAccessPointData, IAccessPointModified } from "../../Resources/Points/types"
import { keyValueArrayToObject } from "../../Utils/objArr"
import {
	default_vendorOptions_inlet,
	default_vendorOptions_station,
} from "../datasets/VendorOptions/default_vendorOptions"
import { ISiteConfig } from "../types"
import { bir_drawerContent_movableContainers } from "./bir/drawer/bir_drawerContent_movableContainers"
import { default_eventsDatesConfig } from "./default/default_eventDates"
import { default_fractionsList } from "./default/default_fractions"
import { default_overrides_events } from "./default/default_overrides"
import { default_drawerContent_allocations } from "./default/drawer/default_drawerContent_allocations"
import { default_drawerContent_containers } from "./default/drawer/default_drawerContent_containers"
import { default_drawerContent_customers } from "./default/drawer/default_drawerContent_customers"
import { default_drawerContent_operators } from "./default/drawer/default_drawerContent_operators"
import { default_drawerContent_points } from "./default/drawer/default_drawerContent_points"
import { default_fetchAll_events } from "./default/queries/default_events"
import { GET_ALLOCATIONS } from "./default/queries/default_fetchAll_allocations"
import {
	default_fetchAll_containers,
	Idefault_fetchAll_containers,
} from "./default/queries/default_fetchAll_containers"
import {
	default_fetcAll_customers,
	Idefault_fetcAll_customers,
} from "./default/queries/default_fetchAll_customers"
import { default_fetchAll_operators } from "./default/queries/default_fetchAll_operators"
import { default_fetchAll_points } from "./default/queries/default_fetchAll_Points"
import { GET_ALLOCATION_BY_ID } from "./default/queries/default_fetchById_allocations"
import {
	default_fetchById_containers,
	Idefault_fetchById_container,
} from "./default/queries/default_fetchById_containers"
import {
	default_fetchById_customers,
	Idefault_fetchById_customers,
} from "./default/queries/default_fetchById_customers"
import { default_fetchById_operators } from "./default/queries/default_fetchById_operators"
import { default_fetchById_points } from "./default/queries/default_fetchById_points"
import { default_invoices } from "./default/views/default_invoices"
import { default_recyclingStationVisits } from "./default/views/default_recyclingStationVisits"
import { eventFilterConfigGen, filterConfigGen } from "./_drawerFragments/filterConfigGenerator"
import {
	addressFuzzy,
	filterFuzzy,
	fractionEQ,
	idFuzzy,
	stationEQ,
} from "./_drawerFragments/filters"

export const defaultConfig: () => ISiteConfig = () => ({
	menu: ["customers", "points", "containers", "events", "allocations"],
	dashboard: undefined,
	translations: default_translations,
	customers: {
		headers: [{ path: "checkbox" }, { path: "name" }, { path: "properties.geoLocationName" }],
		columnSizes: {
			id: "1fr",
			name: "2fr",
			customerName: "2fr",
			address: "2fr",
			crmId: "1fr",
		},
		filters: filterConfigGen([filterFuzzy("name"), addressFuzzy, stationEQ]),
		drawer: {
			content: default_drawerContent_customers,
			ui: "drawer",
		},
		history: { action: false, location: false },
		data: {
			fetchAll: default_fetcAll_customers,
			fetchAllParse: (data: Idefault_fetcAll_customers) =>
				data.store.data.map(customer => ({
					...customer,
					properties: customer.properties?.reduce(
						(prev, prop) => ({ ...prev, [prop.key]: prop.value }),
						{}
					),
					externalKeys: customer.externalKeys?.reduce(
						(prev, extKey) => ({ ...prev, [extKey.key]: extKey.value }),
						{}
					),
				})),
			fetchById: default_fetchById_customers(),
			fetchByIdParse: (data: Idefault_fetchById_customers) =>
				[data.store.data[0]].map(customer => ({
					...customer,
					properties: customer?.properties?.reduce(
						(prev, prop) => ({ ...prev, [prop.key]: prop.value }),
						{}
					),
					externalKeys: customer?.externalKeys?.reduce(
						(prev, extKey) => ({ ...prev, [extKey.key]: extKey.value }),
						{}
					),
					points: customer?.points?.map(cp => ({ ...cp?.point, priority: cp?.priority })),
				}))[0],
		},
	},
	points: {
		headers: [{ path: "checkbox" }, { path: "name" }],
		history: { action: false, location: false },
		filters: filterConfigGen([filterFuzzy("name")]),
		drawer: {
			ui: "drawer",
			content: default_drawerContent_points,
		},
		data: {
			fetchAll: default_fetchAll_points({}),
			fetchAllParse: (data: IAccessPointData): IAccessPointModified[] =>
				pointsFromResponse(data.store.data, ["ACCESS_PARENT"]),
			fetchById: default_fetchById_points({}),
			fetchByIdParse: (data: IAccessPointData) =>
				[data.store.data[0]].map(point => ({
					...point,
					properties: point.properties?.reduce(
						(prev, prop) => ({ ...prev, [prop.key]: prop.value }),
						{}
					),
					externalKeys: point.externalKeys?.reduce(
						(prev, extKey) => ({ ...prev, [extKey.key]: extKey.value }),
						{}
					),
				}))[0],
		},
	},
	containers: {
		headers: [{ path: "checkbox" }, { path: "name" }, { path: "properties.fraction" }],
		columnSizes: {
			id: "1fr",
			name: "2fr",
			address: "2fr",
			crmId: "1fr",
		},
		filters: filterConfigGen([filterFuzzy("name"), fractionEQ]),
		history: { action: false, location: false },
		drawer: {
			ui: "drawer",
			content: default_drawerContent_containers,
		},
		data: {
			fetchAll: { ...default_fetchAll_containers({ fillLevel: false }) },
			fetchAllParse: (data: Idefault_fetchAll_containers) =>
				data.store.data.map(container => ({
					...container,
					properties: keyValueArrayToObject(container.properties),
					externalKeys: keyValueArrayToObject(container.externalKeys),
				})),
			fetchById: {
				...default_fetchById_containers({
					fillLevel: false,
				}),
			},
			fetchByIdParse: (data: Idefault_fetchById_container) =>
				[data.store.data[0]].map(container => ({
					...container,
					properties: keyValueArrayToObject(container.properties),
					externalKeys: keyValueArrayToObject(container.externalKeys),
				}))[0],
		},
	},
	// Needed for bir AP split
	inlets: {
		data: {
			fetchAll: default_fetchAll_points({ fillLevel: false, pos: true }),
			fetchAllParse: (data: Idefault_fetcAll_customers) =>
				data.store.data.map(customer => ({
					...customer,
					properties: customer.properties?.reduce(
						(prev, prop) => ({ ...prev, [prop.key]: prop.value }),
						{}
					),
					externalKeys: customer.externalKeys?.reduce(
						(prev, extKey) => ({ ...prev, [extKey.key]: extKey.value }),
						{}
					),
				})),
			fetchById: default_fetchById_containers({ fillLevel: false }),
			fetchByIdParse: (data: Idefault_fetchById_customers) =>
				[data.store.data[0]].map(customer => ({
					...customer,
					properties: customer?.properties?.reduce(
						(prev, prop) => ({ ...prev, [prop.key]: prop.value }),
						{}
					),
					externalKeys: customer?.externalKeys?.reduce(
						(prev, extKey) => ({ ...prev, [extKey.key]: extKey.value }),
						{}
					),
					points: customer?.points?.map(cp => ({ ...cp?.point, priority: cp?.priority })),
				}))[0],
		},
		drawer: { ui: "drawer", content: default_drawerContent_containers },
	},
	// Needed for bir AP split
	movableContainers: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ name: "headers.printedTag", path: "externalKeys.tag" },
			{ path: "externalKeys.serviceGuid" },
		],
		filters: filterConfigGen([
			idFuzzy,
			filterFuzzy("name"),
			filterFuzzy("externalKeys.tag", "headers.printedTag"),
			filterFuzzy("externalKeys.serviceGuid"),
		]),
		drawer: {
			ui: "drawer",
			content: bir_drawerContent_movableContainers,
		},
		data: {
			fetchAll: default_fetchAll_points({ pos: true }),
			fetchAllParse: (data: IAccessPointData): IAccessPointModified[] =>
				pointsFromResponse(data.store.data, ["CONTAINER"]).map(p => {
					return p
				}),
			fetchById: default_fetchById_points({}),
			fetchByIdParse: (data: IAccessPointData): IAccessPointModified =>
				pointsFromResponse([data.store.data[0]], ["CONTAINER"]).map(p => {
					return p
				})[0],
		},
		history: { action: false, location: false },
	},
	events: {
		headers: [
			{ path: "checkbox" },
			{ path: "timestamp" },
			{ path: "customer.name" },
			{ path: "identity.ISO" },
			{ path: "identity.EM" },
			{ path: "point.id", name: "headers.point.name" },
			{ path: "point.parent.name" },
			{ path: "type" },
			{ path: "result" },
			{ path: "properties.rejectReason", name: "headers.reason" },
		],
		columnSizes: {
			timestamp: "minmax(200px, 0.8fr)",
			"customer.name": "0.7fr",
			"properties.weight": "0.2fr",
			"identity.ISO": "0.5fr",
			"identity.EM": "0.5fr",
			"point.id": "0.7fr",
			"point.parent.name": "0.7fr",
			type: "0.6fr",
			result: "minmax(80px, 0.5fr)",
			rejectReason: "minmax(80px, 0.5fr)",
		},
		data: {
			fetchAll: default_fetchAll_events({
				pointProperties: true,
			}),
			joinCustomerDetails: true,
			fetchAllParse: data => data,
		},
		filters: eventFilterConfigGen([
			dateTimeFilter(),
			customerNameFilter(),
			identityIsoFilter(),
			identityEmFilter(),
			pointIdFilter(),
			pointParentNameFilter(),
			eventTypeFilter(),
		]),
		overrides: default_overrides_events,
	},
	eventsDates: default_eventsDatesConfig,
	operators: {
		headers: [{ path: "checkbox" }, { path: "name" }, { path: "properties.address" }],
		columnSizes: {
			id: "1fr",
			name: "2fr",
			address: "2fr",
			crmId: "1fr",
		},
		filters: filterConfigGen([filterFuzzy("name")]),
		drawer: {
			content: default_drawerContent_operators,
			ui: "drawer",
		},
		history: { action: false, location: false },
		data: {
			fetchAll: default_fetchAll_operators(),
			fetchAllParse: data =>
				data.store.data.map(operator => ({
					...operator,
					properties: keyValueArrayToObject(operator.properties),
					externalKeys: keyValueArrayToObject(operator.externalKeys),
				})),
			fetchById: default_fetchById_operators(),
			fetchByIdParse: data =>
				[data.store.data[0]].map(operator => ({
					...operator,
					properties: keyValueArrayToObject(operator.properties),
					externalKeys: keyValueArrayToObject(operator.externalKeys),
				}))[0],
		},
	},
	allocations: {
		headers: [
			{ path: "checkbox" },
			{ path: "geoLocationName", name: "headers.name" },
			{ path: "municipality", name: "headers.properties.municipality" },
			{ path: "streetCode", name: "headers.properties.streetCode" },
			{ path: "houseNumber", name: "headers.properties.houseNumber" },
		],
		filters: filterConfigGen([
			filterFuzzy("geoLocationName", "headers.name"),
			filterFuzzy("municipality", "headers.properties.municipality"),
			filterFuzzy("streetCode", "headers.properties.streetCode"),
			filterFuzzy("houseNumber", "headers.properties.houseNumber"),
		]),
		history: {
			action: false,
			location: false,
		},
		drawer: {
			content: default_drawerContent_allocations,
			ui: "drawer",
		},
		data: {
			fetchAll: GET_ALLOCATIONS,
			fetchAllParse: (data: any) => allocationsFromResponse(data),
			fetchById: GET_ALLOCATION_BY_ID,
			fetchByIdParse: (data: any) => allocationFromResponse(data),
		},
	},
	invoices: default_invoices,
	recyclingStationVisits: default_recyclingStationVisits,
	stationModal: {
		modes: [],
		status: {
			hide: false,
		},
		inletStatus: {
			hide: false,
		},
		stationExternalKeyList: [],
		stationPropertyList: [
			{ key: "Municipality", name: "municipality", value: "" },
			{ key: "owner", name: "owner", value: "" },
			{
				key: "vendor",
				name: "vendor",
				value: "",
				type: "textDropdown",
				options: default_vendorOptions_station,
			},
			{ key: "contact", name: "contact", value: "" },
		],
		inletAutoName: false,
		inletNameField: {
			enabled: true,
		},
		inletExternalKeyList: [],
		inletPropertyList: [
			{
				key: "vendor",
				name: "vendor",
				value: "",
				type: "textDropdown",
				options: default_vendorOptions_inlet,
			},
		],
		externalKeyAssignation: {
			enabled: false,
		},
	},
	accessPointModal: {
		enable: false,
		method: "add", // add not implemented yet, only upsert
		allowMultiple: false,
		fieldList: [],
	},
	customerModal: {
		modes: [],
		customerTypes: ["BA"],
		allocationScheme: ["NONE"],
		propertyList: [],
		externalKeyList: [],
	},
	identitiesModal: {
		modes: ["edit"],
		identityType: "ISO",
		defaultKey: "ISO",
		propertyList: [],
		externalKeyList: ["ISO", "EM"],
		requiredKeyList: ["ISO"],
		searchableFieldsKeys: ["ISO", "printed"],
	},
	search: {
		searchablePoints: ["ACCESS_PARENT", "CONTAINER"],
	},
	fractionsList: default_fractionsList,
})

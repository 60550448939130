import styles from "./Enable.module.css"

export const SvgEnable = ({ className }: { className?: string }) => (
	<svg
		className={`${styles.enable} ${className}`}
		width="18"
		viewBox="0 0 512 512"
	>
		<polyline points="416 128 192 384 96 288" />
	</svg>
)

import { SVGProps } from 'react'

export const HarIcon = ({
  fill = 'black',
  width = '57',
  height = '60',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 57 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.018 44.9588C13.7531 43.6568 14.9239 40.3878 13.6219 37.6527C12.3199 34.9176 9.05093 33.7467 6.31582 35.0487C3.58072 36.3507 2.40987 39.6197 3.71185 42.3548C5.00447 45.0899 8.28285 46.2514 11.018 44.9588ZM25.115 26.0004C26.0423 26.0004 26.7823 25.251 26.7823 24.3331C26.7823 23.4058 26.0329 22.6658 25.115 22.6658C24.1877 22.6658 23.4477 23.4151 23.4477 24.3331C23.4383 25.251 24.1877 26.0004 25.115 26.0004ZM0.967378 43.6568C-1.04649 39.4043 0.761308 34.3181 5.01384 32.3042C6.21279 31.7329 7.47731 31.4706 8.71372 31.48C11.2147 31.4987 17.6871 29.7471 17.7152 23.462L47.4174 11.4444C51.2577 10.2829 56.2034 13.6175 56.2034 13.6175L14.2027 33.5219C15.0832 34.2806 15.8325 35.2267 16.357 36.3413C18.3803 40.5939 16.5631 45.68 12.3106 47.6939C8.05805 49.7078 2.98124 47.9093 0.967378 43.6568ZM13.6219 11.0041C14.9239 8.26902 13.7531 4.99063 11.018 3.69802C8.28285 2.39603 5.00447 3.56688 3.71185 6.30199C2.40987 9.03709 3.58072 12.3061 6.31582 13.6081C9.05093 14.9007 12.3199 13.7392 13.6219 11.0041ZM16.9752 21.8883C15.2049 18.2634 10.6995 17.1581 8.71372 17.1768C7.46794 17.1862 6.21279 16.9239 5.01384 16.3526C0.761308 14.3387 -1.04649 9.25253 0.967378 5C2.98124 0.747474 8.06742 -1.06032 12.3199 0.953544C16.5725 2.96741 18.3803 8.05358 16.3664 12.3061C15.8325 13.4208 15.0925 14.3668 14.212 15.1255L23.0356 19.3125C19.7104 20.7175 17.3312 21.7385 17.0314 21.8696L16.9752 21.8883ZM35.6433 25.2791L56.2034 35.03C56.2034 35.03 51.1734 38.6081 47.4174 37.2031L27.4942 29.1476L35.6433 25.2791Z"
        fill={fill}
      />
      <path
        d="M48.0355 57.6883C47.8201 35.7981 45.0287 26.1502 41.1322 17.411C39.2026 13.0836 37.8163 9.5991 36.2427 5.41214C36.0647 4.8876 35.3809 3.67928 36.7298 1.81529C38.0786 -0.0487053 39.8208 -0.254776 40.7949 1.05658C41.9752 2.6302 42.7901 5.40277 43.7268 8.70926C47.2018 20.961 48.8223 24.4923 48.6256 57.0982C48.6162 60.236 48.0636 60.9198 48.0355 57.6883Z"
        fill={fill}
      />
    </svg>
  )
}

import React from "react"
import { match } from "ts-pattern"
import { useTranslation } from "../Contexts"
import { Add } from "../Icons/Add"

import styles from "./Button.module.css"

export type IButtonType = "primary" | "secondary" | "third" | "background" | "danger" | "warning"

type ButtonProps = {
	children: any
	onClick?: any
	type?: IButtonType
	icon?: "add"
	disabled?: boolean
	className?: string
	title?: string
}

const Button = React.forwardRef(
	(
		{ children, onClick, type = "primary", icon, disabled, className, title }: ButtonProps,
		ref: any
	) => {
		const { trans } = useTranslation()
		const clicker = () => onClick && onClick()

		const content = typeof children === "string" ? trans(children) : children
		const classNames = [styles["themed-button"], type ? styles[type] : styles.primary, className]
			.join(" ")
			.trim()

		return (
			<button ref={ref} className={classNames} onClick={clicker} disabled={disabled} title={title}>
				{icon ? (
					match(icon)
						.with("add", () => <Add className={type ? styles["svg-" + type] : undefined} />)
						.exhaustive()
				) : (
					<></>
				)}
				<span title={title} className={className}>
					{content}
				</span>
			</button>
		)
	}
)

export default Button

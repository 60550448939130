import { cloneDeep } from "lodash"
import moment from "moment"
import { keyValueArrayToObject } from "../../../../Utils/objArr"
import { ICustomer, IEvent, IEventModified, IIdentity } from "../../types"
import { IPointWithEventsAndCustomer } from "./types"

export function getIdentity(customer: ICustomer, event: IEvent) {
	const identityId = event.identityId
	if (identityId) {
		const identities = customer.identities?.map(i => i.identity)
		const idents = identities?.find(i => i && i.id === identityId)
		return (
			idents?.externalKeys?.reduce(
				(pi, ci) => ({ ...pi, ...{ [ci.key]: ci.value } }),
				{} as { [key: string]: string }
			) ?? null
		)
	}
	return null
}

export function getIdentities(customer: ICustomer) {
	const identities = customer.identities?.map(i => i.identity?.externalKeys)
	return identities
		?.map(
			is =>
				is?.reduce(
					(pi, ci) => ({ ...pi, ...{ [ci.key]: ci.value } }),
					{} as { [key: string]: string }
				) ?? null
		)
		.filter(is => is) as IIdentity[] | null
}

export const normalize = (
	points: IPointWithEventsAndCustomer[],
	currentLocale: any
): IEventModified[] => {
	const _points = cloneDeep(points)
	const hasEvents = _points.flatMap(p => p.events).length
	return hasEvents
		? _points.flatMap(p =>
				p.events
					? p.events.map(e => ({
							id: e.id,
							type: e.type,
							timestamp: moment(e.timestamp).locale(currentLocale).format("lll"),
							weight: e.weight,
							weightUnit: e.weightUnit,
							fraction: e.fraction,
							properties: e.properties
								? e.properties.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value }), {})
								: {},
							result: e.result,
							ts: e.timestamp,
							customer:
								e.customer?.id && e.customer.customer?.id && e.customer.customer.name
									? {
											id: e.customer.id,
											name: e.customer.customer.name,
											externalKeys: e.customer.customer.externalKeys
												? e.customer.customer.externalKeys.reduce(
														(prev, curr) => ({ ...prev, [curr.key]: curr.value }),
														{}
												  )
												: {},
									  }
									: null,
							point: {
								id: p.id,
								type: p.type,
								name: p.name,
								fraction: p.fraction,
								productName: p.productName,
								volume: p.volume,
								parent: p.parent,
								RFID: p.RFID,
								externalKeys: keyValueArrayToObject(p.externalKeys),
								properties: keyValueArrayToObject(p.properties),
							},
							identity: e.customer?.customer ? getIdentity(e.customer.customer, e) : null,
							identities: e.customer?.customer ? getIdentities(e.customer.customer) : null,
					  }))
					: ([] as IEventModified[])
		  )
		: ([] as IEventModified[])
}

import { gql } from "@apollo/client/core"

export const ihm_fetchAll_operators = () => gql`
	query ACGetOperators {
		store {
			data: operators {
				id
				name
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
			}
			customers {
				operator {
					operator {
						id
					}
				}
			}
		}
	}
`

import { IEventModified } from "../types"

export const filterEventsByCustomerId = (events: IEventModified[], customersIds: string[]) =>
	events.filter(e => customersIds.find(ci => ci === e.customer?.id)) || []

export const filterEventsByIdentity =
	(identKey: string) => (events: IEventModified[], identValue: string) =>
		events.filter(e =>
			e.identities ? e.identities.find(i => i[identKey] === identValue) : false
		) || []

export const filterEventsByPointIds = (events: IEventModified[], pointIds: string[][]) =>
	events.filter(e => pointIds.flatMap(p => p).find(p => p === e.point?.id)) || []

export const filterEventsByParentIds = (events: IEventModified[], pointIds: string[]) =>
	events.filter(e => pointIds.find(p => p === e.point?.parent?.id)) || []

export const filterEventsByEventTypes = (events: IEventModified[], types: string[] | string[][]) =>
	events.filter(e => types.flatMap(t => t).find(t => t === e.type)) || []

export const filterEventsByFractions = (events: IEventModified[], fractions: string[]) =>
	events.filter(e => fractions.find(f => f === e.fraction)) || []

export const filterEventsByProperties =
	(property: string) => (events: IEventModified[], propertyValues: string[]) =>
		events.filter(e =>
			propertyValues.find(p => p === e.properties[property] && e.properties[property] === p)
		) || []

import React, { useRef, useEffect, useState } from "react"
import { isEqual } from "lodash"
import { useTranslation } from "../../Contexts"
import * as L from "leaflet"
import moment from "moment"

function initMap(elem: any) {
	var map = L.map(elem, {
		center: [60.397076, 5.324383],
		zoomControl: false,
		zoom: 15,
	})

	elem._map = map
	L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", {
		attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
	}).addTo(map)
}

const popup = (group: any, useTranslation: any) => {
	const { trans: translate } = useTranslation
	const { last, user, lastTimestamp, timestamps } = group
	const active = last ? `<h3>${translate("history.lastLocation")}</h3>` : ""

	const time = `<p>
        ${
					user
						? translate("history.placedWithUser", [user])
						: translate("history.placedWithoutUser")
				}: ${moment(lastTimestamp).format("lll")}
    </p>`
	const more = timestamps?.length ? `<p>${`${translate("history.previouslyPlaced")}:`}</p>` : ""
	const prevTimes = timestamps.map((ts: number) => {
		return `<p>${moment(ts).format("lll")}</p>`
	})
	return `
		<div class="popup">
            ${active}
            ${time}
            ${more}
			${more && prevTimes.join("")}
		</div > 
	`
}

function addPoints(elem: any, points: any[], useTranslation: any) {
	const map = elem._map
	const oldMarkers = elem._markers

	if (oldMarkers) oldMarkers.clearLayers()

	// markers
	const markers = L.featureGroup()
	const markerGroups: any = {}
	points.forEach((point, index) => {
		const { id, lat, lon, timestamp, user } = point
		if (!lat || !lon) return
		const pos = L.latLng(lat, lon)
		const group = `${lat}/${lon}`
		if (markerGroups[group]) {
			markerGroups[group].timestamps.push(timestamp)
		} else {
			markerGroups[group] = {
				id,
				pos,
				lastTimestamp: timestamp,
				timestamps: [],
				last: index === 0 ? true : false,
				user,
			}
		}
	})
	Object.values(markerGroups).forEach((group: any) => {
		const { last, pos } = group
		const marker = L.circleMarker(pos, {
			radius: 8,
			className: `marker${last ? " last" : ""}`,
		})
		marker.bindPopup(popup(group, useTranslation), { closeButton: false }).on("click", () => {
			map.panTo(pos)
		})
		markers.addLayer(marker)
	})

	map.addLayer(markers)

	// if valid bounds
	const bounds: any = markers.getBounds()
	if (bounds._northEast) {
		if (!elem._markers) {
			map.fitBounds(markers.getBounds())
		} else {
			map.flyToBounds(markers.getBounds(), { duration: 0.25 })
		}
		elem._markers = markers
	}
}

const LocationMap = ({ points }: any) => {
	const mapEl = useRef(null)
	const [renderedPoints, setRenderedPoints] = useState<any>([])
	const translationModule = useTranslation()

	useEffect(() => {
		initMap(mapEl.current)
	}, [])

	useEffect(() => {
		const ids = points.map((p: any) => p.id)
		if (!isEqual(renderedPoints, ids)) {
			addPoints(mapEl.current, points, translationModule)
			setRenderedPoints(ids)
		}
		// eslint-disable-next-line
	}, [points])

	return <div ref={mapEl} className="history-map-el" />
}

export default LocationMap

export const chartColorsWithFgBg = [
	{ fg: "black", bg: "var(--lime-1)" },
	{ fg: "black", bg: "var(--fucales-1)" },
	{ fg: "black", bg: "var(--carrot-1)" },
	{ fg: "black", bg: "var(--beige-1)" },
	{ fg: "white", bg: "var(--black-1)" },
	{ fg: "black", bg: "var(--lightgrey-1)" },
	{ fg: "black", bg: "var(--lime-2)" },
	{ fg: "black", bg: "var(--fucales-2)" },
	{ fg: "black", bg: "var(--carrot-2)" },
	{ fg: "black", bg: "var(--beige-2)" },
	{ fg: "black", bg: "var(--black-2)" },
	{ fg: "black", bg: "var(--lightgrey-2)" },
	{ fg: "black", bg: "var(--lime-3)" },
	{ fg: "black", bg: "var(--fucales-3)" },
	{ fg: "black", bg: "var(--carrot-3)" },
	{ fg: "black", bg: "var(--beige-3)" },
	{ fg: "black", bg: "var(--black-3)" },
	{ fg: "black", bg: "var(--lightgrey-3)" },
	{ fg: "black", bg: "var(--lime-4)" },
	{ fg: "black", bg: "var(--fucales-4)" },
	{ fg: "black", bg: "var(--carrot-4)" },
	{ fg: "black", bg: "var(--beige-4)" },
	{ fg: "black", bg: "var(--black-4)" },
	{ fg: "black", bg: "var(--lightgrey-4)" },
]

export const chartColors = [
	"var(--carrot-4)",
	"var(--lightgrey-1)",
	"var(--lime-1)",
	"var(--fucales-1)",
	"var(--beige-1)",
	"var(--black-1)",

	"var(--lime-2)",
	"var(--fucales-2)",
	"var(--carrot-2)",
	"var(--beige-2)",
	"var(--black-2)",
	"var(--lightgrey-2)",
	"var(--lime-3)",
	"var(--fucales-3)",
	"var(--carrot-3)",
	"var(--beige-3)",
	"var(--black-3)",
	"var(--lightgrey-3)",
	"var(--lime-4)",
	"var(--fucales-4)",
	"var(--carrot-4)",
	"var(--beige-4)",
	"var(--black-4)",
	"var(--lightgrey-4)",
]

import { BarDatum } from "@nivo/bar"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useAuth0, useTranslation } from "../../../Contexts"
import Error from "../../../Resources/_components/Error/Error"
import { Spinner } from "../../../UI"
import { fetchFromDWH, ICenterStats, IDwhResponse, IS_THON_VEST } from "../../Queries/dwhApi"
import { NumberTile } from "../../Tiles/NumberTile"
import { PieChartTile } from "../../Tiles/PieChartTile"
import { BarChartTile } from "../../Tiles/BarChartTile"
import { LineChartTile } from "../../Tiles/LineChartTile"
import { TileGroup } from "../../Tiles/TileGroup"
import { useQuery } from "@apollo/client"
import {
	GetAllTerminalsObject,
	GET_ALL_TERMINALS,
} from "../../../APIfunctionsGeneric/queries/getTerminals"
import { get } from "lodash"
import { Select } from "../../../UI/GenericComponents/Select"

const parseData = (
	{ terminalStats, centerStats, basicStats }: IDwhResponse["data"],
	trans: any
) => {
	const data = IS_THON_VEST ? centerStats : terminalStats
	data.sort((aS, bS) => {
		const [a, b] = [parseInt(aS.period.replace("-", "")), parseInt(bS.period.replace("-", ""))]
		return a - b
	})

	const lastPeriod = data.reduce((prev, stats) => {
		if (stats.period === "last_30_days") return stats
		return prev
	}, {} as ICenterStats)

	// sorting degree pie
	const sortingDegreeLastPeriod = parseFloat(lastPeriod?.sorting_degree || "0")
	const sortingDegreeData = [
		{ id: trans("sorted"), value: sortingDegreeLastPeriod },
		{ id: trans("unsorted"), value: 1 - sortingDegreeLastPeriod },
	]

	// sorting degree progress last 6 months
	const sortingDegreeProgressData = [
		{
			id: trans("sortingDegreeProgress"),
			data: data
				.map(stat => {
					const { sorting_degree, period } = stat
					const deg = parseFloat(sorting_degree)
					if (period === "last_30_days") return undefined
					return { x: moment(period).format("MMM"), y: deg }
				})
				.filter(f => !!f) as { x: string; y: number | string }[],
		},
	]

	// Fraction distribution Last 30 days
	const fractionDistributionData =
		lastPeriod?.fraction_distribution?.reduce((p, dist) => {
			const value = dist.percent
			if (Number(value) === 0) return p
			return [...p, { id: trans(`fraction.${dist.fraction}`), value, fraction: dist.fraction }]
		}, [] as { id: string; value: string | number; fraction: string }[]) || []

	// Waste amount per fraction Last 6 months
	const fractionWasteAmountData = data
		.map(stats => {
			if (stats.period === "last_30_days") return null
			const fractionWeight = stats.fraction_distribution?.reduce((prev, { fraction, weight }) => {
				return { ...prev, [fraction]: parseInt(weight) }
			}, {} as { [fraction: string]: number })
			return { period: moment(stats.period).format("MMM"), ...fractionWeight }
		})
		.filter(f => !!f) as BarDatum[]

	const { total_rest_month, total_waste_month } = lastPeriod

	return {
		basicStats,
		sortingDegreeData,
		fractionDistributionData,
		sortingDegreeProgressData,
		fractionWasteAmountData,
		total_rest_month,
		total_waste_month,
	}
}

export const LfmEnterpriseCenterOverview = () => {
	const [data, setData] = useState<IDwhResponse["data"] | null>(null)
	const [error, setError] = useState(false)
	const [selectedTerminal, setTerminal] = useState("")
	const [loadingCenters, setLoadingCenters] = useState(false)

	const { token } = useAuth0()!
	const { trans } = useTranslation()

	const { data: terminalData, loading: loadingTerminals } = useQuery(GET_ALL_TERMINALS())
	const terminals = get<GetAllTerminalsObject["store"]["accessPoints"]>(
		terminalData,
		"store.accessPoints",
		[]
	)

	useEffect(() => {
		if (terminals.length && !selectedTerminal) setTerminal(terminals[0].id)
	}, [selectedTerminal, terminals])

	useEffect(() => {
		setLoadingCenters(true)
		if (selectedTerminal)
			fetchFromDWH(
				IS_THON_VEST
					? { token, endpoint: "centerStats" }
					: { token, endpoint: "terminalCenterStats", id: selectedTerminal }
			)
				.then(({ data, error: err }) => {
					if (err) {
						console.error(err)
						setError(true)
					} else {
						setData(data)
					}
				})
				.catch(err => {
					console.error(err)
					setError(true)
				})
				.then(() => setLoadingCenters(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTerminal])

	const parsedData = useMemo(() => (data ? parseData(data, trans) : null), [data, trans])

	// const roundString = (num: string) => {
	// 	return Math.round(parseFloat(num))
	// }

	const basicStatsTilePropsMap = {
		active_customers_last_month: {
			title: trans("activeTenants"),
			label: trans(`periods.30days`),
		},
		unique_customers: {
			title: trans("tenants"),
			label: trans(`basicStatsLabels.customers`),
		},
		active_and_inactive_access_points: {
			title: trans("containers"),
			label: trans(`basicStatsLabels.containers`),
		},
		unique_fractions: {
			title: trans("fractions"),
			label: trans(`basicStatsLabels.fractions`),
		},
		totalSorted: {
			title: trans("basicStatsLabels.totalSorted"),
			label: trans(`periods.30days`),
		},
		totalRest: {
			title: trans("basicStatsLabels.totalRest"),
			label: trans(`periods.30days`),
		},
	} as {
		[key: string]: {
			title: string
			label: string
		}
	}

	// THON VEST legacy stuff
	basicStatsTilePropsMap["containers"] = basicStatsTilePropsMap.active_and_inactive_access_points
	basicStatsTilePropsMap["customers"] = basicStatsTilePropsMap.unique_customers
	basicStatsTilePropsMap["fractions"] = basicStatsTilePropsMap.unique_fractions
	basicStatsTilePropsMap["activeCustomers"] = basicStatsTilePropsMap.active_customers_last_month

	if (loadingTerminals || (!parsedData && !error)) return <Spinner />
	if (error) return <Error>{trans("errors.failedFetch")}</Error>

	const {
		basicStats: [basicStats],
		sortingDegreeData,
		sortingDegreeProgressData,
		fractionDistributionData,
		fractionWasteAmountData,
		total_rest_month,
		total_waste_month,
	} = parsedData!

	const parsedBasicStats = Object.entries(basicStats || {}).filter(
		IS_THON_VEST
			? () => true
			: ([bs]) =>
					bs !== "inactive_access_points" &&
					bs !== "rejected_disposals_last_week" &&
					bs !== "successful_disposals_last_week" &&
					bs !== "unique_identities" &&
					bs !== "active_app_users_last_month"
		  )

	const bsLen = parsedBasicStats.length + (total_rest_month ? 1 : 0) + (total_waste_month ? 1 : 0)

	return (
		<>
			{!IS_THON_VEST && terminals.length > 1 && (
				<div style={{ marginLeft: "1rem" }}>
					<Select
						data={terminals.map(t => ({ option: t.name, value: t.id }))}
						placeholder={trans("searchCenters")}
						onChange={option => setTerminal(option.value || "")}
						loading={loadingCenters}
					/>
				</div>
			)}
			<div className="dashboard-grid">
				{!!parsedBasicStats.length && (
					<TileGroup rows={bsLen + (bsLen % 2) * 2} cols={2}>
						{parsedBasicStats.map(([key, val], index) => {
							const tileProps = basicStatsTilePropsMap[key]
							return (
								<NumberTile
									key={`basicStat${index}`}
									number={val}
									formatThousands
									tileProps={tileProps}
								/>
							)
						})}
						{!!total_rest_month && (
							<NumberTile
								key={`basicStat-total-sorted`}
								number={Math.round(total_rest_month)}
								tileProps={basicStatsTilePropsMap.totalRest}
								postfix=" Kg"
								formatThousands
							/>
						)}
						{!!total_rest_month && !!total_waste_month && (
							<NumberTile
								key={`basicStat-total-rest`}
								number={Math.round(total_waste_month - total_rest_month)}
								tileProps={basicStatsTilePropsMap.totalSorted}
								postfix=" Kg"
								formatThousands
							/>
						)}
					</TileGroup>
				)}
				<TileGroup rows={8} cols={2}>
					<PieChartTile
						data={sortingDegreeData}
						legend
						tileProps={{
							title: trans("sortingDegree"),
							label: trans("periods.30days"),
							rows: 4,
							cols: 2,
						}}
					/>
					<LineChartTile
						tileProps={{
							title: trans("sortingDegreeProgress"),
							label: trans("periods.6months"),
							cols: 2,
							rows: 4,
						}}
						labels={true}
						percentValuesY={true}
						colorAea
						points
						data={sortingDegreeProgressData}
					/>
				</TileGroup>
				{!!fractionDistributionData.length && (
					<PieChartTile
						data={fractionDistributionData}
						tileProps={{
							title: trans("fractionDistribution"),
							label: trans("periods.30days"),
							rows: 8,
							cols: 2,
						}}
						useIcons
						parseFractionColors
					/>
				)}
				<BarChartTile
					data={fractionWasteAmountData}
					tileProps={{
						title: trans("fractionWasteProgress"),
						label: trans("periods.6months"),
						cols: 2,
						rows: 8,
					}}
					useFilter
					valueFormat=">-,d"
				/>
			</div>
		</>
	)
}

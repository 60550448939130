import { Diff } from "deep-diff"
import React from "react"
import { useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import { ResolvedPoints } from "./HistoryTypes"

type IChange = Diff<any> | undefined

export const HistoryChange = ({
	change,
	keyProp,
	resolvedPoints,
}: {
	change: IChange
	keyProp: string
	resolvedPoints: ResolvedPoints
}) => {
	const { siteConfig } = useSiteConfig()
	const { trans: translate, currentLocale } = useTranslation()
	const prefix = (change: any): string => {
		switch (change.kind) {
			case "E":
				return "updated"
			case "D":
				return "deleted"
			case "N":
				return "added"
			case "A": // indicates object nesting
				return prefix(change.item)
			default:
				return ""
		}
	}
	const key = (change: any, rootPath?: string[]): string => {
		if (change.kind === "A") return key(change.item, rootPath || change.path)
		const path = change.rhs || change.lhs
		if (path && typeof path === "object" && change.kind !== "N" && change.kind !== "D")
			return path.key
		return change?.path?.join(".") || rootPath
	}
	const value = (change: any, side: "lhs" | "rhs"): string => {
		if (change.kind === "A") return value(change.item, side)
		const changeSide = change[side]
		if (changeSide && typeof changeSide === "object") return changeSide.value
		return changeSide
	}
	const isObjectDiff = (change: any): any[] => {
		const kind = change.kind
		if (kind === "A") return isObjectDiff(change.item)
		const path = change.rhs || change.lhs
		if (path && typeof path === "object" && (kind === "N" || kind === "D"))
			return [true, kind, path]
		return [false]
	}
	const changeFrom = value(change, "lhs")
	const changeTo = value(change, "rhs")
	const [objectDiff, type, object] = isObjectDiff(change)
	const affectedProperties = objectDiff
		? Object.entries(object).map(([property, value]): any[] => {
				if (property !== "__typename") return [property, value]
				return []
		  })
		: []
	const changeType = translate(`history.${prefix(change)}`)
	const changeKey = key(change)
	const translateKey = (text: string) => {
		const locale = currentLocale
		const translations = siteConfig.translations
		const root = translations[locale][text] && typeof translations[locale][text] !== "object"
		const headers =
			translations[locale].headers[text] && typeof translations[locale].headers[text] !== "object"
		const preText = ["properties.", "externalKeys.", "points.", "identities."]
			.find(p => text?.includes(p))
			?.replace("ies.", "y")
			.replace("s.", "") as "property" | "externalKey" | "point" | "identity"
		const pointId = preText === "point" ? resolvedPoints[text?.split(".")[1]] : undefined
		if (pointId)
			return `${translate(preText).toLowerCase()} "${[pointId, ...text.split(".").slice(2)].join(
				"."
			)}"`
		if (preText) return `${translate(preText).toLowerCase()} "${text.replace(/^.*?\./g, "")}"`
		if (root) return translate(`${text}`)
		if (headers) return translate(`headers.${text}`)
		return `"${text}"`
	}

	return (
		<>
			{objectDiff ? (
				<tr className="point-history-data-row">
					<Ctd>{`${
						type === "N" ? translate("history.addedTo") : translate("history.deletedFrom")
					} ${translateKey(changeKey)}:`}</Ctd>
					<td />
					<td />
				</tr>
			) : (
				<tr className="point-history-data-row">
					<Ctd>{`${changeType} ${translateKey(changeKey)}`}</Ctd>
					<Ctd className={changeFrom ? undefined : "undefined-property"}>
						{changeFrom ? changeFrom : "--"}
					</Ctd>
					<Ctd className={changeTo ? undefined : "undefined-property"}>
						{changeTo ? changeTo : "--"}
					</Ctd>
				</tr>
			)}
			{objectDiff &&
				affectedProperties &&
				affectedProperties.map((propertyValue, index) => {
					if (!propertyValue || !propertyValue.length) return null
					const [property, value] = propertyValue
					return (
						<tr className="point-history-data-row" key={keyProp + index}>
							<Ctd className="nested-object-property">{translateKey(property)}</Ctd>
							{type === "D" ? <Ctd>{value}</Ctd> : <td className="undefined-property">--</td>}
							{type === "N" ? <Ctd>{value}</Ctd> : <td className="undefined-property">--</td>}
						</tr>
					)
				})}
		</>
	)
}

/**
 * A Custom <td> element. Used to handle long values by adding tooltip and allow scroll on click
 */
const Ctd = ({
	children,
	className,
}: {
	children: string | number
	className?: string | undefined
}) => {
	const text = children.toString()
	const [Clicked, setClicked] = React.useState(false)
	const handleClick = () => {
		setClicked(c => !c)
	}
	return (
		<td
			className={`${Clicked ? "data-row-expand " : ""}${className ? className : ""}`}
			title={text !== "--" && text.length > 10 ? text : undefined}
			onClick={handleClick}
		>
			{children}
		</td>
	)
}

import { Moment } from "moment"
import { localeFromLocalStorage } from "../../../../Contexts/Translation"
import { IEventModified } from "../../types"
import { normalize } from "../eventsByCustomerIds/prepare"
import { fetchEventsByIdentity } from "./fetch"

export const getEventsByIdentity = (identKey: string) => (
	identValues: string[],
	dates: { from: Moment; to: Moment }
) =>
	identValues?.length
		? fetchEventsByIdentity(identKey, identValues, dates).then(e =>
				e.store?.customers?.length ? normalize(e.store.customers, localeFromLocalStorage) : []
		  )
		: (new Promise(() => []) as Promise<IEventModified[]>)

import { gql } from "apollo-boost"

export const GET_EVENTS_BY_EVENT_TYPES = gql`
	query ACGetEventsByInterval($start: DateTime!, $end: DateTime!, $types: [String]!) {
		store {
			events(startTimeIso: $start, endTimeIso: $end, types: $types, eventResultFilter: "") {
				id
				type
				timestamp
				result
				identityId
				weight: property(key: "weight")
				weightUnit: property(key: "weightUnit")
				fraction: property(key: "fraction")
				properties {
					key
					value
				}
				customer {
					id
					customer {
						id
						name
						externalKeys {
							key
							value
						}
						identities {
							identity {
								id
								externalKeys {
									key
									value
								}
							}
						}
					}
				}

				point {
					id
					point {
						id
						type
						name
						fraction: property(key: "fraction")
						productName: property(key: "productName")
						volume: property(key: "filledVolume")
						parent: ancestor(type: "ACCESS_PARENT") {
							id
							name
						}
						properties {
							key
							value
						}
						externalKeys {
							key
							value
						}
					}
				}
			}
		}
	}
`

import { useQuery } from "@apollo/client"
import moment from "moment"
import { useMemo } from "react"
import { localeFromLocalStorage } from "../../Contexts/Translation"
import { useSiteConfig } from "../../States/siteConfig"
import { useArgs } from "../Filter"
import { ICustomerInvoice } from "./types"

export function useGetInvoices() {
	const dataFetch = useSiteConfig().siteConfig.invoices.data

	// Used to get params from the URL, where we want the default time value
	const args = useArgs()
	const timespan = "timestamp" in args && args.timestamp?.value ? args.timestamp.value : undefined
	const [from, to] = useMemo(
		() => timespan || [moment().startOf("day").toISOString(), moment().endOf("day").toISOString()],
		[timespan]
	)

	// Get data with GQL with variables attained from the URL (start and end date)
	const { loading, error, data, client } = useQuery(dataFetch.fetchAll, {
		variables: {
			start: from,
			end: to,
		},
	})

	const invoices = useMemo<ICustomerInvoice[]>(
		() => (data ? parseInvoiceData(data.store.events) : []),
		[data]
	)
	return { loading, error, data: invoices, client }
}

export function parseInvoiceData(data: any[]): ICustomerInvoice[] {
	if (!data.length) return []

	const invoices = data.map(invoiceData => {
		const invoice: ICustomerInvoice = {
			id: invoiceData.id,
			visitId: invoiceData.visitId,
			name: invoiceData.customer.customer?.name ?? "",
			customerClass: invoiceData.customer.customer?.customerClass ?? "",
			postalCode: invoiceData.customer.customer?.postalCode ?? "",
			orgNr: invoiceData.customer.customer?.orgNr ?? "",
			timestampRaw: invoiceData.timestamp,
			timestamp: moment(invoiceData.timestampIso).locale(localeFromLocalStorage).format("lll"),
			paymentAmountIncludingVAT: invoiceData.paymentAmountIncludingVAT ?? 0,
			paymentAmountExcludingVAT: invoiceData.paymentAmountExcludingVAT ?? 0,
			customerRepresentativeName: invoiceData.initiatedByCustomerRefName ?? "",
			referenceText: invoiceData.paymentRef ?? "",
			paymentType: invoiceData.paymentType ?? "",
			totalBillableWeight: invoiceData.totalBillableWeight ?? "",
			orderId: invoiceData.orderId ?? "",
		}

		//TODO: Remove this if data is fixed?
		if (invoice.paymentType.toLowerCase() === "vipps") {
			invoice.paymentAmountIncludingVAT = invoice.paymentAmountIncludingVAT / 100
			invoice.paymentAmountExcludingVAT = invoice.paymentAmountExcludingVAT / 100
		}

		return invoice
	})

	return invoices
}

import { gql } from "apollo-boost"
import { IKeyValue } from "../../../../Utils"

export const GET_ALLOCATIONS = gql`
	query ACGetAllocations {
		data: allocationTemplates {
			id
			geoLocationName: property(key: "geoLocationName")
			geoLocationCode: externalKey(key: "geoLocationCode")
			assignHousehold: property(key: "assignHousehold")
			assignBusiness: property(key: "assignBusiness")
			externalKeys {
				key
				value
			}
			properties {
				key
				value
			}
		}
	}
`

export type IAllocationRaw = { data: IAllocationTemplate[] | null }

export type IAllocationTemplate = {
	assignBusiness: string
	assignHousehold: string
	geoLocationCode: string
	geoLocationName: string
	id: string
	points: IAllocationPoint[] | null
	properties?: IKeyValue[]
	externalKeys?: IKeyValue[]
}

export type IAllocationPoint = {
	point: {
		id: string
		name: string
		pos: number[] | null
	}
	priority: number | null
}

export type IAllocationModified = {
	points: IAllocationModifiedPoint[]
	municipality: string
	streetCode: string
	houseNumber: string
	assignBusiness: string
	assignHousehold: string
	geoLocationCode: string
	geoLocationName: string
	id: string
}

export type IAllocationModifiedPoint = {
	priority: number | null
	id: string
	name: string
	pos: number[] | null
}

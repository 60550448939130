import React, { useRef, useEffect } from "react"
import * as L from "leaflet"

import { useTranslation } from "../../../Contexts"

function initMap(elem: any) {
	var map = L.map(elem, {
		center: [60.397076, 5.324383],
		zoomControl: false,
		zoom: 15,
		// dragging: false,
		// scrollWheelZoom: false,
		// touchZoom: false,
	})

	elem._map = map

	L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", {
		attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
	}).addTo(map)
}

const popupMarkup = (point: any, trans: any) => {
	const { id, status, name } = point
	const statusMessage = trans("headers.status") + ": " + trans(`status.${status}`)
	return `
		<div class="popup">
			<h1>${name}</h1>
			<span>${statusMessage}</span>
			<div>ID: ${id}</div>
		</div > 
	`
}

function addPoints(elem: any, points: any[], backupPoints: any[], trans: any) {
	const map = elem._map
	const oldMarkers = elem._markers
	if (oldMarkers) {
		oldMarkers.clearLayers()
	}

	const markers = L.featureGroup()
	points.forEach((p: any) => {
		const pos = p.pos
		if (!pos) return

		const marker = L.circleMarker(pos, {
			radius: 5,
			className: "map-point",
		})
		marker.bindPopup(popupMarkup(p, trans), { closeButton: false })
		markers.addLayer(marker)
	})

	backupPoints.forEach((p: any) => {
		const pos = p.pos
		if (!pos) return

		const marker = L.circleMarker(pos, {
			radius: 5,
			className: "map-backup-point",
		})
		marker.bindPopup(popupMarkup(p, trans), { closeButton: false })
		markers.addLayer(marker)
	})

	map.addLayer(markers)
	const bounds: any = markers.getBounds()

	// if valid bounds
	if (bounds._northEast) {
		if (!elem._markers) {
			map.fitBounds(markers.getBounds())
		} else {
			map.flyToBounds(markers.getBounds(), { duration: 0.25 })
		}
		elem._markers = markers
	}
}

function zoomToPoint(elem: any, point: any) {
	const map = elem._map
	if (map && point?.pos?.lat && point?.pos?.lon) {
		map.flyTo(point.pos)
	}
}

export const Map = ({ points, backupPoints, zoomPoint, height = "310px" }: any) => {
	const { trans } = useTranslation()
	const mapEl = useRef(null)

	const primaryPoints = points.filter((p: any) => p.priority === 1)
	const bckPoints = points.filter((p: any) => p.priority > 1)

	useEffect(() => {
		initMap(mapEl.current)
	}, [])

	useEffect(() => {
		addPoints(mapEl.current, primaryPoints || [], bckPoints || [], trans)
	}, [points, backupPoints, trans, primaryPoints, bckPoints])

	useEffect(() => {
		if (zoomPoint) {
			zoomToPoint(mapEl.current, zoomPoint)
		}
	}, [zoomPoint])

	return <div ref={mapEl} style={{ height: height }}></div>
}

import { BarDatum } from "@nivo/bar"
import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "../../../Contexts"
import { FilterIcon } from "../../../Icons/Filter"
import { Checkbox } from "../../../UI"

export const Filter = ({
	data,
	indexBy,
	keys,
	onFilter,
}: {
	data: BarDatum[]
	indexBy: string
	keys: string[]
	onFilter: Dispatch<SetStateAction<BarDatum[]>>
}) => {
	const [show, setShow] = useState(false)
	const [filters, setFilters] = useState<{ [key: string]: boolean }>({})
	useEffect(() => {
		setFilters(
			keys.reduce((prev, d) => {
				return { ...prev, [d]: false }
			}, {} as { [key: string]: boolean })
		)
	}, [keys])

	useEffect(() => {
		if (!Object.values(filters).includes(true)) onFilter(data)
		else
			onFilter(
				data.map(datum => {
					return Object.entries(datum).reduce((prev, [k, v]) => {
						if (filters[k] || k === indexBy) return { ...prev, [k]: v }
						return prev
					}, {} as typeof datum)
				})
			)
	}, [data, filters, indexBy, onFilter])

	const { trans } = useTranslation()

	return (
		<div className="filter">
			<FilterIcon
				height={32}
				width={32}
				style={{ cursor: "pointer" }}
				onClick={() => setShow(p => !p)}
			/>
			{show && (
				<div className="filter-modal">
					{keys.map(key => {
						return (
							<FilterOption
								key={`filter-option-${key}`}
								text={trans(`fraction.${key}`)}
								checked={filters[key] || false}
								onClick={e => {
									setFilters(prev => ({ ...prev, [key]: e.target.checked }))
								}}
							/>
						)
					})}
				</div>
			)}
		</div>
	)
}

const FilterOption = (props: {
	text: string
	onClick?: ChangeEventHandler<HTMLInputElement>
	checked: boolean
}) => {
	return (
		<div className="flex-row flex-center-left" style={{ margin: "0.3rem 0 " }}>
			<Checkbox checked={props.checked} onChange={props.onClick}>
				{props.text}
			</Checkbox>
		</div>
	)
}

import React, { useState, useRef, useEffect, useCallback } from "react"
import { useTranslation } from "../../../../Contexts"
import "./DrawerField.css"
import InputSelect from "../../../InputSelect"

export const ReadOnlyField = ({ header, value }: any) => {
	const { trans } = useTranslation()
	return (
		<div className="datum">
			<div className="header">
				<span>{trans(header)}</span>
			</div>
			<input type="text" value={value || ""} readOnly={true} />
		</div>
	)
}

export type IDropdownOption = {
	[key: string]: any
	name?: string
	value: string
}

export type IMultivalDropdownOption = {
	[key: string]: any
	name: string
	// value: string
	values: readonly { type: "property" | "externalKey"; key: string; value: string }[]
}

/**
 *
 * @param header Transable key in translation object
 */
export const DrawerField = ({
	header,
	value = "",
	onChange: onChangeCallback,
	// inputController = "input",
	inputType = "text",
	permission = "read",
	dropDownOptions,
	multivalDropDownOptions,
	dropdownWidth = 166,
	dropdownHeight = 100,
	placeholder,
}: {
	header: string
	placeholder?: string
	permission?: "read" | "edit"
} & (
	| ((
			| {
					inputType?: "text"
					onChange: (value: string) => void
			  }
			| {
					inputType?: "number"
					onChange: (value: number) => void
			  }
	  ) & {
			inputType?: "text" | "number"
			value: any
			// onChange: (value: string) => any
			onChange: any
			// inputController?: "input"
			// inputType?: "text" | "number"
			dropDownOptions?: undefined
			multivalDropDownOptions?: undefined
			dropdownWidth?: undefined
			dropdownHeight?: undefined
	  })
	| ((
			| {
					inputType?: "dropdown"
					onChange: (value: IDropdownOption) => void
					multivalDropDownOptions?: undefined
					dropDownOptions: IDropdownOption[] | Promise<IDropdownOption[]>
			  }
			| {
					inputType?: "multivalue"
					onChange: (value: IMultivalDropdownOption) => void
					dropDownOptions?: undefined
					multivalDropDownOptions: IMultivalDropdownOption[]
			  }
	  ) & {
			// inputType?: "dropdown" //| "multivalue"
			value: any
			onChange: any
			// inputController: "dropdown"

			// dropDownOptions: {
			// 	[key: string]: any
			// 	name: string
			// 	value: string
			// }[]
			dropdownWidth?: number
			dropdownHeight?: number
	  })
)) => {
	const [isEditing, setIsEditing] = useState(false)
	const [isHovering, setIsHovering] = useState(false)
	const [savedValue, setSavedValue] = useState(value || "")
	const [currentValue, setCurrentValue] = useState(value || "")
	const inputRef = useRef<any>(null)
	const [cancelled, setCancelled] = useState<boolean>()
	const { trans } = useTranslation()

	const [inputOptions, setInputOptions] = useState<IDropdownOption[] | IMultivalDropdownOption[]>(
		[]
	)

	useEffect(() => {
		const getOptions = async () =>
			Promise.resolve(inputType === "multivalue" ? multivalDropDownOptions : dropDownOptions)
		getOptions().then(opts => setInputOptions(opts || []))
	}, [dropDownOptions, inputType, multivalDropDownOptions])

	useEffect(() => {
		if (isEditing) {
			inputRef?.current?.select()
		}
	}, [isEditing])

	useEffect(() => {
		setCurrentValue(value || "")
		setSavedValue(value || "")
	}, [value])

	const onChange = (ev: any) => ev && setCurrentValue(ev.target.value)

	const onSave = useCallback(
		(value: string) => {
			setSavedValue(value)
			setIsEditing(false)
			onChangeCallback(value)
		},
		[setSavedValue, setIsEditing, onChangeCallback]
	)

	const onCancel = useCallback(() => {
		setCancelled(!cancelled)
		setIsEditing(false)
		setCurrentValue(savedValue)
	}, [savedValue, setIsEditing, setCurrentValue, cancelled])

	useEffect(() => {
		const ENTER = 13
		const ESCAPE = 27

		const input = inputRef.current
		function enterListener(event: any) {
			if (event?.keyCode === ENTER) {
				onSave(input?.value)
			}
			if (event?.keyCode === ESCAPE) {
				onCancel()
			}
		}

		if (inputRef) {
			input?.addEventListener("keydown", enterListener)
			return () => input?.removeEventListener("keydown", enterListener)
		}
	}, [inputRef, onSave, onCancel])

	if (permission === "read") return <ReadOnlyField header={header} value={value} />

	return (
		<div
			className={`datum ${isEditing ? "edit" : ""}`}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<div className="header">
				<span>{trans(header)}</span>

				{isHovering && !isEditing && (
					<svg
						id="pencil"
						width="15"
						height="15"
						viewBox="0 0 512 512"
						onClick={() => setIsEditing(true)}
					>
						<polygon points="364.13 125.25 87 403 64 448 108.99 425 386.75 147.87 364.13 125.25" />
						<path d="M420.69,68.69,398.07,91.31l22.62,22.63,22.62-22.63a16,16,0,0,0,0-22.62h0A16,16,0,0,0,420.69,68.69Z" />
					</svg>
				)}

				{isEditing && (
					<>
						<svg
							id="save"
							width="15"
							height="15"
							viewBox="0 0 512 512"
							onClick={() => onSave(currentValue)}
						>
							<path d="M465.94,119.76l-73.7-73.7h0A47.68,47.68,0,0,0,358.3,32H96A64,64,0,0,0,32,96V416a64,64,0,0,0,64,64H416a64,64,0,0,0,64-64V153.7A47.68,47.68,0,0,0,465.94,119.76ZM120,112H296a8,8,0,0,1,8,8v48a8,8,0,0,1-8,8H120a8,8,0,0,1-8-8V120A8,8,0,0,1,120,112ZM259.75,431.91a80,80,0,1,1,76.16-76.16A80.06,80.06,0,0,1,259.75,431.91Z" />
							<circle cx="256" cy="352" r="48" />
						</svg>
						<svg id="cross" width="15" height="15" viewBox="0 0 18 18" onClick={onCancel}>
							<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z" />
						</svg>
					</>
				)}
			</div>
			{!isEditing && (
				<input
					value={currentValue?.name !== undefined ? currentValue?.name : currentValue || ""}
					readOnly={true}
				/>
			)}
			{isEditing &&
				(inputType === "text" || inputType === "number" ? (
					<input
						type={inputType}
						ref={inputRef}
						value={currentValue}
						title={currentValue}
						onChange={onChange}
						readOnly={!isEditing}
						placeholder={placeholder ? trans(placeholder) : undefined}
					/>
				) : (
					<InputSelect
						refObj={inputRef}
						label="name"
						defaultValue={savedValue || "none"}
						// noSort
						options={inputOptions}
						// options={inputType === "multivalue" ? multivalDropDownOptions : dropDownOptions}
						onSelect={setCurrentValue}
						optionsWidth={dropdownWidth}
						optionsHeight={dropdownHeight}
						disabled={!isEditing}
						extraClass={`datum-select ${isEditing ? "edit" : ""}`}
						inputClass="datum-select-label"
						cancelSelected={cancelled}
						placeholder={placeholder}
					/>
				))}
		</div>
	)
}

/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useQuery } from "@apollo/client"
import { cloneDeep, get, indexOf } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import { InputSelect, Spinner } from "../../UI"
import { DataGridEditable } from "../../UI/DataGridEditable/DataGridEditable"
import exportCSV from "../../Resources/exportCSV"
import { IOperatorQueryResult, IRow } from "../../Resources/types"
import { ICustomerConfigState, IReducerAction } from "./CustomerModalReducer"
import Tutorial from "./Tutorial"
import { useGeneralConfig } from "../../States/generalConfig"

const GET_ACCESS_PARENTS_AND_OPERATORS = gql`
	query {
		store {
			stations: accessPoints(type: "ACCESS_PARENT") {
				id
				name
			}
			operators {
				id
				name
				externalKeys {
					key
					value
				}
				properties {
					key
					value
				}
				description
			}
		}
	}
`

// Mantas overcomplicated clipboard text to rows converter
const clipToRows = (text: string, columns: ICustomerConfigState["tableColumns"]) => {
	const dataRows = text.split("\n").reduce((prev, curr, index) => {
		const row = curr.replace(/^(.*)\r$/g, "$1")
		const formatedRow = {
			...row.split("\t").reduce(
				(p, c, i) => {
					return { ...p, [columns[i].key]: c }
				},
				{ id: String(index) } as IRow
			),
		}
		return [...prev, formatedRow]
	}, [] as IRow[])
	return dataRows
}

export const ImportFromTable = ({
	state,
	dispatch,
	setError,
	customers,
}: {
	state: ICustomerConfigState
	dispatch: React.Dispatch<IReducerAction>
	setError: React.Dispatch<React.SetStateAction<string | null>>
	customers: IRow[]
}) => {
	const { siteConfig } = useSiteConfig()
	const { trans, currentLocale } = useTranslation()
	const jwt = useGeneralConfig().authJwt

	const config = siteConfig.customerModal
	const translations = siteConfig.translations[currentLocale]
	const mandatory = {
		station:
			typeof config?.mandatory?.station === "function"
				? config.mandatory.station(jwt)
				: !!config?.mandatory?.station,
	}

	const { data, loading } = useQuery(GET_ACCESS_PARENTS_AND_OPERATORS)
	const operators = get<IOperatorQueryResult>(data, "store.operators", [])
	const stations = get<{ id: string; name: string }[]>(data, "store.stations", [])

	if (!config?.externalKeyList.length && !config?.customerAutoKey)
		throw new Error("No external keys configured")

	const flatTranslations = useMemo(
		() => ({
			customerName: translations.customerModal.customerName,
			description: translations.customerModal.description,
			points: translations.customerModal.points,
			...translations.customerModal.properties,
			...translations.customerModal.externalKeys,
			...translations.properties,
		}),
		[translations]
	)

	const CustomerKey = config.customerAutoKey?.key || config.externalKeyList[0].key
	const CustomerIDs = useMemo(
		() => customers.map((c: any) => c.externalKeys[CustomerKey]).filter(f => !!f),
		[customers]
	)

	const [tableError, setTableError] = useState("")
	const [tableWarning, setTableWarning] = useState("")
	const { tableColumns: columns, tableRows, errorFields = {}, warningFields = {} } = state
	const setRows = (rows: IRow[]) => {
		dispatch({
			type: "setTableRows",
			// assign station IDs based on station names
			rows: rows.map(row => ({
				...row,
				stationsToAssign_ids:
					row["stationsToAssign_names"]
						?.split(";")
						.map(
							(station: string) =>
								stations.find(
									({ name }) => name.trim().toLowerCase() === station.trim().toLowerCase()
								)?.id
						)
						.filter((f: any) => !!f) || [],
			})),
		})
	}

	const columnNames = columns.map(col => col.name)

	const customerTypeOptions = config?.customerTypes.map(type => {
		return { name: trans(`customerModal.customerTypes.${type}`), value: type }
	}) || [
		{ name: "HB", value: "HB" },
		{ name: "HA", value: "HA" },
		{ name: "BA", value: "BA" },
		{ name: "BAVD", value: "BAVD" },
	]

	const operatorOptions = operators.map(op => ({
		name: op.name,
		value: op.id,
	}))

	useEffect(() => {
		if (operators.length) dispatch({ type: "setOperatorList", list: operators.map(op => op.id) })
	}, [operators])

	useEffect(() => {
		const thisConfig = siteConfig.customerModal
		const colKeys: ICustomerConfigState["tableColumns"] = thisConfig
			? [
					...(thisConfig.customerAutoKey ? [] : [thisConfig.externalKeyList[0]]),
					{ key: "customerName", name: "customerName", required: true },
					...(config.useDescriptionField
						? [{ key: "description", name: "description", required: false }]
						: []),
					...thisConfig.propertyList,
					{ key: "stationsToAssign_names", name: "points", required: mandatory.station },
			  ]
			: []
		const parsedColumns = colKeys.map(({ key: col, name, required }) => {
			return {
				key: col,
				name: flatTranslations[name] + (required ? "*" : ""),
				required,
			}
		})
		dispatch({ type: "setTableColumns", columns: parsedColumns })
	}, [flatTranslations, siteConfig.customerModal])

	useEffect(() => {
		const fieldErrors = Object.entries(errorFields).length
		if (fieldErrors) setTableError(`${fieldErrors} ${trans("customerModal.errorMissing")}`)
		else setTableError("")
	}, [errorFields])

	useEffect(() => {
		const fieldWarnings = Object.entries(warningFields).length
		if (fieldWarnings) setTableWarning(`${fieldWarnings} ${trans("customerModal.errorDuplicate")}`)
		else setTableWarning("")
	}, [warningFields])

	const onPasteHandler = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault()
		const cb = e.clipboardData
		const clipText = cb.getData("text/plain")
		const rows = clipToRows(clipText, columns)
		setRows(rows)
		// check cell errors/warnings
		const missingFields = rows.reduce((p, row, rowIndex) => {
			const missingCells = columns.reduce((prev, { key, required }) => {
				const cell = row[key]
				if (required && !cell?.trim()) return { ...prev, [`${rowIndex}_${key}`]: true }
				return prev
			}, {} as { [key: string]: boolean })
			return { ...p, ...missingCells }
		}, {} as { [key: string]: boolean })

		const warningFields = rows.reduce((p, row, rowIndex) => {
			if (CustomerIDs.includes(row[CustomerKey]?.trim()))
				return { ...p, [`${rowIndex}_CustomerKey`]: true }
			return p
		}, {} as { [key: string]: boolean })
		dispatch({ type: "setErrorFields", fields: missingFields })
		dispatch({ type: "setWarningFields", fields: warningFields })
	}

	const editCell = (row: number, colName: string, value: any) => {
		const newData = cloneDeep(tableRows)
		newData[row][colName] = value
		setRows(newData)
	}

	const cellRenderer = ({
		id,
		column: columnName,
		rowContent,
	}: {
		id: string
		column: string
		cellContent: any
		rowContent: IRow
		rowIndex: number
	}) => {
		const { required, key } = columns[indexOf(columnNames, columnName)]
		const extKeyField = !config.customerAutoKey && columnName === columnNames[0]
		const value = rowContent[key] || ""
		return (
			<Input
				value={value}
				error={required ? !value?.trim() : undefined}
				warning={extKeyField ? CustomerIDs.includes(value?.trim()) : undefined}
				onChange={e => {
					const value = e.target.value
					if (required) {
						if (!value.trim())
							dispatch({
								type: "setErrorFields",
								fields: { ...errorFields, [`${id}_${key}`]: true },
							})
						else if (errorFields[`${id}_${key}`])
							dispatch({ type: "deleteErrorField", field: `${id}_${key}` })
					}
					if (extKeyField) {
						const duplicate = CustomerIDs.includes(value.trim())
						if (duplicate)
							dispatch({
								type: "setWarningFields",
								fields: { ...warningFields, [`${id}_${key}`]: true },
							})
						else if (warningFields[`${id}_${key}`])
							dispatch({ type: "deleteWarningField", field: `${id}_${key}` })
					}
					editCell(Number(id), key, value)
				}}
			/>
		)
	}

	if (loading) return <Spinner />

	return (
		<div>
			<div style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}>
				<input
					id="testPasteInput"
					className="paste-import-input"
					placeholder={trans("customerModal.pasteHere")}
					onPaste={onPasteHandler}
					value=""
					onChange={() => null}
				/>
				{customerTypeOptions.length > 1 && (
					<InputSelect
						placeholder={"customerModal.selectType"}
						style={{ marginLeft: "1rem" }}
						label="name"
						defaultValue={"none"}
						noSort
						options={customerTypeOptions}
						error={!state.customerType}
						onSelect={(option: { id: string; name: string; [key: string]: any }) =>
							dispatch({ type: "set", key: "customerType", value: option.id })
						}
						optionsWidth={165}
						optionsHeight={120}
					/>
				)}
				{operatorOptions.length > 1 && (
					<InputSelect
						placeholder={"customerModal.selectOperator"}
						style={{ marginLeft: "1rem" }}
						label="name"
						defaultValue={"none"}
						noSort
						options={operatorOptions}
						error={!state.operatorID}
						onSelect={(option: { value: string; name: string }) =>
							dispatch({ type: "set", key: "operatorID", value: option.value })
						}
						optionsWidth={180}
						optionsHeight={120}
					/>
				)}
				<button
					style={{ marginLeft: "1rem" }}
					onClick={() => {
						exportCSV({
							filename: "template.csv",
							fields: columnNames.map(c => ({
								label: c,
								value: "",
							})),
						})([])
					}}
				>
					{trans("customerModal.downloadTemplate")}
				</button>
			</div>
			<div
				style={{
					minHeight: "400px",
				}}
			>
				{tableRows.length ? (
					<DataGridEditable columns={columnNames} rows={tableRows} cellRender={cellRenderer} />
				) : (
					<Tutorial />
				)}
			</div>
			{tableError && <div className="error-message">{tableError}</div>}
			{tableWarning && <div className="warning-message">{tableWarning}</div>}
		</div>
	)
}

const Input = (props: {
	placeholder?: string
	value: string
	onChange: React.ChangeEventHandler<HTMLInputElement>
	disabled?: boolean
	error?: boolean
	warning?: boolean
}) => {
	return (
		<input
			type="text"
			className={`${props.warning ? "warning-border" : ""} ${props.error ? "error-border" : ""}`}
			placeholder={props.placeholder}
			value={props.value}
			onChange={props.onChange}
			disabled={props.disabled}
		/>
	)
}

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { lfmEnterprise_containertypes } from "../../../datasets/containerTypes/lfmEnterprise"
import { df_input_fraction, df_input_name, df_input_terminal } from "../../_drawerFragments/inputs"

export const lfmEnterprise_drawerContent_containers: IDrawerContent = {
	image: { comp: "image", order: 1 },
	customersAllocated: {
		comp: "customersAllocated",
		config: { dataPath: "customersAggregate.count" },
		order: 2,
	},
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name({ header: "stationModal.inletName" }),
			{
				updater: "name",
				key: "parent.name",
				header: "headers.parent.name",
				permission: "read",
				type: "text",
			},
			df_input_terminal(),
			df_input_fraction(),
			{
				updater: "multivalue",
				type: "multivalue",
				key: "properties.containerOptionID",
				header: "headers.type",
				placeholder: "stationModal.selectType",
				multivalDropdownOptions: lfmEnterprise_containertypes(),
				translate: true,
				translatePrefix: "containerOptionID",
				permission: "edit",
			},
			{
				updater: "property",
				key: "properties.binCount",
				header: "properties.binCount",
				permission: "edit",
				type: "number",
			},
		],
	},
	pointEditorDrawer: {
		comp: "pointEditorDrawer",
		order: 4,
		config: {
			permissions: ["create", "read"],
			title: "points",
		},
	},
}

import { get, isEmpty } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { AutoSizer, Column, Table } from "react-virtualized"
import { isStaging } from "../../../Configs/config"
import { useTranslation } from "../../../Contexts"
import { useQueryParam } from "../../../domHooks"
import { useSiteConfig } from "../../../States/siteConfig"
import { Checkbox } from "../../../UI"
import { sortGridList } from "../../../Utils/sorting"
import {
	IAction,
	IColumnSizes,
	IElem,
	IHeader,
	IRow,
	IListViewConfig,
	EntityType,
} from "../../types"
import { usePagination } from "../Pagination/Pagination"
import "./DataGrid.css"

function calculateTableStyle(headers: IListViewConfig["headers"], columnSizes: IColumnSizes) {
	const columns = headers.map((h, i) => {
		if (h.path !== "checkbox") {
			const hint = get(columnSizes, h.path, "1fr")
			if (hint.includes("minmax")) {
				return hint
			}
			return `minmax(100px, ${hint})`
		} else {
			return `minmax(50px, 50px)`
		}
	})

	return {
		gridTemplateColumns: columns.join(" "),
	}
}

type IMultiSelect = {
	checked: string[]
	setChecked: React.Dispatch<React.SetStateAction<string[]>>
	hover: number
	setHover: React.Dispatch<React.SetStateAction<number>>
	rows: IRow[]
	elems: IElem[]
	actions: IAction[]
}

const MultiSelect = ({
	checked,
	setChecked,
	hover,
	setHover,
	rows,
	elems,
	actions,
}: IMultiSelect) => {
	if (!actions || actions.length === 0) {
		return <div id="datagrid-checkboxes"></div>
	}

	return (
		<div id="datagrid-checkboxes">
			<label id="header">
				<Checkbox
					type="checkbox"
					checked={checked.length === rows.length && rows.length > 0}
					onChange={() =>
						checked.length === rows.length ? setChecked([]) : setChecked(rows.map(r => r.id))
					}
				></Checkbox>
			</label>
		</div>
	)
}

function defaultSorter(header: string, order: "asc" | "desc") {
	return (data: IRow[]) => sortGridList(data, header, order)
}

type IDataGrid = {
	headers: IListViewConfig["headers"]
	entityType?: EntityType
	rows: IRow[]
	onRowClick?: (key: IRow) => void
	onCellClick?: (row: IRow, header: IHeader) => boolean
	overrides?: { [key: string]: (key: IRow) => any }
	actions: IAction[]
	allowEmptyField?: boolean
	columnSizes?: IColumnSizes
}

const DataGrid = ({
	headers,
	entityType,
	rows,
	onRowClick,
	onCellClick,
	overrides,
	actions = [],
	allowEmptyField = false,
	columnSizes = {},
}: IDataGrid) => {
	const [hover, setHover] = useState<number>(-1)
	const match = useRouteMatch("/resources/:type/:id")
	const { trans } = useTranslation()
	const history = useHistory()
	const { setNumElems } = usePagination()
	const [checked, setChecked] = useState<string[]>([])
	const [vScrollbarSize, setVScrollbarSize] = useState(0)
	const { siteConfig } = useSiteConfig()

	// This is not reliable
	const type: string | null = get(match, "params.type", null)

	const defaultSort = get(siteConfig, entityType + ".defaultSort", undefined)

	const [sorter, setSorter] = useState(
		defaultSort
			? {
					sortFunc: defaultSorter(defaultSort.header, defaultSort.order || "asc"),
					descending: defaultSort.order || false,
					column: defaultSort.header || "",
			  }
			: {
					sortFunc: defaultSorter("name", "asc"),
					descending: false,
					column: "",
			  }
	)

	const [{ header }]: {
		header: string | null
	}[] = useQueryParam("eventsDrawer", { header: null })
	const id: string | null = get(match, "params.id", null)

	const [activeCell, setActiveCell] = useState<{
		id: string | null
		header: IHeader | null
	}>({ id, header })

	const sortedRows = useMemo(() => sorter.sortFunc(rows), [sorter, rows])

	const onCheckboxChange = (id: string) => () => {
		const index = checked.indexOf(id)
		if (index >= 0) {
			checked.splice(index, 1)
			setChecked([...checked])
		} else {
			setChecked([...checked, id])
		}
	}

	const addMultiSelectCheckbox = (elemId: string, i: number) => {
		const style = {
			opacity: hover === i || checked.includes(elemId) ? "1" : "0",
		}
		return (
			<label key={i} onMouseEnter={() => setHover(i)} onMouseLeave={() => setHover(-1)}>
				<Checkbox
					style={style}
					checked={checked.includes(elemId)}
					onChange={onCheckboxChange(elemId)}
				></Checkbox>
			</label>
		)
	}

	useEffect(() => {
		const UP = 38
		const DOWN = 40

		function updownlistener(event: any) {
			if (event.keyCode === UP) {
				event.preventDefault()
				const current = sortedRows.findIndex(r => r.id === id)
				const newId = sortedRows[Math.max(0, current - 1)].id
				history.replace({
					pathname: `/resources/${type}/${newId}`,
					search: history.location.search,
				})
			}

			if (event.keyCode === DOWN) {
				event.preventDefault()
				const current = sortedRows.findIndex(r => r.id === id)
				const newId = sortedRows[Math.min(sortedRows.length - 1, current + 1)].id
				history.replace({
					pathname: `/resources/${type}/${newId}`,
					search: history.location.search,
				})
			}
		}

		if (id) {
			window.addEventListener("keydown", updownlistener)
			return () => window.removeEventListener("keydown", updownlistener)
		}
	}, [id, type, sortedRows, history])

	useEffect(() => {
		setNumElems(rows.length)
	}, [setNumElems, rows])

	// Allow clients to override how a column is rendered.
	// If no override render cell value as text.
	const renderCell = (row: IRow, header: IHeader) => {
		if (overrides && overrides[header]) {
			return overrides[header](row)
		}
		return get(row, header, allowEmptyField ? "" : `KEY ${header} NOT FOUND`)
	}

	const renderExport = (_rows: IRow[], _headers: IListViewConfig["headers"]) => {
		if (_rows?.length && !isEmpty(overrides)) {
			return _rows.map(_r => ({
				...(Object.fromEntries<string>(
					_headers.map(_h => [
						_h.path,
						_h.path === "result" ? _r["result"] : renderCell(_r, _h.path),
					])
				) as IRow),
				id: _r.id,
			}))
		}
		return _rows
	}

	/* const rowClick = (row: IRow) => {
		if (onRowClick) {
			return () => onRowClick(row)
		}
	} */

	const cellClick = (row: IRow, header: IHeader) => {
		return (event: any) => {
			if (onCellClick && checked.length === 0) {
				event.stopPropagation()
				const allowed = onCellClick(row, header)
				if (allowed) {
					setActiveCell({ id: row.id, header })
				} else {
					if (onRowClick) {
						onRowClick(row)
					}
				}
			} else {
				if (onRowClick) {
					onRowClick(row)
				}
			}
		}
	}

	const cellClass = (row: IRow, header: IHeader) => {
		if (id && activeCell.header && row.id === activeCell.id && header === activeCell.header) {
			return "active"
		}
		return ""
	}

	const sortByHeader = (header: string) => () => {
		const descending = header === sorter.column ? !sorter.descending : false
		setSorter({
			column: header,
			descending,
			sortFunc: defaultSorter(header, descending ? "desc" : "asc"),
			// sortFunc: (rows: IRow[]) => {
			// 	return sortGridList(rows, header, descending ? "desc" : "asc")
			// },
		})
	}

	const unCheckAll = () => setChecked([])

	const drawerShowing = id
	const actionStyle = {
		transform: checked.length > 0 ? "translate(0,0)" : undefined,
		width: drawerShowing ? "calc(100% - 950px - 3rem)" : "calc(100% - 450px - 3rem)",
	}
	const checkedElems = (_rows: IRow[], _checked: string[]) => {
		return _rows.filter(r => _checked.includes(r.id))
	}

	return (
		<div id="datagrid-container">
			<MultiSelect
				{...{
					checked,
					setChecked,
					hover,
					setHover,
					rows,
					elems: sortedRows,
					actions,
				}}
			/>
			{sortedRows ? (
				<AutoSizer>
					{({ width, height }) => (
						<Table
							id="main"
							headerRowRenderer={() => (
								<div
									className="tr ReactVirtualized__Table__headerRow"
									style={{
										...calculateTableStyle(headers, columnSizes),
										width: width - vScrollbarSize,
									}}
								>
									{headers.map((h, i) => (
										<div
											key={`dataGrid_main_headers_` + i}
											onClick={sortByHeader(h.path)}
											className="th ReactVirtualized__Table__headerColumn"
											title={trans(h.name ? h.name : `headers.${h.path}`)}
										>
											{trans(h.name ? h.name : `headers.${h.path}`)}
										</div>
									))}
								</div>
							)}
							headerHeight={50}
							height={isStaging ? height - 48 : height}
							rowCount={sortedRows.length}
							rowGetter={index => sortedRows[index.index]}
							rowHeight={45}
							width={width}
							onScrollbarPresenceChange={({ vertical, size }) => {
								setVScrollbarSize(vertical ? size : 0)
							}}
						>
							{headers.map((h, i) => (
								<Column
									key={`dataGrid_columns_` + i}
									width={100}
									dataKey={h.path}
									cellRenderer={({ rowData, rowIndex }) => (
										<div
											className={`tr ReactVirtualized__Table__row 
                    ${
											(id && rowData.id === id) ||
											hover === rowIndex ||
											checked.includes(rowData.id)
												? "active"
												: ""
										}`}
											role="row"
											aria-rowindex={rowIndex}
											/*onClick={rowClick(rowData)}*/
											onMouseEnter={() => setHover(rowIndex)}
											onMouseLeave={() => setHover(-1)}
											style={{
												...calculateTableStyle(headers, columnSizes),
												width: width,
											}}
										>
											<div
												key={`dataGrid_headers_checkbox` + rowIndex}
												aria-colindex={rowIndex}
												role="gridcell"
												className={`td ReactVirtualized__Table__rowColumn ${cellClass(
													rowData,
													h.path
												)}`}
											>
												<span>{addMultiSelectCheckbox(rowData.id, rowIndex)}</span>
											</div>
											{headers.map(
												(h, i: number) =>
													h.path !== "checkbox" && (
														<div
															key={`dataGrid_headers_` + rowIndex + "_" + i}
															aria-colindex={i + 1}
															role="gridcell"
															title={
																[renderCell(rowData, h.path)].map(val =>
																	typeof val !== "object" ? val : undefined
																)[0]
															}
															className={`td ReactVirtualized__Table__rowColumn ${cellClass(
																rowData,
																h.path
															)}`}
															onClick={cellClick(rowData, h.path)}
														>
															<span>{renderCell(rowData, h.path)}</span>
														</div>
													)
											)}
										</div>
									)}
								/>
							))}
						</Table>
					)}
				</AutoSizer>
			) : (
				<></>
			)}
			<div className="actions" style={actionStyle}>
				<span>
					{checked.length} {checked.length === 1 ? trans("rowSelected") : trans("rowsSelected")}
				</span>
				{actions.map((a, i: number) => (
					<button
						key={i}
						onClick={() => a.func(checkedElems(renderExport(sortedRows, headers), checked))}
					>
						{trans(a.label)}
					</button>
				))}
				<svg
					onClick={unCheckAll}
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z" />
				</svg>
			</div>
		</div>
	)
}

export default DataGrid

import { fetchFunc } from "../../Utils/api"
import { GET_CUSTOMER_EXT_KEYS_AND_PROPS, GET_POINT_EXT_KEYS_AND_PROPS } from "./query"
import { ICustomersExtKeysAndPropsRaw, IExtKeysAndProps, IPointsExtKeysAndPropsRaw } from "./types"

export const fetchCustomerExtKeyAndProps = (id: string) =>
	fetchFunc<ICustomersExtKeysAndPropsRaw, IExtKeysAndProps | null>(
		GET_CUSTOMER_EXT_KEYS_AND_PROPS,
		customersRaw =>
			customersRaw.data.store.customers?.length ? customersRaw.data.store.customers[0] : null,
		{ variables: { id: id }, fetchPolicy: "no-cache" }
	)

export const fetchPointExtKeysAndProps = (id: string) =>
	fetchFunc<IPointsExtKeysAndPropsRaw, IExtKeysAndProps | null>(
		GET_POINT_EXT_KEYS_AND_PROPS,
		pointsRaw =>
			pointsRaw.data.store.accessPoints?.length ? pointsRaw.data.store.accessPoints[0] : null,
		{ variables: { id: id }, fetchPolicy: "no-cache" }
	)

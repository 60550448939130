import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { timeAgo } from "../../../../Utils/timeAgo"
import { ihm_containertypes } from "../../../datasets/containerTypes/ihm"
import {
	df_input_fraction,
	df_input_name,
	df_input_status,
	df_input_terminal,
} from "../../_drawerFragments/inputs"
import {
	df_link_deleteContainer,
	df_link_history,
	df_link_IHMcontainerEvents,
	df_link_keyEditor,
	df_link_keyEditorTerminal,
} from "../../_drawerFragments/links"
import { ihm_fractionList } from "../fractions"

export const ihm_drawerContent_containers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name({ header: "stationModal.inletName" }),
			{
				updater: "externalKey",
				key: "externalKeys.containerId",
				header: "headers.containerId",
				permission: "read",
				type: "text",
			},
			{
				updater: "name",
				key: "parent.name",
				header: "stationModal.stationName",
				permission: "read",
				type: "text",
			},
			df_input_terminal(),
			df_input_status(),
			df_input_fraction({
				parantecesOriginal: true,
				dropdownOptions: ihm_fractionList.map(f => ({ value: f })),
			} as any),
			{
				updater: "multivalue",
				type: "multivalue",
				key: "properties.containerOptionID",
				header: "headers.type",
				placeholder: "stationModal.selectType",
				multivalDropdownOptions: ihm_containertypes(),
				translate: true,
				translatePrefix: "containerOptionID",
				permission: "edit",
			},
			{
				updater: "name", //assignedCustomers
				key: "assignedCustomers",
				header: "properties.assignedCustomers",
				permission: "read",
				type: "text",
			},
			{
				updater: "none",
				key: "fillLevelTs",
				header: "fillLevelTs",
				parseValue: (val, trans) => timeAgo(typeof val === "number" ? val : parseInt(val), trans),
				disabled: ({ data }) =>
					data?.streamed?.fillLevel === undefined || data?.streamed?.fillLevel === null,
			},
		],
	},
	image: { comp: "image", order: 1 },
	customersAllocated: {
		comp: "customersAllocated",
		config: { dataPath: "customersAggregate.count" },
		order: 2,
	},
	linkSection: {
		comp: "links",
		order: 4,
		buttons: [
			df_link_IHMcontainerEvents,
			df_link_history,
			df_link_keyEditor,
			df_link_keyEditorTerminal,
			df_link_deleteContainer,
		],
	},
	pointEditorDrawer: undefined,
}

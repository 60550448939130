import React from "react"
import { useModal } from "../../Contexts"
import { EntityType } from "../../Resources/types"
import { useGeneralConfig } from "../../States/generalConfig"
import { useSiteConfig } from "../../States/siteConfig"
import History from "./History"
import "./History.css"
import Map from "./Map"

interface IHistoryModal {
	id: string
	name?: string
	type: EntityType
}

const HistoryModal = ({ id, name, type }: IHistoryModal) => {
	const { siteConfig } = useSiteConfig()
	const jwt = useGeneralConfig().authJwt

	// Config
	const showLocations = React.useMemo(() => {
		if (type === "customers") return false
		if (type === "containers") return siteConfig[type].history?.location
		return siteConfig.points.history.location
	}, [type, siteConfig])
	const showChanges = React.useMemo(() => {
		if (jwt?.permissions?.includes("wiq-admin")) return true
		if (type === "customers") return siteConfig?.customers.history.action // TODO: .action
		if (type === "containers") return siteConfig.containers.history.action
		return siteConfig.points.history.action
	}, [
		jwt?.permissions,
		type,
		siteConfig?.customers.history.action,
		siteConfig.containers.history.action,
		siteConfig.points.history.action,
	])

	const [points, setPoints] = React.useState([])
	const [showMap, setShowMap] = React.useState((!showChanges && showLocations) || false)
	const { hideModal } = useModal()

	const CloseButton = () => {
		return (
			<svg
				className="close"
				onClick={hideModal}
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z" />
			</svg>
		)
	}

	return (
		<div
			className={`history-modal${showMap ? " show-map" : ""}`}
			onClick={e => e.stopPropagation()}
		>
			<div className="history-map">{showLocations && <Map points={points} zoomPoint={0} />}</div>
			<div className="history-data">
				<CloseButton />
				<History
					id={id}
					type={type === "customers" ? "customer" : "point"}
					setPoints={setPoints}
					locationHistory={showLocations}
					changeHistory={showChanges}
					name={name}
					setShowMap={setShowMap}
				/>
			</div>
		</div>
	)
}

export default HistoryModal

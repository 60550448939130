import { Fragment, useCallback, useMemo } from "react"
import { useModal, useTranslation } from "../../../../Contexts"
import { CreateAccessPointModal } from "../../../../Modals/CreateAccessPointModal/CreateAccessPointModal"
import { ExtKeysAndPropsModal } from "../../../../Modals/ExtKeysAndPropsModal/ExtKeysAndPropsModal"
import { SelectAccessPointsModal } from "../../../../Resources/Points/Modals"
import {
	IPointByIdAccessPointModified,
	IPointByIdBaseAncestor,
} from "../../../../Resources/Points/types"
import { useSiteConfig } from "../../../../States/siteConfig"
import { Ask, Button, FillLevel } from "../../.."
import { ActionButton } from "../../../ActionButton/ActionButton"
import { round } from "../../../../Utils/numbers"
import { FlexBox } from "../../../Layouts/FlexBox/FlexBox"
import { GridBox, numToCssGridUnitRange } from "../../../Layouts/GridBox/GridBox"
import styles from "./PointEditor.module.css"
import { Link } from "react-router-dom"
import { IGetCustomerByIdPoint } from "../../../../Configs/configurations/default/queries/default_fetchById_customers"
import { CARROT_ADMIN, hasPermissions } from "../../../../Utils"
import { EntityType } from "../../../../Resources/types"
import { drawerPointListSorting } from "../../../../Utils/sorting"
import { timeAgo } from "../../../../Utils/timeAgo"

type IAllocationModifiedPoint = {
	priority: number | null
	id: string
	name: string
	pos: number[] | null
}

type IPointPropOptional = Partial<IGetCustomerByIdPoint> &
	Partial<IAllocationModifiedPoint> &
	Partial<IPointByIdAccessPointModified>
// & Partial<{parent: {id: string, name: string, [key:string]: any}}>

export type IPointEditorDrawerPerms = ("read" | "edit" | "create")[]

type IPointProp = { id: string; name: string } & IPointPropOptional

export type PointEditorEntities =
	| "points"
	| "backupPoints"
	| "containers"
	| "stations"
	| "customers"
	| "accessPoints"
	| "inlets"

export const PointEditor = <PointData extends IPointProp>({
	title,
	entity,
	entityName,
	points,
	parentId,
	updatePoints,
	terminal,
	zoomTo,
	showOnMap = true,
	permissions,
}: {
	title: PointEditorEntities
	parentId?: string
	entity: EntityType
	entityName: string
	terminal?: IPointByIdBaseAncestor
	points: {
		data: PointData[]
		fields: { field: keyof PointData; transKey?: string }[]
	}
	showOnMap?: boolean
	permissions: IPointEditorDrawerPerms
	zoomTo?: (point: any) => void
} & (
	| {
			updatePoints: (operation: "add" | "remove" | "create", entity: any[]) => void
	  }
	| {
			updatePoints?: undefined
	  }
)) => {
	const { trans } = useTranslation()
	const { showModal, hideModal } = useModal()
	const hasWiqAdminPerm = useMemo(() => hasPermissions([CARROT_ADMIN]), [])
	const hasEditorPermission = useCallback(
		(perm?: "read" | "edit" | "create") => !!permissions?.find(p => p === perm),
		[permissions]
	)
	const config = useSiteConfig().siteConfig
	const editPermission = useMemo(() => hasEditorPermission("edit"), [hasEditorPermission])
	const createPermission = useMemo(() => hasEditorPermission("create"), [hasEditorPermission])
	const editExtKeysAndPropsPerm = useMemo(
		() => terminal && hasWiqAdminPerm,
		[hasWiqAdminPerm, terminal]
	)
	const gridColumnNum = useMemo(
		() =>
			"1fr " +
			numToCssGridUnitRange(
				points.fields.length +
					(editPermission ? 1 : 0) +
					(editExtKeysAndPropsPerm ? 1 : 0) +
					(showOnMap ? 0 : -1),
				"min-content"
			),
		[editPermission, points.fields.length, editExtKeysAndPropsPerm, showOnMap]
	)

	const askDeletePoint = (point: any) => () => {
		const removePoint = () => {
			updatePoints && updatePoints("remove", [point])
			hideModal()
		}

		showModal(
			<Ask
				question={trans(
					"ask.removePointFromEntity",
					point.name,
					entity === "allocations" ? trans("allocation").toLowerCase() : entityName,
					trans(title.replace(/(s)$/g || "point", "")).toLowerCase()
				)}
				onYes={removePoint}
				onNo={hideModal}
			/>
		)
	}

	const askAddPoint = () => {
		const onConfirm = (newPoints: any) => {
			updatePoints && updatePoints("add", newPoints)
			hideModal()
		}
		showModal(<SelectAccessPointsModal onConfirm={onConfirm} onCancel={hideModal} />)
	}

	const askCreatePoint = () => {
		showModal(
			<CreateAccessPointModal
				initialStationId={parentId}
				refetchQueries={[
					{ query: config[entity]?.data?.fetchAll },
					{ query: config[entity]?.data?.fetchById, variables: { id: parentId } },
				]}
			/>
		)
	}

	if (!permissions.includes("read")) return null

	const List = ({ data }: { data: PointData[] }) => {
		return (
			<GridBox columnGap={2} rowGap={0.75} columns={gridColumnNum} className={styles.pointsGrid}>
				{data.map((point, i: number) => (
					<Fragment key={`editorPointData_${i}`}>
						<PointName point={point} />
						{points.fields
							// Removing name since already showing as default
							.filter(f => f.field !== "name")
							// Mapping out all point keys specified in fields
							.map((f, i) =>
								f.field === "streamed" && point["streamed"] ? (
									<FillLevelCell key={`editorPointField_${i}`} point={point} />
								) : (
									<div key={`editorPointField_${i}`}>
										{point[f.field]
											? f.transKey
												? trans(`${f.transKey}.${point[f.field]}`)
												: point[f.field]
											: ""}
									</div>
								)
							)}
						{showOnMap ? (
							<div
								className={styles["zoom-button"]}
								style={{
									visibility: point?.pos ? undefined : "hidden",
								}}
								onClick={() => zoomTo && zoomTo(point)}
							>
								<svg
									width="18"
									height="12"
									viewBox="0 0 18 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9 0.375C5.25 0.375 2.0475 2.7075 0.75 6C2.0475 9.2925 5.25 11.625 9 11.625C12.75 11.625 15.9525 9.2925 17.25 6C15.9525 2.7075 12.75 0.375 9 0.375ZM9 9.75C6.93 9.75 5.25 8.07 5.25 6C5.25 3.93 6.93 2.25 9 2.25C11.07 2.25 12.75 3.93 12.75 6C12.75 8.07 11.07 9.75 9 9.75ZM9 3.75C7.755 3.75 6.75 4.755 6.75 6C6.75 7.245 7.755 8.25 9 8.25C10.245 8.25 11.25 7.245 11.25 6C11.25 4.755 10.245 3.75 9 3.75Z"
										fill="#0A7045"
									/>
								</svg>
							</div>
						) : (
							<></>
						)}
						{editPermission ? (
							<div className={styles["delete-button"]}>
								<ActionButton
									type="remove"
									title={trans("remove")}
									onClick={askDeletePoint(point)}
									className={styles.pointRemoveButton}
								/>
							</div>
						) : (
							<></>
						)}
						{editExtKeysAndPropsPerm && terminal && (
							<EditIcon
								onClick={() =>
									point &&
									showModal(
										<ExtKeysAndPropsModal
											id={point.id}
											name={point.name || point.id}
											type="point"
											onCancel={hideModal}
										/>
									)
								}
								tooltip={trans("extKeysAndProps.openModalText")}
							/>
						)}
					</Fragment>
				))}
			</GridBox>
		)
	}

	const primaryPoints = points.data.filter(d => (d.priority || 1) < 2).sort(drawerPointListSorting)
	const backupPoints = points.data
		.filter(d => d.priority && d.priority > 1)
		.sort(drawerPointListSorting)

	return (
		<div className={styles.points}>
			<h1>{trans(title)}</h1>
			<List data={primaryPoints}></List>
			{editPermission || createPermission ? (
				<FlexBox justifyContent="flex-end" className={styles["buttonSpaceBefore"]}>
					{editPermission && (entity === "customers" || entity === "allocations") && (
						<Button onClick={askAddPoint}>actions.addNew</Button>
					)}
					{createPermission && <Button onClick={askCreatePoint}>actions.addNew</Button>}
				</FlexBox>
			) : (
				<></>
			)}
			{!!backupPoints.length && (
				<>
					<h1>{trans("backupPoints")}</h1>
					<List data={backupPoints} />
				</>
			)}
		</div>
	)
}

const pointLink = (point: IPointProp) => {
	const config = useSiteConfig.getState().siteConfig
	return point.type === "CONTAINER" && config.movableContainers.drawer.ui !== "disabled"
		? `/resources/movableContainers/${point.id}`
		: point.type === "ACCESS_POINT" &&
		  point.parentTypeGroup &&
		  config.inlets.drawer.ui !== "disabled"
		? `/resources/inlets/${point.id}`
		: (point.type === "ACCESS_POINT" || point.type === "GROUP") &&
		  config.containers.drawer.ui !== "disabled"
		? `/resources/containers/${point.id}`
		: `/resources/points/${point.id}`
}

const PointName = ({ point }: { point: IPointProp }) => {
	const { trans } = useTranslation()
	const fraction = point?.properties?.find(p => p.key === "fraction")?.value
	const pointName = (
		<span
			title={fraction ? trans(`fraction.${fraction}`) + " (" + point.id + ")" : undefined}
			className={styles.pointName}
		>
			{point["name"] || (fraction ? trans(`fraction.${fraction}`) : undefined) || point.id}
		</span>
	)
	const link = pointLink(point)
	return <div>{point["type"] ? <Link to={link}>{pointName}</Link> : pointName}</div>
}

const FillLevelCell = ({ point }: { point: IPointProp }) => {
	const { trans } = useTranslation()
	const fillLevel = point.streamed?.fillLevel
	const fillTs = parseInt((point?.streamed?.versionSpec || "")?.slice(0, 8), 16)
	const interval = timeAgo(fillTs || 0, trans)

	// if (fillLevel === null || fillLevel === undefined) return <div />
	return (
		<div
			title={
				fillLevel !== null && fillLevel !== undefined
					? fillTs
						? `${round(parseFloat(fillLevel))}% - ${interval}`
						: interval
					: undefined
			}
		>
			<FillLevel value={fillLevel || 0} />
		</div>
	)
}

const EditIcon = (props: { onClick?: () => void; tooltip?: string }) => (
	<div
		style={{ display: "inline-block", marginLeft: "0.5rem", verticalAlign: "middle" }}
		className="pointer"
		onClick={props.onClick}
		title={props.tooltip}
	>
		<svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.9833 4.48122L2.66957 15.7949L2.42466 18.4889C2.36812 19.1109 2.88915 19.6319 3.51109 19.5754L6.2051 19.3304L17.5188 8.01675L13.9833 4.48122Z"
				fill="#2E3A59"
			/>
			<path
				d="M18.2259 7.30964L20.3472 5.18831C20.7378 4.79778 20.7378 4.16462 20.3472 3.77409L18.2259 1.65277C17.8354 1.26225 17.2022 1.26225 16.8117 1.65277L14.6904 3.77411L18.2259 7.30964Z"
				fill="#2E3A59"
			/>
		</svg>
	</div>
)

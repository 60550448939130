import React from "react"
import { IconWarning } from "../../Icons/Danger"
import { IconInfo } from "../../Icons/Info"
import { IconSuccess } from "../../Icons/Success"
import { IconDanger } from "../../Icons/Warning"
import "./AlertLine.css"

export const AlertLine = ({
	type,
	message,
	show,
	hideButton,
	setShow,
	className,
}: {
	type: "warning" | "success" | "danger" | "info"
	message: string
	show: boolean
	hideButton?: boolean
	setShow?: () => void
	className?: string
}) => {
	const svgCloseColor =
		type === "warning"
			? "var(--warning-dark)"
			: type === "success"
			? "var(--success-dark)"
			: type === "danger"
			? "var(--danger-dark)"
			: type === "info"
			? "var(--info-dark)"
			: ""

	return (
		<>
			{show ? (
				<div className={`AlertLine ${type} ${className}`}>
					<div className="AlertLine_icon">
						{type === "warning" ? (
							<IconWarning fill="var(--warning-dark)" />
						) : type === "success" ? (
							<IconSuccess fill="var(--success-dark)" />
						) : type === "danger" ? (
							<IconDanger fill="var(--danger-dark)" />
						) : type === "info" ? (
							<IconInfo fill="var(--info-dark)" />
						) : (
							<></>
						)}
					</div>
					<div className="AlertLine_message">{message}</div>
					{hideButton && (
						<button className="AlertLine_close" onClick={setShow}>
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill={svgCloseColor}
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z"></path>
							</svg>
						</button>
					)}
				</div>
			) : (
				<></>
			)}
		</>
	)
}

import { gql } from "apollo-boost"
import { AccessPointStatus } from "../../../Utils/gqlRequestTypes/generic"

interface KeyVal {
	key: string
	value?: string
}
interface TERMINAL_PAYLOAD {
	name: string
	properties?: KeyVal[]
	externalKeys?: KeyVal[]
}
interface STATION_PAYLOAD {
	parent?: { id: string }
	name: string
	status: AccessPointStatus
	properties?: KeyVal[]
	externalKeys?: KeyVal[]
	geoLocation?: {
		decimalDegrees: string
	}
}
interface INLET_PAYLOAD {
	name: string
	status: AccessPointStatus
	properties?: KeyVal[]
	externalKeys?: KeyVal[]
}
export interface TERMINAL_MUTATION {
	type: "TERMINAL"
	payload: TERMINAL_PAYLOAD
	mutation: STATION_MUTATION
}
export interface STATION_MUTATION {
	type: "ACCESS_PARENT"
	payload: STATION_PAYLOAD
	mutations?: INLET_MUTATION[]
}
export interface INLET_MUTATION {
	type: "ACCESS_POINT"
	payload: INLET_PAYLOAD
}

interface AP_MUTATION {
	type: "TERMINAL" | "ACCESS_PARENT" | "ACCESS_POINT"
	payload: PAYLOAD
	mutations?: INLET_MUTATION[]
	mutation?: STATION_MUTATION
}
// type AP_MUTATION = TERMINAL_MUTATION | STATION_MUTATION | STATION_MUTATION_WO_TERMINAL | INLET_MUTATION

type PAYLOAD = TERMINAL_PAYLOAD | STATION_PAYLOAD

// JSON stringify puts keys in quotes, where GQL requires keys are quotes
const objToString = (obj: any) => {
	// eslint-disable-next-line no-useless-escape
	return JSON.stringify(obj)
		.replace(/"([^"]+)":/g, "$1:")
		.replace(/\uFFFF/g, '\\"')
}

const APmutationListGen = (mutationList: AP_MUTATION[]) => {
	return mutationList.reduce((str, mut, index) => {
		return str + `mut${index}:${APmutationGen(mut)}`
	}, "")
}

const APmutationGen = (mut: AP_MUTATION): string => `
    addAccessPointMutation (input: {
        wait: true
        payload: {
            type: ${mut.type}
            ${objToString(mut.payload).replace(/^{(.*)}$/g, "$1")}
        }
    }) {
        commandProcessError
        primaryKey
        ${mut?.mutations?.length ? `mutations {${APmutationListGen(mut.mutations)}}` : ""}
        ${mut?.mutation ? `mutations {${APmutationGen(mut.mutation)}}` : ""}
    }
`

export const NewAPmutationGen = (mut: TERMINAL_MUTATION | STATION_MUTATION) => {
	// console.log(APmutationGen(mut))
	return gql`mutation {
        ${APmutationGen(mut)}
    }`
}

import { mergeConfig } from "../config"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { defaultConfig } from "./default"
import { iksConfigObj } from "./iks"

export const himConfig = (): ISiteConfig => mergeConfig(defaultConfig(), himConfigObj)

const himConfigObj: IPartialSiteConfig = {
	...iksConfigObj,
}

import { FC } from "react"
import create from "zustand"
import { combine } from "zustand/middleware"
import ModalContainer from "../ModalContainer"

export const useConfirmationDialog = create(
	combine(
		{
			show: false,
			title: "",
			// desciption: "",
			onAccept: () => {},
		},
		set => ({
			openConfirmationDialog: (title: string, onAccept: () => void) =>
				set({ show: true, title, onAccept }),
			closeConfirmationDialog: () => set({ show: false }),
		})
	)
)

export const ConfirmationDialog: FC<{}> = () => {
	const { show, title, onAccept, closeConfirmationDialog } = useConfirmationDialog()
	if (!show) return null
	return (
		<div id="modal">
			<ModalContainer
				onCancel={closeConfirmationDialog}
				onConfirm={() => {
					closeConfirmationDialog()
					onAccept()
				}}
				onConfirmType="danger"
				title={title}
				width="400px"
			></ModalContainer>
		</div>
	)
}

export const Add = ({
	fill,
	className,
}: {
	fill?: string
	className?: string
}) => (
	<svg
		className={className}
		viewBox="0 0 256 256"
		style={{ strokeWidth: "2rem", width: "0.8rem" }}
	>
		<line stroke={fill || "#000"} x1="128" y1="0" x2="128" y2="256"></line>
		<line stroke={fill || "#000"} x1="0" y1="128" x2="256" y2="128"></line>
	</svg>
)

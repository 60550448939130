import { nanoid } from "nanoid"
import { IContainerTypes } from "../../datasets/containerTypes/all"
import { ISiteConfig } from "../../types"

export const _cf_lfmEnterprise_accessPointModal = (
	fractions: string[],
	containerTypes: IContainerTypes[]
): ISiteConfig["accessPointModal"] => ({
	enable: true,
	method: "upsert",
	// stationExternalKeyName: "ImportID",
	containerExternalKeyName: "ImportID",
	containerExternalKeyValueGenerator: () => `B${nanoid(5)}`,
	// singleContainer: true,
	fieldList: [
		{
			inputType: "text",
			keyType: "name",
			key: "name",
			placeholder: "headers.name",
			required: false,
		},
		{
			keyType: "property",
			key: "fraction",
			placeholder: "headers.properties.fraction",
			required: true,
			inputType: "dropdown",
			options: fractions.map(f => ({
				name: `fraction.${f}`,
				value: f,
			})),
		},
		{
			inputType: "multiValue",
			placeholder: "headers.type",
			required: true,
			options: containerTypes.map(({ name, values }) => ({
				name: `containerOptionID.${name}`,
				values,
			})),
		},
	],
})

import { gql } from "@apollo/client/core"
import { IStatus } from "../../../../Resources/types"

export const default_fetchById_containers = ({ fillLevel }: { fillLevel?: boolean }) => gql`
	query ACGetPointById($id: String) {
		store {
			data: accessPoints(id: $id) {
				id
				name
				type
				status
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
				pos: geoLocation {lat: latitude lon: longitude dd: decimalDegrees}
				points: children {
					id
					name
					type
					status
					properties {
						key
						value
					}
					pos: geoLocation {lat: latitude lon: longitude dd: decimalDegrees}
					${
						fillLevel
							? `streamed {
								fillLevel: property(key: "fillLevel")
								versionSpec: property(key: "_versionSpec_fillLevel")
							}`
							: ""
					}
					parentTypeGroup: ancestor(type: "GROUP") {id}
				}
				${
					fillLevel
						? `streamed {
							fillLevel: property(key: "fillLevel")
							versionSpec: property(key: "_versionSpec_fillLevel")
						}`
						: ""
				}
				parentTypeGroup: ancestor(type: "GROUP") {id}
				parent: ancestor(type: "ACCESS_PARENT") {
					id
					name
					customers {
						id
						name
					}
				}
				terminal: ancestor(type: "TERMINAL") {
					id
					name
				}
				
				customersAggregate {
					count
				}
			}
		}
	}
`
// TODO: FINISH TYPE
export type Idefault_fetchById_container = {
	store: {
		data: IFetchByIdContainer[]
	}
}

export type IFetchByIdContainer = {
	id: string
	name: string
	type: string
	status: IStatus | null
	properties: { key: string; value: string | null }[]
	externalKeys: { key: string; value: string | null }[]
	pos?: {
		lat: number
		lon: number
	} | null
	points: IFetchByIdContainer[]
	streamed?: {
		fillLevel: string | null
	}
}

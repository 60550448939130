import { FC, useEffect, useState } from "react"
import { ITileProps, Tile } from "./Tile"
import { ResponsiveBar, BarDatum } from "@nivo/bar"
import { Filter } from "./components/Filter"
import { ValueFormat } from "@nivo/core"
import { useTranslation } from "../../Contexts"
import { fractionMapping } from "../../Configs/datasets/fractionMappings/fractionMappings"

export const BarChartTile: FC<{
	data: BarDatum[]
	tileProps?: ITileProps
	useFilter?: boolean
	valueFormat?: ValueFormat<number>
}> = ({ data, tileProps, useFilter, valueFormat }) => {
	const [keys, setKeys] = useState<string[]>([])
	const [filteredData, setFilteredData] = useState(data)

	const { trans } = useTranslation()

	useEffect(() => {
		setKeys(
			data.reduce(
				(prev, obj) => [
					...prev,
					...Object.entries(obj).reduce((p, [k]) => {
						if (k === "period" || prev.includes(k)) return p
						return [...p, k]
					}, [] as string[]),
				],
				[] as string[]
			)
		)
	}, [data])

	return (
		<Tile {...tileProps}>
			{useFilter && <Filter data={data} indexBy="period" onFilter={setFilteredData} keys={keys} />}
			<ResponsiveBar
				data={filteredData}
				margin={{ top: 20, right: 0, bottom: 25, left: 0 }}
				indexBy={"period"}
				keys={keys}
				enableGridY={false}
				axisLeft={null}
				colors={datum => {
					return (fractionMapping[datum.id] && fractionMapping[datum.id].color) || "var(--black-1)"
				}}
				labelSkipHeight={20}
				valueFormat={valueFormat}
				tooltipLabel={datum => {
					return trans(`fraction.${datum.id}`)
				}}
			/>
		</Tile>
	)
}

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import {
	df_input_fraction,
	df_input_status,
	df_input_terminal,
} from "../../_drawerFragments/inputs"
import {
	df_link_deleteContainer,
	df_link_history,
	df_link_keyEditor,
	df_link_keyEditorTerminal,
} from "../../_drawerFragments/links"

export const default_drawerContent_containers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [df_input_terminal(), df_input_status(), df_input_fraction()],
	},
	image: { comp: "image", order: 1 },
	customersAllocated: {
		comp: "customersAllocated",
		config: { dataPath: "customersAggregate.count" },
		order: 2,
	},
	linkSection: {
		comp: "links",
		order: 4,
		buttons: [
			df_link_history,
			df_link_keyEditor,
			df_link_keyEditorTerminal,
			df_link_deleteContainer,
		],
	},
	pointEditorDrawer: {
		comp: "pointEditorDrawer",
		order: 4,
		config: {
			permissions: ["create", "read"],
			title: "points",
		},
	},
}

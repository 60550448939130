import { SVGProps } from 'react'

export const LithiumbatterierIcon = ({
  fill = 'black',
  width = '12',
  height = '26',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 12 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.70661 0.631348V2.40743H3.36401V0.631348H8.70661Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.673706 4.1835V25.5343H11.3968V4.1835H0.673706ZM5.14481 6.8665H6.96361V8.6048H8.70661V10.4187H6.96361V12.157H5.14481V10.4187H3.36401V8.6048H5.14481V6.8665ZM3.36401 21.113H8.70661V22.8513H3.36401V21.113ZM3.5155 19.2614V13.8953H4.34911V18.5056H6.96361V19.2614H3.5155ZM8.55501 13.8953H7.75931V14.6889H8.55501V13.8953ZM7.75931 15.2935H8.55501V19.2614H7.75931V15.2935Z"
        fill={fill}
      />
    </svg>
  )
}

import React from "react"
import { useTranslation } from "../Contexts"

import "./TextInputField.css"

const TextInputField = React.forwardRef(
	({ placeholder, value, onChange, extraClass, readOnly }: any, ref: any) => {
		const className = ["text-input-field", extraClass, readOnly ? "readOnly" : undefined]
			.filter(x => x)
			.join(" ")
		const { trans } = useTranslation()
		return (
			<label className={className}>
				<input
					ref={ref}
					type="text"
					placeholder={placeholder && trans(placeholder)}
					value={value}
					onChange={onChange}
					readOnly={readOnly}
				/>
			</label>
		)
	}
)

export default TextInputField

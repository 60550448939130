/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "@apollo/client"
import { diff } from "deep-diff"
import { get, pick } from "lodash"
import { useTranslation } from "../../Contexts"
import { Spinner } from "../../UI"
import { keyValueArrayToObject } from "../../Utils/objArr"
import "./History.css"
import { HistoryEntry } from "./HistoryEntry"
import { GET_CUSTOMER_HISTORY, GET_POINT_HISTORY, RESOLVE_POINTS } from "./HistoryQueries"
import {
	AccessPointHistoryEntry,
	CustomerHistoryEntry,
	HistoricAccessPoint,
	HistoricCustomer,
	IHistoryData,
	IHistoryType,
	ResolvedPoints,
	ResolvePointsData,
} from "./HistoryTypes"
import { LocationEntry } from "./LocationEntry"
import { translate } from "../../Contexts/Translation"
import { fetchFunc } from "../../Utils/api"

interface IHistoryProps {
	id: string
	type: IHistoryType
	title?: string
	locationHistory?: boolean
	changeHistory?: boolean
	setPoints: any
	name?: string
	setShowMap: any
}

const History = ({
	id,
	type,
	title = "title",
	locationHistory = false,
	changeHistory = true,
	setPoints,
	name,
	setShowMap,
}: IHistoryProps) => {
	const query = type === "point" ? GET_POINT_HISTORY : GET_CUSTOMER_HISTORY
	const queryDataType = type === "point" ? "accessPointEntityHistory" : "customerEntityHistory"
	const { data, loading, error } = useQuery(query, {
		variables: { id: id },
		fetchPolicy: "no-cache", // Otherwise data would be incorrect
	})
	const { trans } = useTranslation()
	const [tab, setTab] = useState(changeHistory ? 0 : 1)
	const [pointsToResolve, setPointsToResolve] = useState<string[]>([])
	const [resolvedPoints, setResolvedPoints] = useState<ResolvedPoints>({})
	const changeTab = (tabIndex: number) => {
		setTab(tabIndex)
		if (tabIndex === 1) {
			setShowMap(true)
		} else {
			setShowMap(false)
		}
	}

	const history = get<(AccessPointHistoryEntry | CustomerHistoryEntry)[]>(data, queryDataType, [])

	useEffect(() => {
		if (history?.length)
			setPointsToResolve(
				history
					.reduce(
						(prev, h) => [
							...prev,
							...(h?.current.points?.map(p => p.id) || []),
							...(h?.previous?.points?.map(p => p.id) || []),
						],
						[] as string[]
					)
					.reduce((prev, id) => (prev.includes(id) ? prev : [...prev, id]), [] as string[])
			)
	}, [history])

	useEffect(() => {
		if (pointsToResolve.length)
			fetchFunc(
				RESOLVE_POINTS,
				(data: ResolvePointsData) =>
					setResolvedPoints(
						data?.data?.store?.accessPoints.reduce(
							(prev, point) => ({ ...prev, [point.id]: point.name }),
							{}
						)
					),
				{ variables: { ids: pointsToResolve } }
			)
	}, [pointsToResolve])

	// parse arrays to object so Diff can name changes in arrays instead of indexers
	const prepareHistData = (histEntry: HistoricAccessPoint | HistoricCustomer) => ({
		...histEntry,
		externalKeys: keyValueArrayToObject(histEntry.externalKeys),
		properties: keyValueArrayToObject(histEntry.properties),
		...(histEntry?.points
			? {
					points: histEntry?.points?.reduce(
						(prev, point) => ({ ...prev, [point.id]: pick(point, "priority") }),
						{}
					),
			  }
			: {}),
		...(histEntry?.identities
			? { identities: histEntry?.identities?.reduce((p, i) => ({ ...p, [i.id]: "" }), {}) }
			: {}),
	})
	const preparedData =
		// [...history] ||
		history
			.map(h => ({
				...h,
				current: prepareHistData(h.current),
				previous: h.previous ? prepareHistData(h.previous) : null,
			}))
			.sort((a, b) => b.timestamp - a.timestamp)
	const previousList = preparedData.map((h: any) => h.previous)
	const parseHistory = (ts: number): IHistoryData => {
		const { timestamp, command, current, previous } = preparedData.find(h => h.timestamp === ts)!
		const user = command?.command?.user?.name || command?.command?.user?.id || translate("unknown")
		const changes = diff<any>(previous, current, {
			normalize: (path, key, lhs, rhs) => {
				if (
					["externalKeys", "properties", "points", "identities", "geoLocation"].some(k => k === key)
				) {
					if (lhs === null) lhs = {}
					if (rhs === null) rhs = {}
				}
				return [lhs, rhs]
			},
		})
		return {
			type: current && !previous ? "created" : "changed", // !previousList[index] ? "created" : "changed",
			timestamp,
			user,
			changes: changes as any,
		}
	}

	// map marker positions
	const generateLocations = () => {
		const locations: any[] = preparedData
			.map((h: any, index: number) => {
				const { current, command } = h
				if (index === 0 && current.geoLocation === null) return null
				const previous = previousList[index - 1]
				const user = command?.command?.userId
				const location = index === 0 ? current.geoLocation : previous.geoLocation
				if (!location) return null
				return {
					id: index,
					lat: location.latitude,
					lon: location.longitude,
					timestamp: h.timestamp,
					user,
				}
			})
			.filter((p: any) => !!p)
			.reverse()
			.filter((l: any, i: number, ar: any[]) => {
				const { lat, lon } = l
				const prevLoc = ar[i - 1]
				if (!prevLoc) return true
				const { lat: pLat, lon: pLon } = prevLoc
				if (pLat === lat && pLon === lon) return false
				return true
			})
			.reverse()

		return locations
	}

	// 7 lines of code bellow fixes stupid rerender issue because of setPoints()
	const locations = useMemo(() => {
		if (!locationHistory) return []
		return generateLocations()
	}, [history])

	useEffect(() => {
		if (locations?.length) setPoints(locations)
	}, [locations])

	if (error) {
		console.error(error)
	}

	return (
		<div className="point-history">
			<div className="flex-row history-title">
				<h1>{trans(`history.${title}`)}</h1>
				<div className="spacer" />
				{name && <h2>{name}</h2>}
			</div>

			<div className="flex-row history-tabs">
				{changeHistory && (
					<div
						className={tab === 0 ? "active" : undefined}
						onClick={() => {
							changeTab(0)
						}}
					>
						{trans(`history.changes`)}
					</div>
				)}
				{locationHistory && (locations?.length > 0 || !changeHistory) && (
					<div
						className={tab === 1 ? "active" : undefined}
						onClick={() => {
							changeTab(1)
						}}
					>
						{trans(`history.locations`)}
					</div>
				)}
			</div>

			{loading && <Spinner />}
			{!loading &&
				(history?.length !== 0 ? (
					<div>
						{tab === 0 &&
							history.map((h, index) => {
								const key = "HistoryEntry" + index
								return (
									<HistoryEntry
										key={key}
										keyProp={key}
										data={parseHistory(h.timestamp)}
										resolvedPoints={resolvedPoints}
									/>
								)
							})}
						{tab === 1 &&
							(locations.length ? (
								locations.map((loc: any, index: number) => {
									const key = "LocationEntry" + index
									return <LocationEntry key={key} data={loc} />
								})
							) : (
								<div>{trans("history.noLocationHistory")}</div>
							))}
					</div>
				) : (
					<div>{trans("history.noHistory")}</div>
				))}
		</div>
	)
}

export default History

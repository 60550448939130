import { useState } from "react"
import { Switch, Redirect, Route } from "react-router-dom"

import { useQuery } from "@apollo/client"
import { gql } from "apollo-boost"

import SideNav from "./SideNav"
import Resources from "./Resources"
import "./Main.css"
import Dashboard from "./Dashboard"
import { IsStagingMessage } from "./UI/IsStagingMessage"
import { isStaging } from "./Configs/config"
import { FilterProvider } from "./Resources/Filter"
import { DUMMY_QUERY } from "./APIfunctionsGeneric/queries/dummyQuery"
import { useTranslation } from "./Contexts"
import { Loading } from "./App"

const GET_NUM_REQUESTS = gql`
	{
		numRequests @client
	}
`

const LoadingIndicator = () => {
	const { data } = useQuery(GET_NUM_REQUESTS)
	return (
		<svg
			className={data.numRequests > 0 ? "active" : ""}
			viewBox="0 0 100 1"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<line x1="0" y1="0" x2="100" y2="0" />
		</svg>
	)
}

const fromLocal = JSON.parse((window as any).localStorage.getItem("minimize-sidenav"))
const initialMinimize = fromLocal || false

const Main = () => {
	const { loading, error } = useQuery(DUMMY_QUERY)
	const { trans } = useTranslation()

	const [minimizeSideNav, setMinimizeSideNav] = useState(initialMinimize)

	const setMinimize = (value: boolean) => {
		window.localStorage.setItem("minimize-sidenav", JSON.stringify(value))
		setMinimizeSideNav(value)
	}

	if (loading) return <Loading />

	if (error?.message.includes("status code 401"))
		return (
			<div className="unauthorized-page">
				<div dangerouslySetInnerHTML={{ __html: trans("hints.unauthorized") }} />
			</div>
		)

	const protocol = window.location.protocol
	const newUrl = window.location.host.replace(/legacy[-.]/, "")

	return (
		<>
			<div id="Main" className={minimizeSideNav ? "minimized-sidenav" : ""}>
				<FilterProvider>
					<SideNav minimized={minimizeSideNav} minimize={setMinimize} />
					<main>
						<LoadingIndicator />
						<div style={{
							width: "100%",
							color: "white",
							backgroundColor: "var(--error-color)",
							padding: "10px",
							paddingBottom: "12px",
						}}>
							{trans("hints.outdatedInsightPt1")} {" "}
							<a href={`${protocol}//${newUrl}`} style={{color: "white", textDecoration: "underline"}}>
								{newUrl}
							</a>. {trans("hints.outdatedInsightPt2")} {" "}
							<a href="mailto:support@carrot.tech" style={{color: "white", textDecoration: "underline"}}>
								support@carrot.tech
							</a>.
						</div>

						<Switch>
							<Route path="/" exact>
								<Redirect to="/resources" />
							</Route>
							<Route path="/resources" exact>
								<Redirect to="/resources/customers" />
							</Route>

							<Route path="/resources">
								<Resources />
							</Route>
							<Route path="/dashboard">
								<Dashboard />
							</Route>
						</Switch>
					</main>
				</FilterProvider>
			</div>
			{isStaging ? <IsStagingMessage /> : <></>}
		</>
	)
}

export default Main

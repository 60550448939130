import { useQuery } from "@apollo/client"
import { loader } from "graphql.macro"
import { uniqBy } from "lodash"
import { useMemo } from "react"
import { localeFromLocalStorage } from "../../Contexts/Translation"
import { useSiteConfig } from "../../States/siteConfig"
import { toLeafletFormat } from "../Customers/data"
import {
	IAccessPoint,
	IAccessPointData,
	IAccessPointModified,
	IFillLevel,
	IPointByIdGenericModified,
	IPointByIdGenericRaw,
} from "./types"

// Get specific access_points only (without children) by ids
export const GET_ACCESS_POINTS_ONLY_BY_IDS = loader("./queries/getAccessPointsOnlyByIds.graphql")
// From point type "ACCESS_PARENT" through of a hierachy tree of children points
export const GET_ACCESS_POINTS_RECURSIVE_FROM_ACCESS_PARENTS = loader(
	"./queries/getAccessPointsRecursiveFromAccessParents.graphql"
)

export const GET_ACCESS_POINT_ID_BY_IMPORT_ID = loader(
	"./queries/getAccessPointIdByImportId.graphql"
)

export function renamePointPayload(id: string, name: string) {
	return {
		input: {
			wait: true,
			payload: {
				accessPoint: {
					id,
				},
				updateFields: {
					name,
				},
			},
		},
	}
}

export function transformPoint<
	QUERYDATA extends IPointByIdGenericRaw,
	NORMALIZED extends IPointByIdGenericModified
>(pointRaw: QUERYDATA): NORMALIZED | undefined {
	const point = pointRaw.store.accessPoint
	if (!point?.id) return undefined

	const customerCount = point.customersAggregate.count || null

	return {
		...point,
		accessPoints: point.accessPoints?.map(p => ({ ...p, pos: toLeafletFormat(p.pos) })),
		customerCount,
	} as NORMALIZED
}

/* export const transformPoint = (pointRaw: IPointByIdRaw) =>
	transformPointBase<IPointByIdRaw, IPointByIdModified>(pointRaw) */

export function pointsFromResponse(
	data: IAccessPoint[] | null,
	pointTypesOfInterest: string[]
): IAccessPointModified[] {
	const trans = useSiteConfig.getState().siteConfig.translations

	const result: IAccessPointModified[] = []
	const recur = (data: IAccessPoint[] | null, parent: IAccessPoint | null) => {
		return data?.map(p => {
			if (pointTypesOfInterest.find(ptoi => ptoi === p.type)) {
				const childrenFillLevelAndFractions = getChildrenFillLevelsAndFractions([p], trans)
				result.push({
					...p,
					fillLevels: childrenFillLevelAndFractions as IFillLevel[],
					pos: p.pos || (p.resolveAddress?.length ? p.resolveAddress[0] : null),
					properties:
						p.properties?.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value }), {}) || {},
					externalKeys:
						p.externalKeys?.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value }), {}) || {},
					childrenFractions: uniqBy(childrenFillLevelAndFractions, f => f.fraction),
					installation: parent
						? {
								id: parent.id,
								name: parent.name,
						  }
						: null,
				} as IAccessPointModified)
			}
			if (p.points?.length) recur(p.points, p)
			return null
		})
	}

	if (data) {
		recur(data, null)
	}
	return result
}

export const getChildrenFillLevelsAndFractions = (
	accessPoints: IAccessPoint[] | null,
	trans: any
): IFillLevel[] =>
	accessPoints?.flatMap(ap => {
		const properties: { [key: string]: string | null } | undefined = ap.properties?.reduce(
			(prev, curr) => ({ ...prev, [curr.key]: curr.value }),
			{}
		)
		// removing 0001 and 0000 fractions that are for no use here to fraction view and filtering
		return properties?.fraction && properties.fraction !== "0000" && properties.fraction !== "0001"
			? [
					{
						fraction: properties.fraction,
						fractionName:
							trans[localeFromLocalStorage].fraction[properties.fraction] || properties.fraction,
						fillLevel: ap.streamed?.fillLevel ? parseFloat(ap.streamed.fillLevel) : 0,
						fillLevelTs: ap.streamed?.versionSpec
							? parseInt(ap.streamed?.versionSpec.slice(0, 8), 16)
							: undefined,
					},
					...getChildrenFillLevelsAndFractions(ap.points, trans),
			  ]
			: // removing 0001 and 0000 fractions that are for no use to fraction view and filtering
			properties?.fraction && properties.fraction !== "0000" && properties.fraction !== "0001"
			? [
					{
						fraction: properties.fraction,
						fractionName:
							trans[localeFromLocalStorage].fraction[properties.fraction] || properties.fraction,
					},
					...getChildrenFillLevelsAndFractions(ap.points, trans),
			  ]
			: getChildrenFillLevelsAndFractions(ap.points, trans)
	}) || ([] as IFillLevel[])

export function useGetAccessPoints(pointTypesOfInterest: "points" | "containers") {
	const dataMethods = useSiteConfig.getState().siteConfig[pointTypesOfInterest].data
	const { loading, data, error, client } = useQuery<IAccessPointData>(dataMethods.fetchAll, {
		errorPolicy: "all",
	})
	const points = useMemo(() => (data ? dataMethods.fetchAllParse(data) : []), [data, dataMethods])
	return { loading, points, error, client }
}

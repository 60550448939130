export const containerOptionID_EN = {
	bin40: "Bin 40L",
	bin140: "Bin 140L",
	bin240: "Bin 240L",
	bin360: "Bin 360L",
	bin660: "Bin 660L",
	bin770: "Bin 770L",
	bin1000: "Bin 1000L",
	drum209: "Drum 209L",
	bag: "Bag",
	bag100: "Bag 100L",
	bag125: "Bag 125L",
	container5000: "Container 5m³",
	container10000: "Container 10m³",
	container15000: "Container 15m³",
	container6m3: "Container 6m³",
	container8m3: "Container 8m³",
	compressor10000: "Compressor 10m³",
	compressor14000: "Compressor 14m³",
	compressor15000: "Compressor 15m³",
	compressor16000: "Compressor 16m³",
	compressor18000: "Compressor 18m³",
	compressor20000: "Compressor 20m³",
	openhookcontainer22000: "Open hook container 22m³",
	case1000: "Case 1m³",
	elcage1500: "E-cage 1.5m³",
	ibc1000: "IBC 1m³",
	closedibc1000: "Closed IBC 1m³",
	redbox: "Red box",
	palletframe1000: "Pallet w/ frame 1m³",
	palletbox800: "Pallet box 800L",
	palletbox500: "Pallet box 500L",
	specialcontainer: "Special container",
	mrfillcabinet140: "Mr Fill cabinet 140L",
	mrfillcabinet240: "Mr Fill cabinet 240L",
	pottingercompressor10000: "Pottinger compressor 10m³",
	pottingercompressor12000: "Pottinger compressor 12m³",
	pottingercompressor14000: "Pottinger compressor 14m³",
	pottingercompressor16000: "Pottinger compressor 16m³",
	pottingercompressor18000: "Pottinger compressor 18m³",
	pottingercompressor20000: "Pottinger compressor 20m³",
	carton: "Carton box",
	ball: "Ball",
	liftcontainer4500: "Liftcontainer 4.5m³",
	liftcontainer6000: "Liftcontainer 6m³",
	liftcontainer8000: "Liftcontainer 8m³",
	liftcontainer10000: "Liftcontainer 10m³",
	undergroundContainer08m3: "Underground container 0.8m³",
	undergroundContainer3m3: "Underground container 3m³",
	undergroundContainer5m3: "Underground container 5m³",
}

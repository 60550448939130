import React from "react"
import { useTranslation } from "../../../Contexts"
import "./Error.css"

const Error = ({ children }: any) => {
	const { trans } = useTranslation()
	return (
		<div className="error">
			<header>
				<div style={{ height: 60, width: 60 }}>
					<svg viewBox="0 0 512 512">
						<path
							id="circle"
							d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
						/>
						<path
							id="bang"
							d="M250.26,166.05,256,288l5.73-121.95a5.74,5.74,0,0,0-5.79-6h0A5.74,5.74,0,0,0,250.26,166.05Z"
						/>
						<path
							id="dot"
							d="M256,367.91a20,20,0,1,1,20-20A20,20,0,0,1,256,367.91Z"
						/>
					</svg>
				</div>
				<h1>{trans("error")}</h1>
			</header>
			<div className="message">{children}</div>
		</div>
	)
}

export default Error

import {
	IFilterFetch,
	IFiltersObjFetch,
} from "../../../Resources/Events/components/EventsFilter/types"
import { IFilterSpec, IFilterSpecBase, IRow } from "../../../Resources/types"

export const filterConfigGen =
	(filters: ((data: IRow[]) => IFilterSpecBase)[]) =>
	(client: any, data: IRow[]): IFilterSpec => ({
		config: {
			client,
		},
		specs: filters.map(f => f(data)),
	})

export const eventFilterConfigGen = (filters: IFilterFetch[]) => (): IFiltersObjFetch => ({
	type: "API",
	filters,
})

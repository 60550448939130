import { IEventDatesConfig } from "../../../Resources/Events/types"

export const bir_eventsDatesConfig: IEventDatesConfig = {
	filterEventsByTime: true, // Limiting event results or not
	maxUnfilteredDays: 0,
	maxUnfilteredHours: 12,
	maxFilteredDays: 7, // If set to 0 - unlimited
	calendarIntervals: [
		[1, "day"],
		[2, "days"],
		[3, "days"],
		[1, "week"],
		[1, "month"],
		[3, "months"],
	],
}

import { IDropdownOption } from "../../../UI/Drawer/components/Inputs/DrawerField"

export const default_vendorOptions_station: IDropdownOption[] = [
	{ value: "Strømbergs" },
	{ value: "Namdal Ressurs" },
	{ value: "EnviroPac" },
	{ value: "Total Holding" },
	{ value: "Envac" },
]

export const default_vendorOptions_inlet: IDropdownOption[] = [
	{ value: "VConsyst" },
	{ value: "Sulo" },
	{ value: "EcoBins" },
	{ value: "Shop2Win" },
	{ value: "Namdal Ressurs Connect (NRC)" },
	{ value: "Sesam" },
]

import { SVGProps } from 'react'

export const PlastfolieIcon = ({
  fill = 'black',
  width = '423',
  height = '341',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 423 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M359.945 126.673H322.149L145.772 303.051H183.567C227.661 303.051 246.559 340.847 246.559 340.847L422.937 164.469C422.937 164.469 404.039 126.673 359.945 126.673Z"
        fill={fill}
      />
      <path
        d="M76.48 277.855C48.6479 277.855 26.0864 255.292 26.0864 227.461C26.0864 199.629 48.6479 177.067 76.48 177.067C104.313 177.067 126.873 199.629 126.873 227.461C126.873 255.292 104.313 277.855 76.48 277.855ZM303.252 76.28C303.252 34.532 269.409 0.689315 227.661 0.689315C206.789 0.689315 187.891 9.15062 174.211 22.8293L23.0292 174.009C9.3516 187.689 0.890137 206.588 0.890137 227.461C0.890137 269.208 34.7323 303.051 76.48 303.051C97.3533 303.051 116.251 294.589 129.932 280.912L281.112 129.731C294.791 116.051 303.252 97.1533 303.252 76.28"
        fill={fill}
      />
      <path
        d="M76.4801 208.563C66.0441 208.563 57.5828 217.025 57.5828 227.461C57.5828 237.897 66.0441 246.359 76.4801 246.359C86.9174 246.359 95.3788 237.897 95.3788 227.461C95.3788 217.025 86.9174 208.563 76.4801 208.563Z"
        fill={fill}
      />
    </svg>
  )
}

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { orkla_containertypes } from "../../../datasets/containerTypes/orkla"
import { df_input_name } from "../../_drawerFragments/inputs"
import { df_link_keyEditor, df_link_routeGenerator } from "../../_drawerFragments/links"

export const orkla_drawerContent_containers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name({ permission: "read" }),
			{
				updater: "property",
				key: "properties.volume",
				header: "headers.properties.volume",
				permission: "read",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.status",
				header: "headers.properties.status",
				permission: "read",
				type: "text",
				translate: true,
				translatePrefix: "type",
			},
			{
				updater: "multivalue",
				type: "multivalue",
				key: "properties.containerOptionID",
				header: "headers.type",
				placeholder: "stationModal.selectProduct",
				multivalDropdownOptions: orkla_containertypes(),
				translate: true,
				translatePrefix: "containerOptionID",
				permission: "read",
			},
			{
				updater: "property",
				key: "properties.version",
				header: "headers.properties.version",
				permission: "edit",
				type: "text",
			},
		],
	},
	linkSection: {
		comp: "links",
		buttons: [
			df_link_routeGenerator("events", "eventsAtContainer", "pointName"),
			df_link_keyEditor,
		],
		order: 1,
	},
	pointEditorDrawer: null,
	map: null,
}

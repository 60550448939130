import React, { useReducer, useEffect } from "react"
import { cloneDeep, pullAt, isEqual } from "lodash"

import "./MultiForm.css"

function genId() {
	return Math.floor((1 + Math.random()) * 0x1000000)
		.toString(16)
		.substring(1)
}

function row(create: any) {
	return {
		...create(),
		_id: genId(),
	}
}

function initialState(create: any) {
	return [row(create)]
}

const reducer = (create: any) => (state: any, action: any) => {
	const newState = cloneDeep(state)
	switch (action.type) {
		case "update":
			const oldValue = state[action.index]
			if (isEqual(oldValue, action.newValue)) {
				return state
			}
			newState[action.index] = action.newValue
			return newState
		case "add":
			newState.push(row(create))
			return newState
		case "remove":
			pullAt(newState, [action.index])
			return newState
		default:
			throw new Error(`Unknown action: ${action.type}`)
	}
}

const Row = ({ rowId, content, index, dispatch, listLength }: any) => {
	// const firstRow = index === 0
	const lastRow = index === (listLength || 1) - 1
	const add = () => dispatch({ type: "add", index })
	const remove = () => dispatch({ type: "remove", index })

	return (
		<div key={rowId} className="multi-form-row">
			{content}
			<div className="svg-container">
				<svg width="20" height="20" viewBox="0 0 512 512" onClick={lastRow ? add : remove}>
					{lastRow && <line x1="256" y1="112" x2="256" y2="400" />}
					<line x1="400" y1="256" x2="112" y2="256" />
				</svg>
			</div>
		</div>
	)
}

const MultiForm = ({ create, render, validate, onChange }: any) => {
	const [state, dispatch] = useReducer(reducer(create), initialState(create))
	const update = (index: number) => (newValue: any) => dispatch({ type: "update", index, newValue })

	useEffect(() => {
		if (validate) {
			if (state.filter(validate).length === state.length) {
				onChange(state)
			}
		} else {
			onChange(state)
		}
	}, [onChange, state, validate])

	return (
		<div className="multi-form-container">
			{state.map((row: any, index: number) => (
				<Row
					key={row._id}
					content={render(row, update(index))}
					dispatch={dispatch}
					listLength={state.length}
					index={index}
				/>
			))}
		</div>
	)
}

export default MultiForm

export function mergeValuesByFractionLabel<T>(
	accessPoints: T extends {
		id: string
		fraction: string | null
	}[]
		? T
		: never,
	trans?: any
) {
	const sortByFractionToObj = accessPoints.reduce((prev: any, curr) => {
		if (curr.fraction) {
			const fractionName = curr.fraction ? trans(`fraction.${curr.fraction}`) : curr.fraction
			return {
				...prev,
				[fractionName]: fractionName in prev ? [...prev[fractionName], curr.id] : [curr.id],
			}
		} else {
			return prev
		}
	}, {})
	return Object.keys(sortByFractionToObj).map(k => ({
		label: k,
		value: sortByFractionToObj[k],
	}))
}

export const mergeValuesByLabel = (data: { label: string | null; value: string }[]) => {
	const sortByLabelToObj = data.reduce((prev: any, curr) => {
		if (curr.label) {
			return {
				...prev,
				[curr.label]: curr.label in prev ? [...prev[curr.label], curr.value] : [curr.value],
			}
		} else {
			return prev
		}
	}, {})
	return Object.keys(sortByLabelToObj).map(k => ({
		label: k,
		value: sortByLabelToObj[k],
	}))
}

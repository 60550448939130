import React from "react"
import { Button, Spinner } from "."
import { IButtonType } from "./Button"
import styles from "./ModalContainer.module.css"

type IModalContainer = {
	width: string
	maxWidth?: string
	minHeight?: string
	maxHeight?: string
	title?: string
	entity?: string
	loading?: boolean
	loadingConfirm?: boolean
	onConfirm?: () => void
	onConfirmText?: string
	onConfirmType?: IButtonType
	onConfirmDisabled?: boolean
	onCancel: () => void
	onCancelType?: IButtonType
	onCancelText?: string
	disableCloseButton?: boolean
	disableCancelButton?: boolean
	buttonsOverride?: JSX.Element
}

const ModalContainer: React.FC<IModalContainer> = ({
	width,
	maxWidth,
	minHeight,
	maxHeight,
	onConfirm,
	onConfirmText = "ask.confirm",
	onConfirmType = "primary",
	onConfirmDisabled,
	onCancel,
	onCancelText = "ask.cancel",
	onCancelType = "third",
	disableCloseButton,
	disableCancelButton,
	buttonsOverride,
	loading,
	loadingConfirm,
	title,
	entity,
	children,
}) => {
	const CloseButton = () => {
		return (
			<svg
				className={styles.close}
				onClick={onCancel}
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z" />
			</svg>
		)
	}

	return (
		<div
			className={styles.modalContainer}
			style={{ width, maxWidth, minHeight, maxHeight }}
			onClick={(ev: any) => ev.stopPropagation()}
		>
			{!disableCloseButton && <CloseButton />}
			{title && (
				<div className={styles.title}>
					<h1>{title}</h1>
					{entity && <h2>{entity}</h2>}
				</div>
			)}
			{loading ? <Spinner centered /> : <></>}
			<div className={`${loading ? styles.hide : ""}`}>{children}</div>
			{buttonsOverride ? (
				buttonsOverride
			) : onConfirm ? (
				<>
					<div className={styles.buttonsContainer}>
						<div className={styles.buttons}>
							{loadingConfirm ? <Spinner height={20} thickness={10} /> : <></>}
							<Button
								type={onConfirmType}
								onClick={onConfirm}
								disabled={loadingConfirm || onConfirmDisabled}
							>
								{onConfirmText}
							</Button>
							{!disableCancelButton && (
								<Button type={onCancelType} onClick={onCancel}>
									{onCancelText}
								</Button>
							)}
						</div>
					</div>
				</>
			) : (
				<></>
			)}
		</div>
	)
}

export default ModalContainer

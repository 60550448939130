import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { df_input_name } from "../../_drawerFragments/inputs"
import {
	df_link_customerEvents,
	df_link_deleteCustomer,
	df_link_history,
	df_link_identitiesOverview,
	df_link_keyEditor,
} from "../../_drawerFragments/links"

export const default_drawerContent_customers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name(),
			{
				updater: "property",
				key: "properties.geoLocationName",
				header: "headers.properties.geoLocationName",
				permission: "edit",
				type: "text",
			},
		],
	},
	map: {
		comp: "map",
		order: 1,
		config: {
			backupPointsDataPath: "backupPoints",
			pointsDataPath: "points",
		},
	},
	linkSection: {
		comp: "links",
		order: 4,
		buttons: [
			df_link_customerEvents,
			df_link_identitiesOverview,
			df_link_history,
			df_link_keyEditor,
			df_link_deleteCustomer,
		],
	},
	pointEditorDrawer: {
		comp: "pointEditorDrawer",
		order: 4,
		config: {
			permissions: ["edit", "read"],
			title: "stations",
		},
	},
	identityList: {
		comp: "identities",
		order: 5,
		enable: true,
	},
}

import { SVGProps } from 'react'

export const TrevirkeIcon = ({
  fill = 'black',
  width = '305',
  height = '246',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 305 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M168.9 6.79999L176.4 9.69998L190.5 5L204.6 0.299988L216.1 7.10001C222.4 10.9 228 14 228.6 14C229.2 14 240.2 10.8 253 7C276.3 0 276.3 0 288.4 0H300.5L224.5 76L148.5 152H74.5H0.5L76.4 76.1L152.3 0.199982L156.9 2C159.4 3 164.8 5.09999 168.9 6.79999ZM301.7 38.1L299.3 47.5L301.7 54.5L304.1 61.5L299.4 71.2L294.6 80.9L299.4 88.1L304.2 95.3L238.4 161.1C202.1 197.4 172.2 227 171.8 227C171.3 227 171 210.9 171 191.3V155.5L237.3 89.2C311.4 15.1 306.6 18.9 301.7 38.1ZM87 179.1C100 196 108.3 217.1 109.7 236.7L110.3 246H98.2H86V242.1C86 236.1 83.5 224.3 80.5 216.5C74.6 200.6 62.5 185.5 48.1 175.9C43.9 173.1 40.3 170.6 40.1 170.4C39.9 170.2 48.7 170 59.8 170H79.9L87 179.1ZM153 208V246H141.1H129.2L128.5 239.2C127.5 227.3 125 214.7 121.9 205.5C118.7 196.3 110.7 180 106.4 174.3C105.1 172.6 104 170.9 104 170.6C104 170.3 115 170 128.5 170H153V208ZM15.5 181.5C24 183.3 36.7 189.4 43.5 195C57 206.3 65.3 221.9 67.5 240.2L68.2 246H56.1C49.5 246 44 245.7 44 245.2C43.9 242 40.7 230.4 39 227C36.1 221.4 28.7 213.5 23.2 210.3C18.1 207.3 8 204 3.9 204H1V192V180H4.8C6.8 180 11.7 180.7 15.5 181.5ZM12 225C18.9 228.6 25 237.2 25 243.5V246H13H1V234V221.9L4.8 222.6C6.8 223 10.1 224.1 12 225Z"
        fill={fill}
      />
    </svg>
  )
}

// import { IFractions } from "./types"

export const fractions = {
	"-8": "Farget, myk plast",
	"-5": "Reparerbare tekstiler",
	"-4": "Ødelagte klesplagg",
	"-3": "Sko",
	"-2": "Sparepærer",
	"-1": "Lysstoffrør",
	"1": "Hår",
	"2": "Kaffegrut",
	"10": "Matavfall - Produksjonssvinn",
	"12": "Matavfall - Datosvinn",
	"13": "Kleshengere",
	"0000": "Flere", // different types
	"0001": "Punkt",
	"1111": "Matavfall",
	"1128": "Frityr",
	"1131": "Hageavfall",
	"1141": "Rent trevirke",
	"1149": "Blandet bearbeidet trevirke",
	"1200": "Papir, papp og kartong",
	"1211": "Avis og magasinpapir",
	"1221": "Brunt papir og bølgepapp",
	"1231": "Emballasjekartong",
	"1251": "Kontorpapir",
	"1261": "Papir til makulering",
	"1299": "Papir og papp",
	"1300": "Glass",
	"1312": "Glassemballasje",
	"1322": "Blandet glass- og metallemballasje",
	"1447": "Jern og metall",
	"1452": "Blandet metall",
	"1599": "Blandet EE-avfall",
	"1617": "Mineralull og isolasjon",
	"1618": "Keramikk og porselen",
	"1700": "Plast",
	"1711": "Folieplast",
	"1712": "Folieplast, annen",
	"1722": "Hardplast, annen",
	"1729": "Blandet myk og hard plastemballasje",
	"1731": "Ekspandert og ekstrudert plast, emballasje",
	"1799": "Blandet plastikk",
	"1911": "Tekstiler",
	"6003": "Smittefarlig avfall",
	"7011": "Spillolje",
	"7042": "Organiske løsemidler",
	"7051": "Maling, lim og lakk",
	"7055": "Spraybokser",
	"7086": "Lysstoffrør og sparepærer",
	"7092": "Bilbatterier",
	"7093": "Småbatterier",
	"7094": "Litiumbatterier",
	"7098": "Impregnert trevirke",
	"7133": "Rengjøringsmidler",
	"7220": "Fotokjemikalier",
	"7261": "Gasser i trykkbeholdere",
	"7263": "Gasser i trykkbeholdere",
	"9010": "Usortert avfall",
	"9911": "Blandet husholdningsavfall",
	"9912": "Blandet avfall til sortering",
	"9913": "Utsortert brennbart restavfall",
	"9916": "Kostemasse",
	"9999": "Restavfall",
	"129917": "Papir og plast",
	"7086021": "Lyspærer",
	"7051021": "Tonerkasetter",
	"99111111": "Blandet husholdningsavfall og matavfall",
	soap: "Såpe",
	"Shower Soap": "Dusjsåpe",
	"Hand Dishwash detergent": "Håndoppvaskmiddel",
	"Cleaning Spray": "Kjøkkenspray",
	Handsoap: "Håndsåpe",
	"Laundry detergent": "Tøyvaskemiddel",
	NOT_INITIALIZED: "Ukjent",
	null: "Ukjent",
}

/**
 * Both property and externalKey translations
 */
export const properties = {
	fraction: "Fraksjon",
	fractionDesc: "Fraksjonsbeskrivelse",
	customerType: "Kundetype",
	hardwareReference: "Maskinvare refereranse",
	Municipality: "Kommune",
	GeoLocationAccuracy: "Lokasjonsnøyaktighet",
	hatchTypeCode: "Lukekode",
	productName: "Produktnavn",
	productBrand: "Merke",
	filledVolume: "Volum",
	filleVolumeUnit: "Volumenhet",
	ProductionBatch: "Versjon",
	taxNumber: "Org. nummer",
	geoLocationName: "Kundeadresse",
	contact: "Kontaktinformasjon",
	CRMID: "CRMID",
	CustomerID: "Kunde ID",
	owner: "Stasjonseier",
	municipality: "Kommunenummer",
	stationNumber: "Anleggsnummer",
	volume: "Volum",
	binType: "Type",
	binCount: "Antall",
	UID: "Unik ID",
	containerId: "Beholder ID",
	StationID: "Stasjons ID",
	vendor: "Leverandør",
	client: "Oppdragsgiver",
	customerCategory: "Kategori",
	contactPerson: "Kontaktperson",
	contactEmail: "E-post",
	contactMobile: "Telefonnummer",
	totalCustomerArea: "Areal",
	orgForm: "Organisasjonsform",
	companyCode: "Næringskode",
	companyDescription: "Næringsbeskrivelse",
	postal: "Poststed",
	postalCode: "Postnummer",
	postAddress: "Fakturaadresse",
	postPostal: "Faktura poststed",
	postPostalCode: "Faktura postnummer",
	invoiceEmail: "Faktura e-post",
	assignedCustomers: "Kunde tilknyttet beholder",
	gps: "GPS-koordinater",
	operationsContactEmail: "Senter drift e-post",
}

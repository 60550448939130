import { gql } from "apollo-boost"

export const default_fetchAll_events = ({
	pointProperties,
	pointExternalKeys,
}: Partial<{
	pointProperties: boolean
	pointExternalKeys: boolean
}>) => gql`
	query ACDefaultGetEvents($start: DateTime!, $end: DateTime!) {
		store {
			events(startTimeIso: $start, endTimeIso: $end, eventResultFilter: "") {
				id
				type
				result
				identityId
				timestamp
				properties {
					key
					value
				}
				customer {
					id
					customer {
						id
						name
					}
				}
				point {
					id
					point {
						id
						type
						name
						${
							pointProperties
								? `properties {
							key
							value
						}`
								: ""
						}
						${
							pointExternalKeys
								? `externalKeys {
							key
							value
						}`
								: ""
						}
						parent: ancestor(type: "ACCESS_PARENT") {
							id
							name
						}
					}
				}
			}
		}
	}
`

import { SVGProps } from 'react'

export const GassbeholdereIcon = ({
  fill = 'black',
  width = '232',
  height = '248',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 232 248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M141.8 20.2L142 39H151H160V50C160 59.1 160.3 61 161.5 61C164.3 61 182.5 67.2 188.5 70.3C207.5 79.8 220.9 94 227.5 111.5L230.5 119.5L230.8 159.7L231.1 200H207.6H184V209V218H207.5H231V233V248L131.8 247.8L32.5 247.5V233V218.5L55.8 218.2L79 218V209V200H55.4H31.9L32.1 159.4L32.3 118.8L16.1 102.6L0 86.5L25.8 60.7L51.5 35L67.2 50.6L82.8 66.3L92.9 63.4L103 60.5V49.7V39H112.5H122V20V0.899994L131.8 1.19998L141.5 1.5L141.8 20.2Z"
        fill={fill}
      />
    </svg>
  )
}

import { ApolloQueryResult, DocumentNode, FetchResult, QueryOptions } from "@apollo/client"
import { isEmpty } from "lodash"
import { localeFromLocalStorage } from "../Contexts/Translation"
import { useApolloStore } from "../States/apollo"
import { useGlobalAlert } from "../States/globalAlert"
import { useSiteConfig } from "../States/siteConfig"

export function fetchFunc<Return, EndResult>(
	query: DocumentNode,
	func: (arg: ApolloQueryResult<Return>) => EndResult,
	params?: Omit<QueryOptions, "query">
) {
	const { client } = useApolloStore.getState()

	if (client) {
		return handleQueryErrorWithGlobalAlert<Return>(
			client.query<Return>({
				query: query,
				...params,
			})
		).then(func)
	} else {
		throw new Error("Problem connecting to api client")
	}
}

function handleQueryErrorWithGlobalAlert<Return>(theReturn: Promise<ApolloQueryResult<Return>>) {
	const { siteConfig } = useSiteConfig.getState()
	const { setGlobalAlert } = useGlobalAlert.getState()

	return theReturn.then(data => {
		const statusMsg: string = getQueryErrorFromApollo(data)
		if (statusMsg?.length) {
			setGlobalAlert({
				type: "danger",
				message: siteConfig.translations[localeFromLocalStorage].errors.failed + ". " + statusMsg,
			})
			console.error(statusMsg)
		}
		return data
	})
}

function getQueryErrorFromApollo<Return>(mutationReturn: ApolloQueryResult<Return>) {
	const errorMsg = mutationReturn.errors?.reduce(
		(prev, curr) => (curr.message ? prev + curr.message + ". " : ""),
		""
	)

	if (errorMsg?.length) {
		console.error(errorMsg)
	}
	return errorMsg || ""
}

export function postFunc<Return>(
	query: DocumentNode,
	func: (value: FetchResult<Return, Record<string, any>, Record<string, any>>) => void,
	params?: {
		variables?: {
			[key: string]: any
		}
		refetchQueries?: {
			query: DocumentNode
			variables?: {
				[key: string]: any
			}
		}[]
		fetchPolicy?: "no-cache"
	},
	errorFunc?: (err: any) => void
) {
	const { client } = useApolloStore.getState()

	if (client) {
		return handleMutationErrorWithGlobalAlert<Return>(
			client.mutate<Return>({
				mutation: query,
				...params,
				errorPolicy: "all",
			}),
			func,
			errorFunc
		)
	} else {
		throw new Error("Problem connecting to api client")
	}
}

export function handleMutationErrorWithGlobalAlert<Return>(
	mutationReturn: Promise<FetchResult<Return, Record<string, any>, Record<string, any>>>,
	func?: (value: FetchResult<Return, Record<string, any>, Record<string, any>>) => void,
	errorFunc?: (err: any) => void
) {
	const { siteConfig } = useSiteConfig.getState()
	const { setGlobalAlert } = useGlobalAlert.getState()

	return mutationReturn
		.then(data => {
			const statusMsg: string = getMutationErrorFromApollo(data)
			if (statusMsg?.length) {
				if (errorFunc) {
					errorFunc(
						siteConfig.translations[localeFromLocalStorage].errors.failed + ". " + statusMsg
					)
				}
				setGlobalAlert({
					type: "danger",
					message: siteConfig.translations[localeFromLocalStorage].errors.failed + ". " + statusMsg,
				})
				console.error(statusMsg)

				return undefined
			}
			return func ? func(data) : undefined
		})
		.catch(err => {
			if (errorFunc) {
				errorFunc(err)
			}
			setGlobalAlert({
				type: "danger",
				message: siteConfig.translations[localeFromLocalStorage].errors.failed + ". " + err.message,
			})
			console.error(err)
		})
}

function getMutationErrorFromApollo<Return>(
	mutationReturn: FetchResult<Return, Record<string, any>, Record<string, any>>
) {
	const errorMsg = mutationReturn.errors?.reduce(
		(prev, curr) => (curr.message ? prev + curr.message + ". " : ""),
		""
	)

	if (errorMsg?.length) {
		console.error(errorMsg)
		return errorMsg
	}

	const apiErrMsg =
		typeof mutationReturn.data === "object" && !isEmpty(mutationReturn.data)
			? Object.values(mutationReturn.data as unknown as object)?.reduce(
					(prev, curr) => prev + (curr.commandProcessError ? curr.commandProcessError + ". " : ""),
					""
			  )
			: ""

	if (apiErrMsg?.length) {
		console.error(apiErrMsg)
	}

	return apiErrMsg
}

export const globalAlertMutationSaved = () =>
	useGlobalAlert.getState().setGlobalAlert({
		type: "success",
		message:
			useSiteConfig.getState().siteConfig.translations[localeFromLocalStorage]["success"]["saved"],
	})

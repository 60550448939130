import { get, last, uniqBy } from "lodash"
import {
	BUSINESS_PARENT_FILTER,
	PRIMARY_ADDRESS_QUERY,
	PRIMARY_POINT_LIST_FILTER,
} from "../../../Resources/Customers/data"
import { IFilterSpecBase, IRow } from "../../../Resources/types"

export const genericDateTimeFilter =
	(lookupKey: string, translateName?: string) =>
	(data: IRow[]): IFilterSpecBase => ({
		id: lookupKey,
		control: {
			component: "DateTimeSelect",
		},
		args: {
			lookupKey: lookupKey,
		},
	})

/** lookupKey example: properties.geoLocation */
export const filterFuzzy =
	(lookupKey: string, translateName?: string) =>
	(data: IRow[]): IFilterSpecBase => ({
		id: last(lookupKey.split(".")) || lookupKey,
		control: {
			component: "SearchInput",
			placeholder: translateName ? translateName : `headers.${lookupKey}`,
		},
		filter: {
			type: "fuzzy",
			lookupKey,
		},
	})

export const filterEQ =
	({
		lookupKey,
		translateName,
		id,
		transOptionsKey,
	}: {
		lookupKey: string
		translateName?: string
		id?: string
		transOptionsKey?: string
	}) =>
	(data: IRow[]): IFilterSpecBase => ({
		id: id || last(lookupKey.split(".")) || lookupKey,
		control: {
			component: "SearchSelect",
			placeholder: translateName ? translateName : `headers.${lookupKey}`,
			options: {
				type: "simple",
				data,
				lookupKey,
				labelTransField: transOptionsKey,
				label: lookupKey,
			},
		},
		filter: {
			type: "eq",
			lookupKey,
		},
	})

export const idFuzzy = (data: IRow[]): IFilterSpecBase => ({
	id: "id",
	control: {
		component: "SearchInput",
		placeholder: "headers.id",
	},
	filter: {
		type: "fuzzy",
		lookupKey: "id",
	},
})

export const nameEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "name",
	control: {
		component: "SearchSelect",
		placeholder: "headers.name",
		options: {
			type: "simple",
			data,
			lookupKey: "name",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "name",
	},
})

export const fractionEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "fraction",
	control: {
		component: "SearchSelect",
		placeholder: "headers.properties.fraction",
		options: {
			type: "simple",
			data,
			lookupKey: "properties.fraction",
			labelTransField: "fraction",
			label: "properties.fraction",
			showOriginalValue: true,
		},
	},
	filter: {
		type: "eq",
		lookupKey: "properties.fraction",
	},
})

export const fractionByHierarchyEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "fraction",
	control: {
		component: "SearchSelect",
		placeholder: "headers.properties.fraction",
		options: {
			type: "labelValue",
			data: uniqBy(
				data.flatMap(p =>
					p.childrenFractions.map((f: any) => ({ label: f.fractionName, value: f.fraction }))
				),
				f => f.value
			),
		},
	},
	filter: {
		type: "fractionsByPointHierachy",
		lookupKey: "properties.fraction",
	},
})

// NOT DONE
export const IHMStationFractionEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "fraction",
	control: {
		component: "SearchSelect",
		placeholder: "headers.properties.fraction",
		options: {
			type: "labelValue",
			data: uniqBy(
				data.flatMap(p =>
					p.accessPoints.map((ap: any) => ({
						label: ap.properties.fraction && console.log(ap.properties.fraction),
						value: ap.properties.fraction,
					}))
				),
				f => f.value
			),
		},
	},
	filter: {
		type: "fractionsByPointHierachy",
		lookupKey: "properties.fraction",
	},
})

export const parentEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "parent",
	control: {
		component: "SearchSelect",
		placeholder: "headers.parent.name",
		options: {
			type: "simple",
			data,
			lookupKey: "parent.name",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "parent.name",
	},
})

export const terminalEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "terminal",
	control: {
		component: "SearchSelect",
		placeholder: "headers.terminal.name",
		options: {
			type: "simple",
			data,
			lookupKey: "terminal.name",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "terminal.name",
	},
})

export const containerIdFuzzy = (data: IRow[]): IFilterSpecBase => ({
	id: "containerId",
	control: {
		component: "SearchInput",
		placeholder: "headers.containerId",
	},
	filter: {
		type: "fuzzy",
		lookupKey: "externalKeys.containerId",
	},
})

export const customerNameEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "customerName",
	control: {
		component: "SearchSelect",
		placeholder: "customerName",
		options: {
			type: "simple",
			data,
			lookupKey: "customerName",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "customerName",
	},
})

export const containerTypeEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "type",
	control: {
		component: "SearchSelect",
		placeholder: "properties.binType",
		options: {
			type: "simple",
			data,
			label: "properties.containerType",
			labelTransField: "containerType",
			lookupKey: "properties.containerType",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "properties.containerType",
	},
})

export const volumeEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "volume",
	control: {
		component: "SearchSelect",
		placeholder: "headers.volume",
		options: {
			type: "simple",
			data,
			lookupKey: "properties.volume",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "properties.volume",
	},
})

export const addressFuzzy = (): IFilterSpecBase => ({
	id: "address",
	control: {
		component: "SearchInput",
		placeholder: "headers.properties.address",
	},
	filter: {
		type: "fuzzy",
		lookupKey: "properties.geoLocationName",
	},
})

export const operatorEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "operator",
	control: {
		component: "SearchSelect",
		placeholder: "operator",
		options: {
			type: "simple",
			data: data,
			lookupKey: "operator",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "operator",
	},
})

export const externalTagEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "externalTag",
	control: {
		component: "SearchSelect",
		placeholder: "headers.externalTag",
		options: {
			type: "simple",
			data: data,
			lookupKey: "externalTag",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "externalTag",
	},
})

export const externalServiceGuidFuzzy = (data: IRow[]): IFilterSpecBase => ({
	id: "externalServiceGuid",
	control: {
		component: "SearchInput",
		placeholder: "headers.externalServiceGuid",
	},
	filter: {
		type: "fuzzy",
		lookupKey: "externalServiceGuid",
	},
})

export const fillLevel = (data: IRow[]): IFilterSpecBase => ({
	id: "fillLevel",
	control: {
		component: "SearchInput",
		placeholder: "headers.fillLevelDesc",
	},
	filter: {
		type: "fillLevelEqOrLargerThan",
		lookupKey: "fillLevel",
	},
})

export const stationEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "station",
	control: {
		component: "SearchSelect",
		placeholder: "headers.station",
		options: {
			type: "query",
			query: PRIMARY_POINT_LIST_FILTER,
			errorPolicy: "all",
			transform: (data: any) =>
				data?.store?.accessPoints?.map((v: any) => ({
					label: v.name,
					value: v.id,
				})),
		},
	},
	filter: {
		type: "query",
		query: PRIMARY_ADDRESS_QUERY,
		variable: "pointId",
		transform: (data: any) => get(data, "store.accessPoint.customers", []),
		lookupKey: "id",
	},
})

export const businessParentEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "parent",
	control: {
		component: "SearchSelect",
		placeholder: "parent",
		options: {
			type: "query",
			query: BUSINESS_PARENT_FILTER,
			errorPolicy: "all",
			transform: (data: any) =>
				data?.store?.customers?.map((v: any) => ({
					label: v.name,
					value: v.id,
				})),
		},
	},
	filter: {
		type: "eq",
		lookupKey: "ancestor.id",
	},
})

export const PAAvtaleFuzzy = (data: IRow[]): IFilterSpecBase => ({
	id: "crmId",
	control: {
		component: "SearchInput",
		placeholder: "headers.properties.crmId",
	},
	filter: {
		type: "fuzzy",
		lookupKey: "externalKeys.PAAvtaleID",
	},
})

export const installationEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "inst",
	control: {
		component: "SearchSelect",
		placeholder: "headers.installation.name",
		options: {
			type: "simple",
			data,
			lookupKey: "installation.name",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "installation.name",
	},
})

export const printedTagFuzzy = (data: IRow[]): IFilterSpecBase => ({
	id: "Tag",
	control: {
		component: "SearchInput",
		placeholder: "headers.printedTag",
	},
	filter: {
		type: "fuzzy",
		lookupKey: "externalKeys.printedTag",
	},
})

export const statusEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "status",
	control: {
		component: "SearchSelect",
		placeholder: "headers.status",
		options: {
			type: "simple",
			data,
			lookupKey: "status",
			labelTransField: "status",
			label: "status",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "status",
	},
})

export const stationTypeEQ = (data: IRow[]): IFilterSpecBase => ({
	id: "stationType",
	control: {
		component: "SearchSelect",
		placeholder: "headers.stationType",
		options: {
			type: "simple",
			data,
			lookupKey: "properties.stationType",
			labelTransField: "stationType",
			label: "properties.stationType",
		},
	},
	filter: {
		type: "eq",
		lookupKey: "properties.stationType",
	},
})

/* TEMPLATE
export const filter = (data: IRow[]): IFilterSpecBase => ({

})
*/

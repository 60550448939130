import React from 'react'
import './Inputs.css'

export const Radio = ({ name, children, style, value, checked, onChange }: any) => {
  return (
    <div className="checkbox radio">
      <input
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        type="radio"
      />
      <label style={style}>
        <span>{children}</span>
      </label>
    </div >
  )
}


export const Checkbox = ({ name, children, style, value, checked, onChange }: any) => {
  return (
    <div className="checkbox">
      <input
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        type="checkbox"
      />
      <label style={style}>
        <span>{children}</span>
      </label>
    </div >
  )
}

import { gql } from "apollo-boost"
import { IKeyValue } from "../../../../Utils"

//startTimeIso: "2022-04-11T00:07:00.984Z"
//endTimeIso: "2022-05-06T20:16:05.853Z"

export const default_fetchAll_invoices = gql`
	query getEventsOfTypePayment($start: DateTime!, $end: DateTime!) {
		store {
			events(
				startTimeIso: $start
				endTimeIso: $end
				types: ["PAYMENT"]
				excludeByProperties: { key: "paymentType", value: "zeroPayment" }
			) {
				id
				type
				result
				timestampIso
				timestamp
				paymentType: property(key: "paymentType")
				paymentAmountIncludingVAT: property(key: "paymentAmount")
				paymentAmountExcludingVAT: property(key: "paymentAmountExcludingVAT")
				totalBillableWeight: property(key: "totalBillableWeight")
				paymentRef: property(key: "paymentRef")
				visitId: property(key: "visitId")
				initiatedByCustomerRefName: property(key: "initiatedByCustomerRefName")
				orderId: property(key: "orderId")
				customer {
					customer {
						name
						customerClass: property(key: "customerClass")
						postalCode: property(key: "postalCode")
						orgNr: property(key: "orgNumber")
					}
				}
			}
		}
	}
`
export interface IDefault_fetchAll_invoices {
	store: {
		events: {
			id: string
			name: string
			type: string
			point: {
				point: {
					properties: IKeyValue[] | null
				}
			}
			customer: {
				customer: {
					name: string
					id: string
					properties: IKeyValue[] | null
				}
			}
			timestampIso: Date
			properties: IKeyValue[] | null
			externalKeys: IKeyValue[] | null
		}[]
	}
}

import { getContainerTypes } from "./all"

export const ihm_containertypes = () =>
	getContainerTypes([
		"bin140",
		"bin240",
		"bin360",
		"bin660",
		"bin770",
		"bin1000",
		"liftcontainer4500",
		"liftcontainer6000",
		"liftcontainer8000",
		"liftcontainer10000",
		"undergroundContainer08m3",
		"undergroundContainer3m3",
		"undergroundContainer5m3",
		// "container6m3",
		// "container8m3",
		"ball",
		"bag",
	])

import React from "react"
import { useHistory } from "react-router-dom"

import { useTranslation, useModal } from "../../Contexts"

import DataGrid from "../_components/DataGrid/DataGrid"
import exportCSV from "../exportCSV"

import { EditAccessPoints } from "../Points/Modals"

import { useAddPointsToCustomers } from "./data"
import { IListViewConfig } from "../types"

function csvOptions(headers: IListViewConfig["headers"], trans: any) {
	const filename = `${trans("customers")}.csv`
	const fields = headers.map(h => ({
		label: trans(h.name ? h.name : `headers.${h.path}`),
		value: h.path,
	}))

	return { filename, fields }
}

const Grid = (props: any) => {
	const { customers, headers, onCellClick, columnSizes = {} } = props
	const history = useHistory()
	const { trans } = useTranslation()
	const { showModal, hideModal } = useModal()
	const addPointsToCustomers = useAddPointsToCustomers()

	const askEditPoints = (customers: any[]) => {
		const onConfirm = ({ newPoints, removePoints }: any) => {
			addPointsToCustomers(customers, newPoints, removePoints)
			//.then(result => console.log('result', result)) TODO: error handling
			hideModal()
		}

		showModal(<EditAccessPoints onConfirm={onConfirm} onCancel={hideModal} />)
	}

	const actions = [
		{
			label: "actions.exportCsv",
			func: exportCSV(csvOptions(headers, trans)),
		},
		{
			label: "actions.editCustomerPoints",
			func: askEditPoints,
		},
	]

	return (
		<DataGrid
			headers={headers}
			columnSizes={columnSizes}
			entityType={"customers"}
			rows={customers}
			actions={actions}
			onRowClick={(customer: any) =>
				history.replace({
					pathname: `/resources/customers/${customer.id}`,
					search: history.location.search,
				})
			}
			onCellClick={onCellClick ? onCellClick({ history }) : undefined}
			allowEmptyField
		/>
	)
}

export default Grid

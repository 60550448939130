import { gql } from "apollo-boost"

export const default_fetchById_recyclingStationVisits = gql`
	query get_session_stats_by_id($visitId: String, $start: DateTime!, $end: DateTime!) {
		store {
			events(
				startTimeIso: $start
				endTimeIso: $end
				requiredProperties: [{ key: "visitId", value: $visitId }]
			) {
				type
				timestampIso
				paymentAmountIncludingVAT: property(key: "paymentAmount")
				paymentAmountExcludingVAT: property(key: "paymentAmountExcludingVAT")
				totalBillableWeight: property(key: "totalBillableWeight")
				paymentType: property(key: "paymentType")
				paymentRef: property(key: "paymentRef")
				initiatedByCustomerRefName: property(key: "initiatedByCustomerRefName")
				weight: property(key: "weight")
				deltaWeight: property(key: "deltaWeight")
				visitId: property(key: "visitId")
				point {
					point {
						sessionId: externalKey(key: "ImportID")
					}
				}
			}
		}
	}
`

import { gql } from "apollo-boost"
import { CUSTOMER_TYPE } from "../../../Utils/gqlRequestTypes/generic"

interface KeyVal {
	key: string
	value?: string
}

interface NewPoint {
	id: string
	priority?: number
}

interface CUSTOMER_PAYLOAD {
	name: string
	allocationScheme?: "NONE" | string
	properties?: KeyVal[]
	externalKeys?: KeyVal[]
	newPoints?: NewPoint[]
	operator?: { id: string }
}

export interface CUSTOMER_MUTATION {
	payload: CUSTOMER_PAYLOAD
	type: CUSTOMER_TYPE
}

// JSON stringify puts keys in quotes, where GQL requires keys are quotes
const objToString = (obj: any) => {
	// eslint-disable-next-line no-useless-escape
	return JSON.stringify(obj)
		.replace(/"([^"]+)":/g, "$1:")
		.replace(/\uFFFF/g, '\\"')
}

const CUSTOMERmutationGen = (mut: CUSTOMER_MUTATION): string => `
    addCustomerMutation (input: {
        wait: true
        payload: {
            customerType: ${mut.type}
            ${objToString(mut.payload).replace(/^{(.*)}$/g, "$1")}
        }
    }) {
        commandProcessError
        primaryKey
    }
`

export const NewCUSTOMERmutationGen = (mut: CUSTOMER_MUTATION) => {
	// console.log(CUSTOMERmutationGen(mut))
	return gql`mutation {
        ${CUSTOMERmutationGen(mut)}
    }`
}

import moment from "moment"
import { useEffect, useState } from "react"
import { useAuth0, useTranslation } from "../../../Contexts"
import Error from "../../../Resources/_components/Error/Error"
import { Spinner } from "../../../UI"
import { fetchFromDWH, IDwhResponse } from "../../Queries/dwhApi"
import { FractionSortingListTile } from "../../Tiles/FractionSortingListTile"
import { ListTile } from "../../Tiles/ListTile"

export const LfmEnterpriseTenantOverview = () => {
	const [unparsedData, setUnparsedData] = useState<IDwhResponse["data"]["tenantoverview"] | null>(
		null
	)
	const [error, setError] = useState(false)

	const { token } = useAuth0()!
	const { trans } = useTranslation()

	useEffect(() => {
		fetchFromDWH({
			token,
			endpoint: "tenantOverview",
			search: { startTimeISO: moment().add(-30, "d").toISOString() },
		})
			.then(({ data, error: err }) => {
				if (err) {
					console.error(err)
					setError(true)
				} else {
					setUnparsedData(data.tenantoverview)
				}
			})
			.catch(err => {
				console.error(err)
				setError(true)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!unparsedData && !error) return <Spinner />
	if (error) return <Error>{trans("errors.failedFetch")}</Error>

	const data =
		unparsedData?.map(row => {
			return [
				row.customer_name || "",
				(row.sorting_degree || 0) * 100,
				row.distinct_waste_types,
				row.transactions,
				row.total_waste || 0,
			]
		}) || []

	return (
		<div className="dashboard-grid">
			<ListTile
				tileProps={{
					cols: 4,
					rows: 4,
					title: trans("overviewOverTenants"),
					label: trans("periods.30days"),
				}}
				headers={[
					trans("tenant"),
					trans("sortingDegree"),
					trans("totalFractions"),
					trans("registeredDisposals"),
					trans("totalWeight") + " (kg)",
				]}
				defaultSortHeader={trans("sortingDegree")}
				defaultSorting="des"
				data={data}
				formatThousands
				suffix={{ 1: "%" }}
			/>
			<FractionSortingListTile
				tileProps={{ cols: 4, rows: 5, title: trans("tenantDetails") }}
				searchPlaceholder={trans("searchTenants")}
				data={
					unparsedData?.map(row => {
						return { customerID: row.customerid, customerName: row.customer_name || "" }
					}) || []
				}
				formatThousands
			/>
		</div>
	)
}

import { FC } from "react"
import { Link, useRouteMatch } from "react-router-dom"

export const DashboardNavigation: FC<{
	keyName: string
	tabs: {
		key: string
		name: string
	}[]
}> = ({ keyName, tabs }) => {
	const match = useRouteMatch<{ view: string }>({ path: "/dashboard/:view" })

	return (
		<div className="listmenu dashboard-nav">
			<ul>
				{tabs.map((tab, i) => {
					return (
						<li key={`dashboard-tab-${i}`}>
							<Link
								to={{ pathname: `/dashboard/${tab.key}` }}
								className={match?.params.view === tab.key ? "active" : ""}
							>
								{tab.name}
							</Link>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { df_input_name } from "../../_drawerFragments/inputs"

export const iris_drawerContent_customers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name(),
			{
				updater: "property",
				key: "properties.geoLocationName",
				header: "headers.properties.geoLocationName",
				permission: "read",
				type: "text",
			},
			{
				updater: "externalKey",
				key: "externalKeys.unitCode",
				header: "headers.unitCode",
				permission: "read",
				type: "text",
			},
		],
	},
}

import { DocumentNode } from "apollo-link"
import { postFunc } from "../Utils/api"
import { generateGQLrequest } from "../Utils/gqlRequestGenerator"
import { UpsertAccessPointEntryPayload } from "../Utils/gqlRequestTypes/upsertAccessPointMutation"

export const upsertAccessPoints = ({
	// parentId,
	accessPoints,
	refetchQueries,
	onStartCallBack,
	onFailCallBack,
}: {
	// parentId: string
	accessPoints: UpsertAccessPointEntryPayload[]
	refetchQueries?: {
		query: DocumentNode
		variables?: {
			[key: string]: any
		}
	}[]
	onStartCallBack?: () => void
	onFailCallBack?: () => void
}) => {
	const CREATE_ACCESS_POINTS = generateGQLrequest({
		type: "upsertAccessPointsMutation",
		input: {
			wait: true,
			payload: {
				accessPoints,
			},
		},
	})
	// console.log(CREATE_ACCESS_POINTS)
	postFunc(
		CREATE_ACCESS_POINTS,
		() => {
			onStartCallBack && onStartCallBack()
		},
		{ refetchQueries: refetchQueries || [] },
		() => {
			onFailCallBack && onFailCallBack()
		}
	)
}

import { DocumentNode } from "apollo-link"
import { useModal, useTranslation } from "../../../../Contexts"
import { localeFromLocalStorage } from "../../../../Contexts/Translation"
import { IdentityStatus } from "../../../../Modals/IdentitiesHandlerModal/functions/data"
import { IdentitiesHandler } from "../../../../Modals/IdentitiesHandlerModal/IdentitiesHandler"
import { IRow } from "../../../../Resources/types"
import { useSiteConfig } from "../../../../States/siteConfig"
import { Button } from "../../../../UI"
import { FlexBox } from "../../../Layouts/FlexBox/FlexBox"
import styles from "./Identities.module.css"

export const Identities = ({
	customerData,
	refetchQuery,
}: {
	customerData: IRow | undefined
	updateIdentityStatus: (status: IdentityStatus, identities: any[]) => void
	refetchQuery: DocumentNode
}) => {
	const { trans } = useTranslation()
	const siteConfig = useSiteConfig(state => state.siteConfig)
	const { showModal } = useModal()

	const identities: {
		[key: string]: string
		id: string
		status: string
	}[] =
		customerData?.identities?.map((identity: any) => ({
			id: identity.id,
			status: identity.status,
			...identity?.i?.keys?.reduce(
				(prev: any, { key, value }: any) => ({ ...prev, [key]: value }),
				{} as {
					[key: string]: string
					id: string
					status: string
				}
			),
		})) || []

	const onIdentitiesHandler = () => {
		customerData &&
			showModal(
				<IdentitiesHandler
					customerId={customerData.id}
					entity={customerData.name}
					// onClose={hideModal}
					refetchQuery={refetchQuery}
				/>
			)
	}

	const columns = siteConfig.identitiesModal.externalKeyList.slice(0, 2)

	return (
		<div className={styles["identities"]}>
			<h1>{trans("identities")}</h1>
			<div className={styles.tableIdentities}>
				<div style={{ gridTemplateColumns: columns.length === 2 ? "2fr 2fr 1fr" : undefined }}>
					{columns.map((c, i) => (
						<p key={`identity_col${i}`}>
							{siteConfig.translations[localeFromLocalStorage]?.headers[c]
								? trans("headers." + c)
								: c}
						</p>
					))}
					<p>{trans("headers.status")}</p>
				</div>
				<div>
					{identities.slice(0, 4).map((id, i) => {
						return (
							<div
								key={`identity_row${i}`}
								style={{ gridTemplateColumns: columns.length === 2 ? "2fr 2fr 1fr" : undefined }}
							>
								{columns.map((c, ci) => (
									<p key={`identity_cell${i}_${ci}`} title={id[c]}>
										{id[c]}
									</p>
								))}
								<p>{trans(`status.${id.status}`)}</p>
							</div>
						)
					})}
					{identities.length > 4 && (
						<p>{`(${identities.length - 4}) ` + trans("more").toLowerCase()}</p>
					)}
				</div>
			</div>
			<FlexBox justifyContent="flex-end" className={styles["buttonSpaceBefore"]}>
				<Button onClick={onIdentitiesHandler}>actions.edit</Button>
			</FlexBox>
		</div>
	)
}

import { SVGProps } from 'react'

export const BatterierIcon = ({
  fill = 'black',
  width = '153',
  height = '354',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 153 354"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M114.26 0.492002H38.6692V25.6893H114.26V0.492002Z" fill={fill} />
      <path
        d="M114.26 139.075H89.0626V164.272H63.8653V139.075H38.6693V113.877H63.8653V88.6813H89.0626V113.877H114.26V139.075ZM114.26 315.453H38.6693V290.256H114.26V315.453ZM0.873291 50.8867V353.248H152.055V50.8867H0.873291"
        fill={fill}
      />
    </svg>
  )
}

import { localeFromLocalStorage } from "../../../../Contexts/Translation"
import { useSiteConfig } from "../../../../States/siteConfig"
import { mergeValuesByLabel } from "../../../../Utils/selectOptions"
import { getEventsByCustomerId } from "../../api/eventsByCustomerIds"
import { getEventsByEventTypes } from "../../api/eventsByEventType"
import { fetchAndfilterEventsByFractions } from "../../api/eventsByFraction/prepare"
import { getEventsByIdentity } from "../../api/eventsByIdentities"
import { getEventsByParentIds, getEventsByPointIds } from "../../api/eventsByPoints"
import { fetchAndfilterEventsByProperties } from "../../api/eventsByProperties/prepare"
import {
	fetchAllCustomers,
	fetchAllPointIdsWithTransName,
	fetchAllPoints,
	fetchAllPointsContainerIds,
	fetchAllPointsParents,
	fetchAllPointsRfidISOs,
	fetchAllPointUniqueNames,
} from "../../api/filterOptions"
import {
	IFilterDateTimeSelect,
	IFilterSearchOnceInputFetch,
	IFilterSearchSelectFetch,
} from "../../components/EventsFilter/types"
import {
	filterEventsByCustomerId,
	filterEventsByEventTypes,
	filterEventsByFractions,
	filterEventsByIdentity,
	filterEventsByParentIds,
	filterEventsByPointIds,
	filterEventsByProperties,
} from "../../filters/filters"

export const dateTimeFilter = (): IFilterDateTimeSelect => ({
	id: "time",
	control: {
		component: "DateTimeSelect",
	},
})

export const customerNameFilter = (): IFilterSearchSelectFetch => {
	return {
		id: "customerName",
		control: {
			component: "SearchSelect",
			placeholder: "headers.customer.name",
			options: fetchAllCustomers,
			filter: {
				fetch: getEventsByCustomerId,
				func: filterEventsByCustomerId,
			},
		},
	}
}

export const identityIsoFilter = (): IFilterSearchOnceInputFetch => {
	return {
		id: "identityISO",
		control: {
			component: "SearchOnceInput",
			placeholder: "identityISO",
			filter: {
				fetch: getEventsByIdentity("ISO"),
				func: filterEventsByIdentity("ISO"),
			},
		},
	}
}

export const identityEmFilter = (): IFilterSearchOnceInputFetch => {
	return {
		id: "identityEM",
		control: {
			component: "SearchOnceInput",
			placeholder: "identityEM",
			filter: {
				fetch: getEventsByIdentity("EM"),
				func: filterEventsByIdentity("EM"),
			},
		},
	}
}

export const pointIdFilter = (
	control?: Partial<IFilterSearchSelectFetch["control"]>
): IFilterSearchSelectFetch => {
	return {
		id: "pointId",
		control: {
			component: "SearchSelect",
			placeholder: "headers.point.name",
			options: fetchAllPointIdsWithTransName,
			filter: {
				fetch: getEventsByPointIds,
				func: filterEventsByPointIds,
			},
			...control,
		},
	}
}

export const pointParentNameFilter = (): IFilterSearchSelectFetch => {
	return {
		id: "parent",
		control: {
			component: "SearchSelect",
			placeholder: "headers.point.parent.name",
			options: fetchAllPointsParents,
			filter: {
				fetch: getEventsByParentIds,
				func: filterEventsByParentIds,
			},
		},
	}
}

export const pointUniqueNameFilter = (): IFilterSearchSelectFetch => {
	return {
		id: "pointProductName",
		control: {
			component: "SearchSelect",
			placeholder: "headers.point.name",
			options: fetchAllPointUniqueNames,
			filter: {
				fetch: getEventsByPointIds,
				func: filterEventsByPointIds,
			},
		},
	}
}

export const pointNameFilter = (): IFilterSearchSelectFetch => {
	return {
		id: "pointName",
		control: {
			component: "SearchSelect",
			placeholder: "headers.point.name",
			options: fetchAllPoints,
			filter: {
				fetch: getEventsByPointIds,
				func: filterEventsByPointIds,
			},
		},
	}
}

export const eventTypeFilter = (): IFilterSearchSelectFetch => {
	return {
		id: "eventTypes",
		control: {
			component: "SearchSelect",
			placeholder: "headers.event.type",
			options: trans => {
				const { siteConfig } = useSiteConfig.getState() || {}
				return Promise.resolve(
					mergeValuesByLabel(
						Object.entries((siteConfig.translations[localeFromLocalStorage].type as object) || {})
							.filter(([k, v]) => !!k && !!v)
							.map(([k]) => ({
								label: trans(`type.${k}`),
								value: k,
							}))
					)
				)
			},
			filter: {
				fetch: getEventsByEventTypes,
				func: filterEventsByEventTypes,
			},
		},
	}
}

export const fractionFilter = (): IFilterSearchSelectFetch => {
	return {
		id: "fractions",
		control: {
			component: "SearchSelect",
			placeholder: "headers.properties.fraction",
			options: trans => {
				const { fractionsList } = useSiteConfig.getState().siteConfig || {}
				return Promise.resolve(
					fractionsList.map(f => ({
						label: trans(`fraction.${f}`),
						value: f,
					}))
				)
			},
			filter: {
				fetch: fetchAndfilterEventsByFractions,
				func: filterEventsByFractions,
			},
		},
	}
}

export const propertyFilter = (property: string): IFilterSearchOnceInputFetch => {
	return {
		id: `property-${property}`,
		control: {
			component: "SearchOnceInput",
			placeholder: `headers.properties.${property}`,
			filter: {
				fetch: fetchAndfilterEventsByProperties(property),
				func: filterEventsByProperties(property),
			},
		},
	}
}

export const containerIdFilter = (): IFilterSearchSelectFetch => {
	return {
		id: "containerId",
		control: {
			component: "SearchSelect",
			placeholder: "headers.containerId",
			options: fetchAllPointsContainerIds,
			filter: {
				fetch: getEventsByPointIds,
				func: filterEventsByPointIds,
			},
		},
	}
}

export const rfidIsoFilter = (): IFilterSearchSelectFetch => {
	return {
		id: "RFID",
		control: {
			component: "SearchSelect",
			placeholder: "headers.RFID",
			options: fetchAllPointsRfidISOs,
			filter: {
				fetch: getEventsByPointIds,
				func: filterEventsByPointIds,
			},
		},
	}
}

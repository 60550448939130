import { IEventDatesConfig } from "../../../Resources/Events/types"

export const lfmEnterprise_eventsDatesConfig: IEventDatesConfig = {
	filterEventsByTime: true, // Limiting event results or not
	maxUnfilteredDays: 21,
	maxUnfilteredHours: 0,
	maxFilteredDays: 0, // If set to 0 - unlimited
	calendarIntervals: [
		[1, "day"],
		[2, "days"],
		[3, "days"],
		[1, "week"],
		[2, "weeks"],
		[3, "weeks"],
	],
}

import { Route, Switch, useRouteMatch } from "react-router"
import { IKSDefaultOverview } from "./IKSDefaultOverview"

const IKSDefault = () => {
	const match = useRouteMatch()

	// const { trans } = useTranslation()

	return (
		<div className="dashboard">
			{/* <DashboardNavigation
				keyName="view"
				tabs={[
					{ key: "centers", name: trans("centerOverview") },
					{ key: "tenants", name: trans("tenantOverview") },
				]}
			/> */}
			<Switch>
				<Route path={`${match.path}`} exact>
					{/* <Redirect to={`${match.path}/centers`} /> */}
					<IKSDefaultOverview />
				</Route>
			</Switch>
		</div>
	)
}

export const IKSDefaultDashboard = <IKSDefault />

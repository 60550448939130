/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/client"
import { gql } from "apollo-boost"
import { get } from "lodash"
import React, { useEffect, useState } from "react"
import { useTranslation } from "../../Contexts"
import { SvgRemove } from "../../Icons/Remove"
import { useGeneralConfig } from "../../States/generalConfig"
import { useSiteConfig } from "../../States/siteConfig"
import { Button, InputSelect, Spinner } from "../../UI"
import { ICustomerConfigState, IReducerAction } from "./CustomerModalReducer"

const GET_ACCESS_PARENTS_AND_OPERATORS = gql`
	{
		store {
			accessPoints(type: "ACCESS_PARENT") {
				id
				type
				name
				fraction: property(key: "fraction")
				defaultTerminal: externalKey(key: "defaultTerminal")
			}
			operators {
				id
				name
				externalKeys {
					key
					value
				}
				properties {
					key
					value
				}
				description
			}
		}
	}
`

export const AddSingleCustomer = (props: {
	state: ICustomerConfigState
	dispatch: React.Dispatch<IReducerAction>
}) => {
	const { state, dispatch } = props
	const { siteConfig } = useSiteConfig()
	const { trans } = useTranslation()
	const config = siteConfig.customerModal

	const setProperty = (key: keyof ICustomerConfigState, value: string) => {
		dispatch({ type: "set", key: key, value: value })
	}

	const jwt = useGeneralConfig().authJwt

	const mandatory = {
		station:
			typeof config?.mandatory?.station === "function"
				? config.mandatory.station(jwt)
				: !!config?.mandatory?.station,
	}

	const { data, loading } = useQuery(GET_ACCESS_PARENTS_AND_OPERATORS)
	const APsAndOperators = get(data, "store", [])
	const accessParentsList =
		APsAndOperators?.accessPoints?.map((point: any) => {
			return { name: point.name, value: point.id }
		}) || []

	const operatorOptions = APsAndOperators?.operators?.map((op: any) => ({
		name: op.name,
		value: op.id,
	}))

	const customerTypeOptions = config?.customerTypes.map(type => {
		return { name: trans(`customerModal.customerTypes.${type}`), value: type }
	}) || [
		{ name: "HB", value: "HB" },
		{ name: "HA", value: "HA" },
		{ name: "BA", value: "BA" },
		{ name: "BAVD", value: "BAVD" },
	]

	const allocationSchemeOptions = config?.allocationScheme.map(alloc => {
		return {
			name: trans(`customerModal.allocationSchemes.${alloc}`),
			value: alloc,
		}
	}) || [
		{ name: "Use allocation scheme", value: "" },
		{ name: "Enter points manually", value: "NONE" },
	]

	useEffect(() => {
		if (APsAndOperators?.operators?.length)
			dispatch({
				type: "setOperatorList",
				list: APsAndOperators?.operators.map((op: any) => op.id),
			})
	}, [APsAndOperators?.operators])

	if (loading) return <Spinner />

	const pointAlreadySelected =
		state.points.filter(point => {
			if (state.selectedPoint.id === point.id) return true
			return false
		}).length > 0

	return (
		<div className="config-modal">
			<div>
				<div className="modal-spacer">
					<p>{trans("customerModal.basicInfo")}</p>
				</div>
				<Input
					placeholder={trans("customerName")}
					value={state.name}
					error={!state.name}
					onChange={e => setProperty("name", e.target.value)}
				/>
				{customerTypeOptions.length > 1 && (
					<Dropdown
						placeholder="customerModal.selectType"
						dropdownHeight={120}
						options={customerTypeOptions}
						error={!state.customerType}
						onSelect={option => setProperty("customerType", option.value)}
					/>
				)}
				{siteConfig.customerModal?.externalKeyList
					.filter(({ key }) => key !== siteConfig.customerModal?.customerAutoKey?.key)
					.map((prop, index) => {
						return (
							<Input
								key={`externalKey${index}`}
								placeholder={trans(`properties.${prop.name}`)}
								error={prop.required ? !state.externalKeys[prop.key] : undefined}
								value={state.properties[prop.key]}
								onChange={e =>
									dispatch({
										type: "setKey",
										key: prop.key,
										value: e.target.value,
									})
								}
							/>
						)
					})}
				{siteConfig.customerModal?.propertyList.map((prop, index) => {
					return (
						<Input
							key={`property${index}`}
							placeholder={trans(`properties.${prop.name || prop.key}`)}
							error={prop.required ? !state.properties[prop.key] : undefined}
							value={state.properties[prop.key] || ""}
							onChange={e =>
								dispatch({
									type: "setProperty",
									key: prop.key,
									value: e.target.value,
								})
							}
						/>
					)
				})}
				{/* DISABLED UNTIL DESCRIPTION IS FIXED IN API */}
				{/* <Input
					placeholder={"Description"}
					value={state.description}
					onChange={(e) => setProperty("description", e.target.value)}
				/> */}
			</div>
			<div>
				{operatorOptions.length > 1 && (
					<>
						<div className="modal-spacer">
							<p>{trans("customerModal.operator")}</p>
						</div>
						<InputSelect
							placeholder={"customerModal.selectOperator"}
							style={{ marginBottom: "1rem" }}
							label="name"
							defaultValue={"none"}
							noSort
							error={!state.operatorID}
							options={operatorOptions}
							onSelect={(option: { value: string; name: string }) =>
								dispatch({ type: "set", key: "operatorID", value: option.value })
							}
							optionsWidth={180}
							optionsHeight={120}
						/>
					</>
				)}
				<div className="modal-spacer">
					<p>{trans("customerModal.points")}</p>
				</div>
				{config && config.allocationScheme.length > 1 && (
					<Dropdown
						placeholder="customerModal.allocationScheme"
						dropdownHeight={65}
						options={allocationSchemeOptions}
						error={state.allocationScheme === undefined}
						// defaultValue={{ name: "Use allocation scheme", value: "" }}
						onSelect={option => setProperty("allocationScheme", option.value)}
					/>
				)}
				{state.allocationScheme === "NONE" && (
					<>
						{state.points.length ? (
							state.points.map((point, index) => {
								return (
									<div className="point-row" key={`point${index}`}>
										<p>{`• ${point.name}`}</p>
										<div>
											<SvgRemove
												style={{ width: 16, height: 16, fill: "grey" }}
												onClick={() => {
													dispatch({ type: "removePoint", index })
												}}
											/>
										</div>
									</div>
								)
							})
						) : (
							<div className="point-row">
								<p>{`• ${trans("customerModal.allocationSchemes.NONE")}`}</p>
							</div>
						)}
						<div>
							<div className="modal-spacer">
								<p>{trans("customerModal.addStation")}</p>
							</div>
							<div className={`add-new-point-row`}>
								<Dropdown
									placeholder="selectStation"
									dropdownHeight={250}
									options={accessParentsList}
									error={mandatory.station && !state.points.length}
									onSelect={option => {
										dispatch({
											type: "setPoint",
											point: { id: option.value, name: option.name },
										})
									}}
								/>
							</div>
							<Button
								disabled={pointAlreadySelected || !state.selectedPoint.id}
								onClick={() => {
									if (state.selectedPoint.id && !pointAlreadySelected) {
										dispatch({
											type: "addPoint",
											id: state.selectedPoint.id,
											name: state.selectedPoint.name,
										})
									}
								}}
							>
								{"customerModal.add"}
							</Button>
							<p
								style={{
									fontSize: "12px",
									margin: "0.5rem 0",
								}}
							>
								<i>{trans("hints.ifNoStation")}</i>
							</p>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

const Input = (props: {
	placeholder: string
	value: string
	onChange: React.ChangeEventHandler<HTMLInputElement>
	disabled?: boolean
	helperDialog?: string
	error?: boolean
}) => {
	const [focus, pocus] = useState(false)
	return (
		<div className={`input-form`}>
			<label>
				<input
					type="text"
					className={props.error ? "error-border" : undefined}
					placeholder={props.placeholder}
					value={props.value}
					onChange={props.onChange}
					disabled={props.disabled}
					onFocus={props.helperDialog ? () => pocus(true) : undefined}
					onBlur={props.helperDialog ? () => pocus(false) : undefined}
				/>
				{props.helperDialog && focus && <div className="helper-dialog">{props.helperDialog}</div>}
			</label>
		</div>
	)
}

export const Dropdown = (props: {
	placeholder?: string
	onSelect: (option: any) => void
	options: {
		name: string
		value: string
		[key: string]: any
	}[]
	dropdownWidth?: number
	dropdownHeight?: number
	error?: boolean
	defaultValue?: { name: string; value: string } | null
}) => {
	const { dropdownWidth: w = 252, dropdownHeight: h = 100 } = props
	return (
		<div className="input-form">
			<InputSelect
				placeholder={props.placeholder}
				label="name"
				defaultValue={props.defaultValue || "none"}
				noSort
				options={props.options}
				onSelect={props.onSelect}
				optionsWidth={w}
				optionsHeight={h}
				error={props.error}
			/>
		</div>
	)
}

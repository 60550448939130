import { nanoid } from "nanoid"
import { ihm_containertypes } from "../../../datasets/containerTypes/ihm"
import { ISiteConfig } from "../../../types"
import { ihm_fractionList } from "../fractions"

// const ihmContainers = [] as { name: string; values: any[] }[]

export const ihm_accessPointModal: ISiteConfig["accessPointModal"] = {
	enable: true,
	method: "upsert",
	containerExternalKeyName: "containerId",
	containerExternalKeyValueGenerator: () => `B${nanoid(5)}`,
	singleContainer: false,
	fieldList: [
		{
			inputType: "text",
			keyType: "name",
			key: "name",
			placeholder: "headers.name",
			required: false,
		},
		{
			keyType: "property",
			key: "fraction",
			placeholder: "stationModal.selectFraction",
			required: true,
			inputType: "dropdown",
			options: ihm_fractionList.map(f => ({
				name: `fraction.${f}`,
				value: f,
			})),
		},
		{
			inputType: "multiValue",
			placeholder: "stationModal.selectType",
			required: true,
			options: ihm_containertypes().map(({ name, values }) => ({
				name: `containerOptionID.${name}`,
				values,
			})),
		},
	],
}

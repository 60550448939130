import { Redirect, Route, Switch, useRouteMatch } from "react-router"
import { useTranslation } from "../../../Contexts"
import { DashboardNavigation } from "../../DashboardNavigation"
import { LfmEnterpriseCenterOverview } from "./lfmEnterpriseCenterOverview"
import { LfmEnterpriseTenantOverview } from "./lfmEnterpriseTenantOverview"

const LfmEnterpriseDashboard = () => {
	const match = useRouteMatch()

	const { trans } = useTranslation()

	return (
		<div className="dashboard">
			<DashboardNavigation
				keyName="view"
				tabs={[
					{ key: "centers", name: trans("centerOverview") },
					{ key: "tenants", name: trans("tenantOverview") },
				]}
			/>
			<Switch>
				<Route path={`${match.path}`} exact>
					<Redirect to={`${match.path}/centers`} />
				</Route>
				<Route path={`${match.path}/centers`}>
					<LfmEnterpriseCenterOverview />
				</Route>
				<Route path={`${match.path}/tenants`}>
					<LfmEnterpriseTenantOverview />
				</Route>
			</Switch>
		</div>
	)
}

export const lfmEnterpriseDashboard = <LfmEnterpriseDashboard />

import { gql } from "apollo-boost"
import { IIdentityStatus, IStatus } from "../../../../Resources/types"
import { IKeyValue } from "../../../../Utils"

export const default_fetchById_customers = (fillLevel?: boolean) => gql`
	query GET_CUSTOMER_BY_ID($id: String) {
		store {
			data: customers(id: $id) {
				id
				name
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
				ancestor(type: "BUSINESS_PARENT") {
					id
					name
				}
				customerType
				points {
					priority
					point {
						id
						name
						type
						status
						properties {
							key
							value
						}
						pos: geoLocation {
							lat: latitude
							lon: longitude
						}
					}
					${
						fillLevel
							? `streamed {
								fillLevel: property(key: "fillLevel")
				versionSpec: property(key: "_versionSpec_fillLevel")
					}`
							: ""
					}
				}
				operator {
					operator {
						name
					}
				}
				identities {
					id
					status
					i: identity {
						keys: externalKeys {
							key
							value
						}
					}
				}
			}
		}
	}
`

export type Idefault_fetchById_customers = {
	store: {
		data: IGetCustomerById[]
	}
}

export type IGetCustomerById = {
	id: string
	name: string
	properties: IKeyValue[] | null
	externalKeys: IKeyValue[] | null
	points: ICustomerByIdPoints[] | null
	identities: IGetCustomerByIdIdentity[] | null
}

export type ICustomerByIdPoints = {
	priority: number | null
	point: IGetCustomerByIdPoint
}

export type IGetCustomerByIdPoint = {
	id: string
	name: string
	type: string
	status: IStatus
	properties: { key: string; value: string | null }[]
	pos: {
		lat: number
		lon: number
	} | null
}

export type IGetCustomerByIdIdentity = {
	id: string
	status: IIdentityStatus
	i: {
		keys:
			| {
					key: string
					value: string
			  }[]
			| null
	}
}

export type ICustomerByIdIdentitiesModified = {
	id: string
	status: IIdentityStatus
	[key: string]: string
}

export type ICustomerByIdModified = {
	id: string
	name: string
	properties: { [key: string]: string | null }
	externalKeys: { [key: string]: string | null }
	points: ICustomerByIdPoints[] | null
	identities: IGetCustomerByIdIdentity[] | null
}

export type ICustomerByIdPointModified = {
	id: string
	name: string
	type: string
	status: IStatus | null
	properties: { [key: string]: string | null }
	priority: number | null
	pos: number[] | null
}

export type ICustomerEntityByIdModified = {
	entity: ICustomerByIdModified
	identities: ICustomerByIdIdentitiesModified[]
	points: ICustomerByIdPointModified[]
	backupPoints: ICustomerByIdPointModified[]
}

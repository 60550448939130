import React from "react"
import { fillLevelColor } from "./functions"

const FillLevel = (props: any) => {
	const { value } = props
	const parsedValue = parseFloat(value) // fixes console errors
	return (
		<svg width="50" height="6" viewBox="0 0 100 6" preserveAspectRatio="none">
			<rect width="100" height="6" rx="6" fill="#eaeaea" />
			<rect width={parsedValue} height="6" rx="6" fill={fillLevelColor(value)} />
		</svg>
	)
}

export default FillLevel

import React, { useRef, useEffect } from 'react'
import * as L from 'leaflet';


function initMap(elem: any) {
  var map = L.map(elem, {
    zoomControl: false,
    zoom: 15,
    // dragging: false,
    // scrollWheelZoom: false,
    // touchZoom: false,
  }).setView([60.397076, 5.324383], 14)

  elem._map = map

  L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);
}

const Map = (_props: any) => {
  const mapEl = useRef(null)
  useEffect(() => {
    initMap(mapEl.current)
  }, [])

  // TODO: rename to drawer-map?
  return (
    <div ref={mapEl} id="customer-map"></div>
  )
}

export default Map

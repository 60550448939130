import { FC } from "react"
import { List } from "./components/List"
import { ITileProps, Tile } from "./Tile"

export const ListTile: FC<
	{
		tileProps?: ITileProps
	} & Parameters<typeof List>[0]
> = props => {
	return (
		<Tile {...props.tileProps}>
			<List {...props} />
		</Tile>
	)
}

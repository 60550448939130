import moment from "moment"
import React from "react"
import { useTranslation } from "../../Contexts"
import { DropDownIcon } from "../../Icons/DropDownIcon"
// import { DropDownIcon } from "../DropDownIcon"
import { HistoryChange } from "./HistoryChange"
import { IHistoryData, ResolvedPoints } from "./HistoryTypes"

export interface IHistoryEntryProps {
	keyProp?: String | number
	data: IHistoryData
	resolvedPoints: ResolvedPoints
}

export const HistoryEntry = ({ data, keyProp, resolvedPoints }: IHistoryEntryProps) => {
	const { trans } = useTranslation()
	const [expand, toggleExpand] = React.useState<boolean>(false)
	const handleExpand = () => toggleExpand(prev => !prev)
	const { type, timestamp, user, changes } = data
	if (type === "changed" && !changes?.length) return null

	return (
		<div className={`point-history-entry`}>
			<div
				className={`point-history-header ${type !== "created" && "pointer"}`}
				onClick={type !== "created" ? handleExpand : undefined}
			>
				<div className="flex-row">
					{type !== "created" && (
						<DropDownIcon style={{ transform: `rotate(${expand ? "90" : "0"}deg)` }} />
					)}
					{moment(timestamp).format("lll")}
				</div>
				<div className="flex-row">
					{user ? trans(`history.${type}WithUser`, [user]) : trans(`history.${type}WithoutUser`)}
				</div>
			</div>
			{changes && expand && (
				<table className="point-history-data">
					<thead>
						<tr className="point-history-data-header">
							<th>{trans("history.change")}</th>
							<th>{trans("history.from")}</th>
							<th>{trans("history.to")}</th>
						</tr>
					</thead>
					<tbody>
						{type !== "created" &&
							changes.map((change, index) => {
								return (
									<HistoryChange
										key={`${keyProp}-HistoryData${index}`}
										keyProp={`${keyProp}-HistoryData${index}`}
										change={change}
										resolvedPoints={resolvedPoints}
									/>
								)
							})}
					</tbody>
				</table>
			)}
		</div>
	)
}

import { translate } from "../Contexts/Translation"

export const timeAgo = (timestamp: number, trans?: any) => {
	if (!timestamp) return ""
	const reducedTimestamp = timestamp > 9999999999 ? timestamp / 1000 : timestamp
	const fillInterval = Math.floor(Date.now() / 1000 - reducedTimestamp)
	const now = fillInterval < 1 ? 1 : fillInterval

	const s = Math.floor(now % 60)
	const m = Math.floor(now / 60) % 60
	const h = Math.floor(now / 3600) % 24
	const d = Math.floor(now / 86400)

	const intervalName = d ? "days" : h ? "hours" : m ? "minutes" : "seconds"
	const interval = d || h || m || s

	const string = trans
		? trans("timeAgo." + intervalName, interval)
		: translate("timeAgo." + intervalName, interval)
	return string
}

import React, { useEffect, useMemo, useState } from "react"
import { match } from "ts-pattern"
import { useTranslation } from "../../../../Contexts"
import { useQueryParam } from "../../../../domHooks"
import { useSiteConfig } from "../../../../States/siteConfig"
import { IEventsBrowserFilterParams } from "../../configurations/types/types"
import { getControllers } from "./functions"
import { IFilterFetchWithOptions, IFilterSearchSelectFetch } from "./types"

export const EventsFilter = () => {
	const { siteConfig } = useSiteConfig()
	const { trans } = useTranslation()
	const queryFilters = useQueryParam("filter", {}) as IEventsBrowserFilterParams
	const [filters, setFilters] = useState<IFilterFetchWithOptions[]>([])

	const filterSpec = useMemo(() => siteConfig?.events.filters(), [siteConfig])

	useEffect(() => {
		const getFilters = async () => {
			return await Promise.all(
				filterSpec.filters.map(async f => {
					return match(f.control.component)
						.with("DateTimeSelect", "SearchOnceInput", () => f as IFilterFetchWithOptions)
						.with(
							"SearchSelect",
							async () =>
								({
									...f,
									control: {
										...f.control,
										options: await (f as IFilterSearchSelectFetch).control.options(trans),
									},
								} as IFilterFetchWithOptions)
						)
						.exhaustive()
				})
			)
		}
		getFilters().then(_filters => setFilters(_filters))
	}, [filterSpec.filters, trans])

	const filtersControllers = useMemo(
		() => (filters?.length ? getControllers(filters, queryFilters) : [<div key="filters0"></div>]),
		[filters, queryFilters]
	)

	return (
		<div id="filter-anchor">
			<span>{trans("filterResults")}</span>
			<div>{filtersControllers?.map(c => c)}</div>
		</div>
	)
}

import React, { useContext, useState, useCallback } from "react"

import "./Modal.css"

export type IModalContext = {
	showModal: (_: any) => void
	hideModal: () => void
	disableOffModalClose: (disable: boolean) => void
}

const ModalContext = React.createContext({
	showModal: (_: any) => {},
	hideModal: () => {},
	/**
	 * Use this before showModal to disable off-modal click to close function
	 * @param disable set true to disable
	 */
	disableOffModalClose: (disable: boolean) => {},
} as IModalContext)

export function useModal() {
	return useContext(ModalContext)
}

export const ModalProvider = ({ children }: any) => {
	const [modalContent, setModalContent] = useState(null)
	const [disableOffModalClose, setOffModalClose] = useState(false)

	const hideModal = useCallback(() => {
		setModalContent(null)
		setOffModalClose(false)
	}, [setModalContent, setOffModalClose])
	const value = {
		showModal: setModalContent,
		hideModal,
		disableOffModalClose: setOffModalClose,
	}

	const style = {
		visibility: modalContent ? "visible" : "hidden",
	} as React.CSSProperties

	return (
		<ModalContext.Provider value={value}>
			{children}
			<div
				id="modal"
				style={style}
				onClick={e => {
					// value.hideModal()
				}}
				onMouseDown={e => {
					if (!disableOffModalClose && e.currentTarget === e.target) value.hideModal()
				}}
			>
				{modalContent}
			</div>
		</ModalContext.Provider>
	)
}

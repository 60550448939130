import { SVGProps } from 'react'

export const PorselenIcon = ({
  fill = 'black',
  width = '241',
  height = '341',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 241 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M240.149 170.469H63.7721V0.390674H0.778809C0.778809 0.390674 0.778809 138.973 0.778809 170.469C0.778809 208.264 32.2761 233.461 32.2761 233.461V340.547H202.355L183.457 271.256C183.457 271.256 240.149 227.161 240.149 170.469Z"
        fill={fill}
      />
      <path d="M88.9692 120.075V145.272H240.149V120.075H88.9692Z" fill={fill} />
    </svg>
  )
}

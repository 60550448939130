import React, { useContext, useState } from "react"
import { useTranslation } from "../../../Contexts"
import { useQueryParam } from "../../../domHooks"
import "./Pagination.css"

const PaginationContext = React.createContext({
	numElems: 3405,
	setNumElems: (_: number) => { },
})

export function usePagination() {
	return useContext(PaginationContext)
}

export const PaginationProvider = ({ children }: any) => {
	const [page, setPage] = useQueryParam("page", "0")
	const [numElems, setNumElems] = useState(0)
	const elemsPerPage = 100

	const onNumElemsChange = (numElems: number) => {
		// Make sure we don't stay on page '4' when
		// there are only 3 pages of filtered results
		const lowestVisibleElem = +page * elemsPerPage
		if (numElems < lowestVisibleElem) {
			setPage("0")
		}
		setNumElems(numElems)
	}

	const value = {
		numElems,
		setNumElems: onNumElemsChange,
	}

	return (
		<PaginationContext.Provider value={value}>
			<div className="flexColumns">{children}</div>
		</PaginationContext.Provider>
	)
}

export const Paginator = () => {
	const { numElems } = usePagination()
	const { trans } = useTranslation()

	if (numElems === 0) {
		return <div id="pagination"></div>
	}

	return (
		<div className="pagination">
			<span id="elems">{trans("rowCount", numElems)}</span>
		</div>
	)
}

import styles from "./Image.module.css"
import { useTranslation } from "../../../../Contexts"

export const Image = ({ imageUrl }: { imageUrl?: string | null }) => {
	const { trans } = useTranslation()

	return (
		<div className={styles.image}>
			{imageUrl ? (
				<img src={imageUrl} alt="" />
			) : (
				<div>
					<span>{trans("noImage")}</span>
				</div>
			)}
		</div>
	)
}

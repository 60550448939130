import React, { FC } from "react"

export const TileGroup: FC<{
	cols?: number
	rows?: number
}> = ({ children, cols = 1, rows = 2 }) => {
	return (
		<div
			className="dashboard-tile-group"
			style={{
				gridRow: `span ${rows} / auto`,
				gridColumn: `span ${cols} / auto`,
				// gridColumn: `span minmax(1fr, ${cols}) / auto`,
			}}
		>
			{children}
		</div>
	)
}

import React from "react"
import { useTranslation } from "../Contexts"
import { ModalContainer } from "."
import styles from "./Ask.module.css"

const Ask = ({ question, note, onYes, onNo, height, width }: any) => {
	const { trans } = useTranslation()

	return (
		<ModalContainer width={width || "500px"} onConfirm={onYes} onCancel={onNo}>
			<div className={styles.ask}>
				<h1>{question}</h1>
				{note && (
					<span className={styles.note}>
						<em>{trans("ask.note")}</em>: {note}
					</span>
				)}
			</div>
		</ModalContainer>
	)
}

export default Ask

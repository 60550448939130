import React from "react"
import { useHistory } from "react-router-dom"
import DataGrid from "../_components/DataGrid/DataGrid"

const Grid = ({ allocations, headers }: any) => {
	const history = useHistory()

	const actions = [
		{
			label: "actions.exportCsv",
			func: (elems: any[]) => console.log("export", elems),
		},
	]

	return (
		<DataGrid
			headers={headers}
			rows={allocations}
			actions={actions}
			entityType={"allocations"}
			onRowClick={(allocation: any) =>
				history.replace({
					pathname: `/resources/allocations/${allocation.id}`,
					search: history.location.search,
				})
			}
		/>
	)
}

export default Grid

import Fuse from "fuse.js"
import { get, sortBy } from "lodash"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { List } from "react-virtualized"
import { useTranslation } from "../Contexts"
import { useFocus, useKeyPress } from "../domHooks"
import "./InputSelect.css"
import Spinner from "./Spinner"
import TextInputField from "./TextInputField"

function createFuse(options: any, label: string) {
	return new Fuse(options, {
		shouldSort: true,
		threshold: 0.3,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: [label],
	})
}

const InputSelect = ({
	refObj,
	label,
	placeholder,
	options: inOpts,
	onSelect,
	onChange,
	extraClass,
	inputClass,
	style,
	defaultValue,
	clearOnSelect,
	onMenuOpen,
	noSort,
	optionsWidth,
	optionsHeight,
	disabled,
	error,
	loading,
	cancelSelected,
}: any) => {
	// Options received from parent, sorted
	const options = useMemo(() => (noSort ? inOpts : sortBy(inOpts, label)), [inOpts, label, noSort])

	// Current value of input element
	const [inputValue, setInputValue] = useState(get(defaultValue, label, ""))
	const [lastDefaultValue, setLastDefaultValue] = useState(defaultValue)
	// The last selected value
	const [lastValidValue, setLastValidValue] = useState<any>(defaultValue || null)
	// Options filtered by inputValue
	const [displayOptions, setDisplayOptions] = useState<any[]>(options)

	// Input events
	const inputElem = useRef<HTMLInputElement>(refObj || null)
	const focused = useFocus(inputElem)
	const enterPressed = useKeyPress(inputElem, "Enter")
	//const tabPressed = useKeyPress(inputElem, "Tab")

	// Set selection to current best match if any on enter/tab pressed
	/* useEffect(() => {
		const option = get(displayOptions, 0)
		if (option && (enterPressed || tabPressed)) {
			setLastValidValue(option)
		}
	}, [enterPressed, tabPressed, displayOptions]) */

	// Select default value, if it has been updated
	useEffect(() => {
		if (lastDefaultValue === "none") {
			setLastValidValue(defaultValue)
			setLastDefaultValue(defaultValue)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue])

	useEffect(() => {
		if (cancelSelected !== undefined) {
			setLastValidValue(defaultValue || null)
			setInputValue(get(defaultValue, label, ""))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cancelSelected])

	useEffect(() => {
		if (focused && onMenuOpen) {
			onMenuOpen()
		}
	}, [focused, onMenuOpen])

	// Select all text when input is focused
	useEffect(() => {
		if (focused && !disabled) {
			setInputValue("")
		}
	}, [focused, disabled])

	// Reset input elem to last valid value when losing focus
	useEffect(() => {
		if (!focused) {
			if (clearOnSelect) {
				setInputValue("")
			} else {
				const value = get(lastValidValue, label, "")
				setInputValue(value)
			}
		}
	}, [focused, label, lastValidValue, clearOnSelect])

	// Update displayOptions based on inputValue
	useEffect(() => {
		if (inputValue !== "") {
			const fuse = createFuse(options, label)
			setDisplayOptions(fuse.search(inputValue).map((result: any) => result.item))
		} else {
			setDisplayOptions(options)
		}
	}, [inputValue, label, options])

	// Unfocus input element on new value selected
	useEffect(() => {
		if (enterPressed) inputElem.current!.blur()
	}, [enterPressed])

	// Update input elem to reflect current selection
	useEffect(() => {
		if (lastValidValue && !clearOnSelect) {
			const value = get(lastValidValue, label, "")
			setInputValue(value)
		}
	}, [lastValidValue, label, clearOnSelect])

	// Run callback whenever a new element is selected
	useEffect(() => {
		if (lastValidValue) {
			onSelect(lastValidValue)
		}
		// eslint-disable-next-line
	}, [lastValidValue])

	const containerClass = ["input-select", extraClass].filter(x => x).join(" ")

	const onResultSelected = (option: { id: string; name: string; [key: string]: any }) => () => {
		if (clearOnSelect) {
			setInputValue("")
		}
		setLastValidValue({ ...option })
	}

	return (
		<div className={containerClass} style={style}>
			{loading && (
				<span className={containerClass + " spinnerComp"}>
					<Spinner height={20} thickness={12} />
				</span>
			)}
			<TextInputField
				ref={inputElem}
				placeholder={loading ? undefined : placeholder}
				value={inputValue}
				onChange={(ev: any) => {
					if (!disabled) {
						setInputValue(ev.target.value)
						if (onChange) onChange(ev)
					}
				}}
				readOnly={disabled}
				extraClass={`${inputClass || ""} ${error ? "text-input-error-border" : ""}`}
			/>
			{
				<Results
					label={label}
					focused={focused && !disabled}
					displayOptions={displayOptions}
					onOptionSelected={onResultSelected}
					optionsWidth={optionsWidth}
					optionsHeight={optionsHeight}
				/>
			}
		</div>
	)
}

type IRowRender = {
	key: string
	index: number
	isScrolling: boolean
	isVisible: boolean
	style?: React.CSSProperties
}

const Results = ({
	focused,
	label,
	displayOptions,
	onOptionSelected,
	optionsWidth,
	optionsHeight,
}: any) => {
	const { trans } = useTranslation()

	const Container = ({ children }: any) => (
		<div
			className="input-select-results-container"
			style={{
				visibility: focused ? "visible" : "hidden",
				display: focused ? "block" : "none",
			}}
		>
			{children}
		</div>
	)

	const RowRenderer = ({
		key,
		index, // Index of row within collection
		isScrolling, // The List is currently being scrolled
		isVisible, // This row is visible within the List (eg it is not an overscanned row)
		style, // Style object to be applied to row (to position it)
	}: IRowRender) => {
		return (
			<div
				key={"searchSelect_" + index + "_" + key}
				className="input-select-results"
				onMouseDown={onOptionSelected(displayOptions[index])}
				style={style}
			>
				{displayOptions[index][label]}
			</div>
		)
	}

	if (displayOptions.length > 0) {
		return (
			<Container>
				<List
					width={optionsWidth || 185}
					height={optionsHeight || 300}
					rowCount={displayOptions.length}
					rowHeight={30}
					rowRenderer={RowRenderer}
				/>
			</Container>
		)
	}

	return (
		<Container>
			<div className="input-select-noresults">{trans("noResults")}</div>
		</Container>
	)
}

export default InputSelect

import React, { useState, useContext, useEffect, useCallback } from "react"
import { get } from "lodash"
import { useSiteConfig } from "../States/siteConfig"

// Import supported locales
import "moment/locale/nb"
;(String.prototype as any).format = function (...args: any[]) {
	let a = this
	for (let k in args) {
		a = a.replace("{" + k + "}", args[k])
	}
	return a
}

function getBrowserLanguage() {
	return ["nn-NO", "nb-NO", "nb"].includes(navigator.language) ? "nb" : "en"
}

export const localeFromLocalStorage =
	window.localStorage.getItem("language.preference") || getBrowserLanguage()

const TranslationContext = React.createContext({
	toggleLocale: (_: string) => {},
	currentLocale: localeFromLocalStorage,
	trans: (_: string, ..._formatArgs: any[]): string => "",
})

const Translation = ({ children }: any) => {
	const [locale, setLocale] = useState(localeFromLocalStorage)
	const { siteConfig } = useSiteConfig()

	useEffect(() => {
		window.localStorage.setItem("language.preference", locale)
	}, [locale])

	const toggleLocale = useCallback((loc: string) => setLocale(loc), [])

	const trans = useCallback(
		(key: string, ...args: any[]) => {
			const translation = get(siteConfig.translations, `${locale}.${key}`)
			if (!translation) {
				console.warn(`translation for key '${key}' not found for current locale (${locale})`)
				return `###${key}###`
			}

			return args.length > 0 ? translation.format(...args) : translation
		},
		[locale, siteConfig]
	)

	const [value, setValue] = useState({
		toggleLocale,
		trans,
		currentLocale: locale,
	})

	useEffect(
		() => setValue({ toggleLocale, trans, currentLocale: locale }),
		[toggleLocale, trans, locale]
	)

	return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>
}

export function useTranslation() {
	return useContext(TranslationContext)
}

export default Translation

/**
 * Non hook version of trans()
 */
export const translate = (key: string, ...args: any[]) => {
	const { siteConfig } = useSiteConfig.getState()
	const translation = get(siteConfig.translations, `${localeFromLocalStorage}.${key}`)
	if (!translation) {
		console.warn(
			`translation for key '${key}' not found for current locale (${localeFromLocalStorage})`
		)
		return `###${key}###`
	}

	return args.length > 0 ? translation.format(...args) : translation
}

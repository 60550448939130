import React, { FC } from "react"
import { formatWithThousandSeperator } from "../../Utils/formatFunctions"
import { ITileProps, Tile } from "./Tile"

export const NumberTile: FC<{
	number: string | number
	formatThousands?: boolean
	tileProps?: ITileProps
	postfix?: string
}> = ({ number, formatThousands, tileProps, postfix }) => {
	const value = formatThousands ? formatWithThousandSeperator(number) : number
	return (
		<Tile cssClass="number-tile" {...tileProps}>
			<div className="flex">
				<h1 title={value?.toString()}>
					{value}
					{postfix}
				</h1>
			</div>
		</Tile>
	)
}

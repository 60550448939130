import { FC } from "react"
import { ITileProps, Tile } from "./Tile"
import { ResponsiveLine, Serie } from "@nivo/line"
import { chartColors } from "../styles/colorsForCharts"

export const LineChartTile: FC<{
	data: Serie[]
	tileProps?: ITileProps
	labels?: boolean
	percentValuesY?: boolean
	colorAea?: boolean
	points?: boolean
}> = ({ data, tileProps, labels, percentValuesY, colorAea, points }) => {
	return (
		<Tile {...tileProps}>
			<ResponsiveLine
				data={data}
				margin={{ top: 20, right: 10, bottom: 25, left: 10 }}
				xScale={{ type: "point" }}
				yScale={{ type: "linear", stacked: true }}
				yFormat={percentValuesY ? " >-.0%" : "y"}
				enablePoints={points || false}
				lineWidth={1}
				enableArea={colorAea}
				areaBlendMode="normal"
				enableGridY={false}
				axisLeft={null}
				enablePointLabel={labels}
				// colors={["var(--carrot)"]}
				colors={chartColors}
				useMesh={true}
				enableSlices="x"
			/>
		</Tile>
	)
}

import { gql } from "apollo-boost"

export const ihm_fetcAll_customers = gql`
	query ACIhmGetCustomers {
		store {
			data: customers {
				id
				name
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
				operator {
					operator {
						name
					}
				}
				customerType
				ancestor(type: "BUSINESS_PARENT") {
					id
				}
				points {
					point {
						name
						children {
							id
						}
					}
				}
			}
		}
	}
`

export type Iihm_fetcAll_customers = {
	store: {
		data: {
			id: string
			name: string
			address: string | null
			ImportID: string | null
			operator: {
				operator: {
					name: string
				}
			} | null
			points:
				| {
						point: {
							children:
								| {
										id: string
								  }[]
								| null
						}
				  }[]
				| null
		}[]
	}
}

import React, { useCallback, useState } from "react"
import { useTranslation } from "../../../Contexts"
import { ModalContainer, MultiForm, SelectAccessPoint } from "../../../UI"
import { useGetAccessPoints } from "../data"

function multiFormCreate() {
	return {}
}

function multiFormValidate(accessPoint: any) {
	const { name, id } = accessPoint
	const valid = Boolean(name && id)
	return valid
}

const multiFormSelect = (accessPoints: any[]) => (accessPoint: any, update: any) => {
	return (
		<SelectAccessPoint
			selected={accessPoint}
			accessPoints={accessPoints}
			onSelect={(newValues: any) => update({ ...accessPoint, ...newValues })}
		/>
	)
}

export const SelectAccessPoints = ({ header, accessPoints, onChange }: any) => {
	const { trans } = useTranslation()
	return (
		<div className="select-accesspoints-container">
			{header ? <h2>{trans(header)}</h2> : ""}
			<MultiForm
				create={multiFormCreate}
				validate={multiFormValidate}
				render={multiFormSelect(accessPoints)}
				onChange={onChange}
			/>
		</div>
	)
}

export const SelectAccessPointsModal = ({ onConfirm, onCancel }: any) => {
	const [selectedPoints, setSelectedPoints] = useState<any>([])
	const { trans } = useTranslation()
	const { loading, points: accessPoints } = useGetAccessPoints("points")

	const applyChange = useCallback(() => {
		if (onConfirm && selectedPoints.length > 0) {
			onConfirm(selectedPoints)
		}
	}, [onConfirm, selectedPoints])

	return (
		<ModalContainer
			width="350px"
			title={trans("actions.addExistingPoints")}
			onConfirm={applyChange}
			onCancel={onCancel}
			loading={loading}
		>
			<SelectAccessPoints accessPoints={accessPoints} onChange={setSelectedPoints} />
			<p
				style={{
					fontSize: "12px",
					marginTop: "0.5rem",
				}}
			>
				<i>{trans("hints.ifNoStation")}</i>
			</p>
		</ModalContainer>
	)
}

import { useTranslation } from "../../../Contexts"
import { FlexBox } from "../../Layouts/FlexBox/FlexBox"
import styles from "../Drawer.module.css"

export const CustomersAllocated = ({
	customerCount,
}: {
	customerCount?: number | null | undefined
}) => {
	const { trans } = useTranslation()
	return customerCount ? (
		<FlexBox rowGap={1} asColumns alignItems="flex-end" className={styles.sectionSpace}>
			<div>{trans("customersAllocated", customerCount)}</div>
		</FlexBox>
	) : (
		<></>
	)
}

import { useQuery } from "@apollo/client"
import { gql } from "apollo-boost"
import { get } from "lodash"
import React, { useMemo, useReducer, useState } from "react"
import { IContainerTypes } from "../../Configs/datasets/containerTypes/all"
import { ISiteConfig, KeyValFieldConfig } from "../../Configs/types"
import { useModal, useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import { Button, InputSelect, ModalContainer } from "../../UI"
import { fetchFunc, postFunc } from "../../Utils/api"
import { IUIDGeneratorTypes, uniqueIdGenerator } from "../../Utils/uniqueIdGenerator"
import {
	INLET_MUTATION,
	NewAPmutationGen,
	STATION_MUTATION,
	TERMINAL_MUTATION,
} from "./Functions/generateAPmutation"

import { IDropdownOption } from "../../UI/Drawer/components/Inputs/DrawerField"
import "../CustomerAndStationModal.css"
import {
	IInletState,
	ISetActionTypes,
	IStationConfigState,
	StationConfigReducer,
} from "./StationConfigModalReducer"
import { Select } from "../../UI/GenericComponents/Select"
import { AccessPointStatus } from "../../Utils/gqlRequestTypes/generic"

export type StationModalConfig = {
	modes: "create"[]
	/**
	 * If hide then status will be set as default value. Default does nothing otherwise - InputSelect bug
	 */
	status:
		| {
				hide: true
				default: AccessPointStatus
				required?: false
		  }
		| {
				hide?: false
				default?: AccessPointStatus
				required?: boolean
		  }
	/**
	 * If hide then status will be set as default value. Default does nothing otherwise - InputSelect bug
	 */
	inletStatus:
		| {
				hide: true
				default: AccessPointStatus
				required?: false
		  }
		| {
				hide?: false
				default?: AccessPointStatus
				required?: boolean
		  }
	/**
	 * whenever to use hatchSize property from BIR.
	 */
	inletHatchSizeProp?: boolean
	/**
	 * Not in use anymore
	 */
	inletAutoName?: boolean
	inletNameField?: {
		enabled: boolean
		required?: boolean
	}
	installationAutoKey?: { key: string; generator: IUIDGeneratorTypes }
	installationAutoProp?: {
		key: string
		generator: IUIDGeneratorTypes | "fn"
		fn?: (state: IStationConfigState) => string
	}
	stationAutoKey?: {
		key: string
		generator: IUIDGeneratorTypes
		lenght?: number
		prefix?: string
	}
	installationPropertyList?: KeyValFieldConfig
	installationExternalKeyList?: KeyValFieldConfig
	stationPropertyList: KeyValFieldConfig
	stationExternalKeyList: KeyValFieldConfig
	inletPropertyList: KeyValFieldConfig
	inletExternalKeyList: KeyValFieldConfig
	inletAutoKey?: { key: string; generator: IUIDGeneratorTypes; lenght?: number; prefix?: string }
	containerTypes?: IContainerTypes[]
	/**
	 * If enabled, then uses field for external key and station will be assigned to customer
	 */
	externalKeyAssignation:
		| { enabled: true; externalKey: string; name: string; required: boolean }
		| { enabled: false; externalKey?: string; name?: string; required?: boolean }
}

const GET_STATION_RELATED_DATA = gql`
	{
		store {
			accessPoints(type: "TERMINAL") {
				id
				type
				name
				defaultTerminal: externalKey(key: "defaultTerminal")
			}
		}
	}
`

const RESOLVE_ADDRESS = gql`
	query check_address($address: String, $area: String) {
		resolveAddress(streetAddress: $address, area: $area) {
			latitude
			longitude
			keywords
		}
	}
`

const assignStationToCustomerRequest = (cid: string, pid: string) => {
	return gql`
		mutation assign {
			updateCustomerMutation (input:{
				wait: true
				payload: {
					customerId: "${cid}"
					externalKeyName: ImportID
					newPoints: {id: "${pid}"}
				}
			}) {
				primaryKey
				commandProcessError
			}
		}
	`
}

const createStation = async (
	configState: IStationConfigState,
	onSuccess: (res?: any) => void,
	setError?: any,
	config?: ISiteConfig["stationModal"]
) => {
	const {
		terminal,
		station,
		inlets,
		installationExternalKeys,
		installationProperties,
		stationProperties,
		stationExternalKeys,
		inletProperties,
		inletExternalKeys,
	} = configState
	const { name, status, geoLocation } = station

	const parseAddess = async () => {
		const loc = geoLocation
		if (!loc) return
		if (RegExp(/^\d+\.\d+,\d+\.\d+$/g).test(loc)) return loc // GPS coordinates?
		const [addr, area] = loc.split(",")
		return await fetchFunc(RESOLVE_ADDRESS, res => res.data, {
			variables: {
				address: addr.trim(),
				area: area.trim(),
			},
		}).then((res: any) => {
			if (!res.resolveAddress[0]) return null
			const { latitude, longitude } = res.resolveAddress[0]
			return `${latitude},${longitude}`
		})
	}

	const location = await parseAddess()

	const stationObject: STATION_MUTATION = {
		type: "ACCESS_PARENT",
		payload: {
			parent: terminal.id ? { id: terminal.id } : undefined,
			name: name,
			status: (config?.status.hide && config.status.default) || status || "ACTIVE",
			geoLocation: location ? { decimalDegrees: location } : undefined,
			properties: [
				...(!location && geoLocation ? [{ key: "geoLocationName", value: geoLocation }] : []),
				...Object.entries(stationProperties).reduce((p, [key, value]) => {
					if (value) return [...p, { key, value }]
					return p
				}, [] as { key: string; value: string }[]),
			],
			externalKeys: [
				...(config?.stationAutoKey
					? [
							{
								key: config.stationAutoKey.key,
								value:
									(config.stationAutoKey.prefix || "") +
									uniqueIdGenerator(config.stationAutoKey.generator, config.stationAutoKey.lenght),
							},
					  ]
					: []),
				...Object.entries(stationExternalKeys).reduce((p, [key, value]) => {
					if (value) return [...p, { key, value }]
					return p
				}, [] as { key: string; value: string }[]),
			],
		},
		mutations: inlets.map((inlet: IInletState): INLET_MUTATION => {
			return {
				type: "ACCESS_POINT",
				payload: {
					name: config?.inletAutoName
						? `${inlet.ImportID || ""}-${inlet.fraction}-${
								inlet["volume"] ? "-" + inlet["volume"] : ""
						  }`
						: inlet.name || "",
					status:
						(config?.inletStatus.hide && config.inletStatus.default) || inlet.status || "ACTIVE",
					properties: [
						{ key: "fraction", value: inlet.fraction },
						...(inlet.hatchTypeCode ? [{ key: "hatchTypeCode", value: inlet.hatchTypeCode }] : []),
						...Object.entries(inlet).reduce((p, [key, value]) => {
							if (Object.keys(inletProperties).includes(key) && value) return [...p, { key, value }]
							return p
						}, [] as { key: string; value: string }[]),
					],
					externalKeys: [
						...(config?.inletAutoKey
							? [
									{
										key: config.inletAutoKey.key,
										value:
											(config.inletAutoKey.prefix || "") +
											uniqueIdGenerator(config.inletAutoKey.generator, config.inletAutoKey.lenght),
									},
							  ]
							: []),
						...Object.entries(inlet).reduce((p, [key, value]) => {
							if (Object.keys(inletExternalKeys).includes(key) && value)
								return [...p, { key, value }]
							return p
						}, [] as { key: string; value: string }[]),
					],
				},
			}
		}),
	}

	const mutationObject: TERMINAL_MUTATION | STATION_MUTATION = terminal.id
		? stationObject
		: {
				type: "TERMINAL",
				payload: {
					name: terminal.name,
					externalKeys: [
						...(config?.installationAutoKey
							? [
									{
										key: config.installationAutoKey.key,
										value: uniqueIdGenerator(config.installationAutoKey.generator),
									},
							  ]
							: []),
						...Object.entries(installationExternalKeys).reduce((p, [key, value]) => {
							if (value) return [...p, { key, value }]
							return p
						}, [] as { key: string; value: string }[]),
					],
					properties: [
						...(config?.installationAutoProp
							? [
									{
										key: config.installationAutoProp.key,
										value:
											config.installationAutoProp.generator === "fn" &&
											config.installationAutoProp?.fn
												? config.installationAutoProp?.fn(configState) || ""
												: uniqueIdGenerator(
														config.installationAutoProp.generator as IUIDGeneratorTypes
												  ),
									},
							  ]
							: []),
						...Object.entries(installationProperties).reduce((p, [key, value]) => {
							if (value) return [...p, { key, value }]
							return p
						}, [] as { key: string; value: string }[]),
					],
				},
				mutation: stationObject,
		  }

	const GQL_REQUEST = NewAPmutationGen(mutationObject)

	const GET_ACCESS_POINTS_QUERY = useSiteConfig.getState().siteConfig.points.data.fetchAll

	postFunc(
		GQL_REQUEST,
		res => {
			return res
		},
		{
			refetchQueries: [
				{ query: GET_ACCESS_POINTS_QUERY }, // Refresh point list
				{ query: GET_STATION_RELATED_DATA }, // Refresh terminal list
			],
		}
	).then(res => {
		onSuccess(res)
	})
}

interface IStationConfigProps {
	location?: string
	onClose?: () => void
	useHistory?: any
}

const ListToObject = (list: { key: string; name: string; value: string }[] | undefined) => {
	const convertedObject: any = {}
	list?.forEach(item => {
		convertedObject[item.key] = item.value
	})
	return convertedObject
}

const StationConfig = (props: IStationConfigProps) => {
	const { siteConfig } = useSiteConfig()
	const config = siteConfig.stationModal

	const [state, dispatch] = useReducer(StationConfigReducer, {
		terminal: {
			id: "",
			name: "",
		},
		station: {
			name: "",
			status: "",
			geoLocation: props.location || "",
		},
		installationProperties: ListToObject(config?.installationPropertyList),
		stationProperties: ListToObject(config?.stationPropertyList),
		stationExternalKeys: ListToObject(config?.stationExternalKeyList),
		inletProperties: ListToObject(config?.inletPropertyList),
		inletExternalKeys: ListToObject(config?.inletExternalKeyList),
		installationExternalKeys: ListToObject(config?.stationExternalKeyList),
		inlets: [],
		inlet: {
			name: "",
			fraction: "",
			status: "",
			hatchTypeCode: "",
		},
		showInletCreation: false,
		defaultTerminal: null,
		externalKeyAssignValue: "",
		customMultipleValDropdown: "",
	})
	const [selectTerminal, toggleTerminal] = useState(true)
	const [errorMessage, setError] = useState<string | null>(null)
	// const [requestInProgress, setRequestInProgress] = useState(false) // creates other issues on its own ;(

	const { trans } = useTranslation()
	const { hideModal } = useModal()
	const { data, loading } = useQuery(GET_STATION_RELATED_DATA)

	const stationData = get(data, "store", [])

	const terminals =
		stationData?.accessPoints?.map((point: any) => {
			if (point.defaultTerminal && !state.defaultTerminal)
				dispatch({
					type: "setDefaultTerminal",
					terminal: { name: point.name, value: point.id },
				})
			return { name: point.name, value: point.id }
		}) || []

	const fractionList = useMemo(() => {
		return siteConfig.fractionsList.map(value => {
			return { name: `${trans(`fraction.${value}`)} (${value})`, value }
		})
	}, [siteConfig.fractionsList, trans])

	const statusOptions = [
		{ name: trans("status.ACTIVE"), value: "ACTIVE" },
		{ name: trans("status.INACTIVE"), value: "INACTIVE" },
		{ name: trans("status.PLANNED"), value: "PLANNED" },
		{ name: trans("status.DEPRECATED"), value: "DEPRECATED" },
	]

	const setProperty = (ap: "station" | "terminal" | "inlet", key: string, value: string) => {
		dispatch({ type: "set", ap: ap, key: key, value: value } as ISetActionTypes)
	}

	const checkInlet = () => {
		const requiredExternalKeysInvalid = config?.inletExternalKeyList
			.filter(extKey => extKey.required)
			.some(extKey => !state?.inletExternalKeys[extKey.key])
		const requiredPropertiesInvalid = config?.inletPropertyList
			.filter(prop => prop.required)
			.some(prop => !state?.inletProperties[prop.key])
		return (
			requiredExternalKeysInvalid ||
			requiredPropertiesInvalid ||
			(config?.containerTypes?.length && !state.customMultipleValDropdown)
		)
	}

	const checkStation = () => {
		const requiredExternalKeysInvalid = config?.stationExternalKeyList
			.filter(extKey => extKey.required)
			.some(extKey => !state?.stationExternalKeys[extKey.key])
		const requiredPropertiesInvalid = config?.stationPropertyList
			.filter(prop => prop.required)
			.some(prop => !state?.stationProperties[prop.key])
		const assignationKeyInvalid =
			config?.externalKeyAssignation.required && !state.externalKeyAssignValue
		return requiredExternalKeysInvalid || requiredPropertiesInvalid || assignationKeyInvalid
	}

	const checkInstallation = () => {
		const requiredExternalKeysInvalid = config?.installationExternalKeyList
			?.filter(extKey => extKey.required)
			.some(extKey => !state?.installationExternalKeys[extKey.key])
		const requiredPropertiesInvalid =
			!state.terminal.id &&
			config?.installationPropertyList
				?.filter(prop => prop.required)
				.some(prop => !state?.installationProperties[prop.key])
		return requiredExternalKeysInvalid || requiredPropertiesInvalid
	}

	const addInlet = () => {
		const { fraction, status, name } = state.inlet
		if (config?.inletNameField?.enabled && config?.inletNameField?.required && !name) {
			setError(trans("stationModal.errors.noIName"))
		} else if (!fraction) {
			setError(trans("stationModal.errors.noIFraction"))
		} else if (config?.inletStatus?.required && !status) {
			setError(trans("stationModal.errors.noIStatus"))
		} else if (checkInlet()) {
			setError(trans("stationModal.errors.fields"))
		} else {
			setError("")
			dispatch({ type: "addInlet" })
			config?.containerTypes && dispatch({ type: "setCustomMultipleValDropdown", value: "" })
		}
	}

	const handleConfirm = () => {
		const { name, status, geoLocation: location } = state.station

		if (!state.terminal.id && !state.terminal.name) {
			setError(trans("stationModal.errors.noTerminal"))
			return
		}

		if (!name) {
			setError(trans("stationModal.errors.noStName"))
			return
		}

		if (config?.inletStatus?.required && !status) {
			setError(trans("stationModal.errors.noStatus"))
			return
		}

		if (!location && status === "ACTIVE") {
			setError(trans("stationModal.errors.noLocation"))
			return
		}

		if (
			location &&
			!RegExp(/^\d+\.\d+,\d+\.\d+$|^[\p{L}\d\s]+,(\s)*[\p{L}\d]+$/gu).test(location)
		) {
			setError(trans("stationModal.errors.invalidLocation"))
			return
		}

		if (!state.inlets.length && status === "ACTIVE") {
			setError(trans("stationModal.errors.noInlets"))
			return
		}

		if (checkStation() || checkInstallation()) {
			setError(trans("stationModal.errors.fields"))
			return
		}

		setError("")
		createStation(
			state,
			(res?: any) => {
				if (res) {
					const pointID = res?.data?.addAccessPointMutation?.primaryKey
					if (config?.externalKeyAssignation.enabled) {
						postFunc(
							assignStationToCustomerRequest(state.externalKeyAssignValue, pointID),
							res => {
								return res
							},
							{
								refetchQueries: [
									// { query: GET_ACCESS_POINTS }, // Refresh station list
									{ query: siteConfig.points.data.fetchAll }, // Refresh station list
								],
							}
						).then(() => {
							props.useHistory?.push(`/resources/points/${pointID}`)
						})
					} else {
						props.useHistory?.push(`/resources/points/${pointID}`)
					}
				}
				closeModal()
			},
			setError,
			config
		)
	}

	const closeModal = () => {
		if (props.onClose) props.onClose()
		hideModal()
	}

	return (
		<ModalContainer
			width="600px"
			title={trans("stationModal.title")}
			onConfirm={handleConfirm}
			onCancel={closeModal}
			loading={loading}
		>
			<Label label={trans(`stationModal.${selectTerminal ? "terminal" : "newTerminal"}`)} />
			<div className="flex-row" style={{ alignItems: "center" }}>
				{selectTerminal ? (
					<Input
						type="dropdown"
						placeholder={trans("stationModal.selectTerminal")}
						error={!state.terminal.id}
						onChange={value => setProperty("terminal", "id", value)}
						dropdownOptions={terminals}
						value={state.defaultTerminal?.value}
						dropdownHeight={180}
					/>
				) : (
					<div className="flex-col w100">
						<Input
							type="text"
							placeholder={trans("stationModal.terminalName")}
							value={state.terminal.name}
							error={!state.terminal.name}
							onChange={value => setProperty("terminal", "name", value)}
						/>
						{config?.installationExternalKeyList?.map((prop, index) => {
							return (
								<Input
									type="text"
									key={`stationExternalKeyList${index}`}
									placeholder={trans(`properties.${prop.name}`)}
									value={state.installationExternalKeys[prop.key]}
									error={prop.required ? !state.installationExternalKeys[prop.key] : undefined}
									onChange={value =>
										dispatch({
											type: "setKey",
											path: "installation",
											key: prop.key,
											value,
										})
									}
								/>
							)
						})}
						{config?.installationPropertyList?.map((prop, index) => {
							return (
								<Input
									type="text"
									key={`stationPropertyList${index}`}
									placeholder={trans(`properties.${prop.name}`)}
									value={state.installationProperties[prop.key]}
									error={prop.required ? !state.installationProperties[prop.key] : undefined}
									onChange={value =>
										dispatch({
											type: "setProperty",
											path: "installation",
											key: prop.key,
											value: value,
										})
									}
								/>
							)
						})}
					</div>
				)}
				<div
					className="green-button"
					onClick={() => {
						toggleTerminal(pState => !pState)
						dispatch({ type: "toggleTerminal" })
					}}
				>
					{trans(`stationModal.${selectTerminal ? "createTerminal" : "selectTerminal"}`)}
				</div>
			</div>
			<div className="config-modal">
				<div>
					<Label label={trans("stationModal.station")} />
					<Input
						type="text"
						placeholder={trans("stationModal.stationName")}
						error={!state.station.name}
						onChange={value => setProperty("station", "name", value)}
					/>
					{!config?.status.hide && (
						<Input
							type="dropdown"
							placeholder={trans("stationModal.selectStatus")}
							error={config?.status.required && !state.station.status}
							onChange={value => setProperty("station", "status", value)}
							dropdownOptions={statusOptions}
						/>
					)}
					<Input
						type="text"
						placeholder={trans("stationModal.location")}
						error={
							(state.station.status === "ACTIVE" && !state.station.geoLocation) ||
							(!!state.station.geoLocation &&
								!RegExp(/^\d+\.\d+,\d+\.\d+$|^[\p{L}\d\s]+,(\s)*[\p{L}\d]+$/gu).test(
									state.station.geoLocation
								))
						}
						helperDialog={`${
							state.station.geoLocation &&
							!RegExp(/^\d+\.\d+,\d+\.\d+$|^[\p{L}\d\s]+,(\s)*[\p{L}\d]+$/gu).test(
								state.station.geoLocation
							)
								? trans("stationModal.errorLocation")
								: ""
						}${trans("stationModal.locationExamples")}`}
						onChange={value => setProperty("station", "geoLocation", value)}
					/>
					{config?.externalKeyAssignation.enabled && (
						<Input
							type="text"
							placeholder={trans(`properties.${config.externalKeyAssignation.name}`)}
							error={config.externalKeyAssignation.required && !state.externalKeyAssignValue}
							onChange={value => dispatch({ type: "setExtkeyAssignValue", value })}
						/>
					)}
					{config?.stationExternalKeyList.map((prop, index) => {
						return (
							<Input
								type={prop.type}
								key={`stationExternalKeyList${index}`}
								placeholder={trans(`properties.${prop.name}`)}
								error={prop.required ? !state.stationExternalKeys[prop.key] : undefined}
								onChange={value =>
									dispatch({
										type: "setKey",
										path: "station",
										key: prop.key,
										value,
									})
								}
								dropdownOptions={prop.options}
							/>
						)
					})}
					{config?.stationPropertyList.map((prop, index) => {
						return (
							<Input
								type={prop.type}
								key={`stationPropertyList${index}`}
								placeholder={trans(`properties.${prop.name}`)}
								error={prop.required ? !state.stationProperties[prop.key] : undefined}
								onChange={value =>
									dispatch({
										type: "setProperty",
										path: "station",
										key: prop.key,
										value,
									})
								}
								dropdownOptions={prop.options}
							/>
						)
					})}
				</div>
				<div>
					<Label label={trans(`stationModal.${state.showInletCreation ? "newInlet" : "inlets"}`)} />
					{!state.showInletCreation ? (
						<div className="input-form">
							{!state.inlets.length && (
								<div className="flex-row">
									<p style={{ lineHeight: "35px" }}>{trans("stationModal.noInlets")}</p>
								</div>
							)}
							{state.inlets.map((inlet, index) => {
								const { fraction, status, hatchTypeCode } = inlet
								return (
									<div className="flex-row" key={`inlet${index}`}>
										<p>{`${trans(`fraction.${fraction}`)} (
											${fraction}
									${status ? `, ${status}` : ""}
									${hatchTypeCode ? `, ${hatchTypeCode}` : ""}
								)`}</p>
										<svg
											width="20"
											height="35"
											viewBox="0 0 512 512"
											onClick={() => dispatch({ type: "removeInlet", index })}
										>
											<line x1="400" y1="256" x2="112" y2="256" />
										</svg>
									</div>
								)
							})}
							<Button className="" onClick={() => dispatch({ type: "toggleInletCreation" })}>
								actions.addNew
							</Button>
						</div>
					) : (
						<div>
							{config?.inletNameField?.enabled && !config.inletAutoName && (
								<Input
									type="text"
									placeholder={trans("stationModal.inletName")}
									error={config?.inletNameField?.required && !state.inlet.name}
									onChange={value => setProperty("inlet", "name", value)}
								/>
							)}
							<Input
								type="dropdown"
								placeholder={trans("stationModal.selectFraction")}
								error={!state.inlet.fraction}
								onChange={value => setProperty("inlet", "fraction", value)}
								dropdownOptions={fractionList}
							/>
							{!config?.inletStatus.hide && (
								<Input
									type="dropdown"
									placeholder={trans("stationModal.selectStatus")}
									error={config?.inletStatus.required && !state.inlet.status}
									onChange={value => setProperty("inlet", "status", value)}
									dropdownOptions={statusOptions}
								/>
							)}
							{siteConfig.stationModal?.inletHatchSizeProp && (
								<Input
									type="dropdown"
									placeholder={trans("stationModal.selectHatchSize")}
									onChange={value => setProperty("inlet", "hatchTypeCode", value)}
									dropdownOptions={[
										{ name: trans("stationModal.hatches.L"), value: "L" },
										{ name: trans("stationModal.hatches.S"), value: "S" },
										{ name: trans("stationModal.hatches.LS"), value: "LS" },
									]}
								/>
							)}
							{config?.inletExternalKeyList.map((prop, index) => {
								return (
									<Input
										type={prop.type}
										key={`inletExternalKeyList${index}`}
										placeholder={trans(`properties.${prop.name}`)}
										error={prop.required ? !state.inletExternalKeys[prop.key] : undefined}
										onChange={value =>
											dispatch({
												type: "setKey",
												path: "inlet",
												key: prop.key,
												value,
											})
										}
										dropdownOptions={prop.options}
									/>
								)
							})}
							{config?.containerTypes?.length && (
								<Dropdown
									placeholder="stationModal.selectType"
									onSelect={e => {
										dispatch({ type: "setCustomMultipleValDropdown", value: e.value })
										e?.values?.forEach(
											(val: { type: "externalKey" | "property"; key: string; value: string }) => {
												dispatch({
													type: val.type === "externalKey" ? "setKey" : "setProperty",
													path: "inlet",
													key: val.key,
													value: val.value,
												})
											}
										)
									}}
									options={config?.containerTypes.map(option => {
										return {
											name: trans(`containerOptionID.${option.name}`),
											value: option.name, // just because its required
											values: option.values,
										}
									})}
									error={!state.customMultipleValDropdown}
								/>
							)}
							{config?.inletPropertyList.map((prop, index) => {
								return (
									<Input
										type={prop.type}
										key={`inletPropertyList${index}`}
										placeholder={trans(`properties.${prop.name}`)}
										error={prop.required ? !state.inletProperties[prop.key] : undefined}
										onChange={value =>
											dispatch({
												type: "setProperty",
												path: "inlet",
												key: prop.key,
												value,
											})
										}
										dropdownOptions={prop.options}
									/>
								)
							})}
							<div className="flex-row">
								<Button onClick={addInlet}>ask.save</Button>
								<div style={{ display: "inline-block", width: "0.5rem" }} />
								<Button onClick={() => dispatch({ type: "toggleInletCreation" })}>
									ask.cancel
								</Button>
							</div>
						</div>
					)}
				</div>
			</div>
			{errorMessage && <div className="error-message">{errorMessage}</div>}
		</ModalContainer>
	)
}

export default StationConfig

const Input = (props: {
	type?: "text" | "dropdown" | "textDropdown"
	placeholder: string
	value?: string
	onChange?: (value: string) => any
	disabled?: boolean
	helperDialog?: string
	readonly?: boolean
	error?: boolean
	dropdownOnSelect?: (option: any) => void
	dropdownOptions?: IDropdownOption[]
	dropdownDefaultValue?: IDropdownOption
	dropdownWidth?: number
	dropdownHeight?: number
	translateOptions?: boolean
}) => {
	const [focus, pocus] = useState(false)
	const { type = "text", dropdownWidth: w = 252, dropdownHeight: h = 100 } = props
	return (
		<>
			<Select
				allowInput={type === "textDropdown" || type === "text"}
				dontShowNoResults={type === "textDropdown" || type === "text"}
				placeholder={props.placeholder}
				onChange={o => props.onChange && props.onChange(o.value || o.option)}
				initialValue={props.value}
				noInitialOption={!props.value}
				onInputChange={val =>
					(type === "textDropdown" || type === "text") && props.onChange && props.onChange(val)
				}
				data={
					(type === "text"
						? []
						: props.dropdownOptions?.map(o => ({ option: o.name || o.value, value: o.value }))) ||
					[]
				}
				error={props.error}
				dropdownStyle={{
					maxHeight: h,
					maxWidth: w,
					overflow: "auto",
				}}
				fontSize="0.8rem"
				onFocus={() => pocus(true)}
				onBlur={() => pocus(false)}
			/>
			{/* TODO: Helper dialog */}
			{props.helperDialog && focus && <div className="helper-dialog">{props.helperDialog}</div>}
		</>
	)
}

const Dropdown = (props: {
	placeholder?: string
	onSelect: (option: any) => void
	options: {
		name: string
		value: string
		[key: string]: any
	}[]
	dropdownWidth?: number
	dropdownHeight?: number
	error?: boolean
	defaultValue?: { name: string; value: string } | null
}) => {
	const { dropdownWidth: w = 252, dropdownHeight: h = 100 } = props
	return (
		<div className="input-form">
			<InputSelect
				placeholder={props.placeholder}
				label="name"
				defaultValue={props.defaultValue || "none"}
				noSort
				options={props.options}
				onSelect={props.onSelect}
				optionsWidth={w}
				optionsHeight={h}
				error={props.error}
			/>
		</div>
	)
}

const Label = (props: { label: string }) => {
	return (
		<div className="modal-spacer">
			<p>{props.label}</p>
			<div />
		</div>
	)
}

/* eslint import/no-webpack-loader-syntax:0 */
import { createBrowserHistory } from "history"
import "leaflet/dist/leaflet.css"
import ReactDOM from "react-dom"
import App from "./App"
import { configName } from "./Configs/config"
import { Auth0Provider } from "./Contexts/Auth"
import Translation from "./Contexts/Translation"
import "./Fonts/fonts.css"
import "./index.css"
import * as serviceWorker from "./serviceWorker"

const history = createBrowserHistory()
const onRedirectCallback = (appState: any) => {
	history.push(
		appState && appState.targetUrl
			? appState.targetUrl
			: `${window.location.pathname}${window.location.search}`
	)
}

const domain = window.location.origin

const auth0Config =
	domain.includes("bir") ||
	domain.includes("kvam") ||
	(window.location.origin.includes("localhost") && (configName === "kvam" || configName === "bir")) // for dev
		? {
				audience: "https://wasteiq-bir.no",
				client_id: "LQF3D6RuW06ZTGgRespFJa8PWrqPtdDB",
				domain: "wasteiq-bir.eu.auth0.com",
		  }
		: {
				audience: "https://adminapp.wasteiq.no",
				client_id: "5LnyptHmnvCblLbGKMBQtD5rzp0w6l60",
				domain: "wasteiq.eu.auth0.com",
		  }

ReactDOM.render(
	<Auth0Provider
		audience={auth0Config.audience}
		client_id={auth0Config.client_id}
		domain={auth0Config.domain}
		cacheLocation="localstorage"
		redirect_uri={window.location.origin}
		onRedirectCallback={onRedirectCallback}
	>
		<Translation>
			<App />
		</Translation>
	</Auth0Provider>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from "react"
import { Link, useHistory } from "react-router-dom"

import { stringifyQuery } from "../domHooks"
import { useTranslation } from "../Contexts"

export function pointNameValue(point: any) {
	const { value, pk, score, point: p } = point
	if (!(value && pk)) {
		console.warn("bad point received", point)
		return null
	}

	return {
		type: "point",
		id: pk,
		name: value,
		score: score || 0,
		pointType: p?.type,
	}
}
const PointOption = ({ name, id, pointType }: any) => {
	const { trans } = useTranslation()
	const history = useHistory()

	const customersLinkFilter = stringifyQuery({
		filter: {
			primary: [
				{
					label: name,
					value: id,
				},
			],
		},
		page: 0,
		mode: "list",
	})

	const eventsLinkFilter = stringifyQuery({
		filter: {
			parent: [
				{
					label: name,
					value: name,
				},
			],
		},
		page: 0,
		mode: "list",
	})

	return (
		<div
			onClick={() =>
				history.push(`/resources/${pointType === "CONTAINER" ? "containers" : "points"}/${id}`)
			}
		>
			<h1>{trans("points")}</h1>
			<div className="row">
				<span>{name}</span>
				<Link
					onClick={(ev: any) => ev.stopPropagation()}
					to={{ pathname: "/resources/customers", search: customersLinkFilter }}
				>
					{trans("allCustomersAtPoint")}
				</Link>
			</div>
			<div className="row">
				<span></span>
				<Link
					onClick={(ev: any) => ev.stopPropagation()}
					to={{ pathname: "/resources/events", search: eventsLinkFilter }}
				>
					{trans("eventsAtPoint")}
				</Link>
			</div>
		</div>
	)
}

export default PointOption

import moment from "moment"
import { FC, useEffect, useState } from "react"
import { useAuth0, useTranslation } from "../../Contexts"
import { Spinner } from "../../UI"
import { fetchFromDWH, ITenantDetails } from "../Queries/dwhApi"
import { List } from "./components/List"
import { Select } from "../../UI/GenericComponents/Select"
import { ITileProps, Tile } from "./Tile"

export const FractionSortingListTile: FC<{
	tileProps?: ITileProps
	data: { customerID: string; customerName: string }[]
	searchPlaceholder: string
	defaultSortHeader?: string
	defaultSorting?: "des" | "asc"
	formatThousands?: boolean
}> = props => {
	const { data, searchPlaceholder } = props
	const { token } = useAuth0()!
	const { trans } = useTranslation()

	const [tenantID, setTenantID] = useState<string | null>(null)
	const [tenantData, setTenantData] = useState<ITenantDetails>()

	useEffect(() => {
		if (tenantID)
			fetchFromDWH({ endpoint: "tenantDetails", id: tenantID, token }).then(res => {
				const datum = res.data.tenantdetails?.length
					? res.data.tenantdetails[0]
					: ({ sorting_degree: 0 } as ITenantDetails)
				setTenantData(datum)
			})
	}, [tenantID, token])
	const handleTenantSelect = (tenantID?: string) => {
		setTenantID(tenantID || null)
	}

	return (
		<Tile {...props.tileProps}>
			<div className="h100 flex-col">
				<Select
					data={data.map(({ customerName, customerID })=> ({ option: customerName || "", value: customerID }))}
					placeholder={searchPlaceholder}
					onChange={option => handleTenantSelect(option?.value)}
					searchIcon
				/>
				{tenantData ? (
					<>
						<div className="flex-col">
							<b>{`${trans("sortingDegree")}: ${Math.round(tenantData.sorting_degree * 100)}%`}</b>
							<b>{`${trans("period")}: ${moment(tenantData.period).format("MMMM")}`}</b>
						</div>
						<List
							data={
								tenantData.fraction_distribution?.map(dist => {
									return [trans(`fraction.${dist.fraction}`), dist.count, dist.weight]
								}) || []
							}
							headers={[trans("fractions"), trans("totalThrows"), trans("totalWeight")]}
							defaultSortHeader={props.defaultSortHeader}
							defaultSorting={props.defaultSorting}
							showTotal
							totalCalculationRowList={[false, true, true]}
							formatThousands={props.formatThousands}
						/>
					</>
				) : (
					<Spinner />
				)}
			</div>
		</Tile>
	)
}

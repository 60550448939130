import { cloneDeep } from "lodash"
import React, { useCallback, useReducer } from "react"
import { useTranslation } from "../../../Contexts"
import { ModalContainer, MultiForm, SelectAccessPoint, TextInputField } from "../../../UI"
import { useGetAccessPoints } from "../../Points/data"
import "./NewAllocation.css"

function multiFormCreate() {
	return { priority: 1 }
}

function multiFormValidate(accessPoint: any) {
	const { name, id, priority } = accessPoint
	const valid = Boolean(name && id && priority)
	return valid
}

const multiFormSelect = (accessPoints: any[]) => (accessPoint: any, update: any) => {
	return (
		<SelectAccessPoint
			includePriority
			selected={accessPoint}
			accessPoints={accessPoints}
			onSelect={(newValues: any) => update({ ...accessPoint, ...newValues })}
		/>
	)
}

const initialState = {
	geoLocationName: "",
	municipality: "",
	streetCode: "",
	houseNumber: "",
	points: [],
}

const reducer = (state: any, action: any) => {
	const newState = cloneDeep(state)
	switch (action.type) {
		case "field":
			newState[action.field] = action.value
			return newState
		case "points":
			newState.points = action.points
			return newState
		default:
			throw new Error(`Unknown action: ${action.type}`)
	}
}

function formValid(form: any) {
	const { geoLocationName, municipality, streetCode, houseNumber, points } = form
	return (
		geoLocationName !== "" &&
		municipality !== "" &&
		streetCode !== "" &&
		houseNumber !== "" &&
		points.length > 0
	)
}

export const NewAllocation = ({ onConfirm, onCancel }: any) => {
	const { trans } = useTranslation()
	const [state, dispatch] = useReducer(reducer, initialState)
	const { points: accessPoints, loading } = useGetAccessPoints("points")

	const updatePoints = useCallback(
		(points: any) => dispatch({ type: "points", points }),
		[dispatch]
	)

	const updateValue = useCallback(
		(field: string) => (event: any) =>
			dispatch({ type: "field", field, value: event.target.value }),
		[dispatch]
	)

	const applyChange = useCallback(() => {
		if (onConfirm && formValid(state)) {
			onConfirm(state)
		}
	}, [onConfirm, state])

	return (
		<ModalContainer width="600px" onConfirm={applyChange} onCancel={onCancel} loading={loading}>
			<div className="new-allocation">
				<div className="allocation-form">
					<h1>{trans("allocation")}</h1>
					<form>
						<TextInputField
							placeholder="headers.name"
							value={state.geoLocationName}
							onChange={updateValue("geoLocationName")}
						/>
						<TextInputField
							placeholder="headers.properties.municipality"
							value={state.municipality}
							onChange={updateValue("municipality")}
						/>
						<TextInputField
							placeholder="headers.properties.streetCode"
							value={state.streetCode}
							onChange={updateValue("streetCode")}
						/>
						<TextInputField
							placeholder="headers.properties.houseNumber"
							value={state.houseNumber}
							onChange={updateValue("houseNumber")}
						/>
					</form>
				</div>

				<div className="allocation-points">
					<h1>{trans("stations")}</h1>
					<MultiForm
						create={multiFormCreate}
						validate={multiFormValidate}
						render={multiFormSelect(accessPoints)}
						onChange={updatePoints}
					/>
				</div>
			</div>
		</ModalContainer>
	)
}

import { isEmpty } from "lodash"
import { IFiltersObjFetch } from "../components/EventsFilter/types"
import {
	isIFilterSearchOnceInputFetch,
	isIFilterSearchSelectFetch,
} from "../configurations/filters/types"
import { IEventsBrowserFilterParams } from "../configurations/types/types"
import { IDates, IEventModified, ISearchInputOption } from "../types"

export const getFilteredTableData = (
	filters: IEventsBrowserFilterParams,
	filterSpec: IFiltersObjFetch,
	dates: IDates
) => {
	return filterSpec.filters
		.reduce(async (prev, curr, i) => {
			const { allowApiFetch, data } = await prev

			if (isIFilterSearchSelectFetch(curr) || isIFilterSearchOnceInputFetch(curr)) {
				// If specific search select filter (array) is set (has children) and
				// is first filter set - will trigger api fetch to do the filtering
				if (
					allowApiFetch &&
					filters &&
					curr.id in filters &&
					!isEmpty((filters as any)[curr.id]) &&
					(filters as any)[curr.id]
				) {
					// Set dataValues as string or array to fetch function
					const dataValues = Array.isArray((filters as any)[curr.id])
						? ((filters as any)[curr.id] as ISearchInputOption[])
								?.filter(f => f.value)
								.map(f => f.value)
						: ((filters as any)[curr.id] as string)

					return dataValues?.length
						? {
								allowApiFetch: false,
								data: await curr.control.filter.fetch(dataValues, dates),
						  }
						: {
								allowApiFetch: allowApiFetch,
								data: data?.length ? data : ([] as IEventModified[]),
						  }
				} else if (
					filters &&
					curr.id in filters &&
					!isEmpty((filters as any)[curr.id]) &&
					(filters as any)[curr.id]
				) {
					// If specific search select filter (array) is set (has children) and
					// is NOT first filter set - will trigger function to do the filtering
					return {
						allowApiFetch: allowApiFetch,
						data: curr.control.filter.func(
							data,
							Array.isArray((filters as any)[curr.id])
								? ((filters as any)[curr.id] as ISearchInputOption[])?.map(f => f.value)
								: ((filters as any)[curr.id] as string)
						),
					}
				} else {
					// If specific search select filter (array) is not set (has NO children)
					// - do nothing
					return {
						allowApiFetch: allowApiFetch,
						data: data?.length ? data : ([] as IEventModified[]),
					}
				}
			} else {
				// If specific is DateTime filter - do nothing
				return {
					allowApiFetch: allowApiFetch,
					data: data?.length ? data : ([] as IEventModified[]),
				}
			}
		}, Promise.resolve({ allowApiFetch: true, data: [] as IEventModified[] }))
		.then(async e => e.data)
}

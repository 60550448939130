import { useState } from "react"

// TODO: Move input from station modal and update this?
export const ModalInput = (props: {
	placeholder: string
	value: string
	onChange: React.ChangeEventHandler<HTMLInputElement>
	disabled?: boolean
	helperDialog?: string
	readonly?: boolean
	error?: boolean
}) => {
	const [focus, pocus] = useState(false)
	return (
		<div className={`input-form`}>
			<label>
				<input
					type="text"
					className={props.error ? "error-border" : undefined}
					placeholder={props.placeholder}
					value={props.value}
					onChange={props.onChange}
					disabled={props.disabled}
					onFocus={props.helperDialog ? () => pocus(true) : undefined}
					onBlur={props.helperDialog ? () => pocus(false) : undefined}
				/>
				{props.helperDialog && focus && <div className="helper-dialog">{props.helperDialog}</div>}
			</label>
		</div>
	)
}

import { useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import exportCSV from "../exportCSV"
import { IListViewConfig } from "../types"
import DataGrid from "../_components/DataGrid/DataGrid"

function csvOptions(headers: IListViewConfig["headers"], trans: any) {
	const filename = `${trans("invoice")}.csv`
	const fields = headers.map(h => ({
		label: trans(h.name ? h.name : `headers.${h.path}`),
		value: h.path,
	}))

	return { filename, fields }
}

const Grid = (props: any) => {
	const { invoices, headers, columnSizes = {} } = props
	const { trans } = useTranslation()
	const { overrides } = useSiteConfig().siteConfig.invoices

	const actions = [
		{
			label: "actions.exportCsv",
			func: exportCSV(csvOptions(headers, trans)),
		},
	]
	if (invoices) {
		return (
			<DataGrid
				headers={headers}
				entityType={"invoices" as any}
				columnSizes={columnSizes}
				rows={invoices}
				actions={actions}
				allowEmptyField
				overrides={overrides ? { ...overrides(trans) } : undefined}
			/>
		)
	} else {
		return <div>Tomt</div>
	}
}

export default Grid

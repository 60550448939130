export const ihm_translations: any = {
	en: {
		type: {
			SET_POINT_PROPS: "Configured",
			SET_POINT_LOCATION: "Location update",
			SET_POINT_RFID: "RFID tagged",
		},
		headers: {
			ImportID: "CustomerID",
			geoLocationName: "Customer address",
			operator: "Operator",
			containerId: "ContainerID",
			assignedCustomer: "Customer",
			volume: "Volume",
			RFID: "RFID",
			point: {
				parent: {
					name: "Station name",
				},
				point: {
					RFID: "RFID",
				},
				RFID: "RFID",
			},
			customer: {
				name: "Customer name",
			},
		},
		stationModal: {
			// inlets: "Containers",
		},
		fraction: {
			"9912": "Mixed industrial waste",
		},
		properties: {
			geoLocationName: "Address (customer)",
			postalCode: "Postal code (customer)",
			postal: "City (customer)",
			postAddress: "Postal Address (invoice)",
			postPostalCode: "Postal code (invoice)",
			postPostal: "City (invoice)",
			invoiceEmail: "E-mail (invoice)",
		},
	},
	nb: {
		type: {
			SET_POINT_PROPS: "Konfigurert",
			SET_POINT_LOCATION: "Oppdatert lokasjon",
			SET_POINT_RFID: "RFID-merket",
		},
		headers: {
			ImportID: "KundeID",
			geoLocationName: "Kunde adresse",
			operator: "Operatør",
			containerId: "BeholderID",
			assignedCustomer: "Kunde",
			volume: "Volum",
			RFID: "RFID",
			point: {
				parent: {
					name: "Stasjonsnavn",
				},
				point: {
					RFID: "RFID",
				},
				RFID: "RFID",
			},
			customer: {
				name: "Kundenavn",
			},
		},
		stationModal: {
			// inlets: "Beholdere",
			// station: "Hentested",
		},
		fraction: {
			"9912": "Blandet næringsavfall",
		},
		properties: {
			geoLocationName: "Gateadresse (kunde)",
			postalCode: "Postnummer (kunde)",
			postal: "Poststed (kunde)",
			postAddress: "Gateadresse (faktura)",
			postPostalCode: "Postnummer (faktura)",
			postPostal: "Poststed (faktura)",
			invoiceEmail: "E-post (faktura)",
		},
	},
}

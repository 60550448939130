import { FC } from "react"
import { ITileProps, Tile } from "./Tile"
import { ResponsivePie } from "@nivo/pie"
import { chartColors } from "../styles/colorsForCharts"
import { animated } from "@react-spring/web"
import { fractionMapping } from "../../Configs/datasets/fractionMappings/fractionMappings"

export const PieChartTile: FC<{
	data: {
		id: string | number
		value: string | number
		fraction?: string | number
	}[]
	legend?: boolean
	tileProps?: ITileProps
	useIcons?: boolean
	parseFractionColors?: boolean
}> = ({ data, tileProps, useIcons, legend, parseFractionColors }) => {
	return (
		<Tile {...tileProps}>
			<ResponsivePie
				data={data}
				margin={{ top: 10, right: 0, bottom: legend ? 40 : 0, left: 0 }}
				innerRadius={0.5}
				padAngle={1}
				cornerRadius={3}
				sortByValue={true}
				layers={["arcLinkLabels", "arcs", "arcLabels", "legends"]}
				value={"value"}
				activeOuterRadiusOffset={2}
				valueFormat={">-.0%"}
				enableArcLinkLabels={false}
				// colors={chartColors}
				colors={
					parseFractionColors
						? datum => {
								return (
									(datum?.data?.fraction &&
										fractionMapping[datum.data.fraction] &&
										fractionMapping[datum.data.fraction].color) ||
									"var(--black-1)"
								)
						  }
						: chartColors
				}
				// animate={false}
				arcLabelsSkipAngle={10}
				arcLabelsComponent={
					useIcons
						? ({ style, datum }) => {
								return datum?.data?.fraction && fractionMapping[datum.data.fraction]?.icon ? (
									<animated.g
										transform={(style.transform as unknown) as string}
										style={{ pointerEvents: "none" }}
									>
										{fractionMapping[datum.data.fraction].icon}
									</animated.g>
								) : (
									<div />
								)
						  }
						: undefined
				}
				legends={
					legend
						? [
								{
									anchor: "bottom-left",
									direction: "row",
									// translateX: 0,
									translateY: 30,
									itemsSpacing: 0,
									itemWidth: 100,
									itemHeight: 18,
								},
						  ]
						: []
				}
			/>
		</Tile>
	)
}

import { nanoid } from "nanoid"
import { default_vendorOptions_inlet } from "../../datasets/VendorOptions/default_vendorOptions"
import { ISiteConfig } from "../../types"

export const _cf_bir_accessPointModal = (fractions: string[]): ISiteConfig["accessPointModal"] => ({
	enable: true,
	width: "750px",
	method: "upsert",
	// stationExternalKeyName: "ImportID",
	containerExternalKeyName: "ImportID",
	containerExternalKeyValueGenerator: () => `B${nanoid(5)}`,
	// singleContainer: true,
	defaultStatus: "ACTIVE",
	fieldList: [
		{
			inputType: "text",
			keyType: "name",
			key: "name",
			placeholder: "headers.name",
			required: false,
		},
		{
			keyType: "property",
			key: "fraction",
			placeholder: "headers.properties.fraction",
			required: true,
			inputType: "dropdown",
			options: fractions.map(f => ({
				name: `fraction.${f}`,
				value: f,
			})),
		},
		/*
		Can't be used before https://app.clickup.com/t/1mf20wq is finished
		{
			keyType: "status",
			key: "status",
			placeholder: "headers.status",
			required: false,
			inputType: "dropdown",
			options: ACCESS_POINT_STATUSES.map(v => ({
				name: `status.${v}`,
				value: v,
			})),
		}, */
		{
			keyType: "property",
			key: "hatchTypeCode",
			placeholder: "headers.hatchSize",
			required: false,
			inputType: "dropdown",
			options: ["L", "S", "LS"].map(v => ({
				name: `stationModal.hatches.${v}`,
				value: v,
			})),
		},
		{
			keyType: "property",
			key: "vendor",
			placeholder: "stationModal.vendor",
			required: false,
			inputType: "dropdown",
			translate: false,
			options: default_vendorOptions_inlet.map(v => ({
				name: v.value,
				value: v.value,
			})),
		},
	],
})

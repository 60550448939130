import { SVGProps } from 'react'

export const MalingLimLakkIcon = ({
  fill = 'black',
  width = '361',
  height = '353',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 361 353"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.467 151.272C145.149 151.272 138.829 151.064 132.567 150.665V189.068C132.567 199.504 124.105 207.965 113.669 207.965C103.233 207.965 94.772 199.504 94.772 189.068V182.768C94.772 175.811 89.1307 170.171 82.1733 170.171C75.216 170.171 69.5747 175.811 69.5747 182.768V217.415C69.5747 229.591 59.704 239.461 47.528 239.461C35.3507 239.461 25.48 229.591 25.48 217.415V88.28C25.48 44.7654 88.7573 25.288 151.467 25.288C214.176 25.288 277.451 44.7654 277.451 88.28C277.451 131.795 214.176 151.272 151.467 151.272V151.272ZM302.645 94.9147V88.3227C302.645 88.308 302.647 88.2933 302.647 88.28C302.647 32.5093 238.575 0.0906623 151.467 0.0906623C64.3573 0.0906623 0.283325 32.5093 0.283325 88.28V264.657C0.283325 320.428 64.3573 352.847 151.467 352.847C238.575 352.847 302.647 320.428 302.647 264.657C302.647 264.64 302.645 264.623 302.645 264.605V259.044C271.137 258.715 231.844 237.765 192.501 198.423L183.591 189.517L201.409 171.699L210.316 180.609C244.245 214.532 278.332 233.429 302.645 233.647V132.829C333.725 171.663 343.613 207.665 326.129 225.149C320.227 231.048 312.16 233.732 302.645 233.647V259.044C302.899 259.048 303.157 259.076 303.409 259.076C319.257 259.076 333.105 253.804 343.944 242.965C375.797 211.115 359.472 153.299 302.645 94.9147"
        fill={fill}
      />
    </svg>
  )
}

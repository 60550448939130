import { useState, useEffect, useMemo } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { get, set, isEqual } from "lodash"

export function useKeyPress(ref: any, targetKey: string) {
	const [keyPressed, setKeyPressed] = useState(false)

	useEffect(() => {
		function downHandler({ key }: any) {
			if (key === targetKey) {
				setKeyPressed(true)
			}
		}

		function upHandler({ key }: any) {
			if (key === targetKey) {
				setKeyPressed(false)
			}
		}

		if (ref.current) {
			const elem = ref.current
			elem.addEventListener("keydown", downHandler, true)
			elem.addEventListener("keyup", upHandler)
			return () => {
				elem.removeEventListener("keydown", downHandler)
				elem.removeEventListener("keyup", upHandler)
			}
		}
	}, [ref, targetKey])

	return keyPressed
}

export function useFocus(ref: any) {
	const [focused, setFocused] = useState(false)

	function focusHandler() {
		setFocused(true)
	}

	function blurHandler(e: any) {
		if (e.relatedTarget && e.relatedTarget.className === "ReactVirtualized__Grid ReactVirtualized__List") {
			e.target.focus()
		} else {
			setFocused(false)
		}
	}

	useEffect(() => {
		if (ref) {
			const elem = ref.current
			elem.addEventListener("focus", focusHandler)
			elem.addEventListener("blur", blurHandler)
			return () => {
				elem.removeEventListener("focus", focusHandler)
				elem.removeEventListener("blur", blurHandler)
			}
		}
	}, [ref])

	return focused
}

function toURLSearchParams(obj: any) {
	const sp = new URLSearchParams()
	for (let [key, value] of Object.entries(obj)) {
		sp.set(key, JSON.stringify(value))
	}
	return sp
}

function fromURLSearchParams(search: any) {
	const sp: any = new URLSearchParams(search)
	const ret: any = {}
	for (let [key, value] of sp.entries()) {
		// non-json params are not set by us, ignore
		try {
			ret[key] = JSON.parse(value)
		} catch { }
	}
	return ret
}

const cache = {}
export function useQueryParam(key: string, defaultValue: any) {
	const location = useLocation()
	const history = useHistory()

	const searchParams = useMemo(() => fromURLSearchParams(location.search), [
		location.search,
	])

	const currentValue = get(searchParams, key, defaultValue)
	let cachedValue = get(cache, key)

	if (!isEqual(cachedValue, currentValue)) {
		set(cache, key, currentValue)
		cachedValue = currentValue
	}

	const setCurrentValue = (newValue: any) => {
		const current = fromURLSearchParams(location.search)
		set(current, key, newValue)
		const sp = toURLSearchParams(current)
		history.replace({
			search: `?${sp.toString()}`,
		})
	}

	return [cachedValue, setCurrentValue]
}

export function stringifyQuery(obj: any) {
	const searchParams = new URLSearchParams(window.location.search)
	for (let key in obj) {
		searchParams.set(key, JSON.stringify(obj[key]))
	}
	return `?${searchParams.toString()}`
}

import { indexOf } from "lodash"
import { FC, useMemo, useState } from "react"
import {
	formatNumberWithDecimalPlaces,
	formatWithThousandSeperator,
} from "../../../Utils/formatFunctions"

const sortData = (data: (string | number)[][], sort: "des" | "asc", sortIndex: number) => {
	const sorted = [...data].sort((a, b) => {
		if (sort === "des") return a[sortIndex] < b[sortIndex] ? 1 : -1
		return a[sortIndex] < b[sortIndex] ? -1 : 1
	})
	return sorted
}

const formatCell = (cell: string | number, formatThousands?: boolean, decimalPlaces?: number) => {
	if (typeof cell === "number") {
		const value = decimalPlaces
			? formatNumberWithDecimalPlaces(cell, decimalPlaces)
			: Math.round(cell)
		return formatThousands ? formatWithThousandSeperator(value) : value
	}

	return cell
}

export const List: FC<{
	headers: string[]
	data: (string | number)[][]
	defaultSortHeader?: string
	defaultSorting?: "des" | "asc"
	showTotal?: boolean
	totalCalculationRowList?: boolean[]
	formatThousands?: boolean
	decimalPlaces?: {
		[columnIndex: number]: number
	}
	suffix?: {
		[columnIndex: number]: string
	}
}> = ({
	headers,
	data,
	defaultSortHeader = headers[0],
	defaultSorting = "des",
	showTotal,
	totalCalculationRowList,
	formatThousands,
	decimalPlaces = {},
	suffix = {},
}) => {
	const sortingIndex = indexOf(headers, defaultSortHeader)

	const [sorting, setSorting] = useState<[number, "des" | "asc"]>([sortingIndex, defaultSorting])
	const sortedData = useMemo(() => sortData(data, sorting[1], sorting[0]), [data, sorting])

	const changeSorting = (index: number) => {
		const sortDir = sorting[0] === index ? (sorting[1] === "asc" ? "des" : "asc") : "des"
		setSorting([index, sortDir])
	}

	return (
		<div className="table-tile">
			<div
				className="header"
				style={{
					gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
				}}
			>
				{headers.map((h, i) => {
					return (
						<p key={`header${i}`} title={h} onClick={() => changeSorting(i)}>
							{h}
							{sorting[0] === i ? (sorting[1] === "asc" ? " ↑" : " ↓") : ""}
						</p>
					)
				})}
			</div>
			<div className="body">
				{sortedData.map((row, i) => (
					<div
						key={`row${i}`}
						className="row"
						style={{
							gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
						}}
					>
						{row.map((cell, i) => (
							<p key={`cell${i}`} title={`${cell}` || ""}>
								{formatCell(cell, formatThousands, decimalPlaces[i]) + (suffix[i] || "")}
							</p>
						))}
					</div>
				))}
				{showTotal && (
					<div
						className="total"
						style={{
							gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
						}}
					>
						<b>Total</b>
						{totalCalculationRowList?.map((cell, i) => {
							if (!i) return null
							const total = sortedData.reduce((prev, num) => {
								return prev + Number(num[i] || 0)
							}, 0)
							return (
								<b key={`cell${i}`} title={`${cell}` || ""}>
									{formatCell(total, formatThousands, decimalPlaces[i]) + (suffix[i] || "")}
								</b>
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
}

import { match } from "ts-pattern"
import { useTranslation } from "../../../Contexts"
import { EntityType, IRow } from "../../../Resources/types"

export const DrawerTitle = ({ type: typeUrl, data }: { type: EntityType; data: IRow }) => {
	const { trans } = useTranslation()

	const type = match(typeUrl)
		.with("customers", () => "customer")
		.with("inlets", () => "inlet")
		.with("points", "containers", () =>
			match(data.type as "ACCESS_PARENT" | "ACCESS_POINT" | "GROUP" | "CONTAINER" | "TERMINAL")
				.with("ACCESS_PARENT", () => "station")
				.with("ACCESS_POINT", () => (data?.parentTypeGroup ? "inlet" : "accessPoint"))
				.with("CONTAINER", () => "container")
				.with("GROUP", () => "accessPoint")
				.with("TERMINAL", () => "terminal")
				.otherwise(() => "")
		)
		.with("containers", () => "accessPoint")
		.with("movableContainers", () => "container")
		.with("operators", () => "operator")
		.with("allocations", () => "allocation")
		.otherwise(() => "")

	if (!type) return null

	return (
		<div className="title">
			<label>{trans(type)}</label>
		</div>
	)
}

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { df_input_name } from "../../_drawerFragments/inputs"

export const bir_drawerContent_movableContainers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name(),
			{
				updater: "externalKey",
				key: "externalKeys.tag",
				header: "headers.externalTag",
				permission: "read",
				type: "text",
			},
			{
				updater: "externalKey",
				key: "externalKeys.serviceGuid",
				header: "headers.externalKeys.serviceGuid",
				permission: "read",
				type: "text",
			},
		],
	},
	pointEditorDrawer: null,
	map: null,
}

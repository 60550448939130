/**
 * @description
 * Getting container types by array of container names (keys)
 * ex. ["bin40", "container1000", ...]
 */
/* export const getContainerTypes = (
	containerTypes: containerTypeNames[]
): Partial<allContainerTypes> =>
	allContainerTypes.map(act => containerTypes.find(ct => act.name === ct)).filter(c => !!c)
 */
export const getContainerTypes = (containerTypes: containerTypeNames[]) =>
	containerTypes.map(ct => allContainerTypes.find(act => act.name === ct)!)

export type IContainerTypes = typeof allContainerTypes[number]
export type containerTypeNames = typeof allContainerTypes[number]["name"]

/**
 * @description
 * Contains all container types possible. Other configurations are inheriting from this
 * by using getContainerTypes function.
 */
export const allContainerTypes = [
	{
		name: "bin40",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bin40",
			},
			{
				type: "property",
				key: "containerType",
				value: "bin",
			},
			{
				type: "property",
				key: "volume",
				value: "40",
			},
		],
	},
	{
		name: "bin140",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bin140",
			},
			{
				type: "property",
				key: "containerType",
				value: "bin",
			},
			{
				type: "property",
				key: "volume",
				value: "140",
			},
		],
	},
	{
		name: "bin240",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bin240",
			},
			{
				type: "property",
				key: "containerType",
				value: "bin",
			},
			{
				type: "property",
				key: "volume",
				value: "240",
			},
		],
	},
	{
		name: "bin360",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bin360",
			},
			{
				type: "property",
				key: "containerType",
				value: "bin",
			},
			{
				type: "property",
				key: "volume",
				value: "360",
			},
		],
	},
	{
		name: "bin660",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bin660",
			},
			{
				type: "property",
				key: "containerType",
				value: "bin",
			},
			{
				type: "property",
				key: "volume",
				value: "660",
			},
		],
	},
	{
		name: "bin770",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bin770",
			},
			{
				type: "property",
				key: "containerType",
				value: "bin",
			},
			{
				type: "property",
				key: "volume",
				value: "770",
			},
		],
	},
	{
		name: "bin1000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bin1000",
			},
			{
				type: "property",
				key: "containerType",
				value: "bin",
			},
			{
				type: "property",
				key: "volume",
				value: "1000",
			},
		],
	},
	{
		name: "drum209",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "drum209",
			},
			{
				type: "property",
				key: "containerType",
				value: "drum",
			},
			{
				type: "property",
				key: "volume",
				value: "209",
			},
		],
	},
	{
		name: "bag",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bag",
			},
			{
				type: "property",
				key: "containerType",
				value: "bag",
			},
		],
	},
	{
		name: "bag100",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bag100",
			},
			{
				type: "property",
				key: "containerType",
				value: "bag",
			},
			{
				type: "property",
				key: "volume",
				value: "100",
			},
		],
	},
	{
		name: "bag125",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bag125",
			},
			{
				type: "property",
				key: "containerType",
				value: "bag",
			},
			{
				type: "property",
				key: "volume",
				value: "125",
			},
		],
	},
	{
		name: "bag1000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "bag1000",
			},
			{
				type: "property",
				key: "containerType",
				value: "bag",
			},
			{
				type: "property",
				key: "volume",
				value: "1000",
			},
		],
	},
	{
		name: "container5000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "container5000",
			},
			{
				type: "property",
				key: "containerType",
				value: "container",
			},
			{
				type: "property",
				key: "volume",
				value: "5000",
			},
		],
	},
	{
		name: "container10000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "container10000",
			},
			{
				type: "property",
				key: "containerType",
				value: "container",
			},
			{
				type: "property",
				key: "volume",
				value: "10000",
			},
		],
	},
	{
		name: "compressor10000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "compressor10000",
			},
			{
				type: "property",
				key: "containerType",
				value: "compressor",
			},
			{
				type: "property",
				key: "volume",
				value: "10000",
			},
		],
	},
	{
		name: "compressor14000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "compressor14000",
			},
			{
				type: "property",
				key: "containerType",
				value: "compressor",
			},
			{
				type: "property",
				key: "volume",
				value: "14000",
			},
		],
	},
	{
		name: "compressor15000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "compressor15000",
			},
			{
				type: "property",
				key: "containerType",
				value: "compressor",
			},
			{
				type: "property",
				key: "volume",
				value: "15000",
			},
		],
	},
	{
		name: "compressor16000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "compressor16000",
			},
			{
				type: "property",
				key: "containerType",
				value: "compressor",
			},
			{
				type: "property",
				key: "volume",
				value: "16000",
			},
		],
	},
	{
		name: "compressor18000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "compressor18000",
			},
			{
				type: "property",
				key: "containerType",
				value: "compressor",
			},
			{
				type: "property",
				key: "volume",
				value: "18000",
			},
		],
	},
	{
		name: "compressor20000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "compressor20000",
			},
			{
				type: "property",
				key: "containerType",
				value: "compressor",
			},
			{
				type: "property",
				key: "volume",
				value: "20000",
			},
		],
	},
	{
		name: "openhookcontainer22000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "openhookcontainer22000",
			},
			{
				type: "property",
				key: "containerType",
				value: "hookcontainer",
			},
			{
				type: "property",
				key: "volume",
				value: "22000",
			},
		],
	},
	{
		name: "case1000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "case1000",
			},
			{
				type: "property",
				key: "containerType",
				value: "case",
			},
			{
				type: "property",
				key: "volume",
				value: "1000",
			},
		],
	},
	{
		name: "elcage1500",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "elcage1500",
			},
			{
				type: "property",
				key: "containerType",
				value: "elcage",
			},
			{
				type: "property",
				key: "volume",
				value: "1500",
			},
		],
	},
	{
		name: "ibc1000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "ibc1000",
			},
			{
				type: "property",
				key: "containerType",
				value: "ibc",
			},
			{
				type: "property",
				key: "volume",
				value: "1000",
			},
		],
	},
	{
		name: "closedibc1000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "closedibc1000",
			},
			{
				type: "property",
				key: "containerType",
				value: "closedibc",
			},
			{
				type: "property",
				key: "volume",
				value: "1000",
			},
		],
	},
	{
		name: "redbox",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "redbox",
			},
			{
				type: "property",
				key: "containerType",
				value: "redbox",
			},
		],
	},
	{
		name: "palletframe1000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "palletframe1000",
			},
			{
				type: "property",
				key: "containerType",
				value: "palletframe",
			},
			{
				type: "property",
				key: "volume",
				value: "1000",
			},
		],
	},
	{
		name: "palletbox800",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "palletbox800",
			},
			{
				type: "property",
				key: "containerType",
				value: "palletbox",
			},
			{
				type: "property",
				key: "volume",
				value: "800",
			},
		],
	},
	{
		name: "palletbox500",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "palletbox500",
			},
			{
				type: "property",
				key: "containerType",
				value: "palletbox",
			},
			{
				type: "property",
				key: "volume",
				value: "500",
			},
		],
	},
	{
		name: "specialcontainer",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "specialcontainer",
			},
			{
				type: "property",
				key: "containerType",
				value: "specialcontainer",
			},
		],
	},
	{
		name: "mrfillcabinet140",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "mrfillcabinet140",
			},
			{
				type: "property",
				key: "containerType",
				value: "mrfillcabinet",
			},
			{
				type: "property",
				key: "volume",
				value: "140",
			},
		],
	},
	{
		name: "mrfillcabinet240",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "mrfillcabinet240",
			},
			{
				type: "property",
				key: "containerType",
				value: "mrfillcabinet",
			},
			{
				type: "property",
				key: "volume",
				value: "240",
			},
		],
	},
	{
		name: "pottingercompressor10000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "pottingercompressor10000",
			},
			{
				type: "property",
				key: "containerType",
				value: "pottingercompressor",
			},
			{
				type: "property",
				key: "volume",
				value: "10000",
			},
		],
	},
	{
		name: "pottingercompressor12000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "pottingercompressor12000",
			},
			{
				type: "property",
				key: "containerType",
				value: "pottingercompressor",
			},
			{
				type: "property",
				key: "volume",
				value: "12000",
			},
		],
	},
	{
		name: "pottingercompressor14000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "pottingercompressor14000",
			},
			{
				type: "property",
				key: "containerType",
				value: "pottingercompressor",
			},
			{
				type: "property",
				key: "volume",
				value: "14000",
			},
		],
	},
	{
		name: "pottingercompressor16000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "pottingercompressor16000",
			},
			{
				type: "property",
				key: "containerType",
				value: "pottingercompressor",
			},
			{
				type: "property",
				key: "volume",
				value: "16000",
			},
		],
	},
	{
		name: "pottingercompressor18000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "pottingercompressor18000",
			},
			{
				type: "property",
				key: "containerType",
				value: "pottingercompressor",
			},
			{
				type: "property",
				key: "volume",
				value: "18000",
			},
		],
	},
	{
		name: "pottingercompressor20000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "pottingercompressor20000",
			},
			{
				type: "property",
				key: "containerType",
				value: "pottingercompressor",
			},
			{
				type: "property",
				key: "volume",
				value: "20000",
			},
		],
	},
	{
		name: "carton",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "carton",
			},
			{
				type: "property",
				key: "containerType",
				value: "carton",
			},
		],
	},
	{
		name: "ball",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "ball",
			},
			{
				type: "property",
				key: "containerType",
				value: "ball",
			},
		],
	},
	{
		name: "liftcontainer4500",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "liftcontainer4500",
			},
			{
				type: "property",
				key: "containerType",
				value: "liftcontainer",
			},
			{
				type: "property",
				key: "volume",
				value: "4.5m3",
			},
		],
	},
	{
		name: "liftcontainer6000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "liftcontainer6000",
			},
			{
				type: "property",
				key: "containerType",
				value: "liftcontainer",
			},
			{
				type: "property",
				key: "volume",
				value: "6m3",
			},
		],
	},
	{
		name: "liftcontainer8000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "liftcontainer8000",
			},
			{
				type: "property",
				key: "containerType",
				value: "liftcontainer",
			},
			{
				type: "property",
				key: "volume",
				value: "8m3",
			},
		],
	},
	{
		name: "liftcontainer10000",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "liftcontainer10000",
			},
			{
				type: "property",
				key: "containerType",
				value: "liftcontainer",
			},
			{
				type: "property",
				key: "volume",
				value: "10m3",
			},
		],
	},
	{
		name: "undergroundContainer08m3",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "undergroundContainer08m3",
			},
			{
				type: "property",
				key: "containerType",
				value: "undergroundContainer",
			},
			{
				type: "property",
				key: "volume",
				value: "0.8m3",
			},
			// {
			// 	type: "property",
			// 	key: "producer",
			// 	value: "Molok",
			// },
			// {
			// 	type: "property",
			// 	key: "vendor",
			// 	value: "Strømbergs",
			// },
		],
	},
	{
		name: "undergroundContainer3m3",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "undergroundContainer3m3",
			},
			{
				type: "property",
				key: "containerType",
				value: "undergroundContainer",
			},
			{
				type: "property",
				key: "volume",
				value: "3m3",
			},
			// {
			// 	type: "property",
			// 	key: "producer",
			// 	value: "Molok",
			// },
			// {
			// 	type: "property",
			// 	key: "vendor",
			// 	value: "Strømbergs",
			// },
		],
	},
	{
		name: "undergroundContainer5m3",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "undergroundContainer5m3",
			},
			{
				type: "property",
				key: "containerType",
				value: "undergroundContainer",
			},
			{
				type: "property",
				key: "volume",
				value: "5m3",
			},
			// {
			// 	type: "property",
			// 	key: "producer",
			// 	value: "Molok",
			// },
			// {
			// 	type: "property",
			// 	key: "vendor",
			// 	value: "Strømbergs",
			// },
		],
	},
	{
		name: "container6m3",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "containerontainer5m3",
			},
			{
				type: "property",
				key: "containerType",
				value: "containerontainer",
			},
			{
				type: "property",
				key: "volume",
				value: "6m3",
			},
		],
	},
	{
		name: "container8m3",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "container5m3",
			},
			{
				type: "property",
				key: "containerType",
				value: "container",
			},
			{
				type: "property",
				key: "volume",
				value: "8m3",
			},
		],
	},
	{
		name: "comprimator",
		values: [
			{
				type: "property",
				key: "containerOptionID",
				value: "comprimator",
			},
			{
				type: "property",
				key: "containerType",
				value: "comprimator",
			},
		],
	},
] as const

import {
	IFilterFetch,
	IFilterSearchOnceInputFetch,
	IFilterSearchSelectFetch,
} from "../../components/EventsFilter/types"

export function isIFilterSearchSelectFetch(arg: IFilterFetch): arg is IFilterSearchSelectFetch {
	return "options" in arg.control && "filter" in arg.control && "fetch" in arg.control.filter
}

export function isIFilterSearchOnceInputFetch(
	arg: IFilterFetch
): arg is IFilterSearchOnceInputFetch {
	return "filter" in arg.control && "fetch" in arg.control.filter && !("options" in arg.control)
}

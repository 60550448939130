import { SVGProps } from 'react'

export const SpraybokserIcon = ({
  fill = 'black',
  width = '116',
  height = '280',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 116 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77 19V38H86.5H96V48V58L57.8 57.8L19.5 57.5V48V38.5L28.8 38.2L38 37.9V19V0H57.5H77V19ZM100.2 82.4C105.6 89.1 110 98.9 112.6 110C114.2 116.7 114.4 125.6 114.7 198.7L115.1 280H58.1999H1.19995L0.499951 207.6C0.0999512 167.7 0.0999512 131.2 0.499951 126.3C2.19995 104.8 7.59995 90.1 17.8 79.2L20.8 76L58.1 76.2L95.4 76.5L100.2 82.4Z"
        fill={fill}
      />
      <path
        d="M51.5 13.5C48.2 16.7 48.2 21.9 51.3 25.5C56.3 31.4 66.4 27.4 66.4 19.6C66.4 11.8 57 8.00002 51.5 13.5Z"
        fill={fill}
      />
    </svg>
  )
}

import { ReactNode } from "react"
import styles from "./FlexBox.module.css"
import { Property } from "csstype"

export const FlexBox = ({
	columnGap,
	rowGap,
	asColumns,
	alignItems,
	justifyContent,
	className,
	children,
}: {
	columnGap?: number
	rowGap?: number
	alignItems?: Property.AlignItems
	justifyContent?: Property.JustifyContent
	asColumns?: true
	className?: string
	children: ReactNode
}) => (
	<div
		className={`${styles.flexbox} ${className}`}
		style={{
			columnGap: `${columnGap}rem`,
			rowGap: `${rowGap}rem`,
			flexDirection: asColumns ? "column" : undefined,
			alignItems,
			justifyContent,
		}}
	>
		{children}
	</div>
)

import { gql } from "apollo-boost"

export type GetAllTerminalsObject = {
	store: {
		accessPoints: {
			id: string
			name: string
			[key: string]: any
		}[]
	}
}

export const GET_ALL_TERMINALS = (extraValues?: string[]) => gql`
	query GET_ALL_TERMINALS {
		store {
			accessPoints(type: "TERMINAL") {
				id,
				name,
				${extraValues?.join(",\n") || ""}
			}
		}
	}
`

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import {
	df_input_address,
	df_input_description,
	df_input_gps,
	df_input_name,
	df_input_terminal,
} from "../../_drawerFragments/inputs"

export const ihm_drawerContent_points: IDrawerContent = {
	image: { comp: "image", order: 1 },
	customersAllocated: {
		comp: "customersAllocated",
		config: { dataPath: "customersAggregate.count" },
		order: 2,
	},
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name({ header: "stationModal.stationName" }),
			df_input_terminal(),
			df_input_address(),
			df_input_gps(),
			// df_input_status(),
			df_input_description(),
		],
	},
}

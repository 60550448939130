export const orkla_containertypes = () => [
	{
		name: "KSG900",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KSG900",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KSG",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "900",
			},
		],
	},
	{
		name: "KSG2100",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KSG2100",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KSG",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "2100",
			},
		],
	},
	{
		name: "KDW900",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KDW900",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KDW",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "900",
			},
		],
	},
	{
		name: "KDW2100",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KDW2100",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KDW",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "2100",
			},
		],
	},
	{
		name: "KKS900",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KKS900",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KKS",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "900",
			},
		],
	},
	{
		name: "KKS2100",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KKS2100",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KKS",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "2100",
			},
		],
	},
	{
		name: "KHS900",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KHS900",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KHS",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "900",
			},
		],
	},
	{
		name: "KHS2100",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KHS2100",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KHS",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "2100",
			},
		],
	},
	{
		name: "KLD900",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KLD900",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KLD",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "900",
			},
		],
	},
	{
		name: "KLD2100",
		values: [
			{
				type: "property" as const,
				key: "containerOptionID",
				value: "KLD2100",
			},
			{
				type: "property" as const,
				key: "containerType",
				value: "KLD",
			},
			{
				type: "property" as const,
				key: "volume",
				value: "2100",
			},
		],
	},
]

import { SVGProps } from 'react'

export const MetallembalasjeIcon = ({
  fill = 'black',
  width = '177',
  height = '358',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 177 358"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.4493 79.5973C140.285 59.2107 169.421 28.9333 161.536 11.9707C153.647 -4.98932 111.715 -2.21332 67.8813 18.1733C24.0479 38.56 -5.09206 68.8387 2.79461 85.8013C10.6839 102.761 52.616 99.9853 96.4493 79.5973Z"
        fill={fill}
      />
      <path
        d="M88.464 118.177H0.276001V193.768H176.653V118.177H88.464"
        fill={fill}
      />
      <path
        d="M0.276001 357.547H176.653V269.359H0.276001V357.547Z"
        fill={fill}
      />
      <path
        d="M0.276001 244.161H176.653V218.965H0.276001V244.161Z"
        fill={fill}
      />
    </svg>
  )
}

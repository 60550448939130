import { SVGProps } from "react"

export const HardplastIcon = ({
	fill = "black",
	width = "530",
	height = "662",
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="530"
			height="662"
			viewBox="0 0 530 662"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M49 661.311V421.312H481V661.311H529V277.312L481 241.311V172.811C462.5 -57.1865 67 -55.1866 49 172.311V241.311L1 277.312V661.311H49ZM49 301.312V373.312H145C141.115 259.829 136.939 200.138 118 116.311C104.128 137.583 99.0932 151.498 97 181.311V265.312L49 301.312ZM241 373.312V50.3116C203.64 55.8828 184.568 61.0392 158 78.3115C176.923 142.554 190.538 227.915 193 373.312H241ZM288.5 373.312V50.3116C325.86 55.8828 344.931 61.0392 371.5 78.3115C352.577 142.554 338.962 227.915 336.5 373.312H288.5ZM481 301.312V373.312H385C388.885 259.829 393.061 200.138 412 116.311C425.872 137.583 430.907 151.498 433 181.311V265.312L481 301.312Z"
				fill={fill}
			/>
			<path
				d="M481 241.311L529 277.312V661.311H481V421.312H49V661.311H1V277.312L49 241.311M481 241.311C481 241.311 481 198.813 481 172.811M481 241.311V172.811M481 172.811C462.5 -57.1865 67 -55.1866 49 172.311M49 241.311C49 241.311 49 190.313 49 172.311M49 241.311V172.311M241 50.3116C241 176.451 241 373.312 241 373.312M241 50.3116C203.64 55.8828 184.568 61.0392 158 78.3115C176.923 142.554 190.538 227.915 193 373.312M241 50.3116V373.312M241 373.312C241 373.312 211.745 373.312 193 373.312M241 373.312H193M49 373.312V301.312L97 265.312V181.311C99.0932 151.498 104.128 137.583 118 116.311C136.939 200.138 141.115 259.829 145 373.312H49ZM288.5 50.3116V373.312H336.5C338.962 227.915 352.577 142.554 371.5 78.3115C344.931 61.0392 325.86 55.8828 288.5 50.3116ZM481 373.312V301.312L433 265.312V181.311C430.907 151.498 425.872 137.583 412 116.311C393.061 200.138 388.885 259.829 385 373.312H481Z"
				stroke={fill}
			/>
		</svg>
	)
}

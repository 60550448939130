import { IKeyValue, IKeyValueObj } from "../Utils"

/**
 *
 * @description
 * Use it with array sort function
 */
export const sortAscObjByField = (field: string) => (a: any, b: any) => {
	if (a[field] < b[field]) {
		return -1
	}
	if (a[field] > b[field]) {
		return 1
	}
	return 0
}

/**
 *
 * @description
 * Use it with array sort function
 */
export const sortDescObjByField = (field: string) => (a: any, b: any) => {
	if (b[field] < a[field]) {
		return -1
	}
	if (b[field] > a[field]) {
		return 1
	}
	return 0
}

export const keyValueArrayToObject = (arr: IKeyValue[] | undefined | null): IKeyValueObj =>
	(arr?.reduce((prev, extKey) => ({ ...prev, [extKey.key]: extKey.value }), {}) ||
		{}) as IKeyValueObj

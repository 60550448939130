import React from 'react'
import { useHistory } from 'react-router-dom'

import { useTranslation } from '../Contexts'


export function customerCRMKeyValue(crm: any) {
  const { pk, value, score } = crm
  if (!(pk && value)) {
    console.warn('bad customer crm received', crm)
    return
  }

  return {
    type: 'customer-crm-key',
    id: pk,
    name: value,
    score: score || 0,
  }
}

const CustomerCRMOption = ({ id, name }: any) => {
  const { trans } = useTranslation()
  const history = useHistory()

  return (
    <div onClick={() => history.push(`/resources/customers/${id}`)}>
      <h1>{trans('headers.crmId')}</h1>
      <div className="row">
        <span>{name}</span>
      </div>
    </div>
  )
}

export default CustomerCRMOption

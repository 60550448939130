import { CSSProperties, FC, MutableRefObject, useEffect, useRef } from "react"
import create from "zustand"
import { combine } from "zustand/middleware"
import { useTranslation } from "../Contexts"
import "./ContextMenu.css"

export type TContextMenuItem = {
	label: string
	translateLabel?: boolean
	fn: (...args: any[]) => any
	className?: string
	style?: CSSProperties
}

export const useContextMenu = create(
	combine(
		{
			show: false,
			ref: null as unknown as MutableRefObject<HTMLDivElement | null>,
			pos: { x: 0, y: 0 },
			items: [] as TContextMenuItem[],
		},
		set => ({
			openContextMenu: (e: React.MouseEvent, items: TContextMenuItem[]) => {
				set(({ ref }) => {
					e.preventDefault()
					ref?.current?.focus()
					return { show: true, items, pos: { x: e.pageX + 2, y: e.pageY + 2 } }
				})
			},
			/** Requires to close manually or by other means */
			openContextMenuByPos: (pos: { x: number; y: number }, items: TContextMenuItem[]) => {
				set({ show: true, items, pos: { x: pos.x + 2, y: pos.y + 2 } })
			},
			closeContextMenu: () => set(() => ({ show: false })),
			setRef: (ref: MutableRefObject<HTMLDivElement | null>) => set({ ref }),
		})
	)
)

export const ContextMenu: FC<{}> = () => {
	const { trans } = useTranslation()
	const { show, pos, items, closeContextMenu, setRef } = useContextMenu()
	const ref = useRef<HTMLDivElement | null>(null)
	useEffect(() => {
		setRef(ref)
	}, [ref, setRef])
	return (
		<div
			ref={ref}
			className="contextMenu"
			style={{
				top: pos.y,
				left: pos.x,
			}}
			tabIndex={0} // needed for onBlur to work
			onBlur={() => {
				closeContextMenu()
			}}
		>
			{show &&
				items.map(({ label, translateLabel, fn, style, className }, index) => (
					<div
						key={`contextMenu_item${index}`}
						onClick={() => {
							closeContextMenu()
							fn()
						}}
						style={style}
						className={className}
					>
						{translateLabel ? trans(label) : label}
					</div>
				))}
		</div>
	)
}

import React from "react"
import { IElem, IRow } from "../../Resources/types"
import { Checkbox } from "../Inputs"
import styles from "./DataGridEditable.module.css"

type IMultiSelect = {
	checked: string[]
	setChecked: React.Dispatch<React.SetStateAction<string[]>>
	rows: IRow[]
	elems: IElem[]
	active: boolean
}

export const MultiSelect = ({
	checked,
	setChecked,
	rows,
	elems,
	active,
}: IMultiSelect) => {
	if (!active) {
		return <div className={styles["datagrid-checkboxes"]}></div>
	}

	return (
		<div className={styles["datagrid-checkboxes"]}>
			<label id="header">
				<Checkbox
					type="checkbox"
					checked={checked.length === rows.length && rows.length > 0}
					onChange={() =>
						checked.length === rows.length
							? setChecked([])
							: setChecked(rows.map((r) => r.id))
					}
				></Checkbox>
			</label>
		</div>
	)
}

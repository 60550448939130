import { InputSelect } from "../../UI"

export const ModalDropdown = (props: {
	placeholder?: string
	onSelect: (option: any) => void
	options: {
		name: string
		value: string
		[key: string]: any
	}[]
	dropdownWidth?: number
	dropdownHeight?: number
	error?: boolean
	defaultValue?: { name: string; value: string } | null
}) => {
	const { dropdownWidth: w = 252, dropdownHeight: h = 100 } = props
	return (
		<div className="input-form">
			<InputSelect
				placeholder={props.placeholder}
				label="name"
				defaultValue={props.defaultValue || "none"}
				noSort
				options={props.options}
				onSelect={props.onSelect}
				optionsWidth={w}
				optionsHeight={h}
				error={props.error}
			/>
		</div>
	)
}

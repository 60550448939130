import { parseInvoiceData } from "../../../../Resources/Invoices/data"
import { IListViewConfigInvoices } from "../../../../Resources/types"
import { filterConfigGen } from "../../_drawerFragments/filterConfigGenerator"
import { filterFuzzy, genericDateTimeFilter } from "../../_drawerFragments/filters"
import { default_fetchAll_invoices } from "../queries/default_fetchAll_invoices"

export const default_invoices: IListViewConfigInvoices = {
	defaultSort: { header: "timestampRaw", order: "desc" },
	headers: [
		{ path: "checkbox" },
		{ path: "timestamp" },
		{ path: "orderId" },
		{ path: "visitId" },
		{ path: "name" },
		{ path: "customerClass" },
		{ path: "orgNr" },
		{ path: "postalCode" },
		{ path: "paymentAmountIncludingVAT" },
		{ path: "paymentAmountExcludingVAT" },
		{ path: "paymentType" },
		{ path: "totalBillableWeight" },
		{ path: "customerRepresentativeName" },
		{ path: "referenceText" },
	],
	filters: filterConfigGen([
		genericDateTimeFilter("timestamp"),
		filterFuzzy("id"),
		filterFuzzy("orgNr"),
		filterFuzzy("name"),
		filterFuzzy("postalCode"),
		filterFuzzy("paymentType"),
		filterFuzzy("customerRepresentativeName"),
		filterFuzzy("referenceText"),
	]),
	data: {
		fetchAll: default_fetchAll_invoices,
		fetchAllParse: (data: any) => parseInvoiceData(data),
	},
	overrides: trans => ({
		customerClass: e => trans(`customerClass.${e.customerClass}`),
	}),
}

import { gql } from "@apollo/client/core"

export const ihm_fetchById_operators = () => gql`
	query ACGetOperators($id: String) {
		store {
			data: operators(id: $id) {
				id
				name
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
			}
			customers {
				operator {
					operator {
						id
					}
				}
			}
		}
	}
`

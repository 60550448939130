import create from "zustand"
import { siteConfig, configName } from "../Configs/config"
import { ISiteConfig } from "../Configs/types"

type IUseSiteConfig = {
	siteConfig: ISiteConfig
	setSiteConfig: (_siteConfig: ISiteConfig) => void
}

export const useSiteConfig = create<IUseSiteConfig>(set => ({
	siteConfig: siteConfig(configName),
	setSiteConfig: _siteConfig => set(() => ({ siteConfig: _siteConfig })),
}))

import React from "react"
import { match } from "ts-pattern"
import { SvgDelete } from "../../Icons/Delete"
import { SvgDisable } from "../../Icons/Disable"
import { SvgEnable } from "../../Icons/Enable"
import { SvgRemove } from "../../Icons/Remove"
import styles from "./ActionButton.module.css"

type IActionButton = {
	type: "disable" | "enable" | "delete" | "remove"
	onClick: () => void
	className?: string
	title?: string
}

export const ActionButton = ({
	type,
	onClick,
	className,
	title,
}: IActionButton) => {
	return (
		<span className={styles.svgButton} onClick={onClick} title={title}>
			{match(type)
				.with("disable", () => (
					<SvgDisable className={`${styles.disable} ${className}`} />
				))
				.with("enable", () => (
					<SvgEnable className={`${styles.enable} ${className}`} />
				))
				.with("delete", () => (
					<SvgDelete className={`${styles.delete} ${className}`} />
				))
				.with("remove", () => (
					<SvgRemove className={`${styles.remove} ${className}`} />
				))
				.exhaustive()}
		</span>
	)
}

import { useQuery } from "@apollo/client"
import { gql } from "apollo-boost"
import { useMemo } from "react"
import { useSiteConfig } from "../../States/siteConfig"

export const GET_OPERATORS = () => useSiteConfig.getState().siteConfig.operators.data.fetchAll

export function useGetOperators() {
	const dataMethods = useSiteConfig(state => state.siteConfig.operators.data)
	const {
		loading,
		error,
		data: _data,
		client,
	} = useQuery<{ store: { data: any[] } }>(dataMethods.fetchAll)
	const data = useMemo(() => (_data ? dataMethods.fetchAllParse(_data) : []), [_data, dataMethods])
	return { loading, error, data, client }
}

export const GET_OPERATOR_BY_ID = gql`
	query ACGetOperatorById($id: String) {
		store {
			operators(id: $id) {
				id
				name
			}
		}
	}
`

export type IOperatorByIdRaw = { store: { operators: IOperatorById[] } }

export type IOperatorById = {
	id: string
	name: string
}

export const transformOperator = (data: IOperatorByIdRaw) =>
	data.store.operators?.length ? data.store.operators[0] : ({} as IOperatorById)

export const UPDATE_OPERATOR = gql`
	mutation ACUpdateOperator($input: updateOperatorMutationInput!) {
		updateOperatorMutation(input: $input) {
			primaryKey
			scenarioId
			apiResultCode
			commandProcessError
		}
	}
`

export function renameOperatorPayload(id: string, name: string) {
	return {
		input: {
			wait: true,
			payload: {
				operator: { id },
				updateFields: { name },
			},
		},
	}
}

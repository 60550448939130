import { uniq } from "lodash"
import { Moment } from "moment"
import { localeFromLocalStorage } from "../../../../Contexts/Translation"
import { IEventModified } from "../../types"
import { fetchEventsByPointIds, fetchPointsAndParentsIdsByParentIds } from "./fetch"
import { normalize } from "./prepare"

export const getEventsByPointIds = (pointIds: string[][], dates: { from: Moment; to: Moment }) =>
	pointIds?.length
		? fetchEventsByPointIds(
				// Getting unique point ids from multiple fration selections
				uniq(pointIds.flatMap(p => p)),
				dates
		  ).then(e =>
				e.store?.accessPoints?.length ? normalize(e.store.accessPoints, localeFromLocalStorage) : []
		  )
		: (new Promise(() => []) as Promise<IEventModified[]>)

export const getEventsByParentIds = (pointIds: string[], dates: { from: Moment; to: Moment }) =>
	pointIds?.length
		? fetchPointsAndParentsIdsByParentIds(pointIds).then(ids =>
				fetchEventsByPointIds(ids, dates).then(e =>
					e.store?.accessPoints?.length
						? normalize(e.store.accessPoints, localeFromLocalStorage)
						: []
				)
		  )
		: (new Promise(() => []) as Promise<IEventModified[]>)

import { get, orderBy } from "lodash"
import React from "react"
import { IColumnSizes, IHeader, IRow } from "../../Resources/types"
import { IDGESorter } from "./types"

export const onCheckboxChange = (id: string, checked: string[]) => () => {
	const index = checked.indexOf(id)
	if (index >= 0) {
		checked.splice(index, 1)
		return [...checked]
	} else {
		return [...checked, id]
	}
}

export const sortByHeader =
	(
		header: string,
		sorter: IDGESorter,
		setSorter: React.Dispatch<React.SetStateAction<IDGESorter>>
	) =>
	() => {
		const descending = header === sorter.column ? !sorter.descending : false
		setSorter({
			column: header,
			descending,
			sortFunc: (rows: IRow[]) => {
				return orderBy(rows, [header], [descending ? "desc" : "asc"])
			},
		})
	}

export function calculateTableStyle(
	headers: IHeader[],
	columnSizes: IColumnSizes,
	hasCheckboxes?: boolean
) {
	const columns = [
		...(hasCheckboxes ? ["50px"] : []),
		...headers.map(h => {
			const hint = get(columnSizes, `${h}`, "1fr")
			if (hint.includes("minmax")) {
				return hint
			}
			return `minmax(1rem, ${hint})`
		}),
	]

	return {
		gridTemplateColumns: columns.join(" "),
	}
}

export function defaultSorter(data: IRow[]) {
	return data
}

import { gql } from "apollo-boost"

export const DELETE_CUSTOMER = gql`
	mutation DELETE_CUSTOMER($Id: String!) {
		deleteCustomerMutation(input: { wait: true, payload: { customerId: $Id } }) {
			primaryKey
			commandProcessError
		}
	}
`

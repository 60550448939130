import { SVGProps } from 'react'

export const SkoIcon = ({
  fill = 'black',
  width = '280',
  height = '143',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 280 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.5 7.89999C112.7 12 120.4 15.9 120.7 16.6C120.9 17.2 118.2 23.6 114.6 30.7L108.1 43.7L116.1 47.3L124.1 50.9L130.8 37.4C134.5 30 137.9 24 138.5 24C139 24 143.6 25.8 148.6 28.1L157.8 32.2L151 46.3C146.8 55.1 144.7 60.7 145.4 60.9C146 61.1 149.5 62.8 153.2 64.6L160 68L167 54C170.9 46.3 174.4 40 174.8 40C175.3 40 183.7 43.7 193.6 48.2L211.5 56.3L229 57C243.8 57.7 247.7 58.2 254.7 60.4C265.6 63.9 273.8 70 277.4 77.1C279.7 81.9 280 83.5 280 93.7V105H140H0V67.1V29.2L6.8 28.6C29.1 26.6 43.3 23.4 57.9 17.2C66.1 13.6 83.1 3.29999 84.6 0.899994C84.9 0.299994 85.5 -1.83135e-05 86 0.199982C86.4 0.399982 94.3 3.89999 103.5 7.89999ZM280 133V143H140H0V133V123H140H280V133Z"
        fill={fill}
      />
    </svg>
  )
}

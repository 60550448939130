import { SVGProps } from 'react'

export const PappIcon = ({
  fill = 'black',
  width = '418',
  height = '316',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 418 316"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M343.131 0.988009H153.071V70.28H273.839L343.131 0.988009Z"
        fill={fill}
      />
      <path
        d="M417.148 71.8481L375.529 4.21737L290.201 89.5494L331.817 157.18L417.148 71.8481"
        fill={fill}
      />
      <path
        d="M265.488 95.476H33.386L0.62915 177.365H232.731L265.488 95.476Z"
        fill={fill}
      />
      <path
        d="M269.607 153.02L249.789 202.563H33.386V315.949H269.607V153.02Z"
        fill={fill}
      />
      <path
        d="M327.081 197.552L294.804 145.104V300.2L373.543 221.461V151.088L327.081 197.552"
        fill={fill}
      />
      <path d="M127.873 70.28V0.988009L58.5828 70.28H127.873Z" fill={fill} />
    </svg>
  )
}

import { orderBy } from "lodash"
import { siteConfig } from "../../Configs/config"
import { useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import exportCSV from "../exportCSV"
import { IAction, IColumnSizes, ICSVOptions, IListViewConfig } from "../types"
import DataGrid from "../_components/DataGrid/DataGrid"
import "./Grid.css"

function csvOptions(headers: IListViewConfig["headers"], trans: any): ICSVOptions {
	const filename = `${trans("events")}.csv`
	const fields = headers.map(h => ({
		label: trans(h.name ? h.name : `headers.${h.path}`),
		value: h.path,
	}))

	return { filename, fields }
}

type IGrid = {
	events: any[]
	headers: IListViewConfig["headers"]
	columnSizes: IColumnSizes
}

const Grid = ({ events, headers, columnSizes }: IGrid) => {
	const { trans } = useTranslation()
	const { siteConfig: config } = useSiteConfig()
	const defaultConfig = siteConfig("default")

	const actions: IAction[] = [
		{
			label: "actions.exportCsv",
			func: exportCSV(csvOptions(headers, trans)),
		},
	]

	const overrides = {
		...(defaultConfig.events.overrides ? defaultConfig.events.overrides(trans) : {}),
		...(config.events.overrides ? config.events.overrides(trans) : {}),
	}

	return (
		<DataGrid
			headers={headers}
			rows={orderBy(events, ["ts"], ["desc"])}
			columnSizes={columnSizes}
			actions={actions}
			overrides={overrides}
			allowEmptyField={true}
			// onCellClick={onCellClick}
		/>
	)
}

export default Grid

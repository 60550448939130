import { match } from "ts-pattern"
import { postFunc } from "../../Utils/api"
import { monad } from "../../Utils/fs"
import {
	GET_CUSTOMER_EXT_KEYS_AND_PROPS,
	GET_POINT_EXT_KEYS_AND_PROPS,
	UPDATE_CUSTOMER_EXT_KEYS,
	UPDATE_CUSTOMER_PROPERTIES,
	UPDATE_POINT_EXT_KEYS,
	UPDATE_POINT_PROPERTIES,
} from "./query"
import { IKeyValue } from "./types"

export const saveKeyValueToDb = ({
	id,
	type,
	dataType,
	keyValues,
	okFunc,
	errorFunc,
}: {
	id: string
	type: "customer" | "point"
	dataType: "extKeys" | "properties"
	keyValues: IKeyValue[]
	okFunc: () => void
	errorFunc: () => void
}) =>
	monad(keyValues)
		// Removing records with empty keys
		.map(kvs => kvs.filter(kv => kv.key?.trim()))
		.map(keyValuesWithoutEmpty =>
			// Finding correct apollo mutation call
			postFunc(
				match({ type, dataType })
					.with({ type: "customer", dataType: "extKeys" }, () => UPDATE_CUSTOMER_EXT_KEYS)
					.with({ type: "customer", dataType: "properties" }, () => UPDATE_CUSTOMER_PROPERTIES)
					.with({ type: "point", dataType: "extKeys" }, () => UPDATE_POINT_EXT_KEYS)
					.with({ type: "point", dataType: "properties" }, () => UPDATE_POINT_PROPERTIES)
					.exhaustive(),
				okFunc,
				// Return from api mutation
				// Setting into status error obj
				{
					variables: { id, array: keyValuesWithoutEmpty },
					refetchQueries: [
						{
							query:
								type === "customer"
									? GET_CUSTOMER_EXT_KEYS_AND_PROPS
									: GET_POINT_EXT_KEYS_AND_PROPS,
							variables: {
								id: id,
							},
						},
					],
				},
				errorFunc
			)
		)[0]

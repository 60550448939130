import { gql } from "apollo-boost"

export const GET_ALL_STATIONS = (extraValues?: string[]) => gql`
	query GET_ALL_STATIONS {
		store {
			accessPoints(type: "ACCESS_PARENT") {
				id,
				name,
				${extraValues?.join(",\n") || ""}
			}
		}
	}
`

import { DocumentNode } from "apollo-link"
import { postFunc } from "../Utils/api"
import { generateGQLrequest } from "../Utils/gqlRequestGenerator"
import { UpsertCustomerEntryPayload } from "../Utils/gqlRequestTypes/upsertCustomersMutation"

export const upsertCustomer = ({
	customers,
	refetchQueries,
	onStartCallBack,
	onFailCallBack,
}: {
	customers: UpsertCustomerEntryPayload[]
	refetchQueries?: {
		query: DocumentNode
		variables?: {
			[key: string]: any
		}
	}[]
	onStartCallBack?: () => void
	onFailCallBack?: () => void
}) => {
	const UPSERT_CUSTOMERS_MUTATION = generateGQLrequest({
		type: "upsertCustomersMutation",
		input: {
			wait: true,
			payload: { customers: customers },
		},
	})
	postFunc(
		UPSERT_CUSTOMERS_MUTATION,
		() => {
			onStartCallBack && onStartCallBack()
		},
		{ refetchQueries: refetchQueries || [] },
		() => {
			onFailCallBack && onFailCallBack()
		}
	)
}

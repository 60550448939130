import { DocumentNode } from "apollo-link"
import { fetchFunc, postFunc } from "../../../Utils/api"
import { IRow } from "../../../Resources/types"
import { UPDATE_IDENTITY_STATUS } from "../../../Resources/Customers/data"
import {
	IGetCustomerIdentities,
	IGetCustomerIdentitiesRaw,
	IGetIdentitiesRaw,
	IGetIdentityIdentity,
	IGetIdentityRaw,
	ILocalIdentityWithProps,
} from "../types"
import {
	ADD_IDENT_TO_CUSTOMER,
	CREATE_NEW_IDENTITY,
	genCustomerIdentsPropsUpdate,
	GET_CUSTOMER_IDENTITIES,
	GET_IDENTITIES,
	GET_IDENTITY,
	GET_PARTIAL_IDENTITIES,
	REMOVE_IDENTS_FROM_CUSTOMER,
	SAVE_CUSTOMER_IDENTS_PROPS,
} from "./queries"
import { TIdentityType } from "../IdentitiesHandler"
import { KeyValInput } from "../../../Utils/gqlRequestTypes/generic"

export const fetchCustomerIdentities = (customerId: string) =>
	fetchFunc<IGetCustomerIdentitiesRaw, IGetCustomerIdentities>(
		GET_CUSTOMER_IDENTITIES,
		res =>
			res.data.store.customers?.length
				? res.data.store.customers[0]
				: ({} as IGetCustomerIdentities),
		{ variables: { customerId }, fetchPolicy: "no-cache" }
	)

export const fetchIdentities = (key: string) =>
	fetchFunc<IGetIdentitiesRaw, IGetIdentitiesRaw>(GET_IDENTITIES, res => res.data, {
		variables: { key },
	})

export const fetchPartialIdentities = (key: string, value: string) =>
	fetchFunc<IGetIdentitiesRaw, IGetIdentitiesRaw>(GET_PARTIAL_IDENTITIES, res => res.data, {
		variables: {
			key,
			value: `.*${value
				.split("")
				.map(s => {
					if (!RegExp(/\p{L}|[0-9]|-/gu).test(s)) return ""
					const isNr = !isNaN(parseInt(s))
					return isNr ? s : `[${s.toLowerCase()}${s.toUpperCase()}]`
				})
				.join("")}.*`,
		},
	})

export const fetchIdentity = (id: string) =>
	fetchFunc<IGetIdentityRaw, IGetIdentityIdentity | null>(
		GET_IDENTITY,
		res => (res.data.store.identities?.length ? res.data.store.identities[0] : null),
		{
			variables: { id },
			fetchPolicy: "no-cache",
		}
	)

export const createIdentityForCustomer = ({
	type,
	externalKeys,
	customer,
	okFunc,
	refetchQuery,
	errorFunc,
}: {
	type: TIdentityType
	externalKeys: KeyValInput[]
	customer?: {
		customerId: string
	}

	okFunc: () => void
	refetchQuery: DocumentNode
	errorFunc?: (err: string) => void
}) => {
	postFunc(
		CREATE_NEW_IDENTITY,
		okFunc,
		{
			variables: { type, externalKeys, customer },
			refetchQueries: [
				{
					variables: { id: customer?.customerId },
					query: refetchQuery,
				},
			],
		},
		errorFunc
	)
}

export const postIdentToCustomer = (
	customerId: string,
	identId: string,
	okFunc: () => void,
	refetchQuery: DocumentNode,
	errorFunc?: (err: string) => void
) =>
	postFunc(
		ADD_IDENT_TO_CUSTOMER,
		okFunc,
		{
			variables: { customerId, identId },
			refetchQueries: [
				{
					query: refetchQuery,
					variables: { id: customerId },
				},
			],
		},
		errorFunc
	)

export const removeIdentFromCustomer = (
	identId: string,
	customerId: string,
	okFunc: () => void,
	refetchQuery: DocumentNode
) =>
	postFunc(REMOVE_IDENTS_FROM_CUSTOMER, okFunc, {
		variables: {
			customerId,
			identId,
		},
		refetchQueries: [
			{
				query: refetchQuery,
				variables: { id: customerId },
			},
		],
	})

export const updateCustomerIdentsProps = ({
	idents,
	customerId,
	okFunc,
	errorFunc,
	refetchQuery,
}: {
	idents: { id: string; key: string; value: string }[]
	customerId: string
	okFunc: () => void
	errorFunc: (err: string) => void
	refetchQuery: DocumentNode
}) =>
	postFunc(
		SAVE_CUSTOMER_IDENTS_PROPS(genCustomerIdentsPropsUpdate(customerId, idents)),
		okFunc,
		{
			refetchQueries: [
				{
					query: refetchQuery,
					variables: { id: customerId },
				},
			],
		},
		errorFunc
	)

export type IdentityStatus = "ACTIVE" | "DISABLED"

export const onToggleIdentityStatus =
	(customerId: string, refetch: (id: string) => void, refetchQuery: DocumentNode) =>
	(id: string, status: IdentityStatus) =>
		postFunc(UPDATE_IDENTITY_STATUS, () => refetch(id), {
			variables: {
				input: {
					wait: true,
					payload: {
						identities: [{ id, status: status === "ACTIVE" ? "DISABLED" : "ACTIVE" }],
					},
				},
			},
			refetchQueries: [
				{
					query: refetchQuery,
					variables: { id: customerId },
				},
			],
		})

export const normalizeGetCustomerIdentities = (
	customer: IGetCustomerIdentities
): ILocalIdentityWithProps[] =>
	customer.identities
		? customer.identities.map(i => ({
				id: i.id,
				customerName: customer.name || null,
				customerId: customer.id,
				status: i.status || null,
				properties: i.properties || [],
				externalKeys: i.identity.externalKeys || [],
		  }))
		: ([] as ILocalIdentityWithProps[])

export const normalizeGetIdentity = (ident: IGetIdentityIdentity): ILocalIdentityWithProps => {
	const currIdentity = ident.customer?.identities?.find(i => i.id === ident.id)
	return {
		id: ident.id,
		customerName: ident.customer?.name || null,
		customerId: ident.customer?.id || null,
		status: currIdentity?.status || null,
		properties: currIdentity?.properties ? [...currIdentity.properties] : [],
		externalKeys: ident.externalKeys ? [...ident.externalKeys] : [],
	}
}

export const denormalizeUpdateCustomerIdentsProps = (
	idents: IRow[],
	propsOfInterest: string[]
): {
	id: string
	key: string
	value: any
}[] =>
	idents.flatMap(i =>
		Object.entries(i)
			.filter(([k, v]) => !!propsOfInterest.find(poi => k === poi))
			.map(([key, value]) => ({ id: i.id, key, value }))
	)

import React from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "../../Contexts"
import exportCSV from "../exportCSV"
import { IListViewConfig } from "../types"
import DataGrid from "../_components/DataGrid/DataGrid"

function csvOptions(headers: IListViewConfig["headers"], trans: any) {
	const filename = `${trans("customers")}.csv`
	const fields = headers.map(h => ({
		label: trans(h.name ? h.name : `headers.${h.path}`),
		value: h.path,
	}))

	return { filename, fields }
}

const Grid = (props: any) => {
	const { operators, headers, columnSizes } = props
	const history = useHistory()
	const { trans } = useTranslation()

	const actions = [
		{
			label: "actions.exportCsv",
			func: exportCSV(csvOptions(headers, trans)),
		},
	]

	return (
		<DataGrid
			headers={headers}
			entityType={"operators"}
			columnSizes={columnSizes || {}}
			rows={operators}
			actions={actions}
			allowEmptyField
			onRowClick={(operator: any) =>
				history.replace({
					pathname: `/resources/operators/${operator.id}`,
					search: history.location.search,
				})
			}
		/>
	)
}

export default Grid

import { SVGProps } from "react"

export const LysparerIcon = ({
	fill = "black",
	width = "409",
	height = "673",
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="409"
			height="673"
			viewBox="0 0 409 673"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M120.739 456.5H288.739C302.739 370 392.313 314 406.076 223.5C421.739 120.5 353.239 1 205.239 1C83.2393 1 -13.2607 91 2.73932 223.5C20.2393 322 104.739 359.5 120.739 456.5Z"
				fill={fill}
			/>
			<path d="M288.739 504.5V552.5H120.739V504.5H288.739Z" fill={fill} />
			<path
				d="M120.739 600.5H288.739V624.5L240.739 672.5H168.739L120.739 624.5V600.5Z"
				fill={fill}
			/>
			<path
				d="M120.739 456.5C175.239 456.5 224.739 456.5 288.739 456.5M120.739 456.5C104.739 359.5 20.2393 322 2.73932 223.5C-13.2607 91 83.2393 1 205.239 1C353.239 1 421.739 120.5 406.076 223.5C392.313 314 302.739 370 288.739 456.5M120.739 456.5H288.739M120.739 600.5C186.347 600.5 288.739 600.5 288.739 600.5M120.739 600.5V624.5L168.739 672.5H240.739L288.739 624.5V600.5M120.739 600.5H288.739M288.739 504.5V552.5H120.739V504.5H288.739Z"
				stroke={fill}
			/>
		</svg>
	)
}

import { Link } from "react-router-dom"
import { useMemo } from "react"
import styles from "./DrawerButton.module.css"
import { useTranslation } from "../../../Contexts"
import { stringifyQuery } from "../../../domHooks"
import { Button } from "../.."

type LabelVal = {
	label: string
	value: string
}

export type IFilterQuery = {
	[query: string]: LabelVal[] | undefined
	customerName?: LabelVal[]
	primary?: LabelVal[]
	parent?: LabelVal[]
	pointName?: LabelVal[]
}

// filter: {
// 		customerName?: LabelVal[]
// 		primary?: LabelVal[]
// 		parent?: LabelVal[]
// 	}
// 	page: number
// 	mode: "list"
// }
/**
 *
 * @param text Translatable key in translation object
 */
export const DrawerButton = ({
	type,
	color = "primary",
	text,
	permission = true,
	title,
	onClick,
	pathName,
	filterQuery,
}:
	| {
			type: "link" | "button"
			color?: "primary" | "danger" | "warning"
			text: string
			permission?: boolean
			title?: string
			onClick: () => void
			pathName?: undefined
			filterQuery?: undefined
	  }
	| {
			type: "routerLink"
			color?: "primary" | "danger" | "warning"
			text: string
			permission?: boolean
			title?: string
			pathName: string
			filterQuery?: IFilterQuery
			onClick?: undefined
	  }) => {
	const { trans } = useTranslation()
	const search = useMemo(
		() => (filterQuery ? stringifyQuery({ filter: filterQuery as IFilterQuery }) : undefined),
		[filterQuery]
	)

	return permission ? (
		type === "link" ? (
			<Button
				onClick={onClick}
				type="third"
				className={`${styles.link} ${styles[color]}`}
				title={title}
			>
				{text}
			</Button>
		) : type === "button" ? (
			<Button onClick={onClick} type={color} title={title}>
				{text}
			</Button>
		) : (
			<div className={`${styles.link} ${styles[color]}`}>
				<Link to={{ pathname: pathName as string, search }} title={title}>
					{trans(text)}
				</Link>
			</div>
		)
	) : (
		<></>
	)
}

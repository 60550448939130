import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import {
	df_input_address,
	df_input_gps,
	df_input_name,
	df_input_status,
	df_input_terminal,
	df_input_vendor_station,
} from "../../_drawerFragments/inputs"

export const bir_drawerContent_points: IDrawerContent = {
	image: { comp: "image", order: 1 },
	customersAllocated: {
		comp: "customersAllocated",
		config: { dataPath: "customersAggregate.count" },
		order: 2,
	},
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name({ header: "stationModal.stationName" }),
			{
				updater: "externalKey",
				key: "externalKeys.printedTag",
				header: "headers.id",
				permission: "edit",
				type: "text",
			},
			df_input_terminal(),
			df_input_status(),
			df_input_address(),
			df_input_gps(),
			{
				updater: "property",
				key: "properties.Municipality",
				header: "properties.municipality",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.Oppdragsgiver",
				header: "properties.client",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.owner",
				header: "properties.owner",
				permission: "edit",
				type: "text",
			},
			df_input_vendor_station(),
			{
				updater: "property",
				key: "properties.contact",
				header: "properties.contact",
				permission: "edit",
				type: "text",
			},
		],
	},
	pointEditorDrawer: {
		comp: "pointEditorDrawer",
		order: 4,
		config: {
			permissions: ["read", "create"],
			title: "accessPoints",
		},
	},
}

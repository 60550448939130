export const containerOptionID_NO = {
	bin40: "Spann 40L",
	bin140: "Spann 140L",
	bin240: "Spann 240L",
	bin360: "Spann 360L",
	bin660: "Spann 660L",
	bin770: "Spann 770L",
	bin1000: "Spann 1000L",
	drum209: "Drum 209L",
	bag: "Sekk",
	bag100: "Sekk 100L",
	bag125: "Sekk 125L",
	container5000: "Kontainer 5m³",
	container10000: "Kontainer 10m³",
	container15000: "Kontainer 15m³",
	container6m3: "Kontainer 6m³",
	container8m3: "Kontainer 8m³",
	compressor10000: "Komprimator 10m³",
	compressor14000: "Komprimator 14m³",
	compressor15000: "Komprimator 15m³",
	compressor16000: "Komprimator 16m³",
	compressor18000: "Komprimator 18m³",
	compressor20000: "Komprimator 20m³",
	openhookcontainer22000: "Åpen krokkontainer 22m³",
	case1000: "Kasse 1m³",
	elcage1500: "EE bur 1.5m³",
	ibc1000: "IBC 1m³",
	closedibc1000: "Lukket IBC 1m³",
	redbox: "Rød boks",
	palletframe1000: "Palle med karm 1m³",
	palletbox800: "Palleboks 800L",
	palletbox500: "Palleboks 500L",
	specialcontainer: "Spesiell kontainer",
	mrfillcabinet140: "Mr Fill skap 140L",
	mrfillcabinet240: "Mr Fill skap 240L",
	pottingercompressor10000: "Pottinger komprimator 10m³",
	pottingercompressor12000: "Pottinger komprimator 12m³",
	pottingercompressor14000: "Pottinger komprimator 14m³",
	pottingercompressor16000: "Pottinger komprimator 16m³",
	pottingercompressor18000: "Pottinger komprimator 18m³",
	pottingercompressor20000: "Pottinger komprimator 20m³",
	carton: "Kartong",
	ball: "Ball",
	liftcontainer4500: "Liftkontainer 4.5m³",
	liftcontainer6000: "Liftkontainer 6m³",
	liftcontainer8000: "Liftkontainer 8m³",
	liftcontainer10000: "Liftkontainer 10m³",
	undergroundContainer08m3: "Nedgravd kontainer 0.8m³",
	undergroundContainer3m3: "Nedgravd kontainer 3m³",
	undergroundContainer5m3: "Nedgravd kontainer 5m³",
}

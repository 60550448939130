import { gql } from "apollo-boost"
import { IKeyValue } from "../../../../Utils"
import { CUSTOMER_TYPE } from "../../../../Utils/gqlRequestTypes/generic"

export const default_fetcAll_customers = gql`
	query ACGetCustomers {
		store {
			data: customers {
				id
				name
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
				customerType
			}
		}
	}
`

export type Idefault_fetcAll_customers = {
	store: {
		data: {
			id: string
			name: string
			properties: IKeyValue[] | null
			externalKeys: IKeyValue[] | null
			customerType: CUSTOMER_TYPE | null
		}[]
	}
}

import { ReactNode } from "react"
import styles from "./GridBox.module.css"

/**
 *
 * @param num
 * @param unit Ex. "1fr" | "min-content" | "100px"
 * @returns
 */
export const numToCssGridUnitRange = (num: number, unit: string) =>
	Array.from(Array(num).keys()).reduce(prev => prev + `${unit} `, "")

export const GridBox = ({
	children,
	columns,
	columnUnit,
	rows,
	columnGap = 1,
	rowGap = 1,
	className,
}: {
	children: ReactNode
	columns?: number | string
	columnUnit?: string
	rows?: number | string
	columnGap?: number
	rowGap?: number
	className?: string
}) => {
	return (
		<div
			className={`${styles.gridBox} ${className}`}
			style={{
				gridTemplateColumns:
					columns && typeof columns === "number"
						? numToCssGridUnitRange(columns, columnUnit || "1fr")
						: (columns as string | undefined),
				gridTemplateRows:
					rows && typeof rows === "number"
						? numToCssGridUnitRange(rows, columnUnit || "1fr")
						: (rows as string | undefined),
				columnGap: `${columnGap}rem`,
				rowGap: `${rowGap}rem`,
			}}
		>
			{children}
		</div>
	)
}

import { get } from "lodash"
import { translate } from "../Contexts/Translation"
import { IRow } from "../Resources/types"

// TODO: Should not use any for types
export const drawerPointListSorting = (a: any, b: any) => {
	const getFraction = (p: any) => p?.properties?.find((p: any) => p.key === "fraction")?.value
	const getSortingString = (p: any) =>
		(
			p["name"] ||
			(getFraction(p) ? translate(`fraction.${getFraction(p)}`) : undefined) ||
			p.id
		)?.toLocaleUpperCase() || ""
	return ("" + getSortingString(a)).localeCompare(getSortingString(b)) || a?.id?.localeCompare(b.id)
}

export const sortGridList = (list: IRow[], header: string, order: "asc" | "desc" = "asc") => {
	const getValue = (p: any) => {
		return String(get(p, header) || "").toLocaleUpperCase()
	}
	return list.sort(
		(a, b) =>
			getValue(a).localeCompare(getValue(b), undefined, { numeric: true, sensitivity: "base" }) *
			(order === "asc" ? 1 : -1)
	)
}

import { gql } from "@apollo/client/core"
import { IStatus } from "../../../../Resources/types"

export const default_fetchAll_containers = ({
	fillLevel,
	pos,
	type,
}: {
	fillLevel?: boolean
	pos?: boolean
	type?: "ACCESS_PARENT" | "ACCESS_POINT" | "CONTAINER" | "TERMINAL" | "GROUP"
}) => gql`
    fragment accessPointsData on accessPointExtended {
        id
        name
        type
        status
        properties {
            key
            value
        }
        externalKeys {
            key
            value
        }
		parent: ancestor(type: "ACCESS_PARENT") { name id }
		terminal: ancestor(type: "TERMINAL") { name id }
        ${
					pos
						? `pos: geoLocation {
            lat: latitude
            lon: longitude
        }
        resolveAddress(onlyWhenGeoPosIsUnknown: true) {
            lat: latitude
            lon: longitude
        }`
						: ""
				}
        ${
					fillLevel
						? `streamed {
                fillLevel: property(key: "fillLevel")
				versionSpec: property(key: "_versionSpec_fillLevel")
            }`
						: ""
				}
        __typename
    }

	query ACGetAccessPointsWithFillLevel {
		store {
			data: accessPoints (type: "${type || "ACCESS_POINT"}") {
				...accessPointsData
			}
		}
	}
`

export type Idefault_fetchAll_containers = {
	store: {
		data: IFetchAllAccessPoints[]
	}
}

export type IFetchAllAccessPoints = {
	id: string
	name: string
	type: string
	status: IStatus | null
	properties: { key: string; value: string | null }[]
	externalKeys: { key: string; value: string | null }[]
	pos?: {
		lat: number
		lon: number
	} | null
	resolveAddress?:
		| {
				lat: number
				lon: number
		  }[]
		| null
	streamed?: {
		fillLevel: string | null
	}
}

import { get, isNumber } from "lodash"
import moment from "moment"
import { localeFromLocalStorage } from "../../../Contexts/Translation"
import { IPointModified } from "../../../Resources/Events/types"
import { useSiteConfig } from "../../../States/siteConfig"

// TODO: Replace translations with trans function
export const default_overrides_events = (trans: any) => {
	const translations = useSiteConfig.getState().siteConfig.translations[localeFromLocalStorage]
	return {
		"point.id": (event: any) => {
			const _p = event["point"] as IPointModified | null
			if (!_p || !_p["id"]) {
				return ""
			} else if (!_p.properties?.fraction) {
				return _p["id"]
			} else {
				const f = get(translations.fraction, _p.properties?.fraction)
				return _p["name"]
					? _p["name"] + " (" + _p["id"] + ")"
					: f
					? f + " (" + _p["id"] + ")"
					: _p["id"]
			}
		},
		timestamp: (event: any) =>
			moment(event.ts).locale(localeFromLocalStorage).format("ll") +
			moment(event.ts).locale(localeFromLocalStorage).format(" LTS"),
		fraction: (event: any) => {
			// this should be deprecated
			const f = get(translations.fraction, event.fraction)
			return f ? f + " (" + event.fraction + ")" : event.fraction
		},
		"point.properties.fraction": (event: any) => {
			const f = get(translations.fraction, event.properties?.fraction)
			return f ? f + " (" + event.properties?.fraction + ")" : event.properties?.fraction
		},
		type: (event: any) => {
			const t = get(translations.type, event.type)
			return t || event.type
		},
		"properties.weight": (event: any) => {
			return event.properties?.weight?.length && isNumber(event.properties.weight)
				? parseFloat(event.properties?.weight).toFixed(1)
				: event.properties?.weight
		},
		result: (event: any) => {
			return (
				<div className="result-light">
					<svg width="24" height="24" viewBox="0 0 24 24">
						<circle
							cx="12.5"
							cy="12.5"
							r="10"
							fill={event.result === "ACCEPTED" ? "#22b97a" : "#f44336"}
						/>
					</svg>
				</div>
			)
		},
		"properties.rejectReason": (event: any) =>
			event.result === "ACCEPTED"
				? trans("accepted")
				: get(
						translations.rejectReason,
						event.properties?.rejectReason?.replace(/\[/, "")?.replace(/\].*/, "")
				  ),
	}
}

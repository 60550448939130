import { getCustomersByType } from "../../../../APIfunctionsGeneric/getCustomersByType"
import { RecursivePartial } from "../../../../types"
import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"

export const ihm_drawerContent_customers: RecursivePartial<IDrawerContent> = {
	inputSection: {
		inputs: [
			{
				updater: "name",
				key: "name",
				header: "headers.customerName",
				permission: "edit",
				type: "text",
			},
			{
				updater: "externalKey",
				key: "externalKeys.ImportID",
				header: "headers.ImportID",
				permission: "read",
				type: "text",
			},
			{
				updater: "id",
				key: "operator.operator.name",
				header: "operator",
				permission: "read",
				type: "text",
			},
			{
				updater: "externalKey",
				key: "externalKeys.CRMID",
				header: "customerModal.properties.CRMID",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.orgNumber",
				header: "customerModal.properties.orgNumber",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.orgForm",
				header: "properties.orgForm",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.companyCode",
				header: "properties.companyCode",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.companyDescription",
				header: "properties.companyDescription",
				permission: "edit",
				type: "text",
			},
			// Parent
			{
				updater: "customerParent",
				key: "ancestor.name",
				header: "parentCompany",
				permission: "edit",
				type: "dropdown",
				disabled: ({ data }: any) => data?.customerType?.includes("PARENT"),
				dropdownOptions: async () => {
					const customers = await getCustomersByType(["BUSINESS", "BUSINESS_PARENT"])
					return [
						{
							value: "--",
							name: "--",
						},
						...(customers.map(c => ({ ...c, value: c.id })) || []),
					]
				},
			},
			// customer address
			{
				updater: "property",
				key: "properties.geoLocationName",
				header: "properties.geoLocationName",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.postalCode",
				header: "properties.postalCode",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.postal",
				header: "properties.postal",
				permission: "edit",
				type: "text",
			},
			// Contact info
			{
				updater: "property",
				key: "properties.contactPerson",
				header: "properties.contactPerson",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.contactEmail",
				header: "properties.contactEmail",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.contactMobile",
				header: "properties.contactMobile",
				permission: "edit",
				type: "text",
			},
			// Faktura info
			{
				updater: "property",
				key: "properties.postAddress",
				header: "properties.postAddress",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.postPostalCode",
				header: "properties.postPostalCode",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.postPostal",
				header: "properties.postPostal",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.invoiceEmail",
				header: "properties.invoiceEmail",
				permission: "edit",
				type: "text",
			},
		],
	},
	pointEditorDrawer: {
		comp: "pointEditorDrawer",
		order: 4,
		config: {
			permissions: ["edit", "read"],
			title: "stations",
		},
	},
	identityList: { enable: false },
}

import React from 'react'
import { useHistory } from 'react-router-dom'

import { useTranslation } from '../Contexts'


export function customerGeoLocationNameValue(customerGeoLocationName: any) {
  const { pk, value, score } = customerGeoLocationName
  if (!(pk && value)) {
    console.warn('bad customer geo location name received', customerGeoLocationName)
    return
  }

  return {
    type: 'customer-geolocation-name',
    id: pk,
    name: value,
    score: score || 0,
  }
}

const CustomerGeoLocationNameOption = ({ id, name }: any) => {
  const { trans } = useTranslation()
  const history = useHistory()

  return (
    <div onClick={() => history.push(`/resources/customers/${id}`)}>
      <h1>{trans('customerGeoLocationName')}</h1>
      <div className="row">
        <span>{name}&nbsp;&nbsp;&nbsp;({id})</span>
      </div>
    </div>
  )
}

export default CustomerGeoLocationNameOption

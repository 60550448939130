import en from "date-fns/locale/en-US"
import nb from "date-fns/locale/nb"
import moment, { Moment } from "moment"
import React, { useCallback, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ISiteConfig } from "../../../../Configs/types"
import { useTranslation } from "../../../../Contexts"
import { useQueryParam } from "../../../../domHooks"
import { useSiteConfig } from "../../../../States/siteConfig"
import { InputSelect } from "../../../../UI"
import { IEventDatesConfig } from "../../../Events/types"
import "./DateTimeSelect.css"

const DateTimePicker = ({
	from,
	to,
	onChange,
}: {
	from: string
	to: string
	onChange: ([from, to]: (Date | null)[]) => void
}) => {
	const { siteConfig } = useSiteConfig()
	const { trans, currentLocale } = useTranslation()
	const [fromDate, setFromDate] = useState<Date>(new Date(from))
	const [toDate, setToDate] = useState<Date | null>(new Date(to))
	const [maxEndDateTime, setMaxEndDatetime] = useState<Moment | null>(null)
	const [{ filterEventsByTime, maxFilteredDays }] = useState<IEventDatesConfig>(
		siteConfig.eventsDates
	)

	// Limit date range to dateRangeLogic
	const dateRangeLogic = (_start: Moment) =>
		filterEventsByTime && maxFilteredDays
			? setMaxEndDatetime(_start.clone().add(maxFilteredDays, "days"))
			: setMaxEndDatetime(null)

	function onDatesChange(_dates: Date[] | null) {
		if (_dates) {
			const _start = moment(_dates[0])
			const _end = _dates[1] ? moment(_dates[1]) : null

			// Check if clicked first date in date range (end === null)
			// Or else set max end date as null to reset max limit
			_end === null ? dateRangeLogic(_start) : setMaxEndDatetime(null)

			// Start date will always be set
			setFromDate(_start.toDate())
			_end ? setToDate(_end.toDate()) : setToDate(null)
		}
	}

	// HACK: Editing time input fields themselfs does not change the time,
	// only when selecting time from the dropdowns. This adds readonly to the
	// input fields so the user must only use the dropdowns to change time.
	// Found no way to do readonly only for the input field on the time-DatePicker component.
	useEffect(() => {
		document
			.querySelector(".datepicker-with-time .timepicker")
			?.getElementsByTagName("input")
			?.item(0)
			?.setAttribute("readonly", "true")
		document
			.querySelector(".datepicker-with-time .timepicker")
			?.getElementsByTagName("input")
			?.item(1)
			?.setAttribute("readonly", "true")
	}, [])

	const locale = currentLocale === "nb" ? nb : en
	return (
		<div className="datepickers-popup-container">
			<div className="datepickers">
				<div className="datepicker-with-time">
					<DatePicker
						selected={fromDate}
						startDate={fromDate}
						endDate={toDate}
						onChange={dates => (Array.isArray(dates) ? onDatesChange(dates) : null)}
						locale={locale}
						maxDate={maxEndDateTime?.toDate()}
						selectsRange
						inline
						allowSameDay
					/>
					<div className="timepicker">
						<div>
							<label>{trans("from")}</label>
							<DatePicker
								selected={fromDate}
								onChange={date => (date && !Array.isArray(date) ? setFromDate(date) : null)}
								showTimeSelect
								showTimeSelectOnly
								timeIntervals={15}
								timeCaption="Time"
								dateFormat="HH:mm"
								timeFormat="HH:mm"
							/>
						</div>
						<div>
							<label>{trans("to")}</label>
							<DatePicker
								selected={toDate}
								onChange={date => (date && !Array.isArray(date) ? setToDate(date) : null)}
								showTimeSelect
								showTimeSelectOnly
								timeIntervals={15}
								timeCaption="Time"
								dateFormat="HH:mm"
								timeFormat="HH:mm"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="apply">
				<button onClick={() => onChange([fromDate, toDate])}>{trans("apply")}</button>
			</div>
		</div>
	)
}

const getIntervalByConfig = (today: Moment, trans: any, siteConfig: ISiteConfig) =>
	siteConfig.eventsDates.calendarIntervals.map(i => ({
		label: trans(`interval.${i[0].toString() + i[1].toString()}`),
		value: [today.clone().subtract(i[0], i[1]), today.clone()],
	}))

const DateTimeSelect = ({ filterKey }: any) => {
	const { siteConfig } = useSiteConfig()
	// TODO: typing
	if (!filterKey) throw Error("filterKey not provided")
	const { trans } = useTranslation()

	const startOfNextDay = moment().add(1, "day").startOf("day")
	const intervals = [
		{
			label: trans("interval.custom"),
			value: "custom",
		},
		...getIntervalByConfig(startOfNextDay, trans, siteConfig),
	]

	const [selection, setSelection] = useQueryParam(`args.${filterKey}`, intervals[1])
	const [shouldShowPicker, setShouldShowPicker] = useState(false)

	const onDateTimePicked = useCallback(
		([from, to]: (Date | null)[]) => {
			if (from && to) {
				setShouldShowPicker(false)
				setSelection({
					label: `${moment(from).format("L")} - ${moment(to).format("L")}`,
					value: [from, to],
				})
			}
		},
		[setShouldShowPicker, setSelection]
	)

	const onMenuOpen = useCallback(() => {
		setShouldShowPicker(false)
	}, [setShouldShowPicker])

	const onSelect = useCallback(
		(v: any) => {
			if (v.value === "custom") {
				setShouldShowPicker(true)
			} else {
				setShouldShowPicker(false)
				setSelection(v)
			}
		},
		[setShouldShowPicker, setSelection]
	)

	const [from, to] = selection.value
	return (
		<>
			<InputSelect
				noSort
				label="label"
				placeholder="selectDateTime"
				extraClass="date-time-select"
				defaultValue={selection}
				options={intervals}
				onSelect={onSelect}
				onMenuOpen={onMenuOpen}
			/>
			{shouldShowPicker && <DateTimePicker from={from} to={to} onChange={onDateTimePicked} />}
		</>
	)
}

export default DateTimeSelect

import { gql } from "@apollo/client/core"

export const ihm_fetchAll_containers = (fillLevel?: boolean) => gql`
    fragment data on accessPointExtended {
        id
        name
        properties {
            key
            value
        }
        externalKeys {
            key
            value
        }
        customers: ancestor(type: "ACCESS_PARENT") {
            name id
            customers {
                id
                name
            }
        }
        ${
					fillLevel
						? `streamed {
                            fillLevel: property(key: "fillLevel")
				versionSpec: property(key: "_versionSpec_fillLevel")
            }`
						: ""
				}
        __typename
    }

	query ACGetAccessPointsWithFillLevel {
		store {
			data: accessPoints (type: "ACCESS_POINT") {
				...data
			}
		}
	}
`

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { df_input_id, df_input_name } from "../../_drawerFragments/inputs"

export const iks_drawerContent_customers: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name(),
			df_input_id(),
			{
				updater: "property",
				key: "properties.geoLocationName",
				header: "headers.properties.geoLocationName",
				permission: "edit",
				type: "text",
			},
			{
				updater: "externalKey",
				key: "externalKeys.PAAvtaleID",
				header: "headers.properties.crmId",
				permission: "read",
				type: "text",
			},
		],
	},
}

import React, { useMemo } from "react"
import { useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import { useFilter } from "../Filter"
import Error from "../_components/Error/Error"
import { useGetInvoices } from "./data"
import Grid from "./Grid"

const Invoices = () => {
	const { siteConfig } = useSiteConfig()
	const { trans } = useTranslation()
	const { headers, filters: filter, columnSizes } = siteConfig.invoices

	//Get invoices
	const { error, data: invoices, client } = useGetInvoices()
	//filter invoices
	const filterSpec = useMemo(() => filter(client, invoices), [client, invoices, filter])
	const filteredInvoices = useFilter(invoices, filterSpec)

	if (error) {
		return <Error>{trans("errors.failedFetch")}</Error>
	}

	if (filteredInvoices) {
		return <Grid invoices={filteredInvoices} headers={headers} columnSizes={columnSizes} />
	} else {
		return <></>
	}
}

export default Invoices

import { kvam_translations } from "../../Contexts/translations/configurations/kvam"
import { mergeConfig } from "../config"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { birConfigObj } from "./bir"
import { defaultConfig } from "./default"

const kvamConfigObj: IPartialSiteConfig = {
	...birConfigObj,
	translations: kvam_translations,
	menu: [
		"customers",
		"points",
		"containers",
		"events",
		"allocations",
		"invoices",
		"recyclingStationVisits",
	],
}

export const kvamConfig = (): ISiteConfig => mergeConfig(defaultConfig(), kvamConfigObj)

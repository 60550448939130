import { SVGProps } from "react"

export const MykPlastemballasjeIcon = ({
	fill = "black",
	width = "542",
	height = "650",
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			{...props}
			width="542"
			height="650"
			viewBox="0 0 542 650"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1 1H529V98C525.218 154.134 519.585 185.426 505 241C521.118 297.997 527.272 331.712 529 397.5C532 450.5 517 490.5 517 549.5C518.689 577.17 521.761 595.779 541 649H1L37 385C17.6495 344.37 7.56446 317.082 1 201V1ZM170 121C137 121 137 73 170 73H360C393 73 393 121 360 121H170Z"
				fill={fill}
				stroke={fill}
			/>
		</svg>
	)
}

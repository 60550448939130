import React from "react"

import { Radio } from "../../../../UI"
import { useQueryParam } from "../../../../domHooks"

import "./RadioGroup.css"

const RadioGroup = ({ filterKey, values, defaultValue }: any) => {
	const [value, setValue] = useQueryParam(`args.${filterKey}`, defaultValue)

	return (
		<div className="radio-group">
			<h1>Brikketype</h1>

			{values.map((v: any) => (
				<Radio
					key={v.value}
					name={filterKey}
					value={v.value}
					checked={v.value === value}
					onChange={(ev: any) => setValue(ev.target.value)}
				>
					{v.label}
				</Radio>
			))}
		</div>
	)
}

export default RadioGroup

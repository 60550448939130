import { isEmpty } from "lodash"
import { Moment } from "moment"
import { useApolloStore } from "../../../../States/apollo"
import { GET_EVENTS_BY_CUSTOMER_ID } from "./queries"
import { IEventsDataByCustomerId } from "./types"

export const fetchEventsByCustomerIds = (
	customerIds: string[],
	dates: { from: Moment; to: Moment }
) => {
	const { client } = useApolloStore.getState()

	if (client) {
		return customerIds?.length && !isEmpty(dates)
			? client
					?.query<IEventsDataByCustomerId>({
						query: GET_EVENTS_BY_CUSTOMER_ID,
						variables: {
							start: dates.from,
							end: dates.to,
							customerIds: customerIds,
						},
					})
					.then((events) => events.data)
			: Promise.resolve({} as IEventsDataByCustomerId)
	} else {
		throw new Error("Problems connecting to api client")
	}
}

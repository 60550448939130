import { gql } from "apollo-boost"

export const GET_EVENTS_BY_POINT_IDS = gql`
	query ACGetEventsByPointIds($start: DateTime!, $end: DateTime!, $pointIds: [String]!) {
		store {
			accessPoints(ids: $pointIds) {
				id
				type
				name
				fraction: property(key: "fraction")
				RFID: externalKey(key: "RFID_ISO") # IHM
				productName: property(key: "productName")
				volume: property(key: "filledVolume")
				parent: ancestor(type: "ACCESS_PARENT") {
					id
					name
				}
				externalKeys {
					key
					value
				}
				properties {
					key
					value
				}
				events(startTimeIso: $start, endTimeIso: $end, eventResultFilter: "") {
					id
					type
					timestamp
					result
					identityId
					weight: property(key: "weight")
					weightUnit: property(key: "weightUnit")
					fraction: property(key: "fraction")
					properties {
						key
						value
					}
					customer {
						id
					}
				}
			}
		}
	}
`

export const GET_IDENITIES_BY_CUSTOMER_IDS = (options?: { includeExternalKeys?: boolean }) => gql`
	query ACGetIdentitiesByCustomerIds($customerIds: [String]) {
		store {
			customers(ids: $customerIds) {
				id
				name
				properties {
					key
					value
				}
				${options?.includeExternalKeys ? `externalKeys { key value }` : ""}
				identities {
					identity {
						id
						externalKeys {
							key
							value
						}
					}
				}
			}
		}
	}
`

export const GET_CHILDREN_OF_PARENT_IDS = gql`
	query ACGetAllParentIdsOfPoints($parentIds: [String]) {
		store {
			accessPoints(ids: $parentIds) {
				id
				children {
					id
					children {
						id
						children {
							id
							children {
								id
								children {
									id
								}
							}
						}
					}
				}
			}
		}
	}
`

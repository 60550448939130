export const SvgDelete = ({
	className,
	height,
	width,
	color,
	onClick,
}: {
	className?: string
	height?: string
	width?: string
	color?: string
	onClick?: (...args: any[]) => any
}) => (
	<svg
		viewBox="0 0 12 14"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		height={height}
		width={width}
		fill={color}
		onClick={() => onClick && onClick()}
	>
		<path d="M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM3.345 6.91L4.4025 5.8525L6 7.4425L7.59 5.8525L8.6475 6.91L7.0575 8.5L8.6475 10.09L7.59 11.1475L6 9.5575L4.41 11.1475L3.3525 10.09L4.9425 8.5L3.345 6.91ZM8.625 1L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1H8.625Z" />
	</svg>
)

import {
	parseRecyclingStationVisitByIdData,
	parseRecyclingStationVisitData,
} from "../../../../Resources/RecyclingStationVisits/data"
import { IListViewConfigRecyclingStationVisits } from "../../../../Resources/types"
import { filterConfigGen } from "../../_drawerFragments/filterConfigGenerator"
import { filterFuzzy, genericDateTimeFilter } from "../../_drawerFragments/filters"
import { default_fetchAll_recyclingStationVisits } from "../queries/default_fetchAll_recyclingStationVisits"
import { default_fetchById_recyclingStationVisits } from "../queries/default_fetchById_recyclingStationVisits"

export const default_recyclingStationVisits: IListViewConfigRecyclingStationVisits = {
	defaultSort: { header: "timestampRaw", order: "desc" },
	headers: [
		{ path: "checkbox" },
		{ path: "timestamp" },
		{ path: "orderId", name: "headers.orderId" },
		{ path: "visitId" },
		{ path: "name" },
		{ path: "customerClass" },
		{ path: "orgNr" },
		{ path: "paymentType" },
		{ path: "visitDurationInMinutes" },
		{ path: "zoneWeights" },
		{ path: "postalCode" },
		{ path: "paymentAmountIncludingVAT" },
		{ path: "paymentAmountExcludingVAT" },
		{ path: "totalBillableWeight" },
		{ path: "customerRepresentativeName" },
		{ path: "referenceText" },
	],
	columnSizes: {
		timestamp: "minmax(180px, 0.8fr)",
	},
	filters: filterConfigGen([
		genericDateTimeFilter("timestamp"),
		filterFuzzy("id"),
		filterFuzzy("orgNr"),
		filterFuzzy("name"),
		filterFuzzy("postalCode"),
		filterFuzzy("paymentType"),
	]),
	drawer: {
		ui: "drawer",
		content: {},
	},
	data: {
		fetchAll: default_fetchAll_recyclingStationVisits,
		fetchAllParse: (data: any) => parseRecyclingStationVisitData(data),
		fetchById: default_fetchById_recyclingStationVisits,
		fetchByIdParse: (data: any) => parseRecyclingStationVisitByIdData(data),
	},
	overrides: trans => ({
		customerClass: e => trans(`customerClass.${e.customerClass}`),
	}),
}

import { cloneDeep } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { match } from "ts-pattern"
import { useTranslation } from "../../Contexts"
import { AccessPointStatus } from "../../Utils/gqlRequestTypes/generic"
import { ModalDropdown } from "./dropdown"
import { ModalInput } from "./input"

export type InputKeyValConfigList = ({
	defaultValue?: string
	placeholder?: string
	required?: boolean
} & (
	| {
			inputType: "text"
			key: string
			keyType: "property" | "externalKey" | "name"
			options?: undefined
	  }
	| {
			inputType: "dropdown"
			key: string
			keyType: "property" | "externalKey"
			translate?: boolean
			options: {
				[key: string]: any
				name: string
				// value: string
			}[]
	  }
	| {
			inputType: "dropdown"
			key: string
			keyType: "status"
			translate?: boolean
			options: {
				[key: string]: any
				name: string
				// value: ACCESS_POINT_STATUS
			}[]
	  }
	| {
			inputType: "multiValue"
			key?: undefined
			keyType?: undefined
			defaultValue?: undefined
			translate?: boolean
			options: {
				[key: string]: any
				name: string
				// value?: undefined
				// values: { type: string; key: string; value: string }[]
			}[]
	  }
))[]

export type GeneratorStateValues = {
	[key: string]: {
		type: "property" | "externalKey" | "name" | "status"
		value: string
	}
}

type InputChangeType =
	| {
			type: "text"
			keyType: "property" | "externalKey" | "name"
			key: string
			value: string
	  }
	| {
			type: "dropdown"
			keyType: "property" | "externalKey"
			key: string
			value: string
	  }
	| {
			type: "dropdown"
			keyType: "status"
			key: string
			value: AccessPointStatus
	  }
	| {
			type: "multiValue"
			index: number
			values: {
				type: "property" | "externalKey"
				key: string
				value: string
			}[]
	  }

export const ModalFieldGenerator = ({
	inputs,
	onChange,
}: {
	inputs: InputKeyValConfigList
	onChange?: (values: GeneratorStateValues, allRequiredSet: boolean) => void
}) => {
	const { trans } = useTranslation()
	const initialValues = useMemo(() => {
		return inputs.reduce((prev, input) => {
			if (input.inputType === "multiValue") return prev
			return {
				...prev,
				[input.key]: {
					type: input.keyType,
					value: input.defaultValue || "",
				},
			}
		}, {} as GeneratorStateValues)
	}, [inputs])

	const [values, setValues] = useState<GeneratorStateValues>(initialValues)
	const [selectedMultiValDropdowns, setSelectedMultiValDropdowns] = useState<{
		[key: number]: boolean
	}>({})

	const checkRequiredSet = () =>
		!inputs.some((i, index) => {
			// console.log(i.required)
			// console.log(values[i.key!])
			// console.log(
			// 	index,
			// 	i.inputType === "multiValue" ? !selectedMultiValDropdowns[index] : !values[i.key].value
			// )
			return (
				i.required &&
				(i.inputType === "multiValue" ? !selectedMultiValDropdowns[index] : !values[i.key].value)
			)
		})

	useEffect(() => {
		onChange && onChange(values, checkRequiredSet())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values])

	const onInputValueChange = (change: InputChangeType) => {
		const newState = cloneDeep(values)
		if (change.type === "dropdown" || change.type === "text") {
			newState[change.key] = {
				type: change.keyType,
				value: change.value,
			}
			setValues(newState)
		} else if (change.type === "multiValue") {
			change?.values &&
				setSelectedMultiValDropdowns({ ...selectedMultiValDropdowns, [change.index]: true })
			// console.log(change)
			// console.log(values)
			change?.values &&
				setValues(
					change.values.reduce((prev, value) => {
						return {
							...prev,
							[value.key]: {
								type: value.type,
								value: value.value,
							},
						}
					}, values)
				)
		}
	}

	if (!inputs.length) return null

	return (
		<>
			{inputs.map((inputField, index) => {
				return match(inputField)
					.with(
						// "text",
						{ inputType: "text" },
						input =>
							input.inputType === "text" && (
								<ModalInput
									key={`modal-input-${input.key}-${index}`}
									placeholder={input.placeholder ? trans(input.placeholder) : ""}
									value={values[input.key].value || ""}
									onChange={e => {
										onInputValueChange({
											type: "text",
											key: input.key,
											keyType: input.keyType,
											value: e.target.value,
										})
									}}
									error={input.required && !values[input.key].value}
								/>
							)
					)
					.with(
						{ inputType: "dropdown" },
						input =>
							input.inputType === "dropdown" && (
								<ModalDropdown
									key={`modal-input-${input.key}-${index}`}
									placeholder={input.placeholder}
									options={input.options?.map(opt => ({
										name: input.translate !== false ? trans(opt.name) : opt.name,
										value: opt.value,
									}))}
									error={input.required && !values[input.key].value}
									onSelect={(option: { [key: string]: any; name: string; value: string }) => {
										input.keyType === "status" &&
											onInputValueChange({
												type: "dropdown",
												key: input.key,
												keyType: input.keyType,
												value: option.value as AccessPointStatus,
											})
										input.keyType !== "status" &&
											onInputValueChange({
												type: "dropdown",
												key: input.key,
												keyType: input.keyType,
												value: option.value,
											})
									}}
								/>
							)
					)
					.with(
						{ inputType: "multiValue" },
						input =>
							input.inputType === "multiValue" && (
								<ModalDropdown
									key={`modal-input-${input.key}-${index}`}
									placeholder={input.placeholder}
									options={
										input?.options?.map(opt => {
											return {
												name: input.translate !== false ? trans(opt.name) : opt.name,
												value: opt.value,
												values: opt.values,
											}
										}) || []
									}
									error={input.required && !selectedMultiValDropdowns[index]}
									onSelect={(option: { [key: string]: any; name: string; value: string }) =>
										onInputValueChange({
											type: "multiValue",
											index,
											values: option.values,
										})
									}
								/>
							)
					)
					.exhaustive()
			})}
		</>
	)
}

import { useEffect, useMemo, useState } from "react"
import { useAuth0, useTranslation } from "../../../Contexts"
import Error from "../../../Resources/_components/Error/Error"
import { Spinner } from "../../../UI"
import { fetchFromDWH, IDwhResponse } from "../../Queries/dwhApi"
import { NumberTile } from "../../Tiles/NumberTile"
import { TileGroup } from "../../Tiles/TileGroup"
import { ListTile } from "../../Tiles/ListTile"

const parseData = ({ iksStats: data, basicStats }: IDwhResponse["data"], trans: any) => {
	// data.sort((aS, bS) => {
	// 	const [a, b] = [parseInt(aS.period.replace("-", "")), parseInt(bS.period.replace("-", ""))]
	// 	return a - b
	// })

	// Most active stations
	const activeStationList = data[0]?.accessparent_distribution?.map(station => [
		station.accessparent_name,
		station.reported_number_of_events,
	])

	return {
		basicStats,
		activeStationList,
	}
}

export const IKSDefaultOverview = () => {
	const [data, setData] = useState<IDwhResponse["data"] | null>(null)
	const [error, setError] = useState(false)

	const { token } = useAuth0()!
	const { trans } = useTranslation()

	useEffect(() => {
		fetchFromDWH({ token, endpoint: "iksstats", client: "bir" })
			.then(({ data, error: err }) => {
				if (err) {
					console.error(err)
					setError(true)
				} else {
					setData(data)
				}
			})
			.catch(err => {
				console.error(err)
				setError(true)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const parsedData = useMemo(() => (data ? parseData(data, trans) : null), [data, trans])

	// const roundString = (num: string) => {
	// 	return Math.round(parseFloat(num))
	// }

	const basicStatsTilePropsMap = {
		active_and_inactive_access_points: {
			title: trans("totalPoints"),
			label: trans(`basicStatsLabels.customers`),
		},
		inactive_access_points: {
			title: trans("inactivePoints"),
			label: trans(`basicStatsLabels.customers`),
		},
		unique_customers: {
			title: trans("totalCustomers"),
			label: trans(`basicStatsLabels.inSystem`),
		},
		unique_fractions: {
			title: trans("fractions"),
			label: trans(`basicStatsLabels.fractions`),
		},
		unique_identities: {
			title: trans("totalIdentities"),
			label: trans(`basicStatsLabels.inSystem`),
		},
		rejected_disposals_last_week: {
			title: trans("rejectedDisposals"),
			label: trans(`periods.7days`),
		},
		successful_disposals_last_week: {
			title: trans("successfulDisposals"),
			label: trans(`periods.7days`),
		},
	} as {
		[key: string]: {
			title: string
			label: string
		}
	}

	if (!parsedData && !error) return <Spinner />
	if (error) return <Error>{trans("errors.failedFetch")}</Error>

	const {
		basicStats: [basicStats],
		activeStationList = [],
	} = parsedData!

	return (
		<div className="dashboard-grid">
			{basicStats && (
				<TileGroup rows={Object.keys(basicStats).length + 1} cols={1}>
					{Object.entries(basicStats).map(([key, val], index) => {
						const tileProps = basicStatsTilePropsMap[key]
						return (
							<NumberTile
								key={`basicStat${index}`}
								number={val}
								formatThousands
								tileProps={tileProps}
							/>
						)
					})}
				</TileGroup>
			)}
			<ListTile
				tileProps={{
					title: trans("mostActiveStations"),
					label: trans("periods.7days"),
					cols: 2,
					rows: 4,
				}}
				data={activeStationList}
				headers={[trans("headers.station"), trans("totalThrows")]}
				defaultSortHeader={trans("totalThrows")}
			/>
		</div>
	)
}

import React, { useEffect, useState } from "react"
import { useTranslation } from "../../Contexts"
import { useGlobalAlert } from "../../States/globalAlert"
import ModalContainer from "../../UI/ModalContainer"
import { MultiKeyValue } from "../../UI/MultiKeyValue/MultiKeyValue"
import { IMultiKeyValueFields } from "../../UI/MultiKeyValue/types"
import styles from "./ExtKeysAndPropsModal.module.css"
import { fetchCustomerExtKeyAndProps, fetchPointExtKeysAndProps } from "./fetch"
import { saveKeyValueToDb } from "./functions"
import { IExtKeysAndProps, IKeyValue } from "./types"

type IExtKeysAndPropsModal = {
	id: string
	name: string
	type: "point" | "customer"
	onCancel: () => void
}

export const ExtKeysAndPropsModal = ({ id, name, type, onCancel }: IExtKeysAndPropsModal) => {
	const { trans } = useTranslation()
	const setGlobalAlert = useGlobalAlert(state => state.setGlobalAlert)
	const [tab, setTab] = useState<number>(1)
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false)
	const [extKeysAndProps, setExtKeysAndProps] = useState<IExtKeysAndProps>()

	const [saveBoth, setSaveBoth] = useState<boolean>(false)

	const updateDb = (dataType: "extKeys" | "properties") => (keyValues: IKeyValue[]) => {
		setSaveBoth(false)
		setLoadingConfirm(true)

		saveKeyValueToDb({
			id,
			type,
			dataType,
			keyValues,
			okFunc: () => {
				setGlobalAlert({ type: "success", message: trans("success.saved") })
				setLoadingConfirm(false)
				setExtKeysAndProps(prevState =>
					dataType === "extKeys"
						? {
								externalKeys: keyValues,
								properties: prevState ? prevState.properties : [],
						  }
						: {
								externalKeys: prevState ? prevState.externalKeys : [],
								properties: keyValues,
						  }
				)
			},
			errorFunc: () => setLoadingConfirm(false),
		})
	}

	useEffect(() => {
		setLoading(true)
		const _extKeysAndProps =
			type === "customer" ? fetchCustomerExtKeyAndProps(id) : fetchPointExtKeysAndProps(id)

		_extKeysAndProps
			.then(a => {
				setLoading(false)
				return a ? setExtKeysAndProps(a) : null
			})
			.catch(err => {
				// Set global alert with error
				setGlobalAlert({
					type: "danger",
					message: err.message,
				})
				console.error(err)
				setLoading(false)
			})
	}, [id, type, trans, setGlobalAlert])

	return (
		<ModalContainer
			width={"500px"}
			entity={
				type === "customer"
					? trans("headers.customer.name") + ": " + name
					: trans("pointGeneric") + ": " + name
			}
			title={trans("extKeysAndProps.title")}
			loading={loading}
			loadingConfirm={loadingConfirm}
			onConfirm={() => setSaveBoth(true)}
			onConfirmText="ask.save"
			onCancel={onCancel}
			onCancelText="ask.cancel"
		>
			<div className={styles.extKeysAndPropsModal}>
				<div className={styles.tabMenu}>
					<div
						className={`${styles.tab} ${tab === 1 ? styles.active : ""}`}
						onClick={() => {
							setTab(1)
						}}
					>
						{trans(`extKeysAndProps.externalKeys`)}
					</div>
					<div
						className={`${styles.tab} ${tab === 2 ? styles.active : ""}`}
						onClick={() => {
							setTab(2)
						}}
					>
						{trans(`extKeysAndProps.propterties`)}
					</div>
				</div>
				<div className={`${styles.tabContent} ${tab === 1 ? styles.active : ""}`}>
					<MultiKeyValue
						initKeyValues={extKeysAndProps?.externalKeys}
						save={saveBoth}
						onSave={updateDb("extKeys") as (keyValues: IMultiKeyValueFields[]) => void}
					/>
				</div>
				<div className={`${styles.tabContent} ${tab === 2 ? styles.active : ""}`}>
					<MultiKeyValue
						initKeyValues={extKeysAndProps?.properties}
						save={saveBoth}
						onSave={updateDb("properties") as (keyValues: IMultiKeyValueFields[]) => void}
					/>
				</div>
			</div>
		</ModalContainer>
	)
}

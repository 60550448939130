import { getCustomersByType } from "../../../../APIfunctionsGeneric/getCustomersByType"
import { RecursivePartial } from "../../../../types"
import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { isAdmin } from "../../../../Utils"
import { df_input_name } from "../../_drawerFragments/inputs"

export const lfmEnterprise_drawerContent_customers: RecursivePartial<IDrawerContent> = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name(),
			{
				updater: "customerParent",
				key: "ancestor.name",
				header: "chain",
				permission: "edit",
				type: "dropdown",
				// Causes bugs for non admin users
				disabled: ({ data }: any) => !isAdmin() || data?.customerType?.includes("PARENT"),
				dropdownOptions: async () => {
					const customers = await getCustomersByType(["BUSINESS_PARENT"])
					return [
						{
							value: "--",
							name: "--",
						},
						...(customers.map(c => ({ ...c, value: c.id })) || []),
					]
				},
			},
			{
				updater: "property",
				key: "properties.taxNumber",
				header: "properties.taxNumber",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.Category",
				header: "properties.customerCategory",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.contactPerson",
				header: "properties.contactPerson",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.contactEmail",
				header: "properties.contactEmail",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.contactMobile",
				header: "properties.contactMobile",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.Area",
				header: "properties.totalCustomerArea",
				permission: "edit",
				type: "text",
			},
		],
	},
}

export const default_fractionsList = [
	"-8", // farget, myk plast
	"-4",
	"-3",
	"-2",
	"-1",
	"1",
	"2",
	"0000",
	"0001",
	"1111",
	"1128",
	"1131",
	"1149",
	"1221",
	"1231",
	"1299",
	"1300",
	"1322",
	"1452",
	"1599",
	"1618",
	"1700",
	"1711",
	"1712",
	"1722", // hardplast, annen
	"1799",
	"7051",
	"7055",
	"7086",
	"7093",
	"7094",
	"7133",
	"7261",
	"9912",
	"9913",
	"9916",
	"9999",
	"129917",
	"7086021", // lyspærer
]

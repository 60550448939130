import React, { useMemo } from "react"
import { useTranslation } from "../../Contexts"
import { useSiteConfig } from "../../States/siteConfig"
import { useFilter } from "../Filter"
import Error from "../_components/Error/Error"
import { useToolbar } from "../_components/Toolbar/Toolbar"
import { useGetOperators } from "./data"
import Grid from "./Grid"

const Operators = () => {
	const { trans } = useTranslation()
	const { hideToolbar } = useToolbar()
	const { siteConfig } = useSiteConfig()

	const { headers, filters, columnSizes } = siteConfig.operators

	const { error, data, client } = useGetOperators()

	const filterSpec = useMemo(() => filters(client, data.operators), [client, data, filters])

	const filteredOperators = useFilter(data, filterSpec)

	React.useEffect(() => {
		return () => hideToolbar()
	}, [hideToolbar])

	if (error) {
		console.error(error)
		return <Error>{trans("errors.failedFetch")}</Error>
	}

	return (
		<Grid
			operators={filteredOperators ? filteredOperators : []}
			headers={headers}
			columnSizes={columnSizes}
		/>
	)
}

export default Operators

import { useQuery } from "@apollo/client"
import moment from "moment"
import { useMemo } from "react"
import { localeFromLocalStorage, translate } from "../../Contexts/Translation"
import { useSiteConfig } from "../../States/siteConfig"
import { useArgs } from "../Filter"
import {
	IGetRecyclingStationVisitById,
	IGetRecyclingStationVisits,
	IRecyclingStationCustomer,
	IRecyclingStationEvent,
	IRecyclingStationEventNonAggNormalized,
	IRecyclingStationSession,
	IRecyclingStationSessionVisit,
	IRecyclingStationVisit,
} from "./types"

export function useGetRecyclingStationVisits() {
	const dataFetch = useSiteConfig(state => state.siteConfig.recyclingStationVisits.data)

	// Used to get params from the URL, where we want the default time value
	const args = useArgs()
	const timespan = "timestamp" in args && args.timestamp?.value ? args.timestamp.value : undefined
	const [from, to] = useMemo(
		() => timespan || [moment().startOf("day").toISOString(), moment().endOf("day").toISOString()],
		[timespan]
	)

	// Get data with GQL with variables attained from the URL (start and end date)
	const { loading, error, data, client } = useQuery(dataFetch.fetchAll, {
		variables: {
			start: from,
			end: to,
		},
	})

	const invoices = useMemo<IRecyclingStationVisit[]>(
		() => (data ? parseRecyclingStationVisitData(data.store.accessPoints) : []),
		[data]
	)
	return { loading, error, data: invoices, client }
}

export const parseRecyclingStationVisitData = (
	data: IGetRecyclingStationVisits["store"]["accessPoints"]
): IRecyclingStationVisit[] =>
	data?.flatMap(
		accessPoint =>
			//get all sessions from relevant accesspoint
			accessPoint.children
				.flatMap(session => {
					// get the visits associated with the session
					const sessionVisits = session.eventsAggregate.groupBy.aggKey.keyValue

					return sessionVisits?.map(visitData => {
						const customer = createCustomer(session)

						let visit = createVisit(visitData, customer)

						// Get zone weights and payment type
						visitData.values.map(event => {
							const eventType = event.type.toLowerCase()

							switch (eventType) {
								case "out":
									visit.zoneWeights.push(parseZoneWeight(event))
									break
								case "payment":
									visit = parseAndMapPaymentData(visit, event)
									break
								default:
									break
							}

							return visit
						})

						return visit
					})
				})
				//remove undefined entries in the array
				.filter(item => item) as IRecyclingStationVisit[]
	)

function calculateVisitDurationInMinutes(maxTimestamp: number, minTimestamp: number): number {
	// Get the time difference from start to end, then get the nr of minutes. Round to nearest int
	return Math.round((maxTimestamp - minTimestamp) / 1000 / 60)
}

function createCustomer(session: IRecyclingStationSession): IRecyclingStationCustomer {
	return session.customers.length > 0
		? session.customers[0]
		: {
				// Replace "p-max-xxx-x" with "Paper-QR xxx-x"
				name:
					session.sessionId?.replace(/p-man-([0-9]{1,}-\d)/g, "Papir-QR $1") || session.sessionId, //use sessionId to get p-man values.
				customerClass: session.sessionId?.replace(/p-man-[0-9]{1,}-(\d)/g, "$1") || "unknown",
				orgNr: "",
				postalCode: "",
		  }
}

function createVisit(
	visitData: IRecyclingStationSessionVisit,
	customer: IRecyclingStationCustomer
): IRecyclingStationVisit {
	const visitId = visitData.key === "undefined" ? translate("unknown") : visitData.key
	const visitDurationInMinutes = calculateVisitDurationInMinutes(
		visitData.aggregate.max.timestamp,
		visitData.aggregate.min.timestamp
	)
	const formattedTimestamp = moment(visitData.aggregate.max.timestamp)
		.locale(localeFromLocalStorage)
		.format("lll")

	return {
		orderId: "",
		visitId,
		timestampRaw: visitData.aggregate.max.timestamp,
		timestamp: formattedTimestamp,
		customerClass: customer.customerClass,
		id: visitData.key,
		name: customer.name,
		orgNr: customer.orgNr,
		paymentType: "Ukjent",
		paymentAmountExcludingVAT: 0,
		paymentAmountIncludingVAT: 0,
		totalBillableWeight: "",
		visitDurationInMinutes: visitDurationInMinutes,
		postalCode: customer.postalCode,
		zoneWeights: [],
		customerRepresentativeName: "",
		referenceText: "",
	}
}

function parseAndMapPaymentData(visit: IRecyclingStationVisit, event: IRecyclingStationEvent) {
	// TODO: Clean up this mutable mess
	visit.paymentType = event.paymentType
	visit.paymentAmountExcludingVAT = parseFloat(event.paymentAmountExcludingVAT)
	visit.paymentAmountIncludingVAT = parseFloat(event.paymentAmountIncludingVAT)
	visit.totalBillableWeight = event.totalBillableWeight
	visit.referenceText = event.paymentRef
	visit.customerRepresentativeName = event.initiatedByCustomerRefName
	visit.orderId = event.properties.find(p => p.key === "orderId")?.value || ""

	// vipps payments are stored in "øre"
	if (event.paymentType !== null) {
		if (event.paymentType.toLowerCase() === "vipps") {
			visit.paymentAmountExcludingVAT = visit.paymentAmountExcludingVAT / 100
			visit.paymentAmountIncludingVAT = visit.paymentAmountIncludingVAT / 100
		}
	}

	return visit
}

function parseZoneWeight(event: any) {
	const zoneWeight = event.deltaWeight

	if (zoneWeight !== null) {
		let zoneName = ""
		if (event.children.length > 0) {
			event.children.forEach((container: any) => {
				zoneName = container.point?.point?.eid ?? ""
			})
		}

		return `${zoneName} - ${zoneWeight}kg`
	}
	return ""
}

export const parseRecyclingStationVisitByIdData = (
	data: IGetRecyclingStationVisitById
): IRecyclingStationEventNonAggNormalized[] =>
	data.store.events?.map(e => ({
		...e,
		sessionId: e.point?.point?.sessionId,
	})) || ([] as IRecyclingStationEventNonAggNormalized[])

import { IKSDefaultDashboard } from "../../Dashboard/siteDashboards/IKSDefault"
import { mergeConfig } from "../config"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { defaultConfig } from "./default"
import { default_fetchAll_points } from "./default/queries/default_fetchAll_Points"
import { default_fetchById_points } from "./default/queries/default_fetchById_points"
import { iks_drawerContent_containers } from "./iks/drawer/iks_drawerContent_containers"
import { iks_drawerContent_customers } from "./iks/drawer/iks_drawerContent_customers"
import { iks_drawerContent_points } from "./iks/drawer/iks_drawerContent_points"
import { iks_eventsDatesConfig } from "./iks/iks_eventDates"
import { filterConfigGen } from "./_drawerFragments/filterConfigGenerator"
import {
	fillLevel,
	filterFuzzy,
	fractionByHierarchyEQ,
	installationEQ,
	printedTagFuzzy,
} from "./_drawerFragments/filters"

export const iksConfig = (): ISiteConfig => mergeConfig(defaultConfig(), iksConfigObj)

export const iksConfigObj: IPartialSiteConfig = {
	menu: ["customers", "points", "events", "allocations"],
	dashboard: IKSDefaultDashboard,
	eventsDates: iks_eventsDatesConfig,
	customers: {
		history: { action: true },
		drawer: {
			content: iks_drawerContent_customers,
		},
	},
	points: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ path: "installation.name" },
			{ name: "headers.printedTag", path: "externalKeys.printedTag" },
			{ path: "fillLevel" },
		],
		history: { action: true, location: true },
		filters: filterConfigGen([
			filterFuzzy("name"),
			fractionByHierarchyEQ,
			fillLevel,
			installationEQ,
			printedTagFuzzy,
		]),
		drawer: {
			ui: "drawer",
			content: iks_drawerContent_points,
		},
		data: {
			fetchAll: default_fetchAll_points({ fillLevel: false, pos: true }),
			fetchById: default_fetchById_points({ fillLevel: true }),
			postFetchAll: default_fetchAll_points({ essentials: false, fillLevel: true }),
		},
	},
	containers: {
		drawer: { ui: "drawer", content: iks_drawerContent_containers },
	},
	stationModal: {
		modes: ["create"],
		// inletHatchSizeProp: true,
		// installationAutoKey: { key: "guid", generator: "uuid" },
		// stationAutoKey: { key: "guid", generator: "uuid" },
	},
	identitiesModal: {
		externalKeyList: ["ISO", "printed"],
	},
}

import { SVGProps } from 'react'

export const TekstilerIcon = ({
  fill = 'black',
  width = '285',
  height = '247',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 285 247"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.5 5.69998C97.3 13.3 107 22.9 115.1 26.9C131.5 34.9 153.5 34.9 170 26.8C178 22.9 187.4 13.7 191.5 5.79999L194.5 0H239.8H285V40.5V81H254H223V164V247H142.5H62V164V81H31H0V40.5V0H45.4H90.8L93.5 5.69998Z"
        fill={fill}
      />
    </svg>
  )
}

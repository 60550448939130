import { lfmEnterprise_translations } from "../../Contexts/translations/configurations/lfmEnterprise"
import { lfmEnterpriseDashboard } from "../../Dashboard/siteDashboards/lfmEnterprise"
import { IStationConfigState } from "../../Modals/StationModal/StationConfigModalReducer"
import {
	customerNameFilter,
	dateTimeFilter,
	eventTypeFilter,
	identityIsoFilter,
	pointIdFilter,
	pointParentNameFilter,
} from "../../Resources/Events/configurations/filters"
import { IAuthJwt } from "../../types"
import { mergeConfig } from "../config"
import { lfmEnterprise_containertypes } from "../datasets/containerTypes/lfmEnterprise"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { defaultConfig } from "./default"
import { default_fractionsList } from "./default/default_fractions"
import { Idefault_fetcAll_customers } from "./default/queries/default_fetchAll_customers"
import { default_fetchAll_points } from "./default/queries/default_fetchAll_Points"
import { default_fetchById_points } from "./default/queries/default_fetchById_points"
import { lfmEnterprise_drawerContent_containers } from "./lfmEnterprise/drawer/lfmEnterprise_drawerContent_containers"
import { lfmEnterprise_drawerContent_customers } from "./lfmEnterprise/drawer/lfmEnterprise_drawerContent_customers"
import { lfmEnterprise_eventsDatesConfig } from "./lfmEnterprise/lfmEnterprise_eventDates"
import { _cf_lfmEnterprise_accessPointModal } from "./_configFragments/_cf_lfmEnterprise_accessPointModal"
import { eventFilterConfigGen, filterConfigGen } from "./_drawerFragments/filterConfigGenerator"
import {
	fillLevel,
	filterEQ,
	filterFuzzy,
	fractionByHierarchyEQ,
	fractionEQ,
	installationEQ,
	stationEQ,
} from "./_drawerFragments/filters"

export const lfmEnterpriseConfig = (): ISiteConfig =>
	mergeConfig(defaultConfig(), lfmEnterpriseConfigObj)

export const lfmEnterpriseConfigObj: IPartialSiteConfig = {
	menu: ["customers", "points", "containers", "events"],
	translations: lfmEnterprise_translations,
	dashboard: lfmEnterpriseDashboard,
	eventsDates: lfmEnterprise_eventsDatesConfig,
	identitiesModal: {
		modes: ["edit"],
		externalKeyList: ["ISO", "printed"],
	},
	customers: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ name: "headers.category", path: "properties.Category" },
		],
		drawer: { content: lfmEnterprise_drawerContent_customers },
		filters: filterConfigGen([
			filterFuzzy("name"),
			filterEQ({ lookupKey: "properties.Category", translateName: "properties.customerCategory" }),
			stationEQ,
		]),
		data: {
			fetchAllParse: (data: Idefault_fetcAll_customers) =>
				data.store.data
					// .filter(customer => !customer.customerType?.includes("PARENT"))
					.map(customer => ({
						...customer,
						properties: customer.properties?.reduce(
							(prev, prop) => ({ ...prev, [prop.key]: prop.value }),
							{}
						),
						externalKeys: customer.externalKeys?.reduce(
							(prev, extKey) => ({ ...prev, [extKey.key]: extKey.value }),
							{}
						),
					})),
		},
	},
	points: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ path: "terminal.name" },
			{ path: "fillLevel" },
		],
		filters: filterConfigGen([
			filterFuzzy("name"),
			fractionByHierarchyEQ,
			fillLevel,
			installationEQ,
		]),
		data: {
			fetchAll: default_fetchAll_points({ fillLevel: true }),
			fetchById: default_fetchById_points({ fillLevel: true }),
		},
	},
	containers: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ path: "properties.fraction", name: "properties.fraction" },
			{ path: "parent.name" },
			{ path: "terminal.name" },
		],
		filters: filterConfigGen([
			filterFuzzy("name"),
			fractionEQ,
			filterEQ({ lookupKey: "parent.name", id: "parent" }),
			filterEQ({ lookupKey: "terminal.name", id: "terminal" }),
		]),
		drawer: {
			content: lfmEnterprise_drawerContent_containers,
		},
	},
	events: {
		filters: eventFilterConfigGen([
			dateTimeFilter(),
			customerNameFilter(),
			pointParentNameFilter(),
			pointIdFilter({ placeholder: "headers.properties.fraction" }),
			identityIsoFilter(),
			eventTypeFilter(),
		]),
		headers: [
			{ path: "checkbox" },
			{ path: "timestamp" },
			{ path: "customer.name" },
			{ path: "properties.weight", name: "headers.weight" },
			{ path: "properties.weightUnit", name: "headers.weightUnit" },
			{ path: "point.properties.fraction", name: "headers.fraction" },
			{ path: "type" },
			{ path: "point.parent.name" },
			{ path: "result" },
		],
		columnSizes: {
			"properties.weightUnit": "0.2fr",
		},
	},
	customerModal: {
		modes: ["create", "upsert"],
		customerTypes: ["BUSINESS"],
		allocationScheme: ["NONE"],
		propertyList: [
			{ key: "taxNumber", name: "taxNumber", value: "" },
			{ key: "Category", name: "customerCategory", value: "" },
			{ key: "contactPerson", name: "contactPerson", value: "" },
			{ key: "contactMobile", name: "contactMobile", value: "" },
			{ key: "contactEmail", name: "contactEmail", value: "", required: true },
			{ key: "Area", name: "totalCustomerArea", value: "", required: true }, // Area, is it areal size for shop
		],
		customerAutoKey: {
			generator: "nanoid",
			key: "ImportID",
			lenght: 5,
		},
		externalKeyList: [{ key: "ImportID", name: "CustomerID", value: "", required: true }], // find out about this
		mandatory: {
			station: (jwt: IAuthJwt | null) => jwt?.permissions.some(p => p.includes("terminalList")),
		},
	},
	stationModal: {
		modes: ["create"],
		inletHatchSizeProp: false,
		status: {
			hide: true,
			default: "ACTIVE",
		},
		inletStatus: {
			hide: true,
			default: "ACTIVE",
		},
		inletNameField: {
			enabled: true,
		},
		installationAutoKey: { generator: "nanoid", key: "ImportID" },
		installationAutoProp: {
			generator: "fn",
			key: "accessToken",
			fn: ({ terminal }: IStationConfigState) =>
				terminal.name.trim().replaceAll(" ", "_").toLocaleUpperCase(),
		},
		stationAutoKey: { generator: "nanoid", key: "ImportID", lenght: 5 },
		installationPropertyList: [
			{ key: "operationsContactEmail", name: "operationsContactEmail", required: true },
		],
		stationPropertyList: [],
		inletPropertyList: [],
		inletAutoKey: {
			generator: "nanoid",
			key: "ImportID",
			lenght: 5,
		},
		containerTypes: lfmEnterprise_containertypes(),
	},
	accessPointModal: _cf_lfmEnterprise_accessPointModal(
		default_fractionsList,
		lfmEnterprise_containertypes()
	),
}

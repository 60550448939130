import { CSSProperties, MouseEventHandler } from "react"

export const SvgRemove = ({
	className,
	onClick,
	style,
}: {
	className?: string
	onClick?: MouseEventHandler<SVGSVGElement>
	style?: CSSProperties
}) => (
	<svg
		className={className}
		onClick={onClick}
		style={style}
		viewBox="0 0 18 18"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z"></path>
	</svg>
)

import { SVGProps } from 'react'

export const GlassIcon = ({
  fill = 'black',
  width = '139',
  height = '379',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 139 379"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.4653 353.248H25.3693C25.3693 353.248 31.6693 328.051 69.4653 328.051C107.26 328.051 113.559 353.248 113.559 353.248H69.4653ZM116.861 129.823L94.6613 101.28V0.49197H44.268V101.28L22.0667 129.823C7.87867 148.067 0.17334 170.521 0.17334 193.633V378.445H69.4653H138.756V193.633C138.756 170.521 131.051 148.067 116.861 129.823"
        fill={fill}
      />
    </svg>
  )
}

import React, { useState } from "react"
import { useTranslation } from "../../../../Contexts"
import { useQueryParam } from "../../../../domHooks"
import "./SearchOnceInput.css"

type ISearchInput = {
	placeholder: any
	filterKey: any
}

const SearchOnceInput = ({ placeholder, filterKey }: ISearchInput) => {
	const { trans } = useTranslation()
	const [filter, setFilter] = useQueryParam(`filter.${filterKey}`, "") as [
		string,
		(newValue: any) => void
	]
	const [value, setValue] = useState<string>(filter?.length ? filter : "")
	const [showCheckButton, setCheckButton] = useState<boolean>(false)

	const setValues = (_value: string) => {
		setValue(_value)
		if (!showCheckButton && _value) {
			setCheckButton(true)
		} else if (showCheckButton && !_value) {
			setCheckButton(false)
		}
	}

	const setFilterHideButton = (_value: string | undefined) => {
		setFilter(_value)
		if (!_value) {
			setValue("")
		}
		setCheckButton(false)
	}

	return (
		<label className="filter-searchbox searchOnceInput">
			<input
				type="text"
				placeholder={trans(placeholder)}
				value={value}
				onChange={(ev) => setValues(ev.target.value)}
			/>
			{filter && (
				<button
					onClick={() => setFilterHideButton(undefined)}
					className="clear"
				>
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z"></path>
					</svg>
				</button>
			)}
			{showCheckButton && (
				<button onClick={() => setFilterHideButton(value)}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						height="24px"
						viewBox="0 0 24 24"
						width="24px"
						fill="#000000"
					>
						<path d="M0 0h24v24H0z" fill="none" />
						<path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
					</svg>
				</button>
			)}
		</label>
	)
}

export default SearchOnceInput

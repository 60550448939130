import { isEmpty, isEqual, uniqWith } from "lodash"
import { IMultiKeyValueFields, IMultiKeyValueObjs } from "./types"

// Filtering out all unique fields in object array and setting in to a object with empty values
export const getDynamicEmptyFieldsFromInitialArray = (
	arr: { [key: string]: string | null }[]
) =>
	uniqWith(
		arr.reduce((prev, curr) => [...prev, ...Object.keys(curr)], [] as string[]),
		isEqual
	).reduce(
		(prev, curr) => ({ ...prev, [curr]: "" }),
		{} as { [key: string]: string | null }
	)

export const getDynamicEmptyFieldsFromFields = (fields: string[]) =>
	fields.reduce(
		(prev, curr) => ({ ...prev, [curr]: "" }),
		{} as { [key: string]: string | null }
	)

export const fromArrayToObj = (
	arr: { [key: string]: string | null }[]
): IMultiKeyValueObjs | null => {
	const obj = arr.reduce(
		(prev, curr, i) => ({
			...prev,
			[i]: {
				index: i.toString(),
				fields: {
					...curr,
				},
			},
		}),
		{} as IMultiKeyValueObjs
	)
	return isEmpty(obj) ? null : obj
}

export const fromObjToArray = (
	obj: IMultiKeyValueObjs
): IMultiKeyValueFields[] | null =>
	isEmpty(obj)
		? null
		: Object.values(obj)
				.map((kv) => {
					const { index, ...other } = kv
					return "fields" in other ? other.fields : {}
				})
				.filter((kv) => !isEmpty(kv))

import React from "react"
import { useTranslation } from "../../Contexts"
import moment from "moment"
import { localeFromLocalStorage } from "../../Contexts/Translation"
import { IHistoryData } from "./HistoryTypes"

export interface IHistoryEntryProps {
	data: IHistoryData
}

export const LocationEntry = ({ data }: IHistoryEntryProps) => {
	const { trans } = useTranslation()
	const { type, timestamp, user } = data

	return (
		<div className={`point-history-entry`}>
			<div className={`point-history-header`}>
				<div className="flex-row">
					{moment(timestamp).locale(localeFromLocalStorage).format("lll")}
				</div>
				<div className="flex-row">
					{type === "created"
						? trans("history.created")
						: user
						? trans("history.placedWithUser", [user])
						: trans("history.placedWithoutUser")}
				</div>
			</div>
		</div>
	)
}

import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import {
	df_input_address,
	df_input_gps,
	df_input_name,
	df_input_status,
	df_input_terminal,
} from "../../_drawerFragments/inputs"

export const iks_drawerContent_points: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [
			df_input_name({ header: "stationModal.stationName" }),
			{
				updater: "externalKey",
				key: "externalKeys.printedTag",
				header: "headers.id",
				permission: "edit",
				type: "text",
			},
			df_input_terminal(),
			df_input_status(),
			df_input_address(),
			df_input_gps(),
			{
				updater: "property",
				key: "properties.Municipality",
				header: "properties.municipality",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.owner",
				header: "properties.owner",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.vendor",
				header: "properties.vendor",
				permission: "edit",
				type: "text",
			},
			{
				updater: "property",
				key: "properties.contact",
				header: "properties.contact",
				permission: "edit",
				type: "text",
			},
		],
	},
	pointEditorDrawer: {
		comp: "pointEditorDrawer",
		order: 4,
		config: {
			permissions: ["read"],
			title: "accessPoints",
		},
	},
}

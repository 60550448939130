import { SVGProps } from 'react'

export const RestavfallIcon = ({
  fill = 'black',
  width = '279',
  height = '370',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 279 370"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.961 104.98C145.764 104.98 133.165 104.98 107.969 104.98C63.8733 104.98 0.88266 142.776 0.88266 281.359C0.88266 338.051 0.88266 369.547 0.88266 369.547H278.047C278.047 369.547 278.047 338.051 278.047 281.359C278.047 142.776 215.055 104.98 170.961 104.98Z"
        fill={fill}
      />
      <path
        d="M170.961 79.7827L196.157 35.6894L158.361 16.7907L145.764 54.5867H142.615L125.676 0.650688L75.2813 19.5494L107.969 79.7827H170.961"
        fill={fill}
      />
    </svg>
  )
}

import { filterEventsByProperties } from "../../filters/filters"
import { IDates, IEventModified } from "../../types"
import { getEvents } from "../events"

export const fetchAndfilterEventsByProperties =
	(property: string) => (propertyValues: string | string[], dates: IDates) =>
		propertyValues?.length
			? getEvents(dates).then(events =>
					filterEventsByProperties(property)(
						events,
						typeof propertyValues == "string" ? [propertyValues] : propertyValues
					)
			  )
			: (new Promise(() => []) as Promise<IEventModified[]>)

import { Moment } from "moment"
import { useApolloStore } from "../../../../States/apollo"
import { fetchEventsByCustomerIds } from "../eventsByCustomerIds/fetch"
import { GET_CUSTOMER_IDS_BY_IDENTITY_VALUES } from "./queries"
import { ICustomerIdsByIdentityIds } from "./types"

// const events = fetchData("EM", ["3B-53-ED-51-0B", "25-D2-8C-4E-A1", "21-22-AE-5F-EA"], dates)
// .then(_data =>
// 	_data?.store?.customers?.length
// 			? normalize(_data.store.customers, currentLocale)
// 			: [],
// )
// .catch((err) => {
// 	console.log("Error in GET_EVENTS_BY_IDENTITY: ", err)
// })

export const fetchEventsByIdentity = (
	identKey: string,
	identValues: string[],
	dates: { from: Moment; to: Moment }
) =>
	fetchCustomerIdsByIdentityValues(
		identKey,
		identValues
	).then((customerIds: string[]) =>
		fetchEventsByCustomerIds(customerIds, dates)
	)

const fetchCustomerIdsByIdentityValues = (
	identKey: string,
	identValues: string[]
) => {
	const { client } = useApolloStore.getState()

	if (client) {
		return client
			.query<ICustomerIdsByIdentityIds>({
				query: GET_CUSTOMER_IDS_BY_IDENTITY_VALUES,
				variables: {
					key: identKey,
					values: identValues,
				},
			})
			.then((e) => {
				const customerIds = e.data.store.identities
					.map((i) => i.customer.id)
					.filter((i) => i !== null) as string[]
				return Array.from(new Set(customerIds))
			})
	} else {
		throw new Error("Problems connecting to api client")
	}
}

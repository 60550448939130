import { BatterierIcon } from "../../../Icons/fractions/batterier"
import { ElektronikkIcon } from "../../../Icons/fractions/elektronikk"
import { FrityrIcon } from "../../../Icons/fractions/frityr"
import { GassbeholdereIcon } from "../../../Icons/fractions/gassbeholdere"
import { GlassIcon } from "../../../Icons/fractions/glass"
import { HageavfallIcon } from "../../../Icons/fractions/hageavfall"
import { HarIcon } from "../../../Icons/fractions/har"
import { HardplastIcon } from "../../../Icons/fractions/hardplast"
import { KaffegrutIcon } from "../../../Icons/fractions/kaffegrut"
import { KostemasseIcon } from "../../../Icons/fractions/kostemasse"
import { LithiumbatterierIcon } from "../../../Icons/fractions/lithiumbatterier"
import { LysparerIcon } from "../../../Icons/fractions/lysparer"
import { LysstoffrorIcon } from "../../../Icons/fractions/lysstoffror"
import { MalingLimLakkIcon } from "../../../Icons/fractions/malinglakklim"
import { MatavfallIcon } from "../../../Icons/fractions/matavfall"
import { MetallembalasjeIcon } from "../../../Icons/fractions/metallembalasje"
import { MykPlastemballasjeIcon } from "../../../Icons/fractions/mykPlastemballasje"
import { PappIcon } from "../../../Icons/fractions/papp"
import { PlastIcon } from "../../../Icons/fractions/plast"
import { PlastfolieIcon } from "../../../Icons/fractions/plastfolie"
import { PorselenIcon } from "../../../Icons/fractions/porselen"
import { RengjoringsmiddlerIcon } from "../../../Icons/fractions/rengjoringsmiddler"
import { RestavfallIcon } from "../../../Icons/fractions/Restavfall"
import { SkoIcon } from "../../../Icons/fractions/sko"
import { SpraybokserIcon } from "../../../Icons/fractions/spraybokser"
import { TekstilerIcon } from "../../../Icons/fractions/tekstiler"
import { TrevirkeIcon } from "../../../Icons/fractions/trevirke"

// NOTE: You can find color set here: src\Dashboard\styles\dashboard.css
export const fractionMapping: {
	[fraction in string | number]: {
		color: string
		icon?: JSX.Element
	}
} = {
	"-8": {
		color: "var(--lightgrey-6)",
		icon: <MykPlastemballasjeIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	"-4": {
		color: "var(--black-5)",
		icon: <TekstilerIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	"-3": {
		color: "var(--black-4)",
		icon: <SkoIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	"-2": {
		color: "var(--black-3)",
		icon: <LysstoffrorIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	"-1": {
		color: "var(--black-2)",
		icon: <LysstoffrorIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1: {
		color: "var(--black-1)",
		icon: <HarIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	2: {
		color: "var(--fucales-1)",
		icon: <KaffegrutIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1111: {
		color: "var(--fucales-2)",
		icon: <MatavfallIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1128: {
		color: "var(--fucales-3)",
		icon: <FrityrIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1131: {
		color: "var(--fucales-4)",
		icon: <HageavfallIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1149: {
		color: "var(--fucales-5)",
		icon: <TrevirkeIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1221: {
		color: "var(--beige-1)",
		icon: <PappIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1231: {
		color: "var(--beige-2)",
		icon: <PappIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1299: {
		color: "var(--beige-3)",
		icon: <PappIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1300: {
		color: "var(--beige-4)",
		icon: <GlassIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1322: {
		color: "var(--beige-5)",
		icon: <GlassIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1452: {
		color: "var(--beige-6)",
		icon: <MetallembalasjeIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1599: {
		color: "var(--beige-7)",
		icon: <ElektronikkIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1618: {
		color: "var(--beige-8)",
		icon: <PorselenIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1700: {
		color: "var(--lightgrey-1)",
		icon: <PlastIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1711: {
		color: "var(--lightgrey-2)",
		icon: <PlastfolieIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1712: {
		color: "var(--lightgrey-3)",
		icon: <PlastfolieIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1722: {
		color: "var(--lightgrey-4)",
		icon: <HardplastIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1731: {
		color: "var(--lightgrey-5)",
		icon: <PlastfolieIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	1799: {
		color: "var(--lightgrey-6)",
		icon: <PlastIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	7051: {
		color: "var(--carrot-1)",
		icon: <MalingLimLakkIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	7055: {
		color: "var(--carrot-2)",
		icon: <SpraybokserIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	7086: {
		color: "var(--carrot-3)",
		icon: <LysstoffrorIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	7093: {
		color: "var(--carrot-4)",
		icon: <BatterierIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	7094: {
		color: "var(--carrot-5)",
		icon: <LithiumbatterierIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	7133: {
		color: "var(--carrot-6)",
		icon: <RengjoringsmiddlerIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	7261: {
		color: "var(--carrot-7)",
		icon: <GassbeholdereIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	7086021: {
		color: "var(--carrot-3)",
		icon: <LysparerIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	9912: {
		color: "var(--lime-1)",
		icon: <RestavfallIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	9913: {
		color: "var(--lime-2)",
		icon: <RestavfallIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	9916: {
		color: "var(--lime-3)",
		icon: <KostemasseIcon width="32px" height="32px" x={-16} y={-16} />,
	},
	9999: {
		color: "var(--lime-4)",
		icon: <RestavfallIcon width="32px" height="32px" x={-16} y={-16} />,
	},
}

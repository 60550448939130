import { mergeConfig } from "../config"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { iksConfig } from "./iks"

export const iksWithCreateCustomer = (): ISiteConfig =>
	mergeConfig(iksConfig(), iksWithCreateCustomerObj)

export const iksWithCreateCustomerObj: IPartialSiteConfig = {
	customerModal: {
		modes: ["create", "upsert"],
		customerTypes: ["BUSINESS", "HOUSEHOLD"],
		allocationScheme: ["NONE"],
		useDescriptionField: true,
		propertyList: [{ key: "address" }],
	},
}

export const lfmEnterprise_translations: any = {
	en: {
		type: {
			EMPTYING_REQUEST: "Pickup request",
			EMPTYING_REQUEST_ACCEPTED: "Pickup accepted",
			REPORT_ANOMALY: "Anomaly report",
		},
		fractions: "Waste types",
		area: "Area",
		headers: {
			fraction: "Waste type",
			point: {
				fraction: "Waste type",
			},
			properties: {
				fraction: "Waste type",
			},
		},
		properties: {
			fraction: "Waste type",
			fractionDesc: "Waste type description",
		},
		stationModal: {
			selectFraction: "Select waste type",
			noIFraction: "Please select the waste type of inlet",
		},
		fractionDistribution: "Waste type distribution",
		fewRegisteredFractions: "Few registered fractions",
	},
	nb: {
		type: {
			EMPTYING_REQUEST: "Hentebestilling",
			EMPTYING_REQUEST_ACCEPTED: "Henting akseptert",
			REPORT_ANOMALY: "Avviksmelding",
		},
		fractions: "Avfallstyper",
		area: "Areal",
		headers: {
			fraction: "Avfallstype",
			point: {
				fraction: "Avfallstype",
			},
			properties: {
				fraction: "Avfallstype",
			},
		},
		properties: {
			fraction: "Avfallstype",
			fractionDesc: "Beskrivelse av avfallstype",
		},
		stationModal: {
			selectFraction: "Velg avfallstype",
			noIFraction: "Vennligst oppgi nedkastets avfallstype",
		},
		fractionDistribution: "Fordeling per avfallstype",
		fewRegisteredFractions: "Få registrerte avfallstyper",
	},
}

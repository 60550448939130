import { isArray, mergeWith } from "lodash"
import { match } from "ts-pattern"
import { birConfig } from "./configurations/bir"
import { defaultConfig } from "./configurations/default"
import { himConfig } from "./configurations/him"
import { ihmConfig } from "./configurations/ihm"
import { irisConfig } from "./configurations/iris"
import { orklaConfig } from "./configurations/orkla"
import { lfmEnterpriseConfig } from "./configurations/lfmEnterprise"
import { config, site, staging } from "./devWindowParams"
import { IPartialSiteConfig, ISite, ISiteConfig, IConfig } from "./types"
import { kvamConfig } from "./configurations/kvam"
import { iksConfig } from "./configurations/iks"
import { iksWithCreateCustomer } from "./configurations/iksWithCreateCustomer"

// Get sitename from window.site in production else
// { site } from "./devWindowParams" in dev
const getCurrentSite = (_site: any) => ("site" in window ? (window as any).site : _site)

const getCurrentConfig = (_config: any) => ("config" in window ? (window as any).config : _config)

// Get isStaging from window.isStaging in production else
// { staging } from "./devWindowParams" in dev
const getIfIsStaging = (_isStaging: boolean) =>
	"isStaging" in window ? (window as any).isStaging : _isStaging

export const siteName = getCurrentSite(site) as ISite
export const configName = (getCurrentConfig(config) as IConfig) || (getCurrentSite(site) as ISite)
export const isStaging = getIfIsStaging(staging) as boolean

export const mergeConfig = (
	_defaultConfig: ISiteConfig,
	_localConfig: IPartialSiteConfig
): ISiteConfig =>
	mergeWith(_defaultConfig, _localConfig, (_defaultValue, _localValue) => {
		if (isArray(_defaultValue)) {
			return _localValue
		}
	})

// Config logic
export const siteConfig = (_siteName: IConfig): ISiteConfig =>
	match(_siteName)
		.with("default", defaultConfig)
		.with("iks", iksConfig)
		.with("iksWithCreateCustomer", iksWithCreateCustomer)
		.with("bir", birConfig)
		.with("iris", irisConfig)
		.with("orkla", orklaConfig)
		.with("lfmEnterprise", lfmEnterpriseConfig)
		.with("ihm", ihmConfig)
		.with("him", himConfig)
		.with("kvam", kvamConfig)
		.otherwise(defaultConfig)

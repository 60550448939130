import { SVGProps } from 'react'

export const KaffegrutIcon = ({
  fill = 'black',
  width = '46',
  height = '68',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 46 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1592 59.0843L23.4215 57.9322L23.5526 57.3608C23.5901 57.1735 23.6276 56.9674 23.6557 56.7801L23.8617 55.6186C23.9367 55.2345 23.9741 54.8411 24.0303 54.4477C24.0772 54.0543 24.1427 53.6703 24.1708 53.2769L24.2739 52.106C24.405 50.5418 24.4331 48.9682 24.4237 47.3945C24.3675 45.8209 24.3207 44.2567 24.1334 42.6924C24.0865 42.299 24.0584 41.915 24.0022 41.5215L23.843 40.3507C23.7962 39.9667 23.7306 39.5733 23.665 39.1798C23.5995 38.7864 23.5339 38.393 23.459 37.9996C23.1967 36.4166 22.8782 34.8337 22.5504 33.2413L21.5669 28.4174C21.2578 26.7876 20.9299 25.1577 20.6676 23.4904C20.1431 20.1746 19.7778 16.7557 19.8434 13.3181C19.8715 11.5946 20.012 9.87112 20.2836 8.16636C20.4241 7.31398 20.5833 6.4616 20.7988 5.61859C21.0049 4.78494 21.2578 3.90446 21.595 3.08955C21.7542 2.68678 21.9322 2.30274 22.1195 1.9187C22.5972 0.47621 21.3514 0.307609 20.6021 0.326343C5.36229 1.54403 0.0981445 18.0109 0.0981445 34.3372C0.0981445 48.8277 5.22179 62.9341 16.2465 66.7463C18.2135 67.4301 20.3866 66.4841 21.239 64.5826C21.7074 63.5335 22.1195 62.4751 22.4661 61.3979C22.6066 61.0326 22.6909 60.6392 22.8126 60.2552C22.9438 59.843 23.0655 59.4683 23.1592 59.0843Z"
        fill={fill}
      />
      <path
        d="M27.9082 0.935183C26.0161 0.438742 24.0772 1.64706 23.6932 3.56725C23.6838 3.64219 23.6651 3.70776 23.6557 3.78269C23.5808 4.15736 23.5246 4.5414 23.4777 4.91607C23.4403 5.30011 23.3934 5.67479 23.3747 6.06819C23.3185 6.855 23.281 7.64181 23.281 8.42863C23.281 9.21544 23.2904 10.0022 23.3279 10.7891C23.3653 11.5759 23.4309 12.3627 23.5058 13.1495C24.096 19.4534 25.8663 25.6635 27.1964 32.2109C27.5242 33.8501 27.8239 35.5081 28.0862 37.1847C28.1518 37.6062 28.208 38.0277 28.2548 38.4492C28.311 38.8707 28.3578 39.2922 28.3953 39.7231L28.5264 41.0157C28.5733 41.4466 28.5827 41.8775 28.6014 42.3084C28.77 45.7553 28.5827 49.2304 27.8989 52.6306C27.2151 56.0214 26.1285 59.3466 24.4425 62.3627C23.6089 63.8426 22.6535 65.2477 21.5669 66.5309C21.1735 66.9992 21.5107 67.7205 22.1196 67.7205C22.1289 67.7205 22.1289 67.7205 22.1383 67.7205C37.453 67.7205 45.8176 51.2724 45.8176 34.3091C45.7988 19.3878 40.8906 4.33533 27.9082 0.935183Z"
        fill={fill}
      />
    </svg>
  )
}

import { SVGProps } from 'react'

export const LysstoffrorIcon = ({
  fill = 'black',
  width = '351',
  height = '38',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 351 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44 19V38H31.5H19V33.5V29.1L9.8 28.8L0.5 28.5V19V9.5L9.8 9.2L19 8.89999V4.5V0H31.5H44V19ZM289 19V38H175.5H62V19V0H175.5H289V19ZM332 4.5V9H341.5H351V19V29H341.5H332V33.5V38H319.5H307V19V0H319.5H332V4.5Z"
        fill={fill}
      />
    </svg>
  )
}

import React, { useCallback } from "react"
import { InputSelect } from "../../../../UI"
import { useQueryParam } from "../../../../domHooks"
import "./SearchSelect.css"

const CloseIcon = ({ onClick }: any) => {
	return (
		<div style={{ width: "10px" }}>
			<svg
				onClick={onClick}
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M17.1666 2.48223L15.5177 0.833344L8.99992 7.35112L2.48214 0.833344L0.833252 2.48223L7.35103 9.00001L0.833252 15.5178L2.48214 17.1667L8.99992 10.6489L15.5177 17.1667L17.1666 15.5178L10.6488 9.00001L17.1666 2.48223Z" />
			</svg>
		</div>
	)
}

const SearchSelect = ({ filterKey, placeholder, options }: any) => {
	const [selected, setSelected] = useQueryParam(`filter.${filterKey}`, [])

	const select = useCallback(
		(option: any) => {
			if (selected.findIndex((s: any) => s.value === option.value) === -1) {
				const newSelected = [option, ...selected].sort()
				setSelected(newSelected)
			}
		},
		[selected, setSelected]
	)

	const unSelect = useCallback(
		(option: any) => () => {
			setSelected(selected.filter((v: any) => v.value !== option.value))
		},
		[selected, setSelected]
	)

	return (
		<>
			<InputSelect
				clearOnSelect
				label="label"
				options={options}
				extraClass="search-select"
				placeholder={placeholder}
				onSelect={select}
			/>
			{selected.map((s: any, i: number) => (
				<div className="selected" key={i}>
					{s.label}
					<CloseIcon onClick={unSelect(s)} />
				</div>
			))}
		</>
	)
}

export default SearchSelect

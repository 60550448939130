import { isEqual, uniqWith } from "lodash"

export const uniqueFieldsFromObjectArray = (arr: { [key: string]: string | null }[]) =>
	uniqWith(
		arr.reduce((prev, curr) => [...prev, ...Object.keys(curr)], [] as string[]),
		isEqual
	)

export const isNumber = (a: string | null | undefined) => !isNaN(Number(a))

export const sortAscByField = (field: string) => (a: any, b: any) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0)
export const sortDescByField = (field: string) => (a: any, b: any) => (b[field] < a[field] ? -1 : b[field] > a[field] ? 1 : 0)

/* eslint-disable no-useless-escape */
import { gql } from "apollo-boost"
import { GQL_REQUEST_OPTIONS } from "./gqlRequestTypes"

// JSON stringify puts keys in quotes, where GQL requires keys are quotes
export const objToString = (obj: any, typeList?: string[]) => {
	const unquotedString = JSON.stringify(obj)
		.replace(/"([^"]+)":/g, "\n$1:")
		.replace(/\uFFFF/g, '\\"')
	if (typeList?.length) {
		// NOTE: "(\\w+)" has double \\, because single \ must be escaped in string.
		return unquotedString.replace(RegExp(`(${typeList.join("|")}):"(\\w+)"`, "g"), "$1:$2")
	}
	return unquotedString
}

export const generateGQLrequest = (opts: GQL_REQUEST_OPTIONS) => {
	const {
		type,
		input,
		payload = {
			commandProcessError: true,
			primaryKey: true,
		},
	} = opts

	const typesNotStrings = (() => {
		switch (type) {
			case "upsertCustomersMutation":
				return ["mode"]
			case "upsertAccessPointsMutation":
				return ["mode", "type"]
			default:
				return []
		}
	})()

	// const nestedMutations = (() => {
	// 	switch (type) {
	// 		case "addAccessPointMutation":
	// 			return ["mutations"]
	// 		default:
	// 			return []
	// 	}
	// })()

	// console.log(
	// 	`mutation {${type} (input:
	// 		${objToString(input, typesNotStrings)}
	// 	) {
	// 		${Object.keys(cmdReturn).join(", ")}}
	// 	}`
	// )

	return gql(
		`mutation {\n${type} (input: \n
			${objToString(input, typesNotStrings)}\n
		) {
			${Object.keys(payload).join(", ")}}\n
		}`
	)
}

// ${Object.keys(cmdReturn).join(", ")}}\n

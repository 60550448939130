// import { IFractions } from "./types"

export const fractions = {
	"-8": "Colored, soft plastic",
	"-5": "Repairable textiles",
	"-4": "Ruined clothes",
	"-3": "Shoes",
	"-2": "Energy saving bulb",
	"-1": "Fluorescent tubes",
	"1": "Hair",
	"2": "Coffee grounds",
	"10": "Food waste - Production waste",
	"12": "Food waste - Date expired",
	"13": "Clothes hangers",
	"0000": "Many", // different types
	"0001": "Point",
	"1111": "Food waste",
	"1128": "Frying oil",
	"1131": "Garden waste",
	"1141": "Clean wood",
	"1149": "Mixed processed wood",
	"1200": "Paper and cardboard",
	"1211": "Newspapers and magazines",
	"1221": "Brown paper and corrugated cardboard",
	"1231": "Packaging",
	"1251": "Office paper",
	"1261": "Paper for shredding",
	"1299": "Paper mixed",
	"1300": "Glass",
	"1312": "Glass packaging",
	"1322": "Mixed glass and metal packaging",
	"1447": "Iron and metal",
	"1452": "Mixed metal",
	"1599": "Mixed WEEE waste",
	"1617": "Rock wool and isolation",
	"1618": "Ceramics and porcelain",
	"1700": "Plastic",
	"1711": "Plastic foil",
	"1712": "Plastic foil, other",
	"1722": "Hard plastic, other",
	"1729": "Mixed soft and hard plastic packaging",
	"1731": "Expanded and extruded plastic",
	"1799": "Mixed plastic",
	"1911": "Textiles",
	"6003": "Infectious waste",
	"7011": "Waste oil",
	"7042": "Organic solvents",
	"7051": "Paint, glue and lacquer",
	"7055": "Spray cans",
	"7086": "Fluorescent tubes and energy-saving bulbs",
	"7092": "Car batteries",
	"7093": "Small batteries",
	"7094": "Lithium batteries",
	"7098": "Impregnated wood",
	"7133": "Detergents",
	"7220": "Photo chemicals",
	"7261": "Gases in pressure vessels",
	"7263": "Gases in pressure vessels",
	"9010": "Unsorted waste",
	"9911": "Mixed household waste",
	"9912": "Mixed waste for sorting",
	"9913": "Sorted combustible residual waste",
	"9916": "Debris",
	"9999": "Waste",
	"129917": "Paper and plastic",
	"7086021": "Light bulbs",
	"7051021": "Color cartridges",
	"99111111": "Residual waste and food waste",
	"Shower Soap": "Shower Soap",
	"Hand Dishwash detergent": "Hand Dishwash detergent",
	"Cleaning Spray": "Cleaning Spray",
	Handsoap: "Handsoap",
	"Laundry detergent": "Laundry detergent",
	soap: "Soap",
	NOT_INITIALIZED: "Unknown",
	null: "Unknown",
}

/**
 * Both property and externalKey translations
 */
export const properties = {
	fraction: "Fraction",
	fractionDesc: "Fraction description",
	customerType: "Customer type",
	hardwareReference: "Hardware reference",
	Municipality: "Municipality",
	GeoLocationAccuracy: "Geolocation Accuracy",
	hatchTypeCode: "Hatch type code",
	productName: "Product name",
	productBrand: "Product brand",
	filledVolume: "Volume",
	filleVolumeUnit: "Volume unit",
	ProductionBatch: "Version",
	taxNumber: "Org. number",
	geoLocationName: "Customer address",
	contact: "Contact info",
	CRMID: "CRMID",
	CustomerID: "Customer ID",
	owner: "Station owner",
	municipality: "Municipality number",
	stationNumber: "Station number",
	volume: "Volume",
	binType: "Bin type",
	binCount: "Bin count",
	UID: "Unique ID",
	containerId: "Container ID",
	StationID: "Station ID",
	vendor: "Vendor",
	client: "Client",
	customerCategory: "Category",
	contactPerson: "Contact person",
	contactEmail: "E-mail",
	contactMobile: "Mobile number",
	totalCustomerArea: "Area",
	orgForm: "Legal structure",
	companyCode: "Industry code",
	companyDescription: "Industry description",
	postal: "Postal city",
	postalCode: "Postal code",
	postAddress: "Post address",
	postPostal: "Post postal city",
	postPostalCode: "Post postal code",
	invoiceEmail: "Invoice e-mail",
	assignedCustomers: "Customers assigned",
	gps: "GPS coordinates",
	operationsContactEmail: "Center operation e-mail",
}

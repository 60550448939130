import React, { useState, useRef, useEffect } from "react"
import { get } from "lodash"

import Search from "./Search"
import { Logo } from "./UI"
import { useTranslation, useAuth0 } from "./Contexts"

import "./Nav.css"
import { CARROT_ADMIN, hasPermissions } from "./Utils"

const localeMapping = {
	nb: "NO",
	en: "EN",
}

const LanguageSelect = ({ locale, children }: { locale: "nb" | "en"; children: any }) => {
	const { toggleLocale, currentLocale } = useTranslation()
	return (
		<div
			className={currentLocale === locale ? "active locale" : "locale"}
			onClick={() => toggleLocale(locale)}
		>
			{children}
			<span>{localeMapping[locale]}</span>
		</div>
	)
}

const Norwegian = () => {
	return (
		<LanguageSelect locale="nb">
			<svg viewBox="0 0 1100 800">
				<rect width="1100" height="800" fill="#ef2b2d" />
				<rect width="200" height="800" x="300" fill="#fff" />
				<rect width="1100" height="200" y="300" fill="#fff" />
				<rect width="100" height="800" x="350" fill="#002868" />
				<rect width="1100" height="100" y="350" fill="#002868" />
			</svg>
		</LanguageSelect>
	)
}
const English = () => {
	return (
		<LanguageSelect locale="en">
			<svg viewBox="0 0 7410 3900" preserveAspectRatio="none">
				<rect width="7410" height="3900" fill="#b22234" />
				<path
					d="M0,450H7410m0,600H0m0,600H7410m0,600H0m0,600H7410m0,600H0"
					stroke="#fff"
					strokeWidth="300"
				/>
				<rect width="2964" height="2100" fill="#3c3b6e" />
				<g fill="#fff">
					<g id="s18">
						<g id="s9">
							<g id="s5">
								<g id="s4">
									<path
										id="s"
										d="M247,90 317.534230,307.082039 132.873218,172.917961H361.126782L176.465770,307.082039z"
									/>
									<use href="#s" y="420" />
									<use href="#s" y="840" />
									<use href="#s" y="1260" />
								</g>
								<use href="#s" y="1680" />
							</g>
							<use href="#s4" x="247" y="210" />
						</g>
						<use href="#s9" x="494" />
					</g>
					<use href="#s18" x="988" />
					<use href="#s9" x="1976" />
					<use href="#s5" x="2470" />
				</g>
			</svg>
		</LanguageSelect>
	)
}

function graphiqlUrl() {
	const origin = process.env.REACT_APP_LOCAL_DB_URL
		? process.env.REACT_APP_LOCAL_DB_URL
		: window.location.origin.includes("localhost")
		? "https://bossmyapi.xyz"
		: ""
	return `${origin}/publicgraphql`
}

const Nav = () => {
	const { trans } = useTranslation()
	const { user, logout } = useAuth0()!
	const [userMenuOpen, setUserMenuOpen] = useState(false)
	const dropDownContainerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		function outsideClickDetector(ev: any) {
			let target = ev.target
			do {
				if (target === dropDownContainerRef.current) {
					return
				}
				target = target.parentNode
			} while (target)
			setUserMenuOpen(false)
		}

		if (userMenuOpen && dropDownContainerRef.current) {
			document.addEventListener("click", outsideClickDetector)
			return () => document.removeEventListener("click", outsideClickDetector)
		}
	}, [userMenuOpen])

	const containerClass = userMenuOpen ? "User active" : "User"
	const username = get(user, "name", "unknown")
	return (
		<nav>
			<Logo />

			<section>
				<div className="Search">
					<Search />
				</div>

				<div
					ref={dropDownContainerRef}
					className={containerClass}
					onClick={() => setUserMenuOpen(!userMenuOpen)}
				>
					<span className="username1">{username}</span>
					<svg
						width="11"
						height="4"
						viewBox="0 0 11 4"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						style={{ marginRight: "0.5rem" }}
					>
						<path d="M5.5 4L10.2631 0.25H0.73686L5.5 4Z" fill="black" />
					</svg>
					<ul onClick={ev => ev.stopPropagation()}>
						<li className="username2">
							<span>{username}</span>
						</li>
						{(hasPermissions([CARROT_ADMIN]) || username === "Cookie token") && (
							<li>
								<a href={graphiqlUrl()}>GraphiQL</a>
							</li>
						)}
						<li id="language-select">
							<Norwegian />
							<English />
						</li>
						<li onClick={() => logout({ returnTo: window.location.origin })}>{trans("logout")}</li>
					</ul>
				</div>
			</section>
		</nav>
	)
}

export default Nav

import { gql } from "@apollo/client/core"

export const GET_CUSTOMERS = gql`
	query GET_CUSTOMERS {
		store {
			customers {
				id
				name
			}
		}
	}
`

export const getIdentitiesGQL = (identKey: string) =>
	gql`
	query GET_IDENTITIES {
		store {
			identities {
				id
				externalKey(key: "${identKey}")
			}
		}
	}
`

export const GET_POINT_IDS = gql`
	query GET_POINT_IDS {
		store {
			accessPoints {
				id
				type
				fraction: property(key: "fraction")
			}
		}
	}
`

export const GET_POINTS = gql`
	query GET_POINTS {
		store {
			accessPoints {
				id
				name
				type
				productName: property(key: "productName")
				properties {
					key
					value
				}
			}
		}
	}
`

export const GET_POINT_PARENTS = gql`
	query GET_POINT_PARENTS {
		store {
			accessPoints {
				parent: ancestor(type: "ACCESS_PARENT") {
					id
					name
				}
			}
		}
	}
`

export const GET_POINTS_RFID_ISO = gql`
	query GET_POINTS_RFID_ISO {
		store {
			accessPoints(type: "ACCESS_POINT") {
				id
				RFID: externalKey(key: "RFID_ISO")
			}
		}
	}
`

export const GET_POINTS_CONTAINER_ID = gql`
	query GET_POINTS_CONTAINER_ID {
		store {
			accessPoints(type: "ACCESS_POINT") {
				id
				containerId: externalKey(key: "containerId")
			}
		}
	}
`

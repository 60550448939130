import { gql } from "apollo-boost"
import { fetchFunc } from "../Utils/api"
import { CUSTOMER_TYPE } from "../Utils/gqlRequestTypes/generic"

export type ICustomersByType = {
	store: {
		data: IResponseCustomers
	}
}

type IResponseCustomers = {
	id: string
	name?: string | null
	customerType: string
}[]

export const getCustomersByType = (customerTypes: CUSTOMER_TYPE[]) => {
	const GET_CUSTOMERS_BY_TYPE = gql`
		query GET_CUSTOMERS_BY_TYPE($customerTypes: [CustomerTypes]) {
			store {
				data: customers(customerTypes: $customerTypes) {
					id
					name
					customerType
					externalKeys {
						key
						value
					}
				}
			}
		}
	`
	return fetchFunc<ICustomersByType, IResponseCustomers>(
		GET_CUSTOMERS_BY_TYPE,
		res => res.data.store.data,
		{
			variables: { customerTypes },
		}
	)
	// return null
}

import { DocumentNode } from "graphql"
import { IAccessPointType } from "../../Resources/Points/types"
import { globalAlertMutationSaved, postFunc } from "../../Utils/api"
import { gql } from "@apollo/client/core"

export const UPDATE_POINT = gql`
	mutation ACUpdatePoint($input: updateAccessPointMutationInput!) {
		updateAccessPointMutation(input: $input) {
			primaryKey
			scenarioId
			apiResultCode
			commandProcessError
		}
	}
`

type IUpdateAccessPointMutationInputPayload = {
	accessPoint: {
		id: string
		externalKeyName?: string
	}
	updateFields?: {
		name?: string
		description?: string
		status?: string
		statusReason?: string
		type?: IAccessPointType
	}
	propertiesUpdate?: {
		mode: "SET" | "MERGE"
		props?: { key: string; value: string }[]
		removeList?: string[]
	}
	externalKeysUpdate?: {
		mode: "SET" | "MERGE"
		props?: { key: string; value: string }[]
		removeList?: string[]
	}
	geoLocationUpdate?: {
		mode: "SET" | "MERGE"
		props?: { key: string; value: string }[]
		removeList?: string[]
	}
}

export const updatePoint = (
	inputPayload: IUpdateAccessPointMutationInputPayload,
	refetchQueries:
		| {
				query: DocumentNode
				variables?:
					| {
							[key: string]: any
					  }
					| undefined
		  }[]
		| undefined
) => {
	postFunc(UPDATE_POINT, globalAlertMutationSaved, {
		variables: {
			input: {
				wait: true,
				payload: inputPayload,
			},
		},
		refetchQueries,
	})
}

import moment from "moment"
import { orkla_translations } from "../../Contexts/translations/configurations/orkla"
import {
	customerNameFilter,
	dateTimeFilter,
	eventTypeFilter,
	pointNameFilter,
	propertyFilter,
} from "../../Resources/Events/configurations/filters"
import { IEventModified } from "../../Resources/Events/types"
import { mergeConfig } from "../config"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { defaultConfig } from "./default"
import { default_overrides_events } from "./default/default_overrides"
import { default_fetchAll_containers } from "./default/queries/default_fetchAll_containers"
import { orkla_drawerContent_containers } from "./orkla/drawer/orkla_drawerContent_containers"
import { orkla_drawerContent_customers } from "./orkla/drawer/orkla_drawerContent_customers"
import { eventFilterConfigGen, filterConfigGen } from "./_drawerFragments/filterConfigGenerator"
import { filterEQ, filterFuzzy } from "./_drawerFragments/filters"

export const orklaConfig = (): ISiteConfig => mergeConfig(defaultConfig(), orklaConfigObj)

export const orklaConfigObj: IPartialSiteConfig = {
	menu: ["customers", "containers", "events"],
	translations: orkla_translations,
	events: {
		headers: [
			{ path: "checkbox" },
			{ path: "timestamp" },
			{ path: "type" },
			{ path: "point.name" },
			{ path: "point.properties.volume" },
			{ path: "properties.containerType" },
			{ path: "properties.orderId" },
			{ path: "properties.customerId" },
			{ path: "properties.shippingId" },
			{ path: "properties.dispatchDate" },
			{ path: "properties.products" },
			{ path: "properties.damageType" },
			{ path: "properties.damageDescription" },
			{ path: "properties.damageImages" },
		],
		columnSizes: {
			"customer.name": "1fr",
			"point.name": "0.7fr",
			"point.properties.volume": "0.2fr",
		},
		overrides: (trans: any) => ({
			timestamp: default_overrides_events({}).timestamp,
			"point.properties.volume": (event: IEventModified) => {
				const volume = event.point?.properties?.volume
				return volume ? volume + " L" : ""
			},
			"properties.containerType": (event: IEventModified) => {
				const containerType: string = event?.properties?.containerType || ""
				const containerTypeLetters = containerType.match(/[A-Z]+/)?.[0] || ""
				return containerType ? trans(`containerType.${containerTypeLetters}`) : ""
			},
			"properties.dispatchDate": (event: IEventModified) => {
				const dispatchDate = event.properties?.dispatchDate
				const isValid = dispatchDate && !isNaN(parseFloat(dispatchDate))
				return isValid && dispatchDate
					? moment.unix(parseFloat(dispatchDate)).format("DD.MM.YYYY")
					: ""
			},
			"properties.damageType": (event: IEventModified) => {
				const damageType = event.properties?.damageType
				const transDamageType = damageType ? trans(`damageType.${damageType}`) : ""
				return transDamageType
			},
		}),
		filters: eventFilterConfigGen([
			dateTimeFilter(),
			customerNameFilter(),
			pointNameFilter(),
			eventTypeFilter(),
			propertyFilter("orderId"),
			propertyFilter("shippingId"),
		]),
	},
	customers: {
		headers: [{ path: "checkbox" }, { path: "name" }],
		drawer: {
			content: orkla_drawerContent_customers,
		},
	},
	containers: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ path: "properties.volume" },
			{ path: "properties.containerType" },
			{ path: "properties.status" },
			{ path: "properties.version" },
		],
		filters: filterConfigGen([
			filterFuzzy("name"),
			filterEQ({ lookupKey: "properties.volume" }),
			filterEQ({ lookupKey: "properties.containerType", transOptionsKey: "containerType" }),
			filterEQ({ lookupKey: "properties.status", transOptionsKey: "type" }),
			filterEQ({ lookupKey: "properties.version" }),
		]),
		drawer: {
			content: orkla_drawerContent_containers,
		},
		data: {
			fetchAll: default_fetchAll_containers({ fillLevel: false, type: "CONTAINER" }),
		},
	},
	stationModal: undefined,
	identitiesModal: {
		modes: [],
	},
}

import { Moment } from "moment"
import { localeFromLocalStorage } from "../../../../Contexts/Translation"
import { IEventModified } from "../../types"
import { normalize } from "../eventsByInterval/prepare"
import { fetchEventsByEventTypes } from "./fetch"

export const getEventsByEventTypes = (types: string[], dates: { from: Moment; to: Moment }) =>
	types?.length
		? fetchEventsByEventTypes(types, dates).then(e =>
				e.store?.events?.length ? normalize(e.store.events, localeFromLocalStorage) : []
		  )
		: (new Promise(() => []) as Promise<IEventModified[]>)

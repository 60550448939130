import { omit, pick } from "lodash"
import { MutableRefObject } from "react"
import create from "zustand"
import { combine } from "zustand/middleware"
import { translate } from "../../Contexts/Translation"
import { UPDATE_IDENTITY_STATUS } from "../../Resources/Customers/data"
import { IRow } from "../../Resources/types"
import { useGlobalAlert } from "../../States/globalAlert"
import { useSiteConfig } from "../../States/siteConfig"
import { postFunc } from "../../Utils/api"
import { keyValueArrayToObject } from "../../Utils/objArr"
import { fetchIdentity, removeIdentFromCustomer } from "./functions/data"
import {
	ADD_IDENT_TO_CUSTOMER,
	CREATE_NEW_IDENTITY,
	SAVE_CUSTOMER_IDENT_PROPS,
} from "./functions/queries"
import { ILocalIdentityWithProps } from "./types"

export const useIdentitiesHandlerState = create(
	combine(
		{
			customerIdentites: [] as IRow[],
			customerId: "",
			editingId: "",
			editingIdentity: undefined as IRow | undefined,
			statusChanged: false,
			addingExisting: false,
			allowSave: false,
			gridRef: null as unknown as MutableRefObject<HTMLDivElement | null>,
			loading: false,
			filter: "",
		},
		set => ({
			setFilter: (filter: string) => set({ filter }),
			setLoading: (loading: boolean) => set({ loading }),
			setCustomerIdentities: (customerIdentites: ILocalIdentityWithProps[]) =>
				set({ customerIdentites: normalizeIdents(customerIdentites) }),
			resetCustomerIdentities: () =>
				set({
					customerIdentites: [],
					customerId: "",
					editingId: "",
					statusChanged: false,
					editingIdentity: undefined,
					allowSave: false,
					filter: "",
				}),
			setCustomerId: (id: string) => set({ customerId: id }),
			/** Sets identity in edit mode */
			setEditingId: (id: string) =>
				set(({ customerIdentites, editingId: prevId }) => ({
					editingId: id,
					editingIdentity: customerIdentites.find(i => i.id === id),
					customerIdentites:
						id === "" && prevId === "__TEMP_ID"
							? customerIdentites.filter(i => i.id !== "__TEMP_ID")
							: customerIdentites,
					statusChanged: false,
				})),
			cancelEditing: () =>
				set(({ addingExisting, editingId, customerIdentites }) => ({
					allowSave: false,
					editingId: "",
					editingIdentity: undefined,
					statusChanged: false,
					addingExisting: false,
					customerIdentites: customerIdentites.filter(
						({ id }) => id !== (addingExisting ? editingId : "__TEMP_ID")
					),
				})),
			/** Adds new temporary identity to the list to be modified */
			addNewIdentity: () =>
				set(({ customerIdentites }) => {
					const {
						siteConfig: { identitiesModal },
					} = useSiteConfig.getState()
					const newIdenity = {
						id: "__TEMP_ID",
						...identitiesModal.propertyList.reduce((prev, cur) => ({ ...prev, [cur]: "" }), {}),
						...identitiesModal.externalKeyList.reduce((prev, cur) => ({ ...prev, [cur]: "" }), {}),
						status: "ACTIVE",
					} as IRow
					return {
						editingId: "__TEMP_ID",
						editingIdentity: newIdenity,
						customerIdentites: [newIdenity, ...customerIdentites],
					}
				}),
			/** Adds existing identity to the list */
			addExistingIdentity: (id: string) => {
				fetchIdentity(id).then(identity => {
					const newIdenity = {
						id,
						...keyValueArrayToObject(identity?.externalKeys),
						status: "ACTIVE",
					} as IRow
					set(({ customerIdentites }) => ({
						editingId: id,
						allowSave: checkAllowSave(newIdenity),
						addingExisting: true,
						editingIdentity: newIdenity,
						customerIdentites: [newIdenity, ...customerIdentites.filter(i => i.id !== "__TEMP_ID")],
					}))
				})
			},
			/** Updates a field for currently editing identity */
			updateField: (field: string, value: string) =>
				set(({ editingIdentity, statusChanged }) => {
					const modifiedIdentity = { ...editingIdentity, [field]: value } as IRow
					return {
						editingIdentity: modifiedIdentity,
						statusChanged: field === "status" ? editingIdentity?.status !== value : statusChanged,
						allowSave: checkAllowSave(modifiedIdentity),
					}
				}),
			setGridRef: (ref: MutableRefObject<HTMLDivElement | null>) => {
				set({ gridRef: ref })
			},
			/** Saves changes or creates new identity */
			saveIdentity: () =>
				set(({ allowSave, editingIdentity, editingId, statusChanged, addingExisting }) => {
					if (!allowSave || !editingId || !editingIdentity) return

					editingId === "__TEMP_ID"
						? createNewIdentityForCustomer(editingIdentity, statusChanged)
						: addingExisting
						? addExistingIdentity(editingIdentity, statusChanged)
						: updateIdentity(editingIdentity, statusChanged)
					set({
						allowSave: false,
						editingId: "",
						editingIdentity: undefined,
						statusChanged: false,
						addingExisting: false,
					})
				}),
			removeIdentity: (id: string, customerId: string) => {
				const { fetchById } = useSiteConfig.getState().siteConfig.customers.data
				const { setGlobalAlert } = useGlobalAlert.getState()
				removeIdentFromCustomer(
					id,
					customerId,
					() => {
						setGlobalAlert({ type: "success", message: translate("addIdentities.removedSuccess") })

						set(({ customerIdentites }) => {
							return { customerIdentites: customerIdentites.filter(i => i.id !== id) }
						})
					},
					fetchById
				)
			},
			/** Overwrites identity in a list, based on id */
			modifyIdentityInList: (identity: IRow) =>
				set(({ customerIdentites }) => ({
					customerIdentites: customerIdentites.map(i => (i.id !== identity.id ? i : identity)),
				})),
		})
	)
)

const normalizeIdents = (idents: ILocalIdentityWithProps[]) => {
	return idents.map(ident => {
		const identsPropsAndExtKeys = {
			id: ident.id,
			...keyValueArrayToObject(ident.properties),
			...keyValueArrayToObject(ident.externalKeys),
			status: ident.status,
		}
		return identsPropsAndExtKeys as IRow
	})
}

const removeIdentityFromList = (id: string) =>
	useIdentitiesHandlerState.setState(({ customerIdentites }) => ({
		customerIdentites: customerIdentites.filter(identity => identity.id !== id),
	}))

const addIdentityToList = (identity: IRow) =>
	useIdentitiesHandlerState.setState(({ customerIdentites }) => ({
		customerIdentites: [identity, ...customerIdentites],
	}))

const checkAllowSave = (identity: IRow) => {
	const {
		siteConfig: { identitiesModal },
	} = useSiteConfig.getState()
	return !Object.values(pick(identity, identitiesModal.requiredKeyList)).some(p => !p)
}

const refetchQuery = () => {
	const { customerId } = useIdentitiesHandlerState.getState()
	return {
		variables: { id: customerId },
		query: useSiteConfig.getState().siteConfig.customers.data.fetchById,
	}
}

const createNewIdentityForCustomer = (identity: IRow, statusChanged: boolean) => {
	const {
		identitiesModal: { identityType, externalKeyList, propertyList },
	} = useSiteConfig.getState().siteConfig
	const { setGlobalAlert } = useGlobalAlert.getState()
	const { customerId, modifyIdentityInList, gridRef } = useIdentitiesHandlerState.getState()

	// add new identity to list instantly
	modifyIdentityInList(identity)

	postFunc<{
		addIdentityMutation?: { primaryKey: string; commandProcessError: string | null }
	}>(
		CREATE_NEW_IDENTITY,
		res => {
			const newId = res?.data?.addIdentityMutation?.primaryKey
			const needUpdate = Object.values(pick(identity, propertyList)).some(p => !!p) || statusChanged

			if (!newId) return setGlobalAlert({ type: "danger", message: "No ID returned" })
			const newIdenity = { ...identity, id: newId }

			//  on success add identity to list with proper id
			removeIdentityFromList("__TEMP_ID")
			addIdentityToList(newIdenity)
			;(gridRef?.current as any)?.scrollToRow(-1) // scroll to last row

			return needUpdate
				? updateIdentity(newIdenity, statusChanged)
				: setGlobalAlert({ type: "success", message: translate("addIdentities.addedSuccess") })
		},
		{
			variables: {
				type: identityType,
				externalKeys: Object.entries(pick(identity, externalKeyList)).map(([key, value]) => ({
					key,
					value,
				})),
				customer: { customerId },
			},
			refetchQueries: propertyList.length || statusChanged ? [] : [refetchQuery()],
		},
		err => {
			removeIdentityFromList("__TEMP_ID")
			setGlobalAlert({ type: "danger", message: translate("errors.failedSave") + " " + err })
		}
	)
}

const addExistingIdentity = (identity: IRow, statusChanged: boolean) => {
	const { customerId, editingId } = useIdentitiesHandlerState.getState()
	const {
		identitiesModal: { propertyList },
	} = useSiteConfig.getState().siteConfig
	const { setGlobalAlert } = useGlobalAlert.getState()
	postFunc(
		ADD_IDENT_TO_CUSTOMER,
		() => {
			const needUpdate = Object.values(pick(identity, propertyList)).some(p => !!p) || statusChanged

			return needUpdate
				? updateIdentity(identity, statusChanged)
				: setGlobalAlert({ type: "success", message: translate("addIdentities.addedSuccess") })
		},
		{
			variables: { customerId, identId: editingId },
			refetchQueries: [refetchQuery()],
		},
		err => {
			removeIdentityFromList(editingId)
			setGlobalAlert({ type: "danger", message: translate("errors.failedSave") + " " + err })
		}
	)
}

const updateIdentity = (identity: IRow, statusChanged: boolean) => {
	const {
		identitiesModal: { propertyList },
	} = useSiteConfig.getState().siteConfig
	const { customerId, modifyIdentityInList } = useIdentitiesHandlerState.getState()
	const { setGlobalAlert } = useGlobalAlert.getState()
	const updateIdentStatus = () => updateIdentityStatus(identity)

	const properties = Object.entries(pick(identity, propertyList)).map(([key, value]) => ({
		key,
		value,
	}))

	// update identity properties in list instantly
	modifyIdentityInList(identity)

	if (!propertyList.length && statusChanged) return updateIdentStatus()

	return postFunc(
		SAVE_CUSTOMER_IDENT_PROPS,
		() => {
			if (statusChanged) updateIdentStatus()
			else setGlobalAlert({ type: "success", message: translate("success.saved") })
		},
		{
			variables: {
				input: {
					wait: true,
					payload: {
						customerId,
						updateIdentities: [
							{
								id: identity.id,
								propertiesUpdate: {
									mode: "SET",
									props: properties,
								},
							},
						],
					},
				},
			},
			refetchQueries: statusChanged ? [] : [refetchQuery()],
		},
		err => {
			// undo identity properties and status on fail
			modifyIdentityInList(omit(identity, propertyList, "status") as IRow)
			setGlobalAlert({ type: "danger", message: translate("errors.failedSave") + ": " + err })
		}
	)
}

const updateIdentityStatus = (identity: IRow) => {
	const { setGlobalAlert } = useGlobalAlert.getState()
	const { modifyIdentityInList } = useIdentitiesHandlerState.getState()

	return postFunc(
		UPDATE_IDENTITY_STATUS,
		() => {
			setGlobalAlert({ type: "success", message: translate("success.saved") })
		},
		{
			variables: {
				input: {
					wait: true,
					payload: {
						identities: [{ id: identity.id, status: identity.status }],
					},
				},
			},
			refetchQueries: [refetchQuery()],
		},
		err => {
			// undo identity status in list on fail
			modifyIdentityInList(omit(identity, "status") as IRow)
			setGlobalAlert({ type: "danger", message: translate("errors.failedSave") + ": " + err })
		}
	)
}

import moment from "moment"
import { IEventDatesConfig } from "../types"

export const dateConfigLogics = (
	eventsDatesConfig: IEventDatesConfig,
	timeValue: number[] | undefined,
	hasNoFiltersSet: boolean
) => {
	if (timeValue) {
		if (eventsDatesConfig.filterEventsByTime && hasNoFiltersSet) {
			const toDate = moment(timeValue[1])
			const limitToDate = moment(timeValue[0])
				.clone()
				.add(eventsDatesConfig.maxUnfilteredDays, "days")
				.add(eventsDatesConfig.maxUnfilteredHours, "hours")

			if (toDate.isBefore(limitToDate)) {
				return {
					from: moment(timeValue[0]),
					to: toDate,
				}
			} else {
				return {
					from: moment(timeValue[0]),
					to: limitToDate,
				}
			}
		} else {
			return {
				from: moment(timeValue[0]),
				to: moment(timeValue[1]),
			}
		}
	} else {
		return null
	}
}

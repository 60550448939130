import { IKSDefaultDashboard } from "../../Dashboard/siteDashboards/IKSDefault"
import { bir_eventsDatesConfig } from "./bir/bir_eventDates"
import { pointsFromResponse } from "../../Resources/Points/data"
import { IAccessPointData, IAccessPointModified } from "../../Resources/Points/types"
import { mergeConfig } from "../config"
import { IPartialSiteConfig, ISiteConfig } from "../types"
import { bir_drawerContent_containers } from "./bir/drawer/bir_drawerContent_containers"
import { bir_drawerContent_customers } from "./bir/drawer/bir_drawerContent_customers"
import { bir_drawerContent_points } from "./bir/drawer/bir_drawerContent_points"
import { defaultConfig } from "./default"
import { default_fetchAll_points } from "./default/queries/default_fetchAll_Points"
import { default_fetchById_containers } from "./default/queries/default_fetchById_containers"
import { filterConfigGen } from "./_drawerFragments/filterConfigGenerator"
import {
	addressFuzzy,
	fillLevel,
	filterFuzzy,
	fractionByHierarchyEQ,
	idFuzzy,
	installationEQ,
	PAAvtaleFuzzy,
	printedTagFuzzy,
	stationEQ,
} from "./_drawerFragments/filters"
import { default_vendorOptions_station } from "../datasets/VendorOptions/default_vendorOptions"
import { _cf_bir_accessPointModal } from "./_configFragments/_cf_bir_accessPointModal"
import { default_fractionsList } from "./default/default_fractions"

export const birConfigObj: IPartialSiteConfig = {
	menu: ["customers", "points", "movableContainers", "events", "allocations"],
	dashboard: IKSDefaultDashboard,
	eventsDates: bir_eventsDatesConfig,
	customers: {
		drawer: {
			content: bir_drawerContent_customers,
		},
		headers: [
			{ path: "checkbox" },
			{ path: "id" },
			{ path: "name" },
			{ path: "properties.geoLocationName" },
			{ name: "headers.properties.crmId", path: "externalKeys.PAAvtaleID" },
		],
		filters: filterConfigGen([filterFuzzy("name"), addressFuzzy, stationEQ, PAAvtaleFuzzy]),
		history: { action: true },
	},
	points: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ path: "installation.name" },
			{ name: "headers.printedTag", path: "externalKeys.printedTag" },
			{ path: "fillLevel" },
		],
		history: { action: true, location: true },
		filters: filterConfigGen([
			filterFuzzy("name"),
			fractionByHierarchyEQ,
			fillLevel,
			installationEQ,
			printedTagFuzzy,
		]),
		drawer: {
			ui: "drawer",
			content: bir_drawerContent_points,
		},
		data: {
			fetchAll: default_fetchAll_points({ fillLevel: false, pos: true }),
			fetchById: default_fetchById_containers({ fillLevel: true }), // TODO: check if it should really be containers
			postFetchAll: default_fetchAll_points({ essentials: false, fillLevel: true }),
		},
	},
	containers: {
		headers: [
			{ path: "checkbox" },
			{ path: "name" },
			{ name: "headers.printedTag", path: "externalKeys.tag" },
			{ path: "externalKeys.serviceGuid" },
		],
		filters: filterConfigGen([
			idFuzzy,
			filterFuzzy("name"),
			filterFuzzy("externalKeys.tag", "headers.printedTag"),
			filterFuzzy("externalKeys.serviceGuid"),
		]),
		drawer: {
			content: bir_drawerContent_containers,
		},
		data: {
			fetchAll: default_fetchAll_points({ fillLevel: false, pos: true }),
			fetchAllParse: (data: IAccessPointData): IAccessPointModified[] =>
				pointsFromResponse(data.store.data, ["CONTAINER"]).map(p => {
					return p
				}),
		},
	},
	customerModal: {
		propertyList: [
			{ key: "taxNumber", name: "taxNumber", value: "" },
			{ key: "geoLocationName", name: "geoLocationName", value: "" },
			{ key: "contact", name: "contact", value: "" },
			{ key: "CRMID", name: "CRMID", value: "" },
		],
		customerTypes: ["BA", "BAVD", "HA", "HB"],
	},
	stationModal: {
		modes: ["create"],
		inletHatchSizeProp: true,
		installationAutoKey: { key: "guid", generator: "uuid" },
		stationAutoKey: { key: "guid", generator: "uuid" },
		stationPropertyList: [
			{ key: "Municipality", name: "municipality", value: "" },
			{ key: "Oppdragsgiver", name: "client", value: "" },
			{ key: "owner", name: "owner", value: "" },
			{
				key: "vendor",
				name: "vendor",
				value: "",
				type: "dropdown",
				options: default_vendorOptions_station,
			},
			{ key: "contact", name: "contact", value: "" },
		],
	},
	identitiesModal: {
		propertyList: ["identityHolder"],
	},
	accessPointModal: _cf_bir_accessPointModal(default_fractionsList),
}

export const birConfig = (): ISiteConfig => mergeConfig(defaultConfig(), birConfigObj)

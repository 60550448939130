import { IDrawerContent } from "../../../../UI/Drawer/DrawerContent"
import { df_input_name } from "../../_drawerFragments/inputs"

export const default_drawerContent_operators: IDrawerContent = {
	inputSection: {
		comp: "inputSection",
		order: 0,
		inputs: [df_input_name()],
	},
}

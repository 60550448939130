import { cloneDeep } from "lodash"
import { AccessPointStatus } from "../../Utils/gqlRequestTypes/generic"

export interface IStationConfigState {
	terminal: {
		id: string
		name: string
	}
	station: {
		name: string
		status: AccessPointStatus | ""
		geoLocation: string
	}
	installationProperties: { [key: string]: string }
	stationProperties: { [key: string]: string }
	inletProperties: { [key: string]: string }
	installationExternalKeys: { [key: string]: string }
	stationExternalKeys: { [key: string]: string }
	inletExternalKeys: { [key: string]: string }
	// TODO: add externalKeys too?
	inlets: IInletState[]
	inlet: IInletState
	showInletCreation: boolean
	externalKeyAssignValue: string
	defaultTerminal: null | { name: string; value: string }
	customMultipleValDropdown: string
}

export interface IInletState {
	name: string
	fraction: string
	status: AccessPointStatus | ""
	hatchTypeCode: string
	[key: string]: string
	// vendor: string
}

export declare type ISetActionTypes =
	| { type: "set"; ap: "station"; key: keyof IStationConfigState["station"]; value: string }
	| { type: "set"; ap: "terminal"; key: keyof IStationConfigState["terminal"]; value: string }
	| { type: "set"; ap: "inlet"; key: keyof IInletState; value: string }

export declare type IReducerAction =
	| ISetActionTypes
	| { type: "setProperty"; path: "station" | "inlet" | "installation"; key: string; value: string }
	| { type: "setKey"; path: "station" | "inlet" | "installation"; key: string; value: string }
	| { type: "addInlet" }
	| { type: "removeInlet"; index: number }
	| { type: "toggleTerminal" }
	| { type: "toggleInletCreation" }
	| { type: "setDefaultTerminal"; terminal: { name: string; value: string } }
	| { type: "setExtkeyAssignValue"; value: string }
	| { type: "setCustomMultipleValDropdown"; value: string }

export function StationConfigReducer(state: IStationConfigState, action: IReducerAction) {
	const newState = cloneDeep(state)
	switch (action.type) {
		case "set":
			return {
				...newState,
				[action.ap]: {
					...newState[action.ap],
					[action.key]: action.value,
				},
			}
		case "setProperty":
			newState[`${action.path}Properties` as const][action.key] = action.value
			return newState
		case "setKey":
			newState[`${action.path}ExternalKeys` as const][action.key] = action.value
			return newState
		case "addInlet":
			const { name, fraction, status, hatchTypeCode } = state.inlet
			const inlets = [
				...state.inlets,
				{
					name,
					fraction,
					status,
					hatchTypeCode,
					...Object.entries(state.inletProperties).reduce((p, [k, v]) => {
						return { ...p, [k]: v }
					}, {} as { [key: string]: string }),
					...Object.entries(state.inletExternalKeys).reduce((p, [k, v]) => {
						return { ...p, [k]: v }
					}, {} as { [key: string]: string }),
				},
			]
			//console.log(["yes", inlets, state.inlet, state.inletProperties, state.inletExternalKeys])
			return {
				...state,
				inlets,
				inlet: {
					name: "",
					status,
					fraction: "", // Following does not reset visually, because of <InputSelect>
					hatchTypeCode: "",
				},
				inletProperties: Object.keys(state.inletProperties).reduce((p, k) => {
					return { ...p, [k]: "" }
				}, {}),
				inletExternalKeys: Object.keys(state.inletExternalKeys).reduce((p, k) => {
					return { ...p, [k]: "" }
				}, {}),
				showInletCreation: false,
			}
		case "setExtkeyAssignValue":
			newState.externalKeyAssignValue = action.value
			return newState
		case "removeInlet":
			return {
				...state,
				inlets: state.inlets.filter((_, index) => {
					if (index === action.index) return false
					return true
				}),
			}
		case "toggleTerminal":
			return {
				...state,
				terminal: {
					id: "",
					name: "",
				},
			}
		case "toggleInletCreation":
			return {
				...state,
				showInletCreation: !state.showInletCreation,
				inlet: {
					name: "",
					fraction: "", // Following does not reset visually, because of <InputSelect>
					status: state.inlet.status,
					hatchTypeCode: "",
				},
			}
		case "setDefaultTerminal":
			return {
				...state,
				defaultTerminal: action.terminal,
			}
		case "setCustomMultipleValDropdown":
			return {
				...state,
				customMultipleValDropdown: action.value,
			}
		default:
			throw new Error()
	}
}

import Fuse from "fuse.js"
import { get, isEqual, isNil, uniqWith } from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "../../Contexts"
import { useQueryParam } from "../../domHooks"
import { removeQuotes } from "../../Utils"
import { isNumber } from "../../Utils/general"
import { IAccessPointModified } from "../Points/types"
import { IFilter, IFilterSpec } from "../types"
import DateTimeSelect from "./components/DateTimeSelect/DateTimeSelect"
import RadioGroup from "./components/RadioGroup/RadioGroup"
import SearchInput from "./components/SearchInput/SearchInput"
import SearchSelect from "./components/SearchSelect/SearchSelect"
import "./index.css"

const FilterContext = React.createContext<any>({
	createFilter: (_filters: IFilterSpec) => {},
	filter: (_data: any) => Promise.resolve([]),
	args: [],
	filterViews: null,
})

export const useFilter = (data: any[], filterSpec: IFilterSpec | null) => {
	const [filteredData, setFilteredData] = useState(data)
	const { filter, createFilter } = useContext(FilterContext)

	useEffect(() => {
		createFilter(filterSpec)
	}, [createFilter, filterSpec])

	useEffect(() => {
		filter(data).then((filtered: any[]) => setFilteredData(filtered))
	}, [data, filter])

	return filteredData
}

export const useArgs = () => {
	const { args } = useContext(FilterContext)
	return args
}

// Getting the data by lookup key and translating if possible the label of the output
function getDataKeyAndLabel(
	objects: any[],
	lookupKey: string,
	translate: any,
	label?: string,
	labelTransField?: string,
	showOriginalValue?: boolean
) {
	return getUniqueValueByKeyFunction(objects, o => {
		const valueVal = get(o, lookupKey)
		if (label && labelTransField) {
			const labelVal = get(o, label)
			const ts = labelVal ? translate(`${labelTransField}.${labelVal}`) : null
			return {
				label: ts ? (showOriginalValue ? ts + " (" + valueVal + ")" : ts) : valueVal || "",
				value: valueVal,
			}
		}
		return {
			label: valueVal,
			value: valueVal,
		}
	})
}

function getUniqueValueByKeyFunction(
	objects: object[],
	keyFunc: (o: any) => { label: string; value: string }
) {
	const uniques: { label: string; value: string }[] = []
	objects.forEach((o: object) => {
		uniques.push(keyFunc(o))
	})
	return uniqWith(uniques, isEqual)
}

function byLabel(a: any, b: any) {
	if (a.label < b.label) return -1
	if (a.label > b.label) return 1
	return 0
}

function render(filterSpec: any, translate: any) {
	const client = get(filterSpec, "config.client", null)

	return filterSpec.specs.map((fs: any, i: number) => {
		const { id, control } = fs

		if (control.component === "SearchInput") {
			const { placeholder } = control
			return Promise.resolve(<SearchInput key={i} filterKey={id} placeholder={placeholder} />)
		}

		if (control.component === "SearchSelect") {
			const { placeholder, options } = control

			if (options.type === "simple") {
				const { data, lookupKey, label, labelTransField, showOriginalValue } = options as {
					data: any[]
					lookupKey: string
					label?: string
					labelTransField: string
					showOriginalValue: boolean
				}
				return new Promise(function (resolve) {
					const values = getDataKeyAndLabel(
						data,
						lookupKey,
						translate,
						label,
						labelTransField,
						showOriginalValue
					)
					// Sort and remove empty
					const _values = values.sort(byLabel).filter(v => !isNil(v.label) && v.label !== "")
					resolve(
						<SearchSelect key={i} filterKey={id} placeholder={placeholder} options={_values} />
					)
				})
			}

			if (options.type === "labelValue") {
				const { data } = options as {
					data: any[]
				}
				return new Promise(function (resolve) {
					data.sort(byLabel)
					resolve(<SearchSelect key={i} filterKey={id} placeholder={placeholder} options={data} />)
				})
			}

			if (options.type === "query") {
				const { query, errorPolicy, transform } = options
				return client.query({ query, errorPolicy }).then((result: any) => {
					const data = get(result, "data", [])
					const values = transform(data)
					values.sort(byLabel)
					return <SearchSelect key={i} filterKey={id} placeholder={placeholder} options={values} />
				})
			}
		}

		if (control.component === "DateTimeSelect") {
			return <DateTimeSelect key={i} filterKey={id} />
		}

		if (control.component === "Checkbox") {
			const { values, defaultValue } = control

			return <RadioGroup key={i} filterKey={id} defaultValue={defaultValue} values={values} />
		}

		console.error("bad fs spec", fs)
		throw new Error("Unsupported filterspec")
	})
}

async function init(filterSpec: any, translate: any) {
	const views = await Promise.all(render(filterSpec, translate))

	const filters = filterSpec.specs
		.filter((s: any) => s.filter)
		.map((s: any) => ({ ...s.filter, id: s.id }))

	const args = filterSpec.specs
		.filter((s: any) => s.args)
		.reduce(
			(agg: any, s: any) => ({
				...agg,
				[s.id]: {
					defaultValue: s.args.defaultValue,
					lookupKey: s.args.lookupKey,
				},
			}),
			{}
		)

	return { filters, views, args }
}

async function filterNoop() {
	return (data: any) => data
}

async function filterEq(filter: any, { lookupKey }: any) {
	if (Array.isArray(filter)) {
		return async (data: { label: string; value: string }[] | object) => {
			if (Array.isArray(data)) {
				return data.filter(
					datum => filter.findIndex(({ value }: any) => value === get(datum, lookupKey)) !== -1
				)
			} else {
				// Undefined / empty
				return data
			}
		}
	}

	return (data: any) =>
		data.filter((datum: any) => {
			const value = get(datum, lookupKey)
			return value && value === filter
		})
}

async function filterBeginsWith(filter: any, { lookupKey }: any) {
	if (Array.isArray(filter)) {
		return (data: any) =>
			data.filter(
				(datum: any) =>
					filter.findIndex(({ value }: any) => value.startsWith(get(datum, lookupKey))) !== -1
			)
	}

	return (data: any) =>
		data.filter((datum: any) => {
			const value = get(datum, lookupKey)
			return value && value.startsWith(filter)
		})
}

async function filterFractionsByPointHierachy(
	filter: { label: string; value: string }[],
	{ lookupKey }: any
) {
	return (data: IAccessPointModified[]) => {
		if (Array.isArray(data)) {
			return data.filter(d =>
				d.childrenFractions.find(cf => filter.find(f => cf.fraction === f.value))
			)
		} else {
			// Undefined / empty
			return data
		}
	}
}

async function filterfillLevelEqOrLargerThan(
	filter: string,
	_: { lookupKey: string },
	filterValues: {
		fraction?: { label: string; value: string }[]
	}
) {
	const _filter = isNumber(filter) ? parseFloat(filter) : null

	return (data: IAccessPointModified[]) =>
		_filter && Array.isArray(data)
			? ["fraction" in filterValues && filterValues["fraction"]?.length].map(hasFractionsFilter =>
					// Filter stations by station's inlets fillLevels by also filtering user specified fraction filter
					// This way you can both filter station's inlets thats not possible in an other way in filters.
					hasFractionsFilter
						? data.filter(
								d =>
									!!d.fillLevels.find(
										fl =>
											!!filterValues.fraction?.find(
												ff => ff.value === fl.fraction && (fl?.fillLevel || 0) >= _filter
											)
									)
						  )
						: // If only filtered by fillLevel
						  data.filter(d => !!d.fillLevels.find(fl => (fl?.fillLevel || 0) >= _filter))
			  )[0]
			: data
}

async function filterFuzzy(filter: string, { lookupKey }: { lookupKey: string }) {
	return (data: any) => {
		const fuse = new Fuse(data, {
			shouldSort: true,
			threshold: 0.3,
			location: 0,
			distance: 100,
			minMatchCharLength: 1,
			keys: [lookupKey],
		})

		if (!filter || filter === "") {
			return data
		}
		// Possibility to set " " with a work between and get eq search
		// Good for getting only one customer ex when starting
		// customer search from PointDrawer
		if (filter.charAt(0) === '"') {
			return filter.length === 1 ? filterNoop() : filterEq(removeQuotes(filter), { lookupKey })
		}
		return fuse.search(filter).map((result: any) => result.item)
	}
}

async function filterQuery(
	filter: {
		label: string
		value: string
	}[],
	{ query, variable, errorPolicy, transform, lookupKey }: any,
	client: any
) {
	const fetches = filter.map(cf => {
		return (client as any).query({
			query,
			variables: { [variable]: cf.value },
			errorPolicy,
		})
	})

	const results = await Promise.all(fetches)
	const values = results.flatMap((r: any) => {
		const data = get(r, "data", [])
		return transform(data)
	})

	return async (data: any) => {
		const d = (await data) || data
		return d?.filter(
			(d: any) => values.findIndex((f: any) => get(f, lookupKey) === get(d, lookupKey)) !== -1
		)
	}
}

export const FilterProvider = ({ children }: any) => {
	const [filterViews, setFilterViews] = useState<any>()
	const [filters, setFilters] = useState<IFilter[]>([])

	const [defaultArgsForFilter, setDefaultArgsForFilter] = useState<any[]>([])
	const [client, setClient] = useState(null)

	const [filterValues]: {
		[key: string]: string | { label: string; value: string }[]
	}[] = useQueryParam("filter", {})
	const [argValues]: {
		time: { label: string; value: string[] }
	}[] = useQueryParam("args", {})

	const { trans } = useTranslation()

	const filter = useCallback(
		async (data: any) => {
			const chain = filters.map(async f => {
				const { id, type } = f

				const currentFilter = filterValues[id]
				if (!currentFilter || currentFilter.length === 0) {
					return filterNoop()
				}

				switch (type) {
					case "eq":
						return filterEq(currentFilter as string, f)
					case "startsWith":
						return filterBeginsWith(currentFilter as string, f)
					case "fillLevelEqOrLargerThan":
						return filterfillLevelEqOrLargerThan(currentFilter as string, f, filterValues)
					case "fuzzy":
						return filterFuzzy(currentFilter as string, f)
					case "fractionsByPointHierachy":
						return filterFractionsByPointHierachy(
							currentFilter as {
								label: string
								value: string
							}[],
							f
						)
					case "query":
						return filterQuery(
							currentFilter as {
								label: string
								value: string
							}[],
							f,
							client
						)
					default:
						console.error("unknown filter type", type)
						return filterNoop()
				}
			})

			return Promise.all(chain).then((funcs: any) => {
				return Promise.resolve(
					funcs.reduce(async (data: any, f: any) => {
						const d = (await data) || data
						return f(await d)
					}, data)
				)
			})
		},
		[client, filters, filterValues]
	)

	const args = useMemo(() => {
		const ret: any = {}
		for (let [key, value] of Object.entries(defaultArgsForFilter)) {
			const { lookupKey, defaultValue } = value
			ret[key] = get(argValues, lookupKey, defaultValue)
		}
		return ret
	}, [argValues, defaultArgsForFilter])

	const createFilter = useCallback(
		async (filterSpec: any) => {
			const client = get(filterSpec, "config.client", null)
			setClient(client)

			const result = await init(filterSpec, trans)
			setFilterViews(result.views)
			setFilters(result.filters)
			setDefaultArgsForFilter(result.args)
		},
		[trans]
	)

	return (
		<FilterContext.Provider value={{ filter, filterViews, createFilter, args }}>
			{children}
		</FilterContext.Provider>
	)
}

const Filter = () => {
	const { trans } = useTranslation()
	const { filterViews } = useContext(FilterContext)

	const views = filterViews ? (
		<>
			<span>{trans("filterResults")}</span>
			{filterViews}
		</>
	) : null

	const style = {
		opacity: filterViews ? 1 : 0,
	}
	return (
		<div id="filter-anchor" style={style}>
			{views}
		</div>
	)
}

export default Filter

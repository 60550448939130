import React, { useState, useContext, useCallback } from "react"
import { Button } from "../../../UI"
import "./Toolbar.css"

const ToolbarContext = React.createContext({
	display: false,
	text: "",
	onToolbarAction: (_action: string) => {},
	showToolbar: (_text: string, _onToolbarClicked: any) => {},
	hideToolbar: () => {},
})
export function useToolbar() {
	return useContext(ToolbarContext)
}

export const ToolbarProvider = ({ children }: any) => {
	const defaultOnToolbarClicked = (action: string) =>
		console.log("toolbar action:", action)

	const [buttonText, setButtonText] = useState<any>(null)
	const [shouldDisplay, setShouldDisplay] = useState(false)
	const [toolbarClickedCallback, setToolbarClickedCallback] = useState<any>(
		() => defaultOnToolbarClicked
	)

	const showToolbar = useCallback(
		(text: string, onToolbarClicked: any) => {
			setButtonText(text)
			setShouldDisplay(true)
			setToolbarClickedCallback(() => onToolbarClicked)
		},
		[setButtonText, setShouldDisplay, setToolbarClickedCallback]
	)

	const hideToolbar = useCallback(() => {
		setButtonText(null)
		setShouldDisplay(false)
		setToolbarClickedCallback(() => defaultOnToolbarClicked)
	}, [setButtonText, setShouldDisplay, setToolbarClickedCallback])

	const value = {
		display: shouldDisplay,
		text: buttonText,
		onToolbarAction: toolbarClickedCallback,
		showToolbar,
		hideToolbar,
	}

	return (
		<ToolbarContext.Provider value={value}>{children}</ToolbarContext.Provider>
	)
}

export const Toolbar = () => {
	const { display, onToolbarAction } = useToolbar()

	const style = {
		visibility: display ? "visible" : "hidden",
	} as React.CSSProperties

	const clickHandler = () => {
		onToolbarAction("create")
	}

	return (
		<div className="toolbar" style={style}>
			<Button type="background" onClick={clickHandler}>
				<svg width="20" height="20" viewBox="0 0 512 512">
					<line style={style} x1="256" y1="112" x2="256" y2="400" />
					<line style={style} x1="400" y1="256" x2="112" y2="256" />
				</svg>
			</Button>
		</div>
	)
}

import { SVGProps } from 'react'

export const MatavfallIcon = ({
  fill = 'black',
  width = '227',
  height = '326',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 227 326"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.26 199.217C151.26 117.328 226.851 98.4293 226.851 98.4293C226.851 98.4293 207.953 73.2334 176.457 73.2334C144.961 73.2334 138.661 85.832 113.465 85.832C88.268 85.832 81.9693 73.2334 50.472 73.2334C18.976 73.2334 0.0786591 98.4293 0.0786591 98.4293C0.0786591 98.4293 75.6693 117.328 75.6693 199.217C75.6693 268.509 6.37866 300.005 6.37866 300.005C6.37866 300.005 27.3733 325.201 63.0707 325.201C88.268 325.201 94.5667 312.603 113.465 312.603C132.361 312.603 138.661 325.201 163.859 325.201C199.556 325.201 220.551 300.005 220.551 300.005C220.551 300.005 151.26 268.509 151.26 199.217Z"
        fill={fill}
      />
      <path
        d="M106.2 64.9653H121.741C121.741 64.9653 120.785 26.2947 99.7347 0.096L74.8827 19.0027C74.8827 19.0027 99.66 36.7667 106.2 64.9653Z"
        fill={fill}
      />
    </svg>
  )
}

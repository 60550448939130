import { uniq } from "lodash"
import { Moment } from "moment"
import { fetchFunc } from "../../../../Utils/api"
import { GET_CHILDREN_OF_PARENT_IDS, GET_EVENTS_BY_POINT_IDS, GET_IDENITIES_BY_CUSTOMER_IDS } from "./queries"
import { ICustomerWithIdentitiesByIds, IEventsByPointIds, IEventsByPointIdsSimplified, IParentPointChildrenIds, IPointNestedChildrenIds } from "./types"

export const fetchEventsByPointIds = async (pointIds: string[], dates: { from: Moment; to: Moment }): Promise<IEventsByPointIds> =>
	{
		if (!pointIds?.length) {
			return (new Promise(() => []) as Promise<IEventsByPointIds>)
		}
		const eventsResult = await fetchFunc<IEventsByPointIdsSimplified, IEventsByPointIdsSimplified>(GET_EVENTS_BY_POINT_IDS, cs => cs.data, {
			variables: { start: dates.from, end: dates.to, pointIds },
		})

		const customerIds = uniq(eventsResult?.store.accessPoints.flatMap(ap => ap.events)?.flatMap(e => e?.customer?.id).filter(Boolean)) as string[]

		const customers = await fetchFunc<ICustomerWithIdentitiesByIds, ICustomerWithIdentitiesByIds>(GET_IDENITIES_BY_CUSTOMER_IDS(), cs => cs.data, {
			variables: { customerIds },
		})

		return {
			store: {
				accessPoints: eventsResult.store.accessPoints.map(ap => ({
					...ap,
					events: ap.events?.map(e => {
						const customer = customers.store.customers.find(c => c.id === e.customer?.id)

						return {
							...e,
							customer: customer ? {
								id: e.customer?.id ?? null,
								customer,
							} : null
						}
					}) ?? [],
				})),
			},
		}
	}

// Getting ids of points that has parentIds as ancestors and merging
// them with parentIds self (since parents can hold events as well)
export const fetchPointsAndParentsIdsByParentIds = (parentIds: string[]) =>
	parentIds?.length
		? fetchFunc<IParentPointChildrenIds, string[]>(
				GET_CHILDREN_OF_PARENT_IDS,
				cs => {
					const allChildPointsIdsOfSpecifiedParents = loopChildren(cs.data.store.accessPoints, [])

					// Adding parents to selection - since parents also can hold events
					// Removing point duplicates
					return uniq(allChildPointsIdsOfSpecifiedParents)
				},
				{ variables: { parentIds } }
		  )
		: (new Promise(() => []) as Promise<string[]>)

const loopChildren = (children: IPointNestedChildrenIds[], res: string[]): string[] => {
	const aaa = children?.flatMap(c => c.id)
	const its = children?.flatMap(c => c.children)
	if (its?.length) {
		return loopChildren(its, [...res, ...aaa])
	} else {
		return [...res, ...aaa]
	}
}

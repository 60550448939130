import { useMemo } from "react"
import { match } from "ts-pattern"
import { configName } from "../../../Configs/config"
import { IConfig, ISiteConfig } from "../../../Configs/types"
import { useModal } from "../../../Contexts"
import { DeletetionModal } from "../../../Modals/DeletetionModal/DeletetionModal"
import { ExtKeysAndPropsModal } from "../../../Modals/ExtKeysAndPropsModal/ExtKeysAndPropsModal"
import HistoryModal from "../../../Modals/HistoryModal/HistoryModal"
import { IdentitiesHandler } from "../../../Modals/IdentitiesHandlerModal/IdentitiesHandler"
import { EntityType } from "../../../Resources/types"
import { useGeneralConfig } from "../../../States/generalConfig"
import { useSiteConfig } from "../../../States/siteConfig"
import { IAuthJwt } from "../../../types"
import { DrawerButton, IFilterQuery } from "./DrawerButton"

export type IButtonGeneratorProps = {
	entity: EntityType
	buttons: IGeneratorButton[]
	data: {
		id: string
		[key: string]: any
	}
}

export type IGeneratorButton = {
	text: string
	color?: "primary" | "danger" | "warning"
	disabled?:
		| ((conditions: {
				configName: IConfig
				data: IButtonGeneratorProps["data"]
				jwt: IAuthJwt | null
				config: ISiteConfig[IButtonGeneratorProps["entity"]]
		  }) => boolean)
		| boolean
	title?: string
} & (
	| {
			type: "routerLink"
			function: "filter"
			filterQuery: (data: IButtonGeneratorProps["data"]) => IFilterQuery
			pathName: string
	  }
	| {
			type: "link" | "button"
			function:
				| "openIdentitiesOverview"
				| "openHistoryModal"
				| "openKeyAndPropertyModal"
				| "openTerminalKeyAndPropertyModal"
				| "deleteEntity"
	  }
)

export const ButtonGenerator = ({ entity, data, buttons }: IButtonGeneratorProps) => {
	// const buttons = useMemo(() => links({ entityType }), [entityType, links])

	const config = useSiteConfig().siteConfig[entity]
	const { showModal, hideModal } = useModal()
	const jwt = useGeneralConfig().authJwt

	const buttonFunction = useMemo(
		() => (fn: IGeneratorButton["function"]) =>
			match(fn)
				.with("filter", () => {})
				.with("openKeyAndPropertyModal", "openTerminalKeyAndPropertyModal", fn =>
					showModal(
						<ExtKeysAndPropsModal
							key={`keyEditorModal_${data.id}`}
							id={fn === "openKeyAndPropertyModal" ? data.id : data.terminal?.id}
							name={
								fn === "openKeyAndPropertyModal"
									? data.name || data.id
									: data.terminal?.name || data.terminal?.id
							}
							type={entity === "customers" ? "customer" : "point"}
							onCancel={hideModal}
						/>
					)
				)
				.with("openIdentitiesOverview", "openTerminalKeyAndPropertyModal", fn =>
					showModal(
						<IdentitiesHandler
							key={`keyEditorModal_${data.id}`}
							customerId={data.id}
							entity={data.name}
							// onClose={hideModal}
							refetchQuery={config.data.fetchById}
						/>
					)
				)
				.with(
					"openHistoryModal",
					() =>
						showModal(
							<HistoryModal
								key={`historyModal_${data.id}`}
								id={data.id}
								name={data.name || data.id}
								type={entity}
							/>
						) // TODO: reduce permited types
				) //TODO: add customer type
				.with("deleteEntity", () =>
					showModal(
						<DeletetionModal
							key={`deletionModal_${data.id}`}
							id={data.id}
							name={
								data.name ||
								data.externalKeys?.containerId ||
								data.externalKeys?.ImportID ||
								data.id
							}
							onCancel={hideModal}
							entity={entity}
						/>
					)
				)
				.exhaustive(),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data, entity, hideModal, showModal]
	)

	const buttonList = useMemo(
		() =>
			buttons.map((btn, k) => {
				if (
					btn.disabled && typeof btn.disabled === "function"
						? btn.disabled({
								configName: configName,
								jwt,
								data,
								config,
						  })
						: btn.disabled
				)
					return null
				return (
					match(btn)
						// .with({ disabled: (typeof btn.disabled === "function" ? btn.disabled(conditions) : btn.disabled) === true }, () => <></>)
						.with({ type: "routerLink" }, button => (
							<DrawerButton
								key={`drawerButton_${k}`}
								color={button.color}
								text={button.text}
								title={button.title}
								type={button.type}
								pathName={button.pathName}
								filterQuery={button.filterQuery(data)}
							/>
						))
						.with({ type: "button" }, { type: "link" }, button => (
							<DrawerButton
								key={`drawerButton_${k}`}
								color={button.color}
								text={button.text}
								title={button.title}
								type={button.type}
								onClick={() => buttonFunction(button.function)}
							/>
						))
						.exhaustive()
				)
			}),
		[buttonFunction, buttons, config, data, jwt]
	)

	return <>{buttonList.map(c => c)}</>
}

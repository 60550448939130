import { gql } from "@apollo/client/core"

export const default_fetchAll_operators = () => gql`
	query ACGetOperators {
		store {
			data: operators {
				id
				name
				properties {
					key
					value
				}
				externalKeys {
					key
					value
				}
			}
		}
	}
`

import { gql } from "apollo-boost"
import { get, orderBy } from "lodash"
import { useEffect, useRef, useState } from "react"

import { useTranslation } from "../Contexts"
import { Spinner } from "../UI"

import CustomerCRMOption, { customerCRMKeyValue } from "./CustomerCRMOption"
import CustomerGeoLocationNameOption, {
	customerGeoLocationNameValue,
} from "./CustomerGeoLocationNameOption"
import CustomerNameOption, { customerNameValue } from "./CustomerNameOption"
import IdentityIsoOption, { identityIsoValue } from "./IdentityIsoOption"
import PointOption, { pointNameValue } from "./PointOption"

import { useSiteConfig } from "../States/siteConfig"
import { fetchFunc } from "../Utils/api"
import "./index.css"

const ELASTICSEARCH = gql`
	query ACElasticSearch($value: String) {
		indices {
			customerName(value: $value) {
				values {
					value
					pk
					score
				}
			}
			pointName(value: $value) {
				values {
					value
					pk
					score
					point {
						id
						type
					}
				}
			}
			customerCRMKey(value: $value) {
				values {
					pk
					value
					score
				}
			}
			customerGeoLocationName(value: $value) {
				values {
					pk
					value
					score
				}
			}
			identityIso(value: $value) {
				values {
					pk
					value
					score
					ref: customer {
						customer {
							id
							name
						}
					}
				}
			}
		}
	}
`

function renderOption(option: any, index: number, exact?: boolean) {
	let view = null
	switch (option.type) {
		case "point":
			view = <PointOption {...option} />
			break
		case "customer-crm-key":
			view = <CustomerCRMOption {...option} />
			break
		case "customer-name":
			view = <CustomerNameOption {...option} />
			break
		case "customer-geolocation-name":
			view = <CustomerGeoLocationNameOption {...option} />
			break
		case "identity-iso":
			view = <IdentityIsoOption {...option} />
			break

		default:
			throw Error(`unknown option ${option.type}`)
	}

	return (
		<div key={index} className={`option${exact ? " exact" : ""}`}>
			{view}
		</div>
	)
}

const latest = {
	inputValue: "",
}

const ElasticSearchInput = () => {
	const { trans } = useTranslation()
	const [isLoading, setIsLoading] = useState(false)
	const [inputValue, setInputValue] = useState("")
	const [options, setOptions] = useState<any>(null)
	const containerEl = useRef<HTMLDivElement>(null)
	latest.inputValue = inputValue

	const searchablePoints = useSiteConfig().siteConfig.search.searchablePoints

	const onInputChange = (ev: any) => setInputValue(ev.target.value)

	const search = async (text: string) => {
		setIsLoading(true)

		const data = await fetchFunc<any, any>(ELASTICSEARCH, res => res.data, {
			variables: { value: text },
			errorPolicy: "all",
		})

		const indices = data.indices

		const isos = get(indices, "identityIso.values", []).map(identityIsoValue)
		const points = get(indices, "pointName.values", []).map(pointNameValue)
		const crms = get(indices, "customerCRMKey.values", []).map(customerCRMKeyValue)
		const names = get(indices, "customerName.values", []).map(customerNameValue)
		const geolocs = get(indices, "customerGeoLocationName.values", []).map(
			customerGeoLocationNameValue
		)

		const options = [...isos, ...points, ...crms, ...names, ...geolocs].filter(option => {
			if (option.type === "point" && !searchablePoints.includes(option.pointType)) {
				return false
			}
			return true
		})

		const sortedByScoreDescenting = orderBy(options, "score", "desc")

		setIsLoading(false)
		setOptions(sortedByScoreDescenting)
	}

	// Search with timeout
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (inputValue && inputValue?.length > 0) {
				setIsLoading(true)
				search(inputValue)
			}
		}, 500)
		return () => {
			clearTimeout(timeout)
			setIsLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue])

	const defocusHandler = (ev: any) => {
		console.log(ev)
		setTimeout(() => {
			setInputValue("")
			setOptions(null)
		}, 100)
	}

	return (
		<div ref={containerEl} style={{ width: "100%" }} onBlur={defocusHandler}>
			<label>
				<svg id="search-icon" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
						fill="#D3D3D3"
					/>
				</svg>
				<input
					type="text"
					placeholder={trans("search.placeholder")}
					value={inputValue}
					onChange={onInputChange}
				/>
			</label>
			<div className="results-container">
				{isLoading && (
					<div className="loading-container">
						<Spinner />
						<span>{`${trans("loading")}...`}</span>
					</div>
				)}
				{!isLoading && options?.length > 0 && (
					<div className="options-container">
						{options.map((v: any, i: number) => {
							return renderOption(v, i, v.name.toLowerCase() === inputValue.toLowerCase())
						})}
					</div>
				)}
				{!isLoading && options?.length === 0 && (
					<div className="noresults-container">{trans("noResults")}</div>
				)}
			</div>
		</div>
	)
}

export default ElasticSearchInput

import { ApolloClient, NormalizedCacheObject } from "@apollo/client/core"
import create from "zustand"

export type IUseApolloStore = {
	client: ApolloClient<NormalizedCacheObject> | null
	setClient: (_client: ApolloClient<NormalizedCacheObject>) => void
}

export const useApolloStore = create<IUseApolloStore>((set) => ({
	client: null,
	setClient: (_client) => set(() => ({ client: _client })),
}))

import { isEmpty, isEqual } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "../../Contexts/Translation"
import { useQueryParam } from "../../domHooks"
import { useSiteConfig } from "../../States/siteConfig"
import { AlertLine } from "../../UI/AlertLine/AlertLine"
import { getEvents } from "./api/events"
import { IEventsBrowserFilterParams, IEventsBrowserParamsArgs } from "./configurations/types/types"
import { dateConfigLogics } from "./functions/dates"
import { getFilteredTableData } from "./functions/filterTableFetch"
import Grid from "./Grid"
import "./index.css"
import { IEventModified } from "./types"

const EventGrid = () => {
	const { siteConfig } = useSiteConfig()
	const { trans } = useTranslation()
	const [queryFilters] = useQueryParam("filter", {})
	const hasNoFiltersSet = Object.values(queryFilters as object).every(prop => isEmpty(prop))
	const filters = queryFilters as IEventsBrowserFilterParams
	const filterValue = !Object.values(filters).every(
		v => v === null || v === undefined || isEmpty(v)
	)
	const [queryArgs] = useQueryParam("args", {})
	const args = queryArgs as IEventsBrowserParamsArgs
	const lastArgs = useRef<IEventsBrowserParamsArgs>()
	const lastFilters = useRef<IEventsBrowserFilterParams>()
	const [eventsData, setEventsData] = useState<IEventModified[]>()
	// Checking if browser params are changed - run if they are
	useEffect(() => {
		if (
			(!isEqual(args, lastArgs.current) && args?.time?.value?.length) ||
			!isEqual(filters, lastFilters.current)
		) {
			// Store last browser params for resetting when fetch fails
			const _lastArgs = { ...lastArgs.current }
			const _lastFilters = { ...lastFilters.current }

			// Set new browser params to last params states
			lastArgs.current = { ...args }
			// Removing empty filters and set as active filters
			lastFilters.current =
				(Object.entries(filters).reduce((prev, [key, value]) => {
					if (value && Array.isArray(value)) {
						return { ...prev, [key]: value.filter(f => f.value) }
					} else if (value) {
						return { ...prev, [key]: value }
					}
					return prev
				}, {} as IEventsBrowserFilterParams) as IEventsBrowserFilterParams) || {}

			// Restricting date range if
			const dates = dateConfigLogics(siteConfig.eventsDates, args.time?.value, hasNoFiltersSet)
			if (dates) {
				;(filterValue
					? getFilteredTableData(filters, siteConfig.events.filters(), dates)
					: getEvents(dates)
				)
					.then(events =>
						siteConfig.events.filterEventsFn
							? events?.filter(siteConfig.events.filterEventsFn)
							: events
					)
					.then(events => {
						setEventsData(events)
					})
					.catch(err => {
						// Reset last browser params
						lastArgs.current = { ..._lastArgs }
						lastFilters.current = { ..._lastFilters }
						console.error("tableData", err)
					})
			}
		}
	}, [args, filterValue, filters, hasNoFiltersSet, siteConfig.events, siteConfig.eventsDates])

	return (
		<>
			<div className="alertMessage">
				{hasNoFiltersSet ? (
					<AlertLine
						type="info"
						message={
							siteConfig.eventsDates.maxUnfilteredDays
								? `${trans("eventsUnfilteredDays", siteConfig.eventsDates.maxUnfilteredDays)}`
								: `${trans("eventsUnfilteredHours", siteConfig.eventsDates.maxUnfilteredHours)}`
						}
						show={siteConfig.eventsDates.filterEventsByTime}
					/>
				) : siteConfig.eventsDates.maxFilteredDays ? (
					<AlertLine
						type="info"
						message={trans("eventsFilteredDays", siteConfig.eventsDates.maxFilteredDays)}
						show={siteConfig.eventsDates.filterEventsByTime}
					/>
				) : (
					<></>
				)}
			</div>
			<Grid
				events={eventsData?.length ? eventsData : []}
				headers={siteConfig.events.headers}
				columnSizes={siteConfig.events.columnSizes || {}}
			/>
		</>
	)
}

export default EventGrid

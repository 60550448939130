import { gql } from "apollo-boost"

export const GET_EVENTS_BY_CUSTOMER_ID = gql`
	query ACGetEventsByCustomerId($start: DateTime!, $end: DateTime!, $customerIds: [String]!) {
		store {
			customers(ids: $customerIds) {
				id
				name
				externalKeys {
					key
					value
				}
				identities {
					identity {
						id
						externalKeys {
							key
							value
						}
					}
				}
				events(startTimeIso: $start, endTimeIso: $end) {
					id
					type
					timestamp
					result
					identityId
					weight: property(key: "weight")
					weightUnit: property(key: "weightUnit")
					fraction: property(key: "fraction")
					properties {
						key
						value
					}
					point {
						id
						point {
							id
							type
							name
							RFID: externalKey(key: "RFID_ISO")
							fraction: property(key: "fraction")
							productName: property(key: "productName")
							volume: property(key: "filledVolume")
							parent: ancestor(type: "ACCESS_PARENT") {
								id
								name
							}
							properties {
								key
								value
							}
							externalKeys {
								key
								value
							}
						}
					}
				}
			}
		}
	}
`

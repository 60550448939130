import { isEqual, uniqWith } from "lodash"
import { fetchFunc } from "../../../../Utils/api"
import { mergeValuesByFractionLabel, mergeValuesByLabel } from "../../../../Utils/selectOptions"
import {
	getIdentitiesGQL,
	GET_CUSTOMERS,
	GET_POINTS,
	GET_POINTS_CONTAINER_ID,
	GET_POINTS_RFID_ISO,
	GET_POINT_IDS,
	GET_POINT_PARENTS,
} from "./queries"
import {
	IFetchAllCustomers,
	IFetchAllIdentsEM,
	IFetchAllPointContainerIds,
	IFetchAllPointParents,
	IFetchAllPointRfidISOs,
	IFetchAllPoints,
	IFetchAllPointsIds,
} from "./types"

type ILabelValue = { label: string; value: string }
type ILabelValues = { label: string; value: string[] }

export const fetchAllCustomers = () =>
	fetchFunc<IFetchAllCustomers, ILabelValue[]>(GET_CUSTOMERS, cs =>
		cs.data.store.customers.map(c => ({ label: c.name, value: c.id }))
	)

export const fetchAllIdents = (identKey: string) => () =>
	fetchFunc<IFetchAllIdentsEM, ILabelValue[]>(getIdentitiesGQL(identKey), cs =>
		(
			cs.data.store.identities.filter(i => i.externalKey !== null) as {
				id: string
				externalKey: string
			}[]
		).map(i => ({ label: i.externalKey, value: i.id }))
	)

export const fetchAllPoints = () =>
	fetchFunc<IFetchAllPoints, ILabelValue[]>(GET_POINTS, (ps: { data: IFetchAllPoints }) =>
		ps.data.store.accessPoints.map(p => ({ label: p.name, value: p.id }))
	)

export const fetchAllPointIdsWithTransName = (trans: any) =>
	fetchFunc<IFetchAllPointsIds, ILabelValues[]>(GET_POINT_IDS, ps =>
		mergeValuesByFractionLabel(ps.data.store.accessPoints, trans)
	)

export const fetchAllPointUniqueNames = () =>
	fetchFunc<IFetchAllPoints, ILabelValue[]>(GET_POINTS, ps =>
		mergeValuesByLabel(
			ps.data.store.accessPoints.map(p => ({
				label: p.name,
				value: p.id,
			}))
		)
	)

export const fetchAllPointsParents = () =>
	fetchFunc<IFetchAllPointParents, ILabelValue[]>(GET_POINT_PARENTS, ps => {
		const result = (
			ps.data.store.accessPoints.filter(p => p.parent && p.parent.id) as {
				parent: {
					id: string
					name: string
				}
			}[]
		).map(p => ({ label: p.parent.name, value: p.parent.id }))
		return uniqWith(result, isEqual)
	})

export const fetchAllPointsRfidISOs = () =>
	fetchFunc<IFetchAllPointRfidISOs, ILabelValue[]>(GET_POINTS_RFID_ISO, ps => {
		const result = (
			ps.data.store.accessPoints.filter(p => p.id && p.RFID) as {
				id: string | null
				RFID: string | null
			}[]
		).map(p => ({ label: p.RFID!, value: p.id! }))
		return uniqWith(result, isEqual)
	})

export const fetchAllPointsContainerIds = () =>
	fetchFunc<IFetchAllPointContainerIds, ILabelValue[]>(GET_POINTS_CONTAINER_ID, ps => {
		const result = (
			ps.data.store.accessPoints.filter(p => p.id && p.containerId) as {
				id: string | null
				containerId: string
			}[]
		).map(p => ({ label: p.containerId!, value: p.id! }))
		return uniqWith(result, isEqual)
	})

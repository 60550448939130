import { useSiteConfig } from "../States/siteConfig"
import "./styles/dashboard.css"

export const Dashboard = () => {
	const { dashboard } = useSiteConfig().siteConfig

	if (!dashboard) return Message("Coming soon!")
	return dashboard
}

export default Dashboard

const Message = (text: string) => {
	const style = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
	}

	return (
		<div style={style}>
			<h1>{text}</h1>
		</div>
	)
}

export type IDashboardConfig = JSX.Element
